import React, { useState, useEffect, useContext, useRef } from "react"
import { useGetCall } from "utils/api"
import { toast } from "react-toastify"
import { LoadingContext } from "helpers/LoadingContext"
import View from "components/home/Home.view"
import moment from "moment"

function Home({ onAddTab }) {

	const formikRef = useRef()

	const [user, setUser] = useState({})
	const {loading, setLoading} = useContext(LoadingContext)
	const [refreshValue, setRefreshValue] = useState(0)
	const [lastUpdate, setLastUpdate] = useState(moment())
	const [globalLoading, setGlobalLoading] = useState({
		aboutToday: false,
		alerts: false,
		attendanceChart: false,
		attendanceRanking: false,
		entriesExits: false,
		incidencesChart: false,
		map: false,
		summary: false,
		workedHours: false,
	})
	const [filters, setFilters] = useState(sessionStorage.getItem("filters") ? JSON.parse(sessionStorage.getItem("filters")) : {})
	
	const [payrollPeriods, setPayrollPeriods] = useState([])
	const [payrolls, setPayrolls] = useState([])
	const [offices, setOffices] = useState([])
	const [jobs, setJobs] = useState([])
	const [departments, setDepartments] = useState([])
	const [shifts, setShifts] = useState([])

	const [callPayrolls, reqPayrolls] = useGetCall("/payrolls", {
		onCompleted: (response) => {
			setPayrolls(response.data)
		},
		onError: (error) => {
			if (error.status === 403) {
				if (!toast.isActive("toast-read-timetables-unauthorized")) {
					toast.error("Error, no tienes los permisos para consultar los tipos de nómina", { toastId: "toast-read-timetables-unauthorized" })
				}
			} else {
				if (!toast.isActive("toast-timetables-error")) {
					toast.error("Error al obtener los tipos de nómina", { toastId: "toast-timetables-error" })
				}
			}
		}
	})

	const [callPayrollPeriods, reqPayrollPeriods] = useGetCall("/payrolls/" + filters.payroll_id + "/periods", {
		onCompleted: (response) => {
			setPayrollPeriods(response.data)
		},
		onError: (error) => {
			if (error.status === 403) {
				if (!toast.isActive("toast-read-timetables-unauthorized")) {
					toast.error("Error, no tienes los permisos para consultar los tipos de nómina", { toastId: "toast-read-timetables-unauthorized" })
				}
			} else {
				if (!toast.isActive("toast-timetables-error")) {
					toast.error("Error al obtener los tipos de nómina", { toastId: "toast-timetables-error" })
				}
			}
		}
	})

	const [callOffices, reqOffices] = useGetCall("/offices", {
		onCompleted: (response) => {
			setOffices(response.data)
		},
		onError: (error) => {
			if (error.status === 403) {
				if (!toast.isActive("toast-read-timetables-unauthorized")) {
					toast.error("Error, no tienes los permisos para consultar las sucursales", { toastId: "toast-read-timetables-unauthorized" })
				}
			} else {
				if (!toast.isActive("toast-timetables-error")) {
					toast.error("Error al obtener las sucursales", { toastId: "toast-timetables-error" })
				}
			}
		}
	})

	const [callJobs, reqJobs] = useGetCall("/jobs", {
		onCompleted: (response) => {
			setJobs(response.data)
		},
		onError: (error) => {
			if (error.status === 403) {
				if (!toast.isActive("toast-read-timetables-unauthorized")) {
					toast.error("Error, no tienes los permisos para consultar los puestos", { toastId: "toast-read-timetables-unauthorized" })
				}
			} else {
				if (!toast.isActive("toast-timetables-error")) {
					toast.error("Error al obtener los puestos", { toastId: "toast-timetables-error" })
				}
			}
		}
	})

	const [callDepartments, reqDepartments] = useGetCall("/departments", {
		onCompleted: (response) => {
			setDepartments(response.data)
		},
		onError: (error) => {
			if (error.status === 403) {
				if (!toast.isActive("toast-read-timetables-unauthorized")) {
					toast.error("Error, no tienes los permisos para consultar los departamentos", { toastId: "toast-read-timetables-unauthorized" })
				}
			} else {
				if (!toast.isActive("toast-timetables-error")) {
					toast.error("Error al obtener los departamentos", { toastId: "toast-timetables-error" })
				}
			}
		}
	})

	const [callShifts, reqShifts] = useGetCall("/shifts", {
		onCompleted: (response) => {
			setShifts(response.data)
		},
		onError: (error) => {
			if (error.status === 403) {
				if (!toast.isActive("toast-read-timetables-unauthorized")) {
					toast.error("Error, no tienes los permisos para consultar los turnos", { toastId: "toast-read-timetables-unauthorized" })
				}
			} else {
				if (!toast.isActive("toast-timetables-error")) {
					toast.error("Error al obtener los turnos", { toastId: "toast-timetables-error" })
				}
			}
		}
	})

	const handleRefresh = () => {
		setGlobalLoading(Object.keys(globalLoading).reduce((acc, key) => ({ ...acc, [key]: true }), {}))
		setRefreshValue(refreshValue + 1)
		setLastUpdate(moment())
	}

	useEffect(() => {
		const isGlobalLoading = Object.values(globalLoading).some((value) => value)
		setLoading(isGlobalLoading)
	}, [globalLoading])

	useEffect(() => {
		callPayrolls()
		callOffices()
		callJobs()
		callDepartments()
		callShifts()
		if(filters.payroll_id) callPayrollPeriods()
		sessionStorage.setItem("filters", JSON.stringify(filters))
	}, [filters])

	return (
		<View
			payrollPeriods={payrollPeriods}
			payrolls={payrolls}
			offices={offices}
			jobs={jobs}
			departments={departments}
			shifts={shifts}
			user={user}
			loading={loading}
			filters={filters}
			formikRef={formikRef}
			onAddTab={onAddTab}
			setFilters={setFilters}
			lastUpdate={lastUpdate}
			refreshValue={refreshValue}
			handleRefresh={handleRefresh}
			globalLoading={globalLoading}
			setGlobalLoading={setGlobalLoading}
		/>
	)

}

export default Home