import React from 'react';
import { Formik, Form, Field } from 'formik';

function FormView({ 
    formikRef = () => { }, 
    edit = false, 
    payrolls = [], 
    contracts = [], 
    table = {}, 
    onSubmit, 
    error, 
    setError, 
    onCancel,
    onAddRow,
    onRemoveRow,
    onEditRow,
    rows = []
}) {
    return (
        <div className='container mt-3'>
            <Formik
                innerRef={formikRef}
                initialValues={table}
                onSubmit={onSubmit}>
                {({ values, setFieldValues, resetForm }) =>
                    <Form>

                        <div className='row'>
                            <div className='col-md-6 mt-3'>

                                {/* Key */}
                                <div class="mb-3">
                                    <div class="form-group">
                                        <label class="input">
                                            <Field type="text" name="key" class="input__field form-control" placeholder=" " required value={values.key || ''} />
                                            <span class="input__label"> Clave <span className='text-danger fw-bold'>*</span>
                                            </span>
                                        </label>
                                    </div>
                                </div>

                                {/* Description */}
                                <div class="mb-3">
                                    <div class="form-group">
                                        <label class="input">
                                            <Field type="text" name="description" class="form-control input__field" placeholder=" " required value={values.description || ''} />
                                            <span class="input__label"> Descripción <span className='text-danger fw-bold'>*</span>
                                            </span>
                                        </label>
                                    </div>
                                </div>

                                {/* Payroll */}
                                <div class="mb-3">
                                    <div className='form-group'>
                                        <label class="input">
                                            <Field as="select" name="payroll_id" class="input__field form-control" required value={values.payroll_id || ''}>
                                                <option value="">Seleccione una opción</option>
                                                {
                                                    payrolls.map((payroll) =>
                                                        <option value={payroll.id}>{payroll.description}</option>
                                                    )
                                                }
                                            </Field>
                                            <span class="input__label">
                                                Tipo de nómina
                                                <span className='text-danger fw-bold'>*</span>
                                            </span>
                                        </label>
                                    </div>
                                </div>

                                {/* Contract */}
                                <div class="mb-3">
                                    <div className='form-group'>
                                        <label class="input">
                                            <Field as="select" name="contract_id" class="input__field form-control" value={values.contract_id || ''}>
                                                <option value="">Seleccione una opción</option>
                                                {
                                                    contracts.map((contract) =>
                                                        <option value={contract.id}>{contract.description}</option>
                                                    )
                                                }
                                            </Field>
                                            <span class="input__label">
                                                Tipo de contrato
                                            </span>
                                        </label>
                                    </div>
                                </div>

                            </div>
                            <div className='col-md-6 pt-0 mt-0'>
                                <div class="table-responsive datatable-custom position-relative" >
                                    <div class="dataTables_wrapper no-footer pl-3 pr-3" style={{maxHeight: "60vh"}}>
                                        <table class="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
                                            <thead>
                                                <tr role="row">
                                                    <th>Años inicio</th>
                                                    <th>Años fin</th>
                                                    <th>
                                                        { sessionStorage.getItem("hour_holidays") == "true" ? "Horas" : "Días" }
                                                    </th>
                                                    <th>Acciones</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    rows.map((row, idx) => (
                                                        <tr key={idx} role="row">
                                                            <td>
                                                                <input 
                                                                    type="number" 
                                                                    className="form-control" 
                                                                    value={row.initial_year} 
                                                                    onChange={(e) => onEditRow(idx, 'initial_year', e.target.value)} 
                                                                />
                                                            </td>
                                                            <td>
                                                                <input 
                                                                    type="number" 
                                                                    className="form-control" 
                                                                    value={row.final_year} 
                                                                    onChange={(e) => onEditRow(idx, 'final_year', e.target.value)} 
                                                                />
                                                            </td>
                                                            <td>
                                                                <input 
                                                                    type="number" 
                                                                    className="form-control" 
                                                                    value={row.amount} 
                                                                    onChange={(e) => onEditRow(idx, 'amount', e.target.value)} 
                                                                />
                                                            </td>
                                                            <td>
                                                                <button type="button" className="btn btn-danger" onClick={() => onRemoveRow(idx)}>
                                                                    <i class="bi bi-trash mx-auto"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div>
                                    <button type="button" className="btn btn-primary" onClick={onAddRow}>
                                        <i class="bi bi-plus" style={{marginRight: "7px"}}></i>
                                        Agregar fila
                                    </button>
                                </div>
                            </div>
                        </div>

                        {error ? (
                            <div class="alert alert-danger alert-dismissible fade show d-flex justify-content-between" role="alert">
                                <p className='text-justify' style={{ width: "90%" }}>
                                    {error}
                                </p>
                                <button type="button" class="close" data-dismiss="alert" aria-label="Close" onClick={() => setError(null)}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        ) : null}

                        <div class="pt-3">
                            <button type="submit" class="btn btn-primary">
                                <i class="bi bi-plus" style={{ marginRight: "10px" }}></i>
                                {edit ? "Editar tabla" : "Guardar tabla"}
                            </button>
                            <button type="button" class="btn btn-danger" style={{ marginLeft: "20px" }} onClick={onCancel}>
                                <i class="bi bi-x" style={{ marginRight: "10px" }}></i>
                                Cancelar
                            </button>
                        </div>

                    </Form>
                }</Formik>

        </div >
    )
}

export default FormView;