import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { showResponseMessage } from "utils/message";
import { useDelete, useGetCall, fetchPost } from "utils/api";
import Loading from "helpers/Loading";
import { LoadingContext } from "helpers/LoadingContext";
import View from "components/crh/offices/List.view";

function List({
	addEditModal,
	refresh,
	search
}) {

	const { loading, setLoading } = useContext(LoadingContext);

	const [offices, setOffices] = useState([]);
	const [refreshValue, setRefreshValue] = useState(null);
	const [refreshDelete, setRefreshDelete] = useState(null);

	if (refresh !== refreshValue) {
		setRefreshValue(refresh);
	}

	const [callOffice, reqOffices] = useGetCall("/offices", {
		onCompleted: (response) => {
			setLoading(false);
			setOffices(response.data)
		},
		onError: (error) => {
			setLoading(false);
			showResponseMessage(error.status, "offices", "sucursales", "read")
		}
	})

	const [callDelete, reqDelete] = useDelete("/offices", {
		onCompleted: () => {
			if (!toast.isActive("office-deleted"))
				toast.success("Sucursal eliminada", { toastId: "office-deleted" });

			setRefreshDelete(Math.random());
		},
		onError: (error) => {
			console.error(error)
			showResponseMessage(error.status, "offices", "sucursales", "delete")
		}
	});

	useEffect(() => {
		setLoading(true);
		callOffice({ search });
	}, [refreshValue, refreshDelete, search]);

	const onDelete = (id) => {
		fetchPost("/offices/check_permission", { type: "delete" }).then((response) => {
			if (response.data) {
				const data = response.data;
				if (data.isAllowed === true) {
					if (window.confirm("¿Estás seguro de eliminar la sucursal?")) {
						callDelete({ id: id });
					}
				} else {
					if (!toast.isActive("toast-delete-office-unauthorized")) {
						toast.error("Error, no tienes los permisos para eliminar sucursales", { toastId: "toast-delete-office-unauthorized" })
					}
				}
			}
		})
	}

	const onEdit = (id) => {
		addEditModal(id);
	}

	if (loading) return <Loading />

	return (
		<View offices={offices} onDelete={onDelete} onEdit={onEdit} />
	);
}

export default List;