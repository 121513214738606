import React, { useState, useRef, useEffect } from "react";
import Form from "components/crh/employeeDevices/Form";
import View from "components/crh/employeeDevices/EmployeeDevices.view";
import { fetchPost } from "utils/api";
import { toast } from "react-toastify";
import { downloadExcel } from "utils/files";
import { getUser } from "api/users";

function EmployeeDevices({ theme }) {

  const id = sessionStorage.getItem("id");
  const employeeDeviceRef = useRef(null);
  const [user, setUser] = useState({});
  const countRef = useRef(null);

  const [isVisible, setIsVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [reloadEmployee, setReloadEmployee] = useState(false);
  const [reloadEmployees, setReloadEmployees] = useState(false);

  const [search, setSearch] = useState("");

  const addEmployeeDeviceModal = (id) => {

    fetchPost("/device_employees/check_permission", { table: "EMPLOYEE_DEVICE", type: "edit" }).then((res) => {
      if (res.data) {
        if (res.data.isAllowed === true) {

          setIsVisible(true);
          setModalTitle("Dispositivos");
          setModalContent(
            <Form
              id={id}
              refresh={Math.random()}
              setIsVisible={setIsVisible}
              reloadEmployee={reloadEmployee}
              setReloadEmployee={setReloadEmployee}
              setReloadEmployees={setReloadEmployees}
              theme={theme}
            />
          );

        } else {
          if (!toast.isActive("toast-edit-employees-devices-unauthorized")) {
            toast.error("Error, no tienes los permisos para editar los empleados a lectores", { toastId: "toast-edit-employees-devices-unauthorized" })
          }
        }
      }
    })

  }

  const onChangeSearch = (event) => {
    if (event.target.value === '') {
      setSearch(event.target.value);
    }
  }

  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      setSearch(event.target.value);
    }
  }

  const onDownloadXlsx = async () => {

    fetchPost("/device_employees/check_permission", { table: "EMPLOYEE_DEVICE", type: "read" }).then(async (response) => {
      if (response.data) {
        if (response.data.isAllowed === true) {
          const cells = await employeeDeviceRef.current.generateCells();
          downloadExcel({
            user,
            cells: cells,
            report: "empleados_lectores",
            date: false
          })
        } else {
          if (!toast.isActive("toast-excel-employees-devices-unauthorized")) {
            toast.error("Error, no tienes los permisos para descargar el excel de los empleados a lectores", { toastId: "toast-excel-employees-devices-unauthorized" })
          }
        }
      }
    })
  }

  useEffect(() => {
    getUser(id).then((response) => {
      setUser(response.data)
    });
  }, [id]);

  return (
    <View
      countRef={countRef}
      employeeDeviceRef={employeeDeviceRef}
      onDownloadXlsx={onDownloadXlsx}
      search={search}
      onChangeSearch={onChangeSearch}
      handleKeyUp={handleKeyUp}
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      modalTitle={modalTitle}
      modalContent={modalContent}
      addEmployeeDeviceModal={addEmployeeDeviceModal}
      reloadEmployees={reloadEmployees}
      setReloadEmployees={setReloadEmployees}
      theme={theme}
    />
  );

}

export default EmployeeDevices;