import { API_URL } from "settings";
import { getUrlencoded } from "utils/api";

function getDevice(id) {

    const url = `${API_URL}/devices/${id}`;
    const params = {
        method: "GET",
        headers: {
            "content-Type": "application/json",
            "x-auth-token": sessionStorage.getItem("token"),
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .catch((err) => {
            console.log(err);
            return null;
        });

}

function getDevices(query = {}) {

    const url = `${API_URL}/devices?${getUrlencoded(query)}`;
    const params = {
        method: "GET",
        headers: {
            "content-Type": "application/x-www-form-urlencoded",
            "x-auth-token": sessionStorage.getItem("token"),
        }
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .catch((err) => {
            console.log(err);
            return null;
        });
        
}

function testConnection(serial_number) {
    
    const url = `${API_URL}/devices/connectionInfo?serial_number=${serial_number}`;
    const params = {
        method: "GET",
        headers: {
            "content-Type": "application/json",
            "x-auth-token": sessionStorage.getItem("token"),
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .catch((err) => {
            console.log(err);
            return null;
        });
    
}

export { getDevice, getDevices, testConnection };