import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { getUser } from "api/users";
import { usePut, usePost, useGetCall, fetchPost } from "utils/api";
import Loading from "helpers/Loading";
import { SERVER_URL } from "settings";
import { showResponseMessage } from "utils/message"
import { LoadingContext } from "helpers/LoadingContext";
import View from "components/crh/incidencesAuth/List.view";
import { getLocaleUTC } from "utils/date";

function List({
	refresh,
	filters,
	addModal,
	showEditModal
}) {

	const { loading, setLoading } = useContext(LoadingContext);

	const [incidencesRequests, setIncidencesRequests] = useState([]);
	const [refreshValue, setRefreshValue] = useState(null);
	const [refreshUpdate, setRefreshUpdate] = useState(null);
	const [user, setUser] = useState({});
	const [selected, setSelected] = useState([]);
	const id = sessionStorage.getItem("id");

	if (refresh !== refreshValue) {
		setRefreshValue(refresh);
	}

	const [callIncidenceRequests, reqIncidenceRequests] = useGetCall("/incidences_requests", {
		onCompleted: (response) => {
			setLoading(false);
			setIncidencesRequests(response.data)
		},
		onError: (error) => {
			setLoading(false);
			showResponseMessage(error.status, "incidence_requests", "solicitudes de incidencias", "read");
		}
	})

	const [callAuth, reqAuth] = usePut("/incidences_requests/auth", {
		onCompleted: () => {

			if (!toast.isActive("incidence-request-updated"))
				toast.success("Inicidencia autorizada correctamente", { toastId: "incidence-request-deleted" });

			setRefreshUpdate(Math.random())

		},
		onError: (error) => {
			console.error(error)
			if (error.status === 401 && !toast.isActive("incidence-request-holidays-error"))
				toast.error("No se puede autorizar la incidencia, el empleado no cuenta con suficientes vacaciones", { toastId: "incidence-request-holidays-error" });
			else if (error.status === 403 && !toast.isActive("incidence-request-edit-error"))
				toast.error("Error, no tienes los permisos para autorizar solicitudes de incidencias", { toastId: "incidence-request-edit-error" });
			else if (!toast.isActive("incidence-request-auth-error"))
				toast.error("Error al autorizar la inicidencia", { toastId: "incidence-request-auth-error" });

		}
	})

	const [callAuthGroup, reqAuthGroup] = usePost("/incidences_requests/auth", {
		onCompleted: () => {
			if (!toast.isActive("incidences-requests-updated"))
				toast.success("Inicidencias autorizadas correctamente", { toastId: "incidences-requests-deleted" });

			setRefreshUpdate(Math.random())
		},
		onError: (error) => {
			console.error(error)
			if (error.status === 401 && !toast.isActive("incidence-request-holidays-error"))
				toast.error("No se puede autorizar la incidencia, el empleado no cuenta con suficientes días de vacaciones", { toastId: "incidence-request-holidays-error" });
			else if (error.status === 403 && !toast.isActive("incidence-request-edit-error"))
				toast.error("Error, no tienes los permisos para autorizar solicitudes de incidencias", { toastId: "incidence-request-edit-error" });
			else if (!toast.isActive("incidence-request-auth-error"))
				toast.error("Error al autorizar la inicidencia", { toastId: "incidence-request-auth-error" });
		}
	})

	const [callCancel, reqCancel] = usePut("/incidences_requests/cancel", {
		onCompleted: () => {
			if (!toast.isActive("incidences-requests-cancel"))
				toast.success("Solicitud de incidencia cancelada correctamente", { toastId: "incidences-requests-cancel" });

			setRefreshUpdate(Math.random())
		},
		onError: (error) => {
			console.error(error)
			if (error.status === 403 && !toast.isActive("incidence-request-cancel-error"))
				toast.error("Error, no tienes los permisos para cancelar solicitudes de incidencias", { toastId: "incidence-request-cancel-error" });
			else if (!toast.isActive("incidence-request-cancel-error"))
				toast.error("Error al cancelar la inicidencia", { toastId: "incidence-request-cancel-error" });

		}
	})

	const [callGenerateReport, reqGenerateReport] = usePost('/incidences_requests/report', {
		onCompleted: ({ data }) => {

			const path = "http://34.206.99.108:8000/" + data.path;

			const link = document.createElement('a');
			link.href = path;
			link.target = '_blank';
			document.body.appendChild(link);
			link.click();

			// fetch(path, {
			// 	method: 'GET',
			// 	headers: {
			// 		'Content-Type': 'application/pdf',
			// 	},
			// })
			// 	.then(response => response.blob())
			// 	.then(blob => {
			// 		const url = window.URL.createObjectURL(new Blob([blob]));

			// 		const link = document.createElement('a');
			// 		link.href = url;
			// 		link.download = "Reporte de solicitud de incidencia.pdf";

			// 		document.body.appendChild(link);

			// 		link.click();

			// 		link.parentNode.removeChild(link);
			// 	}).catch(error => {
			// 		console.log(error);
			// 	});

		},
		onError: (error) => {
			console.error(error)
			if (!toast.isActive("incidence-request-report-error"))
				toast.error("Error al crear el reporte de la solicitud de incidencia, por favor intente de nuevo mas tarde.", { toastId: "incidence-request-report-error" });
		}
	})

	const onSelectCheckbox = (id) => {

		const index = selected.findIndex((item) => item === id);
		const newSelected = [...selected];

		if (index !== -1) {
			newSelected.splice(index, 1);
		} else {
			newSelected.push(id)
		}

		setSelected(newSelected);

	}

	useEffect(() => {
		setLoading(true);
		callIncidenceRequests(filters);
		getUser(id).then((response) => {
			if (response && response.data) {
				const user = response.data;
				setUser(user)
			}
		})
	}, [refreshValue, refreshUpdate, id, filters]);

	const onAuth = (id) => callAuth({ id, date: getLocaleUTC() })
	const onAuthGroup = () => callAuthGroup({ data: selected, date: getLocaleUTC() })

	const onCancel = (id) => {
		fetchPost("/permissions/check", { table: "AUTH_INCIDENCE_REQUESTS", permission: "edit" }).then((response) => {
			if (response.data) {
				const data = response.data;
				if (data.isAllowed === true) {
					if (window.confirm("¿Está seguro que desea cancelar la solicitud?")) {
						callCancel({ id })
					}
				} else {
					if (!toast.isActive("toast-edit-incidences-unauthorized")) {
						toast.error("Error, no tienes los permisos para cancelar colicitudes de incidencias", { toastId: "toast-edit-incidences-unauthorized" })
					}
				}
			}
		})
	}

	const onEdit = (id) => {
		showEditModal(id);
	}

	const onDownloadReport = (id) => {
		callGenerateReport({
			id: id,
			offset: new Date().getTimezoneOffset()
		})
	}

	if (loading) return <Loading />

	return (
		<View
			addModal={addModal}
			selected={selected}
			setSelected={setSelected}
			incidencesRequests={incidencesRequests}
			user={user}
			onAuth={onAuth}
			onEdit={onEdit}
			onCancel={onCancel}
			onAuthGroup={onAuthGroup}
			onDownloadReport={onDownloadReport}
			onSelectCheckbox={onSelectCheckbox}
		/>
	);
}

export default List;