import React, { useState, useRef } from "react";
import { usePost } from "utils/api";
import { toast } from "react-toastify";
import { downloadFile } from "utils/files";
import View from "components/crh/departments/BulkLoad.view";

function BulkLoad({ title, isVisible, setIsVisible }) {

    const [csvData, setCsvData] = useState([]);
    const [validations, setValidations] = useState([]);
    const inputFileRef = useRef(null);

    const [callValidate, reqValidate] = usePost("/departments/bulk_validate", {
        onCompleted: ({ data }) => {
            setValidations(data.errors);
        },
        onError: (err) => {
            console.error(err)
            if (err.status === 400) {
                if (!toast.isActive("toast-upload-error-400")) {
                    toast.error("Campos obligatorios requeridos", { toastId: "toast-upload-error-400" });
                }
            } else if (err.status === 403) {
                if (!toast.isActive("toast-bulk-department-unauthorized")) {
                    toast.error("Error, no tienes los permisos para hacer carga masiva de departamentos", { toastId: "toast-bulk-department-unauthorized" })
                }
            } else {
                if (!toast.isActive("toast-upload-error")) {
                    toast.error("El archivo se encuentra vacío, por favor rellene datos", { toastId: "toast-upload-error" });
                }
            }
        }
    });

    const [callSave, reqSave] = usePost("/departments/bulk", {
        onCompleted: () => {

            if (!toast.isActive("toast-save-departments"));
            toast.success("Departamentos cargados correctamente", { toastId: "toast-save-departments" });

            setIsVisible(false);
            inputFileRef.current.value = "";
            setCsvData([]);
            setValidations([]);

        },
        onError: (err) => {
            console.error(err)
            if (err.status === 400) {
                if (!toast.isActive("toast-upload-error-400")) {
                    toast.error("Campos obligatorios requeridos", { toastId: "toast-upload-error-400" });
                }
            } else if (err.status === 403) {
                if (!toast.isActive("toast-bulk-department-unauthorized")) {
                    toast.error("Error, no tienes los permisos para hacer carga masiva de departamentos", { toastId: "toast-bulk-department-unauthorized" })
                }
            } else {
                if (!toast.isActive("toast-upload-error")) {
                    toast.error("El archivo se encuentra vacío, por favor rellene datos", { toastId: "toast-upload-error" });
                }
            }
        }
    });

    const onCancel = () => {
        inputFileRef.current.value = "";
        setCsvData([]);
        setValidations([]);
        setIsVisible(false);
    }

    const onReset = () => {
        inputFileRef.current.value = "";
        setCsvData([]);
        setValidations([]);
    }

    const onDownloadExampleFile = () => {
        const exampleFile = `Clave, Descripcion, Abreviacion`;
        downloadFile({ data: exampleFile, filename: "template_departments.csv", type: ".csv" });
    }

    const onLoadFile = (data) => {
        if (data.length === 1) {
            callValidate({
                rows: data[0].rows,
                headers: data[0].headers
            })
            setCsvData(data[0])
        };
    }

    const onSubmit = () => {
        let overwrite = false;
        if (window.confirm("¿Desea que los elementos repetidos en el sistema se sobrescriban?")) {
            overwrite = true;
        }
        callSave({
            rows: csvData.rows,
            headers: csvData.headers,
            overwrite: overwrite
        })
    }


    return (
        <View
            onDownloadExampleFile={onDownloadExampleFile}
            inputFileRef={inputFileRef}
            onLoadFile={onLoadFile}
            data={csvData}
            validations={validations}
            onSubmit={onSubmit}
            onCancel={onCancel}
            onReset={onReset}
        />
    );
}

export default BulkLoad;