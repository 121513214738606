import React from "react";
import { toast } from 'react-toastify';
import { usePost } from 'utils/api';
import { IS_TEST } from "settings";
import View from "components/auth/Login.view";


function Login() {

	const [callLogin, reqLogin] = usePost("/auth/login", {
		onCompleted: ({ data }) => {

			if(!toast.isActive("login-success"))
				toast.success("Login correcto.", { toastId: "login-success" });
			
			sessionStorage.setItem("id", data.id);
			sessionStorage.setItem("token", data.token);
			sessionStorage.setItem("type", data.type);
			sessionStorage.setItem("hour_holidays", data.hour_holidays);
			
			if (data.type == "SUPERADMIN") {
				window.location.href = (IS_TEST ? "/test" : "") + "/admin";
			} else {
				window.location.href = (IS_TEST ? "/test" : "") + "/crh";
			}	
		},
		onError: (error) => {

			console.error(error);

			switch (error.status) {
				case 401:
					if(!toast.isActive("login-inactive"))
						toast.error("El entorno se encuentra deshabilitado", { toastId: "login-inactive" });

					break;

				default:
					if(!toast.isActive("login-error"))
						toast.error("Usuario o contraseña incorrectos.", { toastId: "login-error" });

					break;
			}

		},
		includeAuth: false
	});

	const onSubmit = (values) => {

		const user = values.user;
		const client = values.client;
		const password = values.password;

		if (!user || !password || !client) {
			toast.error("Todos los campos son obligatorios.");
			return;
		}

		callLogin({
			username: user,
			password: password,
			client: client,
			type: "ADMIN" 
		})

	}

	return <View onSubmit={onSubmit} superAdmin={false} />
}

export default Login;