import React, { useState, useEffect, useRef } from "react"
import { usePost, useGetCall } from "utils/api"
import { toast } from "react-toastify"
import { showResponseMessage } from "utils/message";
import View from "components/crh/holidaysTables/Form.view"

function FormSave({ setIsVisible, refresh }) {

    const fileInputRef = useRef()
    const formikRef = useRef()
    const [rows, setRows] = useState([])
    const [contracts, setContracts] = useState([])
    const [payrolls, setPayrolls] = useState([])
    const [refreshValue, setRefreshValue] = useState(null)

    if (refresh !== refreshValue) {
        setRefreshValue(refresh)
    }

    const [callContracts, reqContracts] = useGetCall('/contracts', {
        onCompleted: (response) => {
            setContracts(response.data);
        }, onError: (error) => {
            showResponseMessage(error.status, "contracts", "contratos", "read")
        }
    })

    const [callPayrolls, reqPayrolls] = useGetCall('/payrolls', {
        onCompleted: (response) => {
            setPayrolls(response.data);
        }, onError: (error) => {
            showResponseMessage(error.status, "payrolls", "nóminas", "read")
        }
    })

    const [callSave, reqSave] = usePost("/holiday_tables", {
        onCompleted: () => {
            if (!toast.isActive("toast-save-table"))
                toast.success("Tabla creada correctamente", { toastId: "toast-save-table" })
            setIsVisible(false)
            formikRef.current.setValues({})
        },
        onError: (err) => {
            console.error(err)
            showResponseMessage(err.status, "holiday_tables", "tablas de vacaciones", "create");
        }
    })

    const onAddRow = () => {
        setRows([...rows, {
            initial_year: 0,
            final_year: 0,
            amount: 0
        }])
    }

    const onRemoveRow = (idx) => {
        setRows(rows.filter((row, index) => index !== idx))
    }

    const onEditRow = (idx, field, value) => {
        const newRows = [...rows]
        newRows[idx][field] = value
        setRows(newRows)
    }

    const onSubmit = (values, { resetForm }) => {
        callSave({
            ...values,
            rows
        })
    }

    const onCancel = () => {
        setIsVisible(false)
        formikRef.current.setValues({})
    }

    useEffect(() => {

        callContracts();
        callPayrolls();

    }, [refreshValue])

    return (
        <View
            rows={rows}
            onAddRow={onAddRow}
            onRemoveRow={onRemoveRow}
            onEditRow={onEditRow}
            fileInputRef={fileInputRef}
            formikRef={formikRef}
            onSubmit={onSubmit}
            onCancel={onCancel}
            contracts={contracts}
            payrolls={payrolls}
            setIsVisible={setIsVisible}
        />
    )

}

export default FormSave