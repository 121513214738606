import React from "react";
import { Field, Formik, Form } from "formik";
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
const format = 'HH:mm';

function ScheduleZoomView({ time, formikRef, onSubmit, onCancel, handleOnChangeTime }) {

    return (
        <div className='container mt-3'>
            <Formik
                innerRef={formikRef}
                initialValues={{}}
                onSubmit={onSubmit}>
                {({ values, setFieldValues }) =>
                    <Form>

                        {/* Email */}
                        <div class="mb-3">
                            <div class="form-group row">
                                <div class="col-md-4">
                                    <h5><label for="">Correo<span className='text-danger fw-bold'>*</span></label></h5>
                                </div>
                                <div class="col-md-8">
                                    <Field type="email" name="email" class="form-control input__field" required value={values.email || ''} />
                                </div>
                            </div>
                        </div>

                        {/* Schedule date */}
                        <div class="mb-3">
                            <div class="form-group row">
                                <div class="col-md-4">
                                    <h5><label for="">Fecha<span className='text-danger fw-bold'>*</span></label></h5>
                                </div>
                                <div class="col-md-8">
                                    <Field type="date" name="date" class="form-control input__field" required value={values.date || ''} />
                                </div>
                            </div>
                        </div>

                        {/* Schedule time */}
                        <div class="mb-3">
                            <div class="row form-group">
                                <div class="col-md-4 d-flex">
                                    <h5><label for="">Hora<span className='text-danger fw-bold'>*</span></label></h5>
                                </div>
                                <div class="col-md-8 d-flex">
                                    <TimePicker style={{ width: "100%" }} format={format} onChange={time => handleOnChangeTime(time)} value={time ? dayjs(time, format) : ''} />
                                </div>
                            </div>
                        </div>

                        <div class="pt-3">
                            <button type="submit" class="btn btn-primary">
                                <i class="bi bi-plus" style={{ marginRight: "10px" }}></i>
                                Agendar cita
                            </button>

                            <button type="button" class="btn btn-danger" style={{ marginLeft: "20px" }} onClick={onCancel}>
                                <i class="bi bi-x" style={{ marginRight: "10px" }}></i>
                                Cancelar
                            </button>
                        </div>

                    </Form>
                }</Formik>

        </div >
    );
}

export default ScheduleZoomView;