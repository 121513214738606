import React, { useState, useEffect, useRef } from "react";
import { usePut } from "utils/api";
import { getSchedule } from "api/schedules";
import { toast } from "react-toastify";
import View from "components/crh/schedules/Form.view";

const format = 'HH:mm';

function FormSet({ id, setIsVisible, refresh }) {

    const formikRef = useRef();
    const [error, setError] = useState(null);
    const [schedule, setSchedule] = useState({});
    const [reset, setReset] = useState(() => { });
    const [refreshValue, setRefreshValue] = useState(null);

    const [hasBreak, setHasBreak] = useState(false);
    const [nightly, setNightly] = useState(false);
    const [totalHours, setTotalHours] = useState(false);
    const [nextDay, setNextDay] = useState(false);

    const [hours, setHours] = useState({
        hours: null,
        entry_from: null,
        entry_start: null,
        entry_until: null,
        break_from: null,
        break_start: null,
        break_until: null,
        return_from: null,
        return_start: null,
        return_until: null,
        exit_from: null,
        exit_start: null,
        exit_until: null,
    })

    if (refresh !== refreshValue) {
        setRefreshValue(refresh);
    }

    const [callUpdate, reqUpdate] = usePut("/schedules", {
        onCompleted: () => {
            if (!toast.isActive("schedule-updated"))
                toast.success("Horario actualizado", { toastId: "schedule-updated" });

            setIsVisible(false);
            reset();
        },
        onError: (err) => {
            console.error(err)
            if (err.status === 400) {
                if (!toast.isActive("schedule-error-400")) {
                    toast.error("Campos obligatorios requeridos", { toastId: "schedule-error-400" });
                }
            } else if (err.status === 403) {
                if (!toast.isActive("toast-edit-schedule-unauthorized")) {
                    toast.error("Error, no tienes los permisos para editar horarios", { toastId: "toast-edit-schedule-unauthorized" })
                }
            } else if (err.status === 409) {
                if (!toast.isActive("schedule-error-409")) {
                    toast.error("Ya existe un horario con esa clave, por favor ingrese otra", { toastId: "schedule-error-409" });
                }
            } else {
                if (!toast.isActive("schedule-error-500")) {
                    toast.error("Error interno del servidor", { toastId: "schedule-error-500" });
                }
            }
        }
    })

    useEffect(() => {

        getSchedule(id).then((response) => {
            if (response.data) {
                if (formikRef.current)
                    formikRef.current.setValues(response.data);

                setSchedule(response.data);

                setHasBreak(response.data.has_break);
                setNightly(response.data.is_nightly);
                setTotalHours(response.data.total_hours);
                setNextDay(response.data.next_day)
                setHours(response.data.hours)

                const newHours = { ...hours };
                newHours.hours = response.data.hours;
                newHours.entry_from = response.data.entry_from;
                newHours.entry_start = response.data.entry_start;
                newHours.entry_until = response.data.entry_until;
                newHours.break_from = response.data.break_from;
                newHours.break_start = response.data.break_start;
                newHours.break_until = response.data.break_until;
                newHours.return_from = response.data.return_from;
                newHours.return_start = response.data.return_start;
                newHours.return_until = response.data.return_until;
                newHours.exit_from = response.data.exit_from;
                newHours.exit_start = response.data.exit_start;
                newHours.exit_until = response.data.exit_until;

                setHours(newHours);
            }
        });
        formikRef.current.setValues({});

    }, [id, refreshValue])


    const onSubmit = (values, { resetForm }) => {

        for (let key in values)
            if (values[key] === "")
                values[key] = null

        const { key, description } = values

        const {
            entry_from, entry_start, entry_until,
            exit_from, exit_start, exit_until,
        } = hours;

        if (!key || !description || !entry_from || !entry_start || !entry_until || !exit_from || !exit_start || !exit_until) {
            toast.error("Rellene los campos obligatorios");
            return;
        }

        values.id = id;
        values.has_break = hasBreak;
        values.is_nightly = nightly;
        values.total_hours = totalHours;
        values.next_day = nextDay;

        setReset(() => () => resetForm({}));
        callUpdate({ ...values, ...hours });

    }

    const onCancel = (resetForm) => {
        setIsVisible(false);
        resetForm({});
    }

    const handleOnChange = (time, type) => {
        const newHours = { ...hours };
        if (time) {
            newHours[type] = time.format(format);
        } else {
            newHours[type] = null;
        }

        setHours(newHours);
    }

    return (
        <View
            formikRef={formikRef}
            error={error}
            edit={true}
            schedule={schedule}
            onSubmit={onSubmit}
            onCancel={onCancel}
            hasBreak={hasBreak}
            nightly={nightly}
            totalHours={totalHours}
            nextDay={nextDay}
            hours={hours}
            setHasBreak={setHasBreak}
            setNightly={setNightly}
            setTotalHours={setTotalHours}
            setNextDay={setNextDay}
            handleOnChange={handleOnChange}
        />
    )


}

export default FormSet;
