import React, { useState, useEffect, useRef } from "react"
import { usePost } from "utils/api"
import { toast } from "react-toastify"
import { useGetCall } from "utils/api";

import { getEmployees } from "api/employees"
import { getOffices } from "api/offices"
import { getJobs } from "api/jobs"
import { getDepartments } from "api/departments"
import { getBosses } from "api/bosses"
import { getGroups } from "api/groups"

import { showResponseMessage } from "utils/message"
import View from "components/crh/groups/Form.view"

function FormSave({ setIsVisible, refresh }) {

    const fileInputRef = useRef()
    const formikRef = useRef()
    const [employees, setEmployees] = useState([])
    const [groups, setGroups] = useState([])
    const [bosses, setBosses] = useState([])
    const [offices, setOffices] = useState([])
    const [jobs, setJobs] = useState([])
    const [departments, setDepartments] = useState([])
    const [refreshValue, setRefreshValue] = useState(null)

    const [selectedOffice, setSelectedOffice] = useState(null)
    const [selectedDepartment, setSelectedDepartment] = useState(null)
    const [selectedJob, setSelectedJob] = useState(null)
    const [selectedBoss, setSelectedBoss] = useState(null)

    const [selectedEmployees, setSelectedEmployees] = useState({})
    const [selectedGroups, setSelectedGroups] = useState({})

    if (refresh !== refreshValue) {
        setRefreshValue(refresh)
        setSelectedEmployees({})
        setSelectedGroups({})
    }

    const [callEmployees, reqEmployees] = useGetCall('/employees', {
        onCompleted: (response) => {
            setEmployees(response.data);
        }, onError: (error) => {
            showResponseMessage(error.status, "employees", "empleados", "read")
        }
    })

    const [callOffices, reqOffices] = useGetCall('/offices', {
        onCompleted: (response) => {
            setOffices(response.data);
        }, onError: (error) => {
            showResponseMessage(error.status, "offices", "sucursales", "read")
        }
    })

    const [callJobs, reqJobs] = useGetCall('/jobs', {
        onCompleted: (response) => {
            setJobs(response.data);
        }, onError: (error) => {
            showResponseMessage(error.status, "jobs", "puestos", "read")
        }
    })

    const [callDepartments, reqDepartments] = useGetCall('/departments', {
        onCompleted: (response) => {
            setDepartments(response.data);
        }, onError: (error) => {
            showResponseMessage(error.status, "departments", "departamentos", "read")
        }
    })

    const [callBosses, reqBosses] = useGetCall('/bosses', {
        onCompleted: (response) => {
            setBosses(response.data);
        }, onError: (error) => {
            showResponseMessage(error.status, "bosses", "jefes directos", "read")
        }
    })

    const [callGroups, reqGroups] = useGetCall('/groups', {
        onCompleted: (response) => {
            setGroups(response.data);
        }, onError: (error) => {
            showResponseMessage(error.status, "groups", "grupos de trabajo", "read")
        }
    })


    const [callSave, reqSave] = usePost("/groups", {
        onCompleted: () => {
            if (!toast.isActive("toast-save-group"))
                toast.success("Grupo creado correctamente", { toastId: "toast-save-group" })
            setIsVisible(false)
            formikRef.current.setValues({})
        },
        onError: (err) => {
            console.error(err)
            showResponseMessage(err.status, "groups", "grupos de trabajo", "create");
        }
    })

    const handleEmployeeCheck = (e, id) => {
        setSelectedEmployees({
            ...selectedEmployees,
            [id]: e.target.checked
        })
    }

    const handleGroupCheck = (e, id) => {
        setSelectedGroups({
            ...selectedGroups,
            [id]: e.target.checked
        })
    }

    const onSubmit = (values, { resetForm }) => {
        callSave({
            ...values,
            employees: Object.keys(selectedEmployees).filter((key) => selectedEmployees[key]),
            groups: Object.keys(selectedGroups).filter((key) => selectedGroups[key])
        })
    }

    const onCancel = (resetForm) => {
        setIsVisible(false)
        resetForm({})
    }

    useEffect(() => {

        callEmployees({ status: "ACTIVE" });
        callOffices();
        callJobs();
        callDepartments();
        callBosses();
        callGroups();

    }, [refreshValue])

    useEffect(() => {

        let params = {
            department_id: selectedDepartment,
            job_id: selectedJob,
            office_id: selectedOffice,
            boss_id: selectedBoss,
            status: "ACTIVE"
        }

        for (let key in params)
            if (params[key] === undefined || params[key] === null || params[key] === "")
                delete params[key]

        getEmployees(params).then((data) => {
            if (data.errors.length > 0) {
                if (!toast.isActive("toast-areas-eror"))
                    toast.error("Error al obtener los empleados", { toastId: "toast-employees-eror" })
            }
            else {
                setEmployees(data.data)
            }
        })


    }, [selectedOffice, selectedJob, selectedDepartment, selectedBoss])

    return (
        <View
            selectedGroups={selectedGroups}
            handleGroupCheck={handleGroupCheck}
            handleEmployeeCheck={handleEmployeeCheck}
            selectedEmployees={selectedEmployees}
            setSelectedEmployees={setSelectedEmployees}
            selectedOffice={selectedOffice}
            selectedDepartment={selectedDepartment}
            selectedJob={selectedJob}
            selectedBoss={selectedBoss}
            setSelectedOffice={setSelectedOffice}
            setSelectedDepartment={setSelectedDepartment}
            setSelectedJob={setSelectedJob}
            setSelectedBoss={setSelectedBoss}
            fileInputRef={fileInputRef}
            formikRef={formikRef}
            onSubmit={onSubmit}
            onCancel={onCancel}
            groups={groups}
            employees={employees}
            bosses={bosses}
            offices={offices}
            jobs={jobs}
            departments={departments}
            setIsVisible={setIsVisible}
        />
    )

}

export default FormSave