import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { useDelete, useGetCall, fetchPost } from "utils/api";
import { showResponseMessage } from "utils/message";
import Loading from "helpers/Loading";
import { LoadingContext } from "helpers/LoadingContext";
import View from "components/crh/incidences/List.view";

function List({
	addEditModal,
	refresh,
	search
}) {

	const { loading, setLoading } = useContext(LoadingContext);

	const [incidences, setIncidences] = useState([]);
	const [refreshValue, setRefreshValue] = useState(null);
	const [refreshDelete, setRefreshDelete] = useState(null);

	if (refresh !== refreshValue) {
		setRefreshValue(refresh);
	}

	const [callIncidences, reqIncidences] = useGetCall("/incidences", {
		onCompleted: (response) => {
			setLoading(false);
			setIncidences(response.data)
		},
		onError: (error) => {
			setLoading(false);
			showResponseMessage(error.status, "incidences", "incidencias", "read");
		}
	})

	const [callDelete, reqDelete] = useDelete("/incidences", {
		onCompleted: () => {
			if (!toast.isActive("incidence-deleted"))
				toast.success("Inicidencia eliminada", { toastId: "incidence-deleted" });

			setRefreshDelete(Math.random());
		},
		onError: (error) => {
			showResponseMessage(error.status, "incidences", "incidencias", "delete");
		}
	});

	useEffect(() => {
		setLoading(true);
		callIncidences({ search });
	}, [refreshValue, refreshDelete, search]);

	const onDelete = (id) => {
		fetchPost("/permissions/check", { table: "INCIDENCES", permission: "delete" }).then((response) => {
			if (response.data) {
				const data = response.data;
				if (data.isAllowed === true) {
					if (window.confirm("¿Estás seguro de eliminar la incidencia?")) {
						callDelete({ id: id });
					}
				} else {
					if (!toast.isActive("toast-delete-incidences-unauthorized")) {
						toast.error("Error, no tienes los permisos para eliminar incidencias", { toastId: "toast-delete-incidences-unauthorized" })
					}
				}
			}
		})
	}

	const onEdit = (id) => {
		addEditModal(id);
	}

	if (loading) return <Loading />

	return (
		<View incidences={incidences} onDelete={onDelete} onEdit={onEdit} />
	);
}

export default List;