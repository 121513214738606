import React from "react";
import View from "components/home/tables/ListFouls.view";

function List({ employees, incidences = [], setIsVisible }) {
	return (
		<View 
            employees={employees}
            incidences={incidences} 
            setIsVisible={setIsVisible} 
        />
	);
}

export default List;