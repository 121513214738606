import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import Loading from "helpers/Loading";
import { useDelete, useGetCall, fetchPost } from "utils/api";
import { showResponseMessage } from "utils/message";
import { LoadingContext } from "helpers/LoadingContext";
import View from "components/crh/visits/colors/List.view";

function ColorsList({
	search,
	refresh,
	addEditModal
}) {

	const { loading, setLoading } = useContext(LoadingContext);

	const [colors, setColors] = useState([]);
	const [refreshValue, setRefreshValue] = useState(null);
	const [refreshDelete, setRefreshDelete] = useState(null);

	if (refreshValue !== refresh) {
		setRefreshValue(refresh);
	}


	const [callColors, reqColors] = useGetCall("/colors", {
		onCompleted: (response) => {
			setLoading(false);
			setColors(response.data)
		},
		onError: (error) => {
			setLoading(false);
			showResponseMessage(error.status, "colors", "colores", "read");
		}
	})


	const [callDelete, reqDelete] = useDelete("/colors", {
		onCompleted: () => {
			if (!toast.isActive("serie-deleted"))
				toast.success("Color eliminado", { toastId: "serie-deleted" });

			setRefreshDelete(Math.random());
		},
		onError: (error) => {
			console.error(error)
			showResponseMessage(error.status, "colors", "colores", "delete");
		}
	});

	useEffect(() => {
		setLoading(true);
		callColors({ search });
	}, [search, refreshValue, refreshDelete]);

	const onDelete = (id) => {
		fetchPost("/permissions/check", { table: "COLORS", permission: "delete" }).then((response) => {
			if (response.data) {
				const data = response.data;
				if (data.isAllowed === true) {
					if (window.confirm("¿Estás seguro de eliminar el color?")) {
						callDelete({ id: id });
					}
				} else {
					if (!toast.isActive("toast-delete-catalogs-unauthorized")) {
						toast.error("Error, no tienes los permisos para eliminar colores", { toastId: "toast-delete-colors-unauthorized" })
					}
				}
			}
		})
	}

	const onEdit = (id) => {
		addEditModal(id);
	}

	if (loading) return <Loading />

	return (
		<View colors={colors} onDelete={onDelete} onEdit={onEdit} />
	);
}

export default ColorsList;