import React from "react";

function ListView({ 
	plannings = [], 
	onDownloadXlsx,
	onEdit, 
	onDetails 
}) {
	return (
		<div class="table-responsive datatable-custom position-relative" >
			<div class="dataTables_wrapper no-footer pt-2 pl-3 pr-3" style={{maxHeight: "60vh"}}>
				<table class="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
					<thead>
						<tr role="row">
							<th style={{ width: "20%" }}>Clave</th>
							<th style={{ width: "40%" }}>Descripción</th>
							<th style={{ width: "40%" }}>Acciones</th>
						</tr>
					</thead>
					<tbody>
						{
							plannings.map((planning) => (
								<tr key={planning.id} role="row">
									<td>{planning?.payroll_period?.key}</td>
									<td>{planning?.payroll_period?.description}</td>
									<td>
										<button className="btn btn-warning my-1" style={{ marginRight: "10px" }} onClick={() => onEdit(planning.id)}>
											<i class="bi bi-pencil" style={{marginRight: "7px"}}></i>
											Editar
										</button>
										<button className="btn btn-primary my-1" style={{ marginRight: "10px" }} onClick={() => onDetails(planning.id)}>
											<i class="bi bi-eye" style={{marginRight: "7px"}}></i>
											Visualizar
										</button>
										<button className="btn btn-success my-1" style={{ marginRight: "10px" }} onClick={() => onDownloadXlsx(planning)}>
											<i class="bi bi-file-earmark-excel" style={{marginRight: "7px"}}></i>
											Descargar Excel
										</button>
									</td>
								</tr>
							))
						}
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default ListView;