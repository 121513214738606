import React from "react";
import NoImage from 'resources/images/no-avatar.png';
import { permissionTables } from "utils/constants";
import { Link } from 'react-router-dom';
import 'resources/styles/Sidebar.scss';

function GeoSideBarView({
	permissions,
	onAddTab,
	setTabs,
	theme,
	user,
	button,
	isCrh
}) {
	return (
		<div className={button === false ? 'sidebar-geo' : 'sidebar_reducer'}
			style={{ background: theme === "light" ? "#282454" : "#111111", zIndex: 100 }}>


			<div className="empresa">
				<div className="logo">
					{
						user?.company?.logo?.url ? (
							<img crossorigin="anonymous" src={user.company.logo.url} alt="Logo empresa" style={{ borderRadius: "50%" }} />
						) : (
							<img src={NoImage} alt="" style={{ borderRadius: "50%" }} />
						)
					}
				</div>

				{
					!isCrh ? (
						<div className="nombre">
							<p>{user?.company?.name ?? 'No se agrego nombre de empresa'}</p>
						</div>
					) : null
				}
			</div>

			{
                <>
					<div className="opciones my-1 mt-2" style={{ marginTop: "0px", marginBottom: "0px", paddingTop: "0px", paddingBottom: "0px" }}>
						<Link to="/attendance" style={{ textDecoration: "none" }}>
							<div className="descripcion text-white">
								<i className="bi bi-fingerprint text-center" style={{ fontSize: "1.7em", width: "2rem" }}></i> <p className="text-center">Asistencia</p>
                        	</div>
						</Link>
                    </div>
					<div className="opciones my-1" style={{ marginTop: "0px", marginBottom: "0px", paddingTop: "0px", paddingBottom: "0px" }}>
						<Link to="/records" style={{ textDecoration: "none" }}>
							<div className="descripcion text-white">
								<i class="bi bi-ui-checks-grid text-center" style={{ fontSize: "1.7rem", width: "2rem" }}></i> <p className="text-center">Registros</p>
                        	</div>
						</Link>
                    </div>
					<div className="opciones my-1" style={{ marginTop: "0px", marginBottom: "0px", paddingTop: "0px", paddingBottom: "0px" }}>
						<Link to="/incidence_request" style={{ textDecoration: "none" }}>
                        	<div className="descripcion text-white">
								<i class="bi bi-calendar-event-fill text-center" style={{ fontSize: "1.7rem", width: "2rem" }}></i> <p className="text-center">Solicitud de Incidencias</p>
                        	</div>
						</Link>
                    </div>
					<div className="opciones my-1" style={{ marginTop: "0px", marginBottom: "0px", paddingTop: "0px", paddingBottom: "0px" }}>
						<Link to="/requests" style={{ textDecoration: "none" }}>
                        	<div className="descripcion text-white">
								<i class="bi bi-person-vcard-fill text-center" style={{ fontSize: "1.7rem", width: "2rem" }}></i> <p className="text-center">Estado de Solicitudes</p>
                        	</div>
						</Link>
                    </div>
                </>
			}


		</div>
	)
}

export default GeoSideBarView;

