import moment from "moment";
import ExcelJS from "exceljs";
import { API_URL } from "settings";
import { elements } from "chart.js";

const loadFile = ({ accept }) => {
  return new Promise((resolve, reject) => {

    let input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", accept);
    input.click();
    input.onchange = () => {
        
      let file = input.files[0];
        let reader = new FileReader();
        
        reader.onload = (e) => {
            let data = e.target.result;
            resolve(data)
        }

        reader.onerror = (error) => 
          reject(error)

        reader.readAsBinaryString(file);

    }
    
  });
}

const downloadFile = ({ data, filename, type, blob }) => {
  if(!blob) blob = new Blob([data], {type});
  if(window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, filename);
  }
  else{
    const elem = window.document.createElement('a');
    elem.href = window.URL.createObjectURL(blob);
    elem.download = filename;        
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
  }
}

const json2csv = (data) => {

    let csv = [];
    let headers = Object.keys(data[0]);

    csv.push(headers.join(","));
    data.forEach((row) => {

        let values = headers.map((header) => {
            let escaped = ('' + row[header]).replace(/"/g, '\\"');
            if(escaped.search(/("|,|\n)/g) >= 0) escaped = '"' + escaped + '"';
            return escaped;
        });

        csv.push(values.join(","));

    });

    return csv.join("\n");

}

const getBase64FromUrl = async (url) => {

  const data = await fetch(url);
  const blob = await data.blob();

  return new Promise((resolve) => {

    const reader = new FileReader();
    reader.readAsDataURL(blob); 

    reader.onloadend = () => {
      const base64data = reader.result;   
      resolve(base64data);
    }

  });

}

const downloadExcel = async ({ user, cells, report, sheetName = "Reporte", start_date = "", end_date = "", header = true, date = true, cells_to_join = [] }) => {

  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet(sheetName);

  if(user && user.company && user.company.logo && user.company.logo.url && header) {

    const image = await getBase64FromUrl(user.company.logo.url);
    
    const imageId1 = workbook.addImage({
      base64: image,
      extension: 'png',
    });
    
    sheet.addImage(imageId1, {
      tl: { col: 0.25, row: 1 },
      ext: { width: 100, height: 100 },
      editAs: 'oneCell'
    });
  
    sheet.addRow([]);
  
    sheet.addRow([
      "",
      "",
      user.company.name,
      ""
    ]);

    sheet.mergeCells('C2:G2');
  
    sheet.addRow([]);
    
    if(date) {

      sheet.addRow([
        "",
        "",
        "Fechas inicial:",
        "",
        start_date ? moment(start_date).format("DD/MM/YYYY") : "-",
      ]);
  
      sheet.mergeCells('C4:D4');
    
      sheet.addRow([
        "",
        "",
        "Fechas final:",
        "",
        end_date ? moment(end_date).format("DD/MM/YYYY") : "-",
      ]);
  
      sheet.mergeCells('C5:D5');

    }
    else {
      
      sheet.addRow([]);
      sheet.addRow([]);

    }
  
    sheet.addRow([]);
    sheet.addRow([]);
	  
  }

  cells.forEach((row) => {

    if(row.length > 0 && row[0] instanceof Object) {

      sheet.addRow(row.map((cell) => cell.value));

      for(let i = 0; i < row.length; i++) {

        const lastRow = sheet.lastRow;
        const currentCell = lastRow.getCell(i + 1);

        if(row[i].color)
          currentCell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF' + row[i].color.replace("#", "") }
          };

        if(row[i].bold)
          currentCell.font = {
            bold: true
          };

        if(row[i].alignment)
          currentCell.alignment = {
            vertical: 'middle',
            horizontal: row[i].alignment
          };

        if(row[i].border) {
          currentCell.border = {
            top: {style:'thin'},
            left: {style:'thin'},
            bottom: {style:'thin'},
            right: {style:'thin'}
          };
        }

        if(row[i].fontColor)
          currentCell.font = {
            color: { argb: 'FF' + row[i].fontColor.replace("#", "") }
          };

      }

    }
    else {
      sheet.addRow(row);
    }

  });

  for (const cells of cells_to_join) {
    sheet.mergeCells(cells);
  }

  let filename = report + ".xlsx";
  if(start_date && end_date) 
    filename = `${report} ${moment.utc(start_date).format("DD [de] MMMM [de] YYYY")} - ${moment.utc(end_date).format("DD [de] MMMM [de] YYYY")}.xlsx`;

  const data = await workbook.xlsx.writeBuffer();
  downloadFile({
    data,
    filename,
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  });

}

const downloadCSV = async ({ user, cells, report, start_date = "", end_date = "", header = true, date = true }) => {

  if(user && user.company && user.company.name && header) {    
    
    cells.unshift([]);

    if(date) {
      cells.unshift([
        "Fechas final:",
        end_date ? moment(end_date).format("DD/MM/YYYY") : "-",
      ]);
      cells.unshift([
        "Fechas inicial:",
        start_date ? moment(start_date).format("DD/MM/YYYY") : "-",
      ]);
    }
    else {
      cells.unshift([]);
      cells.unshift([]);
    }

    cells.unshift([]);
    
    cells.unshift([
      user.company.name,
    ]);

    cells.unshift([]);

  }

  let filename = report + ".csv";
  if(start_date && end_date) 
    filename = `${report} ${moment.utc(start_date).format("DD [de] MMMM [de] YYYY")} - ${moment.utc(end_date).format("DD [de] MMMM [de] YYYY")}.csv`;

  downloadFile({
    data: cells.map((row) => row.join(",")).join("\n"),
    filename,
    type: "text/csv"
  });

}

async function uploadFile(file) {

  let url = `${API_URL}/files/`;
  let formData = new FormData();
  formData.append("file", file);

  let res = await fetch(url, {
    method: "POST",
    headers: {
      "x-auth-token": sessionStorage.getItem("token"),
    },
    body: formData
  });

  let json = await res.json();

  return {
    body: json.data,
    ok: res.status === 200 || res.status === 201
  };

}

function dataURItoBlob(dataURI) {

  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if(dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1]);
  else
    byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for(var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], {type:mimeString});

}

export { uploadFile, loadFile, downloadFile, json2csv, getBase64FromUrl, downloadExcel, downloadCSV, dataURItoBlob };