import React, { useState, useEffect, useRef } from "react";
import { useGetCall, usePut } from "utils/api";
import { toast } from "react-toastify";
import { showResponseMessage } from "utils/message";
import View from "components/crh/visits/profiles/Form.view";

function FormSet({ id, setIsVisible, refresh }) {

  const formikRef = useRef();
  const [error, setError] = useState(null);
  const [profile, setProfile] = useState({});
  const [reset, setReset] = useState(() => { });
  const [refreshValue, setRefreshValue] = useState(null);

  const [profileDevices, setProfileDevices] = useState({});
  const [devices, setDevices] = useState([]);

  if (refresh !== refreshValue) {
    setRefreshValue(refresh);
  }

  const [callDevices, reqDevices] = useGetCall("/devices", {
		onCompleted: (response) => {
			setDevices(response.data)
		},
		onError: (error) => {
			showResponseMessage(error.status, "devices", "lectores", "read")
		}
	})

  const [callProfile, reqProfile] = useGetCall(`/visit_profiles/${id}`, {
    onCompleted: (response) => {
      if (formikRef.current) {
        formikRef.current.setValues(response.data);
      }
      setProfile(response.data);
      setProfileDevices(response.data?.visit_profile_devices.reduce((acc, device) => {
        acc[device.device_id] = device.active;
        return acc;
      }, {}))

    }, onError: (error) => {
      console.error(error)
      showResponseMessage(error.status, "sub_brands", "submarcas", "edit")
    }
  })

  const [callUpdate, reqUpdate] = usePut("/visit_profiles", {
    onCompleted: () => {
      if (!toast.isActive("sub_brands-updated"))
        toast.success("Perfil actualizado correctamente", { toastId: "sub_brands-updated" });

      setIsVisible(false);
      reset();
    },
    onError: (error) => {
      console.error(error)
      showResponseMessage(error.status, "profiles", "perfiles", "edit")
    }
  })

  useEffect(() => {
    callProfile();
    callDevices();
    formikRef.current.setValues({});
  }, [id, refreshValue])


  const onSubmit = (values, { resetForm }) => {

    for (let key in values)
      if (values[key] === "")
        values[key] = null

    const { key, description, abbreviation } = values
    if (!key || !description || !abbreviation) {
      toast.error("Todos los campos son obligatorios");
      return;
    }

    values.id = id;
    setReset(() => () => resetForm({}));
    callUpdate({ ...values, devices: profileDevices });

  }

  const onCancel = (resetForm) => {
    setIsVisible(false);
    resetForm({});
  }

  return (
    <View
      formikRef={formikRef}
      error={error}
      edit={true}
      profile={profile}
      onSubmit={onSubmit}
      onCancel={onCancel}
      devices={devices}
      profileDevices={profileDevices}
      setProfileDevices={setProfileDevices}
    />
  )


}

export default FormSet;