import React, {useState} from "react";
import { usePost } from "utils/api";
import { toast } from "react-toastify";
import { showResponseMessage } from "utils/message";
import View from "components/crh/visits/colors/Form.view";

function FormSave({ setIsVisible }) {

    const [error, setError] = useState(null);
    const [reset, setReset] = useState(() => { });

    const [callSave, reqSave] = usePost("/colors", {
        onCompleted: () => {
            if (!toast.isActive("colors-created"))
                toast.success("Color creado correctamente", {toastId : "colors-created"});
            
            setIsVisible(false);
            reset();
        },
        onError: (error) => {
            console.error(error)
            showResponseMessage(error.status, "colors", "colores", "create")
        }
    })

    const onSubmit = (values, {resetForm}) => {

        for(let key in values)
            if(values[key] === "")
                values[key] = null
    
        const {key, description, abbreviation} = values
        if(!key || !description || !abbreviation) {
            toast.error("Todos los campos son obligatorios");
            return;
        }

        setReset(() => () => resetForm({}));
        callSave(values);
        
    }

    const onCancel = (resetForm) => {
        setIsVisible(false);
        resetForm({});
    }


    return (
        <View 
            onSubmit={onSubmit}
            onCancel={onCancel}
            error={error}
            setIsVisible={setIsVisible} 
            setError={setError}
        />
    )
}

export default FormSave;