import React from 'react';
import { weekDays } from 'utils/constants';
import IncidenceRequest from './IncidenceRequest';
import ViewIncidenceRequest from './ViewIncidenceRequest';

const getPayrollPeriod = (periods, id) => {
  return periods.find(period => period.id.toString() == id);
}

const getWeeklyPeriods = (periods) => {
  let weeklyPeriods = periods.filter(period => period.payroll.key === 'S');
  return weeklyPeriods;
}
 
const filterPlanning = (planning, filters) => {

  let filteredPlanning = planning;

  if(filters.search)
    filteredPlanning = filteredPlanning.filter(employeePlanning => 
      employeePlanning?.employee?.firstname?.toLowerCase().includes(filters.search.toLowerCase()) ||
      employeePlanning?.employee?.dad_lastname?.toLowerCase().includes(filters.search.toLowerCase()) ||
      employeePlanning?.employee?.mom_lastname?.toLowerCase().includes(filters.search.toLowerCase()) ||
      employeePlanning?.employee?.key?.toLowerCase().includes(filters.search.toLowerCase())
    );

  if(filters.office)
    filteredPlanning = filteredPlanning.filter(employeePlanning => employeePlanning?.employee?.office?.id.toString() === filters.office);

  if(filters.job)
    filteredPlanning = filteredPlanning.filter(employeePlanning => employeePlanning?.employee?.job?.id.toString() === filters.job);

  if(filters.department)
    filteredPlanning = filteredPlanning.filter(employeePlanning => employeePlanning?.employee?.department?.id.toString() === filters.department);

  filteredPlanning = filteredPlanning.sort((a, b) => {
    try{
      if(parseInt(a.employee.key) < parseInt(b.employee.key)) return -1;
      if(parseInt(a.employee.key) > parseInt(b.employee.key)) return 1;
    } catch(e){
      if(a.employee.key < b.employee.key) return -1;
      if(a.employee.key > b.employee.key) return 1;
    }
    return 0;
  });

  return filteredPlanning;

}

function FormView({ 
  formikRef = () => { }, 
  edit = false, 
  planning = [],
  payrollPeriods = [],
  offices = [],
  jobs = [],
  departments = [],
  schedules = [],
  filters,
  setFilters,
  planningValues,
  setPlanning,
  setPlanningValues,
  visibleModal, 
  setVisibleModal,
  onChangePlanning,
  onAddIncidence,
  onSubmit, 
  error, 
  setError, 
  onCancel, 
}) {
  return (
    <div 
      className='container mt-3'
      style={{
        maxHeight: "80vh",
        overflowY: "auto",
      }}
    >

      <div className='h6 mb-2'>
        Buscar por:
      </div>

      <div className='d-flex'>
        
        <input 
          type="text" 
          className="form-control"
          placeholder="Buscar empleado" 
          onChange={(e) => setFilters({ ...filters, search: e.target.value })}
        />

        <label class="input ms-2">
          <select class="input__field form-control" required onChange={(e) => setFilters({ ...filters, office: e.target.value })}>
            <option value="">Seleccionar</option>
            {
              offices.map((office, index) => (
                <option key={index} value={office.id}>{office.description}</option>
              ))
            }
          </select>
          <span class="input__label">
            Sucursal
          </span>
        </label>

        <label class="input ms-2">
          <select class="input__field form-control" required onChange={(e) => setFilters({ ...filters, job: e.target.value })}>
            <option value="">Seleccionar</option>
            {
              jobs.map((job, index) => (
                <option key={index} value={job.id}>{job.description}</option>
              ))
            }
          </select>
          <span class="input__label">
            Puesto
          </span>
        </label>

        <label class="input ms-2">
          <select class="input__field form-control" required onChange={(e) => setFilters({ ...filters, department: e.target.value })}>
            <option value="">Seleccionar</option>
            {
              departments.map((department, index) => (
                <option key={index} value={department.id}>{department.description}</option>
              ))
            }
          </select>
          <span class="input__label">
            Departamento
          </span>
        </label>

      </div>

      <div className='h6 mt-2 mb-4'>
        Configuración:
      </div>

      <div className='d-flex mt-3 mb-3'>

        <label class="input">
          <select 
            class="input__field form-control" 
            required 
            onChange={(e) => setPlanningValues({ ...planningValues, tolerance: e.target.value })}
            value={planningValues?.tolerance}
          >
            <option value="">Seleccionar</option>
            {
              Array.from(Array(101), (_, index) => index).map((minute) =>
                <option key={minute} value={minute} selected={planningValues?.tolerance === minute}>
                  {minute} minutos
                </option>
              )
            }
          </select>
          <span class="input__label">
            Tolerancia
            <span className='text-danger fw-bold'>*</span>
          </span>
        </label>

        <label class="input ms-2">
          <select 
            class="input__field form-control" 
            required 
            value={planningValues.payrollPeriod?.id}
            onChange={(e) => setPlanningValues({...planningValues, payrollPeriod: getPayrollPeriod(payrollPeriods, e.target.value)})}
          >
            <option value="">Seleccionar</option>
            {
              getWeeklyPeriods(payrollPeriods).map((period, index) => (
                <option key={index} value={period.id} selected={planningValues.payrollPeriod?.id.toString() === period.id.toString()}>
                  {period.description}
                </option>
              ))
            }
          </select>
          <span class="input__label">
            Periodo semanal
            <span className='text-danger fw-bold'>*</span>
          </span>
        </label>

        {/* <label class="input ms-2">
          <select class="input__field form-control" required onChange={(e) => setPlanningValues({ ...planningValues, calcExtraTime: e.target.value })}>
            <option value="">Seleccionar</option>
          </select>
          <span class="input__label">
            Cálculo tiempo extra
          </span>
        </label>

        <label class="input ms-2">
          <select class="input__field form-control" required onChange={(e) => setPlanningValues({ ...planningValues, roundExtraTime: e.target.value })}>
            <option value="">Seleccionar</option>
          </select>
          <span class="input__label">
            Redondeo tiempo extra
          </span>
        </label> */}

      </div>

      <div class="mb-3">
        <div class="form-group d-flex bg-crh">
          <div class="pt-1" style={{ width: "90%" }}>
            <h5 class="text-center"><label for="switchExtraTime">Tiempo extra</label></h5>
          </div>
          <div class="text-center" style={{ width: "10%" }}>
            <div className="form-check form-switch pt-1" id="extra-time-switch">
              <input 
                class="form-check-input" 
                type="checkbox" 
                id="switchExtraTime"
                checked={planningValues.extra}
                onChange={() => { 
                  setPlanningValues({ ...planningValues, extra: !planningValues.extra });
                 }} 
              />
            </div>
          </div>
        </div>
      </div>

      {
        planningValues?.extra ? (
          <div class="mb-3">
            <div class="form-group">
              <select
                name="type_extra" 
                class="form-control input__field" 
                required 
                value={planningValues?.type_extra}
                onChange={(e) => setPlanningValues({ ...planningValues, type_extra: e.target.value })}
              >
                <option value="">Selecciona el momento del cálculo</option>
                <option value="BEFORE">Calcular antes de la jornada</option>
                <option value="AFTER">Calcular después de la jornada</option>
                <option value="BOTH">Calcular antes y después de la jornada</option>
              </select>
            </div>
          </div>
        ) : null
      }

      <div class="mb-3">
        <div class="form-group d-flex bg-crh">
          <div class="pt-1" style={{ width: "90%" }}>
            <h5 class="text-center"><label for="switchRoundExtraTime">Redondear tiempo extra</label></h5>
          </div>
          <div class="text-center" style={{ width: "10%" }}>
            <div className="form-check form-switch pt-1" id="roundExtraTimeSwitch">
                <input 
                  class="form-check-input" 
                  type="checkbox" 
                  id="switchRoundExtraTime"
                  checked={planningValues.round} 
                  onChange={() => { 
                    setPlanningValues({ ...planningValues, round: !planningValues.round });
                  }}
                />
            </div>
          </div>
        </div>
      </div>

      {
        planningValues?.round ? (
          <div class="mb-3">
            <div class="form-group row">
              <div class="col-md-6">
                <select 
                  name="type_round" 
                  class="form-control input__field" 
                  required 
                  value={planningValues?.type_round || ''}
                  onChange={(e) => setPlanningValues({ ...planningValues, type_round: e.target.value })}
                >
                    <option value="">Selecciona el tipo de redondeo</option>
                    <option value="COMPLETE_HOURS">Horas completas</option>
                    <option value="HALF_HOURS">Medias horas</option>
                </select>
              </div>
              <div class="col-md-6">
                <select
                  name="distribution_round" 
                  class="form-control input__field" 
                  required 
                  value={planningValues?.distribution_round || ''}
                  onChange={(e) => setPlanningValues({ ...planningValues, distribution_round: e.target.value })}
                >
                  <option value="">Selecciona la distribución del redondeo</option>
                  <option value="UP">Arriba (15 minutos)</option>
                  <option value="DOWN">Abajo (15 minutos)</option>
                  <option value="BOTH">Ambos (15 minutos)</option>
                </select>
              </div>
            </div>
          </div>
        ) : null
      }

      <div className='h6 mt-2 mb-0'>
        Planeación:
      </div>

      <div class="table-responsive datatable-custom position-relative" >
        <div class="dataTables_wrapper no-footer pt-2 pl-3 pr-3">
          <table class="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
            <thead>
              <tr role="row">
                <th>
                  ID
                </th>
                <th style={{ width: "22%" }}>
                  Nombre
                </th>
                <th style={{ width: "10%" }}>
                  Lunes
                </th>
                <th style={{ width: "10%" }}>
                  Martes
                </th>
                <th style={{ width: "10%" }}>
                  Miércoles
                </th>
                <th style={{ width: "10%" }}>
                  Jueves
                </th>
                <th style={{ width: "10%" }}>
                  Viernes
                </th>
                <th style={{ width: "10%" }}>
                  Sábado
                </th>
                <th style={{ width: "10%" }}>
                  Domingo
                </th>
              </tr>
            </thead>
            <tbody>
              {
                function(){
                  let filteredPlanning = filterPlanning(planning, filters);
                  return filteredPlanning.map((employeePlanning, index) => (
                    <tr key={index} role="row">
                      <td>
                        {employeePlanning.employee.key}
                      </td>
                      <td>
                        {employeePlanning.employee.firstname} {employeePlanning.employee.dad_lastname} {employeePlanning.employee.mom_lastname}
                      </td>
                      {
                        weekDays.map((weekDay, weekIndex) => 
                          <td>

                            <button
                              type='button'
                              className='btn btn-primary btn-sm w-100 mt-1'
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                if(!visibleModal || visibleModal !== `select-shift-${employeePlanning?.employee?.id}-${weekDay}`)
                                  setVisibleModal(`select-shift-${employeePlanning?.employee?.id}-${weekDay}`);
                                else
                                  setVisibleModal(null);
                              }}
                            >
                              {
                                employeePlanning.params[weekDay]?.schedule?.id 
                                  ? <> <i className='bi bi-pencil'/> {employeePlanning.params[weekDay]?.schedule?.description} </>
                                  : <> <i className='bi bi-plus'/> Horario </>
                              } 
                            </button>

                            {
                              employeePlanning.params[weekDay]?.incidenceRequest?.id 
                                ? <button 
                                    type='button'
                                    className='btn btn-warning btn-sm w-100 mt-1'
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      if(!visibleModal || visibleModal !== `view-${employeePlanning?.employee?.id}-${weekDay}`)
                                        setVisibleModal(`view-${employeePlanning?.employee?.id}-${weekDay}`);
                                      else
                                        setVisibleModal(null);
                                    }}
                                  >
                                    <i className='bi bi-eye'/> SI-{employeePlanning.params[weekDay]?.incidenceRequest?.folio}
                                  </button>
                                : <button 
                                    type='button'
                                    className='btn btn-warning btn-sm w-100 mt-1'
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      if(!visibleModal || visibleModal !== `incidence-${employeePlanning?.employee?.id}-${weekDay}`)
                                        setVisibleModal(`incidence-${employeePlanning?.employee?.id}-${weekDay}`);
                                      else
                                        setVisibleModal(null);
                                    }}
                                  >
                                    <i className='bi bi-plus'/> Incidencia 
                                  </button>
                            }

                            <div 
                              style={{
                                position: "relative",
                              }}
                            >

                              {
                                visibleModal == `select-shift-${employeePlanning?.employee?.id}-${weekDay}` 
                                  ? <div
                                      style={{
                                        position: "relative",
                                        top: index == filteredPlanning.length - 1 ? "-162px" : "-35px",
                                        right: weekIndex == weekDays.length - 1 ? "90px" : "0px",
                                      }}
                                    >
                                      <div
                                        className='card p-2'
                                        style={{ 
                                          display: visibleModal == `select-shift-${employeePlanning?.employee?.id}-${weekDay}` ? "flex" : "none",
                                          position: "absolute",
                                          zIndex: 1000,
                                          width: "200px",
                                          maxHeight: "200px", 
                                          overflowY: "auto", 
                                        }}
                                      >

                                        <div className='form-check'>
                                          <input 
                                            className='form-check-input' 
                                            type='radio' 
                                            name='schedule'
                                            id={`schedule-none-employee-${employeePlanning?.employee?.id}-${weekDay}`} 
                                            onChange={(e) => onChangePlanning(employeePlanning, weekDay, {})}
                                          />
                                          <label className='form-check-label' htmlFor={`schedule-none-employee-${employeePlanning?.employee?.id}-${weekDay}`}>
                                            Selecciona un horario
                                          </label>
                                        </div>

                                        {
                                          function(){

                                            let sortedSchedules = schedules.sort((a, b) => {
                                              if(a.entry_start < b.entry_start) return -1;
                                              if(a.entry_start > b.entry_start) return 1;
                                              if(a.exit_start < b.exit_start) return -1;
                                              if(a.exit_start > b.exit_start) return 1;
                                              return 0;
                                            });

                                            return sortedSchedules.map((schedule, index) => (
                                              <div key={index} className='form-check'>
                                                <input 
                                                  className='form-check-input' 
                                                  type='radio' 
                                                  name='schedule' 
                                                  id={`schedule-${schedule.id}-employee-${employeePlanning?.employee?.id}-${weekDay}`} 
                                                  onClick={(e) => onChangePlanning(employeePlanning, weekDay, schedule)}
                                                />
                                                <label className='form-check-label' htmlFor={`schedule-${schedule.id}-employee-${employeePlanning?.employee?.id}-${weekDay}`}>
                                                  {schedule.description}
                                                </label>
                                              </div>
                                            ))

                                          }()
                                        }

                                      </div>
                                    </div> 
                                  : null
                              }

                              {
                                visibleModal == `incidence-${employeePlanning?.employee?.id}-${weekDay}`
                                  ? <div
                                      style={{
                                        position: "relative",
                                        top: index > filteredPlanning.length - 4 ? "-227px" : "-35px",
                                        right: weekIndex == weekDays.length - 1 ? "608px" : "0px",
                                      }}
                                    >
                                      <div
                                        className='card p-2'
                                        style={{ 
                                          display: visibleModal == `incidence-${employeePlanning?.employee?.id}-${weekDay}` ? "flex" : "none",
                                          position: "absolute",
                                          maxHeight: "350px", 
                                          overflowY: "auto", 
                                        }}
                                      >
                                        <IncidenceRequest
                                          employee={employeePlanning.employee}
                                          onSubmit={(incidenceRequest) => {
                                            onAddIncidence(employeePlanning, weekDay, incidenceRequest)
                                            setVisibleModal(null);
                                          }}
                                          onClose={() => setVisibleModal(null)}
                                        />
                                      </div>
                                      <div
                                        className='card p-2'
                                        style={{ 
                                          display: visibleModal == `view-${employeePlanning?.employee?.id}-${weekDay}` ? "flex" : "none",
                                          width: "320px",
                                          maxHeight: "300px", 
                                          overflowY: "auto", 
                                        }}
                                      >
                                        <ViewIncidenceRequest
                                          incidenceRequest={employeePlanning.params[weekDay]?.incidenceRequest}
                                          employee={employeePlanning.employee}
                                          onClose={() => setVisibleModal(null)}
                                        />
                                      </div>
                                    </div>
                                  : null
                              }

                            </div>

                          </td>
                        )
                      }
                    </tr>
                  ))
              }()
              }
            </tbody>
          </table>
        </div>
      </div>

      <div className='d-flex'>
        <button 
          type='button' 
          className='btn btn-primary mt-3'
          onClick={onSubmit}
        >
          <i className='bi bi-plus'/> Guardar
        </button>
        <button 
          type='button' 
          className='btn btn-danger mt-3 ms-2'
          onClick={onCancel}
        >
          <i className='bi bi-x'/> Cancelar
        </button>
      </div>

    </div>
  )
}

export default FormView;