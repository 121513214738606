import React from "react";

class SelectList extends React.Component {

    render() {
        const { options, title } = this.props;
        return (
            <div class="table-responsive datatable-custom position-relative" {...this.props}>
                <div class="dataTables_wrapper no-footer pt-2 pl-3 pr-3" style={{maxHeight: "60vh"}}>
                    <table class="table table-sm table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
                        <thead>
                            <tr role="row">
                                <th>{title}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                options.map((option, idx) =>
                                    <tr
                                        key={title + idx} 
                                        value={option.value} 
                                        onClick={() => this.onClick(idx)}
                                        style={{
                                            cursor: "pointer"
                                        }}
                                        className={`select-list-item ${this.selected[idx] ? "active" : ""}`}
                                    >
                                        <td>
                                            {option.label}
                                        </td>
                                    </tr>
                                )
                            }
                            {
                                options.length === 0 &&
                                    <tr>
                                        <td>
                                            No hay opciones disponibles
                                        </td>
                                    </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    constructor(props) {
        super(props);
        const length = props.options.length;
        this.selected = Array(length).fill(false);
    }

    onClick(idx) {

        const { onChange, multiple } = this.props;
        this.selected[idx] = !this.selected[idx];

        if(!multiple) {

            for(let i = 0; i < this.selected.length; i++)
                if(i !== idx) 
                    this.selected[i] = false;

            onChange(this.props.values[idx]);

        }
        else {
            
            let selected = [];
            for(let i = 0; i < this.selected.length; i++)
                if(this.selected[i])
                    selected.push(this.props.values[i]);

            onChange(selected);

        }

        this.forceUpdate();

    }

}

export default SelectList;