import React from 'react';
import {Modal as ModalAntd} from 'antd';
import "resources/styles/Modal.scss"

function Modal(props) {

    const {children, title, isVisible, setIsVisible} = props;

    return (
        <ModalAntd
            title={title}
            centered
            bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)', borderRadius: '10px' }}
            open={isVisible}
            onCancel={() => setIsVisible(false)}
            footer={false}
        >
            {children}
        </ModalAntd>
    )

}

export default Modal;