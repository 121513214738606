import React from "react";
import { Formik, Form, Field } from 'formik';
import { TimePicker } from 'antd';
import moment from "moment";

function AttendanceView({ 
    devices,
    filters, 
    onSubmit
}) {
    return (
        <div className='container mt-3'>
            <Formik
                initialValues={filters}
                onSubmit={onSubmit}>
                {({ values, setFieldValue, resetForm }) =>
                    <Form>

                        <div className="row my-2">
                            <div className="col-md-4">
                                <h4>Rango de fechas</h4>
                            </div>
                            <div className="col-md-4">
                                <Field type="date" name="start_date" className="form-control" value={values.start_date || ''} />
                            </div>
                            <div className="col-md-4">
                                <Field type="date" name="end_date" className="form-control" value={values.end_date || ''} />
                            </div>
                        </div>

                        <div className="row my-2">
                            <div className="col-md-4">
                                <h4>Rango de horas</h4>
                            </div>
                            <div className="col-md-4">
                                <TimePicker 
                                    className="form-control"
                                    placeholder="Seleccione una hora"
                                    format="HH:mm"
                                    onChange={(date) => setFieldValue('start_hour', moment(date.toISOString()).local().format("HH:mm:ss"))}
                                    defaultValue={values.start_hour ? moment(values.start_hour, "HH:mm:ss") : null}
                                />
                                {/* <Field type="time" name="start_hour" className="form-control" value={values.start_hour || ''} /> */}
                            </div>
                            <div className="col-md-4">
                                <TimePicker 
                                    className="form-control"
                                    placeholder="Seleccione una hora"
                                    format="HH:mm"
                                    onChange={(date) => setFieldValue('end_hour', moment(date.toISOString()).local().format("HH:mm:ss"))}
                                    defaultValue={values.end_hour ? moment(values.end_hour, "HH:mm:ss") : null}
                                />
                                {/* <Field type="time" name="end_hour" className="form-control" value={values.end_hour || ''} /> */}
                            </div>
                        </div>

                        <div className="row my-2">
                            <div className="col-md-4">
                                <h4>ID de empleado</h4>
                            </div>
                            <div className="col-md-8">
                                <Field type="text" name="key" className="form-control" value={values.pin || ''} />
                            </div>
                        </div>

                        <div className="row my-2">
                            <div className="col-md-4">
                                <h4>Lector</h4>
                            </div>
                            <div className="col-md-8">
                                <Field as="select" name="device_id" className="form-control" value={values.device_id || ''}>
                                    <option value="">Seleccione una opción</option>
                                    {
                                        devices.map(device =>
                                            <option key={device.id} value={device.id}>{device.serial_number} - {device.description}</option>
                                        )
                                    }
                                </Field>
                            </div>
                        </div>

                        <div class="pt-3">
                            <button type="submit" class="btn btn-primary">
                                <i class="bi bi-plus" style={{marginRight: "10px"}}></i>
                                Aceptar
                            </button>
                        </div>

                    </Form>
                }</Formik>

        </div >
    )
}

export default AttendanceView;