import React, { useState, useEffect, useRef } from "react";
import { usePut, useGetCall } from "utils/api";
import { toast } from "react-toastify";
import { showResponseMessage } from "utils/message";
import View from "components/crh/incidences/Form.view";

function FormSet({ id, setIsVisible, refresh }) {

    const [displayColorPicker, setDisplayColorPicker] = useState(false)
    const formikRef = useRef();
    const [error, setError] = useState(null);
    const [reset, setReset] = useState(() => { });
    const [incidence, setIncidence] = useState({});
    const [refreshValue, setRefreshValue] = useState(null);
    const [color, setColor] = useState("#000000");
    const [status, setStatus] = useState(false);
    const [requestRh, setRequestRh] = useState(false);
    const [requestGeo, setRequestGeo] = useState(false);

    if (refresh !== refreshValue) {
        setRefreshValue(refresh);
    }

    const [callUpdate, reqUpdate] = usePut("/incidences", {
        onCompleted: () => {
            if (!toast.isActive("incidence-updated"))
                toast.success("Incidencia actualizada", { toastId: "incidence-updated" });

            setIsVisible(false);
            reset();
        },
        onError: (err) => {
            console.error(err)
            showResponseMessage(error.status, "incidences", "incidencias", "edit");
        }
    })

    const [callIncidence, reqIncidence] = useGetCall(`/incidences/${id}`, {
        onCompleted: (response) => {

            if (response.data) {
                if (formikRef.current) {
                    formikRef.current.setValues(response.data);
                }
                setIncidence(response.data);
                setColor(response.data.color);
                setStatus(response.data.status);
                setRequestRh(response.data.request_rh);
                setRequestGeo(response.data.request_geo);
            }

        }, onError: (error) => {
            showResponseMessage(error.status, "incidences", "incidencias", "read")
        }
    })

    useEffect(() => {
        callIncidence();
        formikRef.current.setValues({});
    }, [id, refreshValue])


    const onSubmit = (values, { resetForm }) => {

        for (let key in values)
            if (values[key] === "")
                values[key] = null

        const { key, description, type, preference } = values
        if (!key || !description || !type || type === "" || !preference || preference === "") {
            toast.error("Rellena los campos obligatorios");
            return;
        }

        setReset(() => () => resetForm({}));
        callUpdate({
            ...values,
            status: status,
            request_rh: requestRh,
            request_geo: requestGeo,
            color: color
        });

    }

    const onCancel = (resetForm) => {
        setIsVisible(false);
        resetForm({});
        setColor("#000000");
        setStatus(false);
        setRequestRh(false);
        setRequestGeo(false);
    }

    const onChangeColor = (background) => setColor(background.hex);
    const onClickShow = () => setDisplayColorPicker(!displayColorPicker);
    const onCloseColorPicker = () => setDisplayColorPicker(false);

    return (
        <View
            displayColorPicker={displayColorPicker}
            formikRef={formikRef}
            error={error}
            edit={true}
            incidence={incidence}
            onSubmit={onSubmit}
            onCancel={onCancel}
            color={color}
            status={status}
            requestRh={requestRh}
            requestGeo={requestGeo}
            setStatus={setStatus}
            setRequestRh={setRequestRh}
            setRequestGeo={setRequestGeo}
            setIsVisible={setIsVisible}
            setError={setError}
            onChangeColor={onChangeColor}
            onClickShow={onClickShow}
            onCloseColorPicker={onCloseColorPicker}
        />
    )


}

export default FormSet;
