import React, { useState, useRef, useEffect } from "react";
import Form from "components/crh/deviceEmployees/Form";
import { fetchPost, usePost } from "utils/api";
import { toast } from "react-toastify";
import { downloadExcel } from "utils/files";
import { getUser } from "api/users";
import View from "components/crh/deviceEmployees/DeviceEmployees.view";

function DeviceEmployees({ theme }) {

  const id = sessionStorage.getItem("id");
  const deviceEmployeeListRef = useRef(null);
  const [user, setUser] = useState({});
  const countRef = useRef(null);

  const [isVisible, setIsVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [edit, setEdit] = useState(false);
  const [excel, setExcel] = useState(false);

  const [reloadDevice, setReloadDevice] = useState(false);
  const [reloadDevices, setReloadDevices] = useState(false);

  const [search, setSearch] = useState("");

  const [callSync, reqSync] = usePost("/devices/sync", {
      onCompleted: () => {

        if (!toast.isActive("toast-update-device"))
            toast.success("Empleados sincronizados correctamente", { toastId: "toast-update-device" })

      },
      onError: (error) => {
          toast.error("Error al sincronizar empleados", { toastId: "toast-update-device" })
      }
  })

  useEffect(() => {
    fetchPost("/permissions/check", { table: "DEVICE_EMPLOYEE", permission: "edit" }).then((response) => {
      if (response.data && response.data.isAllowed === true) {
        setEdit(true);
      }
    })
    fetchPost("/permissions/check", { table: "DEVICE_EMPLOYEE", permission: "excel" }).then((response) => {
      if (response.data && response.data.isAllowed === true) {
        setExcel(true);
      }
    })
  }, [])

  const addDeviceEmployeeModal = (id) => {

    fetchPost("/device_employees/check_permission", { table: "DEVICE_EMPLOYEE", type: "edit" }).then((res) => {
      if (res.data) {
        if (res.data.isAllowed === true) {

          setIsVisible(true);
          setModalTitle("Empleados");
          setModalContent(
            <Form
              id={id}
              refresh={Math.random()}
              setIsVisible={setIsVisible}
              reloadDevice={reloadDevice}
              setReloadDevice={setReloadDevice}
              setReloadDevices={setReloadDevices}
              theme={theme}
            />
          );

        } else {
          if (!toast.isActive("toast-update-devices-employees-unauthorized")) {
            toast.error("Error, no tienes los permisos para editar los lectores a empleados", { toastId: "toast-update-devices-employees-unauthorized" })
          }
        }
      }
    })
  }

  const onSync = (e) => {
    callSync({});
  }

  const onChangeSearch = (event) => {
    if (event.target.value === '') {
      setSearch(event.target.value);
    }
  }

  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      setSearch(event.target.value);
    }
  }

  const onDownloadXlsx = async () => {

    fetchPost("/device_employees/check_permission", { table: "DEVICE_EMPLOYEE", type: "excel" }).then(async (res) => {
      if (res.data) {
        if (res.data.isAllowed === true) {

          const cells = await deviceEmployeeListRef.current.generateCells();
          downloadExcel({
            user,
            cells: cells,
            report: "lectores_empleados",
            date: false
          })
        } else {
          if (!toast.isActive("toast-excel-devices-employees-unauthorized")) {
            toast.error("Error, no tienes los permisos para descargar el excel de los lectores a empleados", { toastId: "toast-excel-devices-employees-unauthorized" })
          }
        }
      }
    })

  }

  useEffect(() => {
    getUser(id).then((response) => {
      setUser(response.data)
    });
  }, [id]);

  return (
    <View
      countRef={countRef}
      edit={edit}
      excel={excel}
      deviceEmployeeListRef={deviceEmployeeListRef}
      onSync={onSync}
      onDownloadXlsx={onDownloadXlsx}
      search={search}
      onChangeSearch={onChangeSearch}
      handleKeyUp={handleKeyUp}
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      modalTitle={modalTitle}
      modalContent={modalContent}
      addDeviceEmployeeModal={addDeviceEmployeeModal}
      reloadDevices={reloadDevices}
      setReloadDevices={setReloadDevices}
      theme={theme}
    />
  );

}

export default DeviceEmployees;