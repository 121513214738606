import React, { useState } from "react";
import { usePost } from "utils/api";
import View from "components/crh/reports/attendance/AttendanceDetails.view";

function AttendanceDetails({ data, setIsVisible, refreshAttendanceLogs }) {
    
    const [locations, setLocations] = useState([])

    const [callDecode, reqDecode] = usePost("/geolocation/update_attendance", {
        onCompleted: (data) => {
            setLocations(data.data)
            refreshAttendanceLogs()
        },
        onError: (err) => {}
    });

    const getLocation = async (log) => {
        await callDecode({ log });
    }

    const closeModal = () => {
        setLocations([]);
        setIsVisible(false);
    }

    return <View 
        data={data}
        locations={locations}
        getLocation={getLocation}
        closeModal={closeModal}
    />;

}

export default AttendanceDetails;