import React from "react";
import moment from 'moment';
import { getNameIncidence } from "utils/constants";

const ViewIncidenceRequest = ({
  incidenceRequest,
  onClose
}) => {

  const incidencesWithHour = [
		"PERMISSION_RELEASE_EARLY",
		"PERMISSION_ARRIVE_LATE",
		"PERMISSION_LEAVE_HOURS",
		"HOURLY_VACATIONS"
	]

  return (
		<div className='container mt-3'>

			<table class="table w-100" style={{ fontSize: "16px" }}>
				<tbody>
					<tr>
						<th scope="row">Folio</th>
						<td>SI - {incidenceRequest?.folio}</td>
					</tr>
					<tr>
						<th scope="row">Empleado</th>
						<td>{incidenceRequest?.employee?.firstname} {incidenceRequest?.employee?.dad_lastname} {incidenceRequest?.employee?.mom_lastname}</td>
					</tr>
					<tr>
						<th scope="row">Incidencia</th>
						<td>{getNameIncidence(incidenceRequest?.incidence?.type)}</td>
					</tr>
					{
						incidenceRequest?.incidence?.type === "INSERT_MARKINGS" ? (
							<tr>
								<th scope="row">Fecha</th>
								<td>{moment.utc(incidenceRequest?.start_date).format('DD/MM/YYYY')}</td>
							</tr>
						) : (
							<>
								<tr>
									<th scope="row">Fecha inicial</th>
									<td>{moment.utc(incidenceRequest?.start_date).format('DD/MM/YYYY')}</td>
								</tr>
								<tr>
									<th scope="row">Fecha final</th>
									<td>{moment.utc(incidenceRequest?.end_date).format('DD/MM/YYYY')}</td>
								</tr>
							</>
						)
					}
					{
						function () {
							const incidence = incidenceRequest?.incidence?.type;
							if (incidence === "INSERT_MARKINGS") {
								return (
									<>
										<tr>
											<th scope="row">Hora</th>
											<td>{incidenceRequest?.start_hour}</td>
										</tr>
										<tr>
											<th scope="row">Lector</th>
											<td>{incidenceRequest?.device?.description}</td>
										</tr>
									</>
								);
							} else if (incidencesWithHour.includes(incidence)) {
								return (
									<>
										<tr>
											<th scope="row">Hora inicial</th>
											<td>{incidenceRequest?.start_hour}</td>
										</tr>
										<tr>
											<th scope="row">Hora final</th>
											<td>{incidenceRequest?.end_hour}</td>
										</tr>
									</>
								);
							} else {
								return (<></>);
							}
						}()
					}

					<tr>
						<th scope="row">Solicitante</th>
						<td>{incidenceRequest?.applicant?.firstname} {incidenceRequest?.applicant?.lastname}</td>
					</tr>

					{
						incidenceRequest?.authorizer_rh && (
							<tr>
								<th scope="row">Auth. RH</th>
								<td>
									<p>{incidenceRequest?.authorizer_rh?.firstname} {incidenceRequest?.authorizer_rh?.lastname} ({incidenceRequest?.authorizer_rh?.username})</p>
									<p>{moment.utc(incidenceRequest?.date_auth_rh).format('DD/MM/YYYY HH:mm:ss')}</p>
								</td>
							</tr>
						)
					}

					{
						incidenceRequest?.authorizer_boss && (
							<tr>
								<th scope="row">Auth. Jefe</th>
								<td>
									<p>{incidenceRequest?.authorizer_boss?.firstname} {incidenceRequest?.authorizer_boss?.lastname} ({incidenceRequest?.authorizer_boss?.username})</p>
									<p>{moment.utc(incidenceRequest?.date_auth_boss).format('DD/MM/YYYY HH:mm:ss')}</p>
								</td>
							</tr>
						)
					}

					{
						incidenceRequest?.file?.url ? (
							<tr>
								<th scope="row">Archivo</th>
								<td><a target="_blank" class="text-decoration-underline" rel="noreferrer" href={incidenceRequest?.file?.url}>Ver archivo</a></td>
							</tr>
						) : null
					}

					{
						incidenceRequest?.observations ? (
							<tr>
								<th scope="row">Observaciones</th>
								<td>{incidenceRequest?.observations}</td>
							</tr>
						) : null
					}

				</tbody>
			</table>


			<div class="pt-3">
				<button type="button" class="btn btn-primary" style={{ marginLeft: "20px" }} onClick={onClose}>
					<i class="bi bi-x" style={{ marginRight: "10px" }}></i>
					Cerrar
				</button>
			</div>

		</div >
	);

}

export default ViewIncidenceRequest;