import React from "react";
import UserList from "components/crh/users/List";
import Modal from "helpers/Modal";

function SubareasView({
	company,
	name,
	search,
	onChangeSearch,
	handleKeyUp,
	isVisible,
	setIsVisible,
	modalTitle,
	modalContent,
	addModal,
	addEditModal,
	modalTitleEdit,
	isVisibleEdit,
	setIsVisibleEdit,
	modalContentEdit,
	filters,
	setFilters
}) {
	return (
		<>
			<div className="content container-fluid p-5">

				<div className="page-header mb-3">
					<div className="row align-items-end">

						<div className="col-sm mb-2 mb-sm-0">
							<h1 className="page-header-title">Usuarios {name ? `- ${name}` : ""}</h1>
						</div>

						<div className="col-sm-auto">
							<button type="button" className="btn btn-primary" onClick={addModal}>
								<i className="bi bi-plus-circle" style={{ marginRight: "7px" }}></i>
								Agregar usuario
							</button>
						</div>
					</div>
				</div>

				<div class="card">
					<div class="card-header card-header-content-md-between">
						<div class="mb-2 mb-md-0 row">
							<div className="col-md-2">
								<div class="input-group input-group-merge input-group-flush">
									<div class="input-group-prepend input-group-text" >
										<i class="bi-search"></i>
									</div>
									<input
										type="search"
										class="form-control"
										placeholder="Buscar empleado"
										onChange={onChangeSearch}
										onKeyUp={handleKeyUp}
									/>
								</div>
							</div>
							<div className='col-md-2 form-group'>
								<label className="input">
									<select 
										className="input__field form-control" 
										onChange={e => setFilters({...filters, type: e.target.value})}
										value={filters.type}
									>
										<option value="">Sin selección</option>
										<option value="GEOLOCATION">Geolocalización</option>
										<option value="ADMIN">Administrador</option>
										<option value="NORMAL">Estándar</option>
									</select>
									<span class="input__label">
										Tipo de usuario
									</span>
								</label>
							</div>
						</div>
					</div>

					<UserList
						refresh={Math.random()}
						company={company}
						filters={filters}
						search={search}
						addEditModal={addEditModal}
					/>

				</div>
			</div>

			<Modal
				title={modalTitle}
				isVisible={isVisible}
				setIsVisible={setIsVisible}
			>
				{modalContent}
			</Modal>

			<Modal
				title={modalTitleEdit}
				isVisible={isVisibleEdit}
				setIsVisible={setIsVisibleEdit}
			>
				{modalContentEdit}
			</Modal>

		</>
	);
}

export default SubareasView;