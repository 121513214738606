import React from 'react';
import { Formik, Form, Field } from 'formik';
import moment from 'moment';

function FormView({
  selectedGroups = {},
  selectedEmployees = {},
  handleGroupCheck = () => { },
  handleEmployeeCheck = () => { },
  formikRef = () => { },
  edit = false,
  group = {},
  jobs = [],
  departments = [],
  offices = [],
  bosses = [],
  groups = [],
  employees = [],
  onSubmit,
  onCancel,
  setSelectedOffice,
  setSelectedDepartment,
  setSelectedJob,
  setSelectedBoss
}) {
  return (
    <div className='container mt-3'>
      <Formik
        innerRef={formikRef}
        initialValues={group}
        onSubmit={onSubmit}>
        {({ values, setFieldValue, resetForm }) =>
          <Form>

            <div className='row my-3'>
              <div className='col-md-6 form-group'>
                <label class="input">
                  <Field type="text" name="key" class="input__field form-control" placeholder=" " required value={values.key || ''} />
                  <span class="input__label">
                    Clave
                    <span className='text-danger fw-bold'>*</span>
                  </span>
                </label>
              </div>
              <div className='col-md-6 form-group'>
                <label class="input">
                  <Field type="text" name="description" class="input__field form-control" placeholder=" " required value={values.description || ''} />
                  <span class="input__label">
                    Descripción
                    <span className='text-danger fw-bold'>*</span>
                  </span>
                </label>
              </div>
            </div>

            <div className='row my-3'>
              <div className='col-md-6 form-group'>
                <label class="input">
                  <input 
                    type="email" 
                    name="group_email" 
                    class="input__field form-control" 
                    placeholder=" "
                    onKeyDown={(e) => {
                      if(e.key === 'Enter' || e.key === ' ') {

                        e.preventDefault();

                        if(!e.target.value) return;
                        if(!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value)) return;

                        setFieldValue('group_email', values.group_email ? values.group_email + ';' + e.target.value : e.target.value);
                        e.target.value = '';

                      }
                    }}
                  />
                  <span class="input__label">
                    Correo
                    <span className='text-danger fw-bold'>*</span>
                  </span>
                </label>
                {
                    values.group_email 
                      ? values.group_email.split(';').map((email, index) => 
                        <span className='badge bg-primary me-1 mt-1'>
                          {email}
                          <i 
                            style={{ cursor: 'pointer' }}
                            class="bi bi-x" 
                            onClick={() => { 
                              setFieldValue('group_email', values.group_email.split(';').filter((_, i) => i !== index).join(';')) 
                            }}
                          />
                        </span>
                      )
                      : ''
                  }
              </div>
              <div className='col-md-6 form-group'>
                <label class="input">
                  <Field as="select" name="type" class="input__field form-control" required value={values.type || ''}>
                    <option value="">Seleccione una opción</option>
                    <option value="EMPLOYEE">Grupo de empleados</option>
                    <option value="GROUP">Grupo de grupos</option>
                  </Field>
                  <span class="input__label">
                    Tipo de grupo
                    <span className='text-danger fw-bold'>*</span>
                  </span>
                </label>
              </div>
            </div>

            <div className='row'>
              <div className='col'>
                <span className='fw-bold'>Filtros de empleados</span>
              </div>
            </div>

            <div className='row my-3'>
              <div className='col-md-3 form-group'>
                <label class="input">
                  <Field as="select" name="office_id" class="input__field form-control" onChange={(e) => setSelectedOffice(e.target.value)}>
                    <option value="">Seleccione una opción</option>
                    {
                      offices.map((office) =>
                        <option value={office.id}>{office.description}</option>
                      )
                    }
                  </Field>
                  <span class="input__label">
                    Sucursal
                  </span>
                </label>
              </div>
              <div className='col-md-3 form-group'>
                <label class="input">
                  <Field as="select" name="department_id" class="input__field form-control" onChange={(e) => setSelectedDepartment(e.target.value)}>
                    <option value="">Seleccione una opción</option>
                    {
                      departments.map((department) =>
                        <option value={department.id}>{department.description}</option>
                      )
                    }
                  </Field>
                  <span class="input__label">
                    Departamento
                  </span>
                </label>
              </div>
              <div className='col-md-3 form-group'>
                <label class="input">
                  <Field as="select" name="job_id" class="input__field form-control" onChange={(e) => setSelectedJob(e.target.value)}>
                    <option value="">Seleccione una opción</option>
                    {
                      jobs.map((job) =>
                        <option value={job.id}>{job.description}</option>
                      )
                    }
                  </Field>
                  <span class="input__label">
                    Puesto
                  </span>
                </label>
              </div>
              <div className='col-md-3 form-group'>
                <label class="input">
                  <Field as="select" name="boss_id" class="input__field form-control" onChange={(e) => setSelectedBoss(e.target.value)}>
                    <option value="">Seleccione una opción</option>
                    {
                      bosses.map((boss) =>
                        <option value={boss.id}>{boss.description}</option>
                      )
                    }
                  </Field>
                  <span class="input__label">
                    Jefe directo
                  </span>
                </label>
              </div>
            </div>

            {
              
              values.type === 'EMPLOYEE' &&

              <div class={"table-responsive datatable-custom position-relative"}>
                <div class="dataTables_wrapper no-footer pt-2 pl-3 pr-3" style={{maxHeight: "60vh"}}>
                  <table class="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
                    <thead>
                      <tr role="row">
                        <th>Seleccionar</th>
                        <th>ID Empleado</th>
                        <th>Nombre</th>
                        <th>Sucursal</th>
                        <th>Departamento</th>
                        <th>Puesto</th>
                        <th>Jefe directo</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        employees.map((employee) => (
                          <tr key={employee.id} role="row">
                            <td>
                              <div className='ms-4'>
                                <input type="checkbox" name="employees" onChange={(e) => handleEmployeeCheck(e, employee.id)} defaultChecked={selectedEmployees[employee.id]} />
                              </div>
                            </td>
                            <td>
                              {employee.key}
                            </td>
                            <td>
                              {employee.firstname} {employee.dad_lastname} {employee.mom_lastname}
                            </td>
                            <td>
                              {employee.office ? employee.office.description : ""}
                            </td>
                            <td>
                              {employee.department ? employee.department.description : ""}
                            </td>
                            <td>
                              {employee.job ? employee.job.description : ""}
                            </td>
                            <td>
                              {employee.boss ? employee.boss.description : ""}
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </div>
              
            }

            {

              values.type === 'GROUP' &&

              <div class={"table-responsive datatable-custom position-relative"}>
                <div class="dataTables_wrapper no-footer pt-2 pl-3 pr-3" style={{maxHeight: "60vh"}}>
                  <table class="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
                    <thead>
                      <tr role="row">
                        <th>Seleccionar</th>
                        <th>Clave</th>
                        <th>Nombre</th>
                        <th>Correo</th>
                        <th>Tipo de grupo</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        groups.map((group) => (
                          <tr key={group.id} role="row">
                            <td>
                              <div className='ms-4'>
                                <input type="checkbox" name="employees" onChange={(e) => handleGroupCheck(e, group.id)} defaultChecked={selectedGroups[group.id]} />
                              </div>
                            </td>
                            <td>
                              {group.key}
                            </td>
                            <td>
                              {group.description}
                            </td>
                            <td>
                              {group.group_email}
                            </td>
                            <td>
                              {group.type === 'EMPLOYEE' ? 'Grupo de empleados' : 'Grupo de grupos'}
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </div>

            }

            <div class="pt-3">
              <button type="submit" class="btn btn-primary">
                <i class="bi bi-plus" style={{ marginRight: "10px" }}></i>
                {edit ? "Editar" : "Guardar"}
              </button>

              <button type="button" class="btn btn-danger" style={{ marginLeft: "20px" }} onClick={onCancel.bind(null, resetForm)}>
                <i class="bi bi-x" style={{ marginRight: "10px" }}></i>
                Cancelar
              </button>
            </div>

          </Form>
        }
      </Formik>
    </div>
  )
}

export default FormView;