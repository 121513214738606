import React, { useState, useEffect } from "react";
import { usePost, useGetCall } from "utils/api";
import { showResponseMessage } from "utils/message";
import { toast } from "react-toastify";
import View from "components/crh/incidencesAuth/Filters.view";

function FormSave({ setIsVisible, setFilters, refresh }) {

    const [users, setUsers] = useState([]);
    const [refreshValue, setRefreshValue] = useState(null);
    const [incidences, setIncidences] = useState([]);

    if (refreshValue !== refresh) {
        setRefreshValue(refresh)
    }

    const [callIncidences, reqIncidences] = useGetCall(`/incidences`, {
        onCompleted: (response) => {
            setIncidences(response.data);
        }, onError: (error) => {
            showResponseMessage(error.status, "incidences", "incidencias", "read");
        }
    })


    const [callSave, reqSave] = usePost("/incidences", {
        onCompleted: () => {
            if (!toast.isActive("incidence-created"))
                toast.success("Incidencia creada correctamente", { toastId: "incidence-created" });

        },
        onError: (err) => {
            console.error(err)

            if (!toast.isActive("incidence-error"))
                toast.error("Error interno del servidor", { toastId: "incidence-error" });
        }
    })

    const [callUsers, reqUsers] = useGetCall('/users', {
        onCompleted: (response) => {
            setUsers(response.data);
        }, onError: (error) => {
            showResponseMessage(error.status, "users", "usuarios", "read")
        }
    })

    useEffect(() => {
        callUsers();
        callIncidences({request_rh: true})
    }, [refreshValue]);


    const onCancel = (resetForm) => {
        setIsVisible(false);
        resetForm({});
    }

    // const onChangeColor = (background) => setColor(background.hex);
    // const onClickShow = () => setDisplayColorPicker(!displayColorPicker);
    // const onCloseColorPicker = () => setDisplayColorPicker(false);

    const onSubmit = (values) => {
        for (let key in values)
            if (values[key] === "")
                values[key] = null

        setFilters(values)
        setIsVisible(false)
    }

    return (
        <View
            users={users}
            incidences={incidences}
            onSubmit={onSubmit}
            onCancel={onCancel}
        />
    )
}

export default FormSave;