import React, { useState, useEffect, useRef } from "react"
import { usePost, useGetCall } from "utils/api"
import { toast } from "react-toastify";
import { getOffices } from "api/offices";
import { getJobs } from "api/jobs";
import { getDepartments } from "api/departments";
import View from "components/crh/devices/Biophotos.view"
import { SERVER_URL } from '../../../settings';

function Biophotos({ id, setIsVisible, refresh }) {

    const [isVisibleBiophoto, setIsVisibleBiophoto] = useState(false);
    const [modalTitleBiophoto, setModalTitleBiophoto] = useState("");
    const [modalContentBiophoto, setModalContentBiophoto] = useState(null);

    const [loading, setLoading] = useState(false)
    const [biodatas, setBiodatas] = useState([])
    const [filters, setFilters] = useState({})

    const [offices, setOffices] = useState([]);
	const [jobs, setJobs] = useState([]);
	const [departments, setDepartments] = useState([]);

    const [callEmployees, reqDevices] = useGetCall("/devices/biophotos", {
		onCompleted: (response) => {
			setLoading(false);
			setBiodatas(response.data)
		},
		onError: (error) => {
			setLoading(false);
			// showResponseMessage(error.status, "devices", "lectores", "read")
		}
	})

    const handlePhotoClick = (biodata) => {
        setIsVisibleBiophoto(true)
        setModalTitleBiophoto(biodata.employee.key + " - " + biodata.employee.firstname + " " + biodata.employee.dad_lastname + " " + biodata.employee.mom_lastname)
        setModalContentBiophoto(
            <div className="d-flex align-items-center justify-content-center w-100">
                <img 
                    crossorigin="anonymous" 
                    src={biodata.bioPhoto.path ? (SERVER_URL + "/" + biodata.bioPhoto.path + "?not-from-cache-please") : (biodata.bioPhoto.url + "?not-from-cache-please")} 
                    alt="Foto"
                    style={{ width: "500px" }}
                />
            </div>
        )
    }

    useEffect(() => {
        setLoading(true)
        callEmployees({ id, ...filters })
    }, [id, refresh, filters])

    useEffect(() => {
        getOffices().then((response) => {
			if (response.errors.length > 0) {
				if (!toast.isActive("toast-offices-eror"))
					toast.error("Error al obtener las sucursales", { toastId: "toast-jobs-eror" })
			} else {
				setOffices(response.data)
			}
		})

		getJobs().then((response) => {
			if (response.errors.length > 0) {
				if (!toast.isActive("toast-jobs-eror"))
					toast.error("Error al obtener los puestos", { toastId: "toast-jobs-eror" })
			} else {
				setJobs(response.data)
			}
		})

		getDepartments().then((response) => {
			if (response.errors.length > 0) {
				if (!toast.isActive("toast-departments-eror"))
					toast.error("Error al obtener los departamentos", { toastId: "toast-departments-eror" })
			} else {
				setDepartments(response.data)
			}
		})
    }, [])

    return (
        <View 
            offices={offices}
            jobs={jobs}
            departments={departments}
            filters={filters}
            setFilters={setFilters}
            isVisibleBiophoto={isVisibleBiophoto}
            setIsVisibleBiophoto={setIsVisibleBiophoto}
            modalTitleBiophoto={modalTitleBiophoto}
            modalContentBiophoto={modalContentBiophoto}
            handlePhotoClick={handlePhotoClick}
            setIsVisible={setIsVisible} 
            biodatas={biodatas}
        />
    )

}

export default Biophotos