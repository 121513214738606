import React from "react";
import Chart from "chart.js/auto";
import moment from "moment";
import Modal from "helpers/Modal";
import ListHours from "components/home/tables/ListHours.js";
import ListTime from "components/home/tables/ListTime.js";
import ListComparativeTime from "components/home/tables/ListComparativeTime.js";
import ListTodayFouls from "components/home/tables/ListFouls.js";
import { ArcElement, Tooltip, Legend, CategoryScale } from 'chart.js';
import { Doughnut } from "react-chartjs-2";
import "resources/styles/Home.scss";

Chart.register(ArcElement, Tooltip, Legend, CategoryScale);

function AboutTodayView({
    lastUpdate,
    currentIncidences,
    globalLoading,
    loading,
    workedChart,
    employees,

    handleRefresh,
    
    isTodayAttendancesVisible,
    isTodayFoulsVisible,
    isTodayPunctualVisible,
    isTodayRetardmentVisible,
    isWorkedHoursVisible,

    setIsWorkedHoursVisible,
    setIsTodayAttendancesVisible,
    setIsTodayFoulsVisible,
    setIsTodayPunctualVisible,
    setIsTodayRetardmentVisible
}) {

    const isGlobalLoading = Object.values(globalLoading).some((value) => value)
    return (
        <>

            <div style={{ position: "absolute", top: "5px", right: "10px" }}>
                { loading && <div className="spinner-border spinner-border-sm text-primary" role="status"></div> }
            </div>

            <div className="d-flex py-2 px-3">
                <span className="h6 fw-bold text-primary">Acerca de hoy</span>
                <small className="ms-3 text-primary">{ moment().format("DD MMMM YYYY") }</small>
            </div>

            <div className="d-flex justify-content-between px-3 w-100">
                <div className="d-flex w-100">
                    <div className="border rounded p-2 w-100 me-3">
                        <small style={{ fontSize: "0.7rem" }} className="fw-bold text-dark">Última actualización</small> <br/>
                        <small style={{ fontSize: "0.7rem" }} className="text-muted"> 
                            { lastUpdate ? moment.utc(lastUpdate).local().format("dddd, DD MMMM YYYY hh:mm a") : "Sin datos" }
                        </small>
                    </div>
                </div>
                <div className="d-flex p-0 px-1" style={{ width: "6em" }}>
                    <button className="btn btn-success w-100 h-100" style={{ fontSize: "0.7rem" }} disabled={isGlobalLoading} onClick={handleRefresh}>
                        { isGlobalLoading 
                            ? <div className="spinner-border spinner-border-sm" role="status"></div>
                            : "Actualizar" 
                        }
                    </button>
                </div>
            </div>

            <div className="d-flex px-3 my-3 d-flex align-items-center flex-fill w-100">
                <div className="d-flex mx-auto pe-3" style={{ width: "50%", position: "relative" }}>
                    <Doughnut 
                        onClick={() => setIsWorkedHoursVisible(true)}
                        style={{ cursor: "pointer", minHeight: "100%" }}
                        data={workedChart}
                        options={{
                            cutout: "75%",
                            responsive: true,
                            maintainAspectRatio: false,
                            plugins: {
                                legend: {
                                    display: false,
                                },
                                tooltips: {
                                    enabled: false
                                }
                            }
                        }}
                        plugins={[]}
                    />
                    <span style={{ position: "absolute", top: "49%", left: "45%", transform: "translate(-45%, -49%)" }} className="h6 fw-bold text-dark">
                        {
                            function(){
                                if(currentIncidences && currentIncidences.total_hours && currentIncidences.worked_hours) {
                                    return (currentIncidences.worked_hours.split(':').reduce((acc, time) => (60 * acc) + +time) / 3600).toFixed(1) + " hrs"
                                }
                            }()
                        }
                    </span>
                </div>
                <div className="d-flex flex-column px-2 flex-fill" style={{ width: "50%" }}>
                    <div className="row my-0 py-0">
                        <div className="col-md-6 m-0 py-0 px-2">
                            <div className="border rounded p-1" onClick={() => setIsTodayAttendancesVisible(true)} style={{ cursor: "pointer" }}>
                                <small style={{ fontSize: "0.7rem" }} className="text-muted">Asistencia</small> <br/>
                                <div className="d-flex align-items-center justify-content-between w-100">
                                    <span className="h6 mt-1 fw-bold text-dark">
                                        {function(){
                                            if(currentIncidences && currentIncidences.attendances) 
                                                return currentIncidences.attendances
                                            return 0
                                        }()}
                                    </span>
                                    <i className="bi bi-arrow-up-right-square text-success" style={{ fontSize: "1rem" }}></i>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 m-0 py-0 px-2">
                            <div className="border rounded p-1" onClick={() => setIsTodayFoulsVisible(true)} style={{ cursor: "pointer" }}>
                                <small style={{ fontSize: "0.7rem" }} className="text-muted">Faltas</small> <br/>
                                <div className="d-flex align-items-center justify-content-between w-100">
                                    <span className="h6 mt-1 fw-bold text-dark">
                                        {function(){
                                            if(currentIncidences && currentIncidences.fouls) 
                                                return currentIncidences.fouls
                                            return 0
                                        }()}
                                    </span>
                                    <i className="bi bi-arrow-down-right-square text-danger" style={{ fontSize: "1rem" }}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-6 m-0 py-0 px-2">
                            <div className="border rounded p-1" onClick={() => setIsTodayPunctualVisible(true)} style={{ cursor: "pointer" }}>
                                <small style={{ fontSize: "0.7rem" }} className="text-muted">A tiempo</small> <br/>
                                <div className="d-flex align-items-center justify-content-between w-100">
                                    <span className="h6 mt-1 fw-bold text-dark">
                                        {function(){
                                            if(currentIncidences && currentIncidences.puntualities) 
                                                return currentIncidences.puntualities
                                            return 0
                                        }()}
                                    </span>
                                    <i className="bi bi-arrow-up-right-square text-success" style={{ fontSize: "1rem" }}></i>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 m-0 py-0 px-2">
                            <div className="border rounded p-1" onClick={() => setIsTodayRetardmentVisible(true)} style={{ cursor: "pointer" }}>
                                <small style={{ fontSize: "0.7rem" }} className="text-muted">Tarde</small> <br/>
                                <div className="d-flex align-items-center justify-content-between w-100">
                                    <span className="h6 mt-1 fw-bold text-dark">
                                        {function(){
                                            if(currentIncidences && currentIncidences.retardments) 
                                                return currentIncidences.retardments
                                            return 0
                                        }()}
                                    </span>
                                    <i className="bi bi-arrow-down-right-square text-danger" style={{ fontSize: "1rem" }}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mx-0">
                <div className="col-md-6 text-center border-top border-end">
                    <small style={{ fontSize: "0.85rem" }} className="fw-bold text-dark">Descanso</small> <br/>
                    <small style={{ fontSize: "0.7rem" }} className="text-muted">
                        {function(){
                            if(currentIncidences && currentIncidences.break_time) {
                                let seconds = currentIncidences.break_time
                                seconds = seconds.split(':').reduce((acc, time) => (60 * acc) + +time)
                                return (seconds / 3600).toFixed(1) + " hrs"
                            }
                            return "0 hrs"
                        }()}
                    </small> <br/>
                </div>
                <div className="col-md-6 text-center border-top border-start">
                    <small style={{ fontSize: "0.85rem" }} className="fw-bold text-dark">Tiempo extra</small> <br/>
                    <small style={{ fontSize: "0.7rem" }} className="text-muted">
                        {function(){
                            if(currentIncidences && currentIncidences.extra_time) {
                                let seconds = currentIncidences.extra_time
                                seconds = seconds.split(':').reduce((acc, time) => (60 * acc) + +time)
                                return (seconds / 3600).toFixed(1) + " hrs"
                            }
                            return "0 hrs"
                        }()}	
                    </small> <br/>
                </div>
            </div>

            <Modal
                title={"Asistencia de hoy"}
                isVisible={isTodayAttendancesVisible}
                setIsVisible={setIsTodayAttendancesVisible}
            >
                <ListTime 
                    employees={employees??[]}
                    incidences={currentIncidences?.tables?.attendance??[]} 
                    setIsVisible={setIsTodayAttendancesVisible}
                    dataKey="entry"
                    label="Hora de entrada"
                />
            </Modal>

            <Modal
                title={"Faltas de hoy"}
                isVisible={isTodayFoulsVisible}
                setIsVisible={setIsTodayFoulsVisible}
            >
                <ListTodayFouls 
                    employees={employees??[]}
                    incidences={currentIncidences?.tables?.foul??[]}
                    setIsVisible={setIsTodayFoulsVisible}
                />
            </Modal>

            <Modal
                title={"Asistencia puntual de hoy"}
                isVisible={isTodayPunctualVisible}
                setIsVisible={setIsTodayPunctualVisible}
            >
                <ListComparativeTime
                    incidences={currentIncidences?.tables?.punctuallity??[]}
                    setIsVisible={setIsTodayPunctualVisible}
                    employees={employees??[]}
                    key1={"entry"}
                    key2={"entryStart"}
                    label1={"Hora de entrada (real)"}
                    label2={"Hora de entrada (horario)"}
                />
            </Modal>

            <Modal
                title={"Retardos de hoy"}
                isVisible={isTodayRetardmentVisible}
                setIsVisible={setIsTodayRetardmentVisible}
            >
                <ListComparativeTime
                    employees={employees??[]}
                    incidences={currentIncidences?.tables?.retardment??[]}
                    setIsVisible={setIsTodayRetardmentVisible}
                    key1={"entry"}
                    key2={"entryStart"}
                    label1={"Hora de entrada (real)"}
                    label2={"Hora de entrada (horario)"}
                />
            </Modal>

            <Modal
                title={"Horas trabajadas de hoy"}
                isVisible={isWorkedHoursVisible}
                setIsVisible={setIsWorkedHoursVisible}
            >
                <ListHours
                    employees={employees??[]}
                    incidences={currentIncidences?.tables?.hours??[]}
                    setIsVisible={setIsWorkedHoursVisible}
                    currentKey="worked"
                    totalKey="total"
                    label="Horas trabajadas"
                />
            </Modal>

        </>
    )
}

export default AboutTodayView