import React from "react";
import Modal from "helpers/Modal";
import { Formik, Form, Field } from 'formik';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import moment from "moment";
const format = 'HH:mm';

function ProfilesListView({
	requests = [],
	profiles = [],
	selectedRequest = null,
	onSelectRequest = () => { },
	onAuth = () => { },
	onCancel = () => { },
	addEditModal = () => { },
	isVisibleEdit = false,
	setIsVisibleEdit = () => { },
	modalTitleEdit = "",
	modalContentEdit = "",
}) {
	return (<>
		<div class="table-responsive datatable-custom position-relative" >
			<div class="dataTables_wrapper no-footer pt-2 pl-3 pr-3" style={{ maxHeight: "60vh" }}>
				<table class="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
					<thead>
						<tr role="row">
							<th>Folio</th>
							<th>Nombre visitante</th>
							<th>A quién visita</th>
							<th>Depto</th>
							<th>Placas</th>
							<th>Marca</th>
							<th>Color</th>
							<th>Autorizó</th>
							<th>Acciones</th>
						</tr>
					</thead>
					<tbody>
						{
							requests.map((request) => (
								<tr key={request.id} role="row">
									<td>{request.folio}</td>
									<td>{request.name}</td>
									<td>{request.host}</td>
									<td>{request?.department?.key}</td>
									<td>{request.plates}</td>
									<td>{request?.brand?.key}</td>
									<td>{request?.color?.key}</td>
									<td>
										
										{ request.status == "PENDING" && "N/A" }

										{ request.status == "AUTH" && 
											<>
												Autorizado por	{request?.authorized_by?.firstname} {request?.authorized_by?.lastname} 
												{moment(request.auth_date).format("DD/MM/YYYY HH:mm:ss")}
											</> 
										}

										{ request.status == "REJECTED" && 
											<>
												Rechazado por	{request?.authorized_by?.firstname} {request?.authorized_by?.lastname} 
												{moment(request.auth_date).format("DD/MM/YYYY HH:mm:ss")}
											</> 
										}

									</td>
									<td>
										{
											request.status == "PENDING" && <>
												<button className="btn btn-success" style={{ marginRight: "10px" }} onClick={() => onSelectRequest(request)}>
													<i class="bi bi-fingerprint"></i>
												</button>
												<button className="btn btn-warning" style={{ marginRight: "10px" }} onClick={() => addEditModal(request.id)}>
													<i class="bi bi-pencil"></i>
												</button>
												<button className="btn btn-danger" style={{ marginRight: "10px" }} onClick={() => onCancel(request.id)}>
													<i class="bi bi-x-circle"></i>
												</button>
											</>
										}
									</td>
								</tr>
							))
						}
					</tbody>
				</table>
			</div>
		</div>

		<Modal
			title={"Autorización de visita"}
			isVisible={selectedRequest != null}
			setIsVisible={() => onSelectRequest(null)}
		>
			<Formik
        initialValues={{}}
        onSubmit={(values) => onAuth(selectedRequest.id, values)}>
        {({ values, setFieldValue, resetForm }) =>
          <Form>

						<div className='col-md-12 form-group my-3'>
							<label class="input">
								<Field as="select" name="visit_profile_id" class="input__field form-control" required value={values.visit_profile_id || ''}>
									<option value="">Seleccione una opción</option>
									{
										profiles.map((profile) =>
											<option value={profile.id}>{profile.description}</option>
										)
									}
								</Field>
								<span class="input__label">
									Perfil
									<span className='text-danger fw-bold'>*</span>
								</span>
							</label>
						</div>

						<div class="col-md-12 mb-3">
							<div class="form-group">
								<div class="row">
									<div class="col-md-2">
										<h5>Fecha inicial<span className='text-danger fw-bold'>*</span></h5>
									</div>
									<div class="col-md-4">
										<Field type="date" name="start_date" class="form-control input__field" required value={values.start_date || ''} />
									</div>
									<div class="col-md-2">
										<h5>Fecha final<span className='text-danger fw-bold'>*</span></h5>
									</div>
									<div class="col-md-4">
										<Field type="date" name="end_date" class="form-control input__field" required value={values.end_date || ''} />
									</div>
								</div>
							</div>
						</div>

						<div class="col-md-12 mb-3">
							<div class="form-group">
								<div class="row">
									<div class="col-md-2">
										<h5 className="">Hora inicial<span className='text-danger fw-bold'>*</span></h5>
									</div>
									<div class="col-md-4">
										<TimePicker className="w-100" format={format} onChange={time => setFieldValue("start_hour", time.format(format))} value={values.start_hour ? dayjs(values.start_hour, format) : ''} />
									</div>
									<div class="col-md-2">
										<h5>Hora final<span className='text-danger fw-bold'>*</span></h5>
									</div>
									<div class="col-md-4">
										<TimePicker className="w-100" format={format} onChange={time => setFieldValue("end_hour", time.format(format))} value={values.end_hour ? dayjs(values.end_hour, format) : ''} />
									</div>
								</div>
							</div>
						</div>

						<div class="col-md-12 mb-3">
							<div class="mb-3">
								<div class="form-group">
									<label class="input">
										<Field type="text" name="observations" class="form-control input__field" placeholder=" " value={values.observations || ''} />
										<span class="input__label">
											Observaciones
										</span>
									</label>
								</div>
							</div>
						</div>

						<div className='d-flex justify-content-end'>
							<button className='btn btn-danger' onClick={() => onSelectRequest(null)}>
								Cerrar
							</button>
							<button className='btn btn-primary ms-2'>
								Autorizar
							</button>
						</div>

					</Form>
				}</Formik>
		</Modal>

		<Modal
				title={modalTitleEdit}
				isVisible={isVisibleEdit}
				setIsVisible={setIsVisibleEdit}
			>
				{modalContentEdit}
			</Modal>

	</>);
}

export default ProfilesListView;