import React, { useState, useEffect, useContext, useRef } from "react";
// import Detail from "components/crh/holidayLogs/Detail";
import { fetchPost } from "utils/api";
import { useGetCall } from "utils/api";
import View from "components/crh/holidayLogs/HolidayLogs.view";
import { downloadCSV, downloadExcel } from "utils/files";
import { showResponseMessage } from "utils/message";
import { formatISODate, formatHolidaysHours, formatHolidaysDays } from "utils/date";
import { LoadingContext } from "helpers/LoadingContext";
import moment from "moment/moment";
import Loading from "helpers/Loading";
import { getUser } from "api/users";
import { toast } from "react-toastify";

function HolidayLogs({ theme }) {

  const id = sessionStorage.getItem("id");
  const logsRef = useRef(null);
  const { loading, setLoading } = useContext(LoadingContext);
  const [logs, setLogs] = useState([]);
  const [user, setUser] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [permissionExcel, setPermissionExcel] = useState(false);
  const [permissionPdf, setPermissionPdf] = useState(false);
  const [lmIsVisible, setLmIsVisible] = useState(false);
  const [permissionCreate, setPermissionCreate] = useState(false);
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({
    status: "ACTIVE"
  });
  const [offices, setOffices] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [payrolls, setPayrolls] = useState([]);

  const [callHolidayLogs, reqHolidayLogs] = useGetCall("/holiday_logs", {
		onCompleted: (response) => {
			setLoading(false);
			setLogs(response.data);
		},
		onError: (error) => {
			setLoading(false);
			showResponseMessage(error.status, "holiday_logs", "estados de cuenta de vacaciones", "read");
		}
	})

  const [callOffices, reqOffices] = useGetCall('/offices', {
    onCompleted: (response) => {
      setLoading(false);
      setOffices(response.data);
    }, onError: (error) => {
      setLoading(false);
      showResponseMessage(error.status, "offices", "sucursales", "read")
    }
  })

  const [callDepartments, reqDepartments] = useGetCall('/departments', {
    onCompleted: (response) => {
      setLoading(false);
      setDepartments(response.data);
    }, onError: (error) => {
      setLoading(false);
      showResponseMessage(error.status, "departments", "departamentos", "read")
    }
  })

  const [callJobs, reqJobs] = useGetCall('/jobs', {
    onCompleted: (response) => {
      setLoading(false);
      setJobs(response.data);
    }, onError: (error) => {
      setLoading(false);
      showResponseMessage(error.status, "jobs", "puestos", "read")
    }
  })

  const [callPayrolls, reqPayrolls] = useGetCall('/payrolls', {
    onCompleted: (response) => {
      setLoading(false);
      setPayrolls(response.data);
    }, onError: (error) => {
      setLoading(false);
      showResponseMessage(error.status, "payrolls", "nóminas", "read")
    }
  })

  const generateCells = () => {

    let csv = [];

    logs.forEach((log) => {

      csv.push([
        `ID:`,
        log.key,
        `Nombre:`,
        `${log.firstname} ${log.dad_lastname} ${log.mom_lastname}`,
        `Sucursal:`,
        log.office ? log.office.abbreviation : "",
        `Departamento:`,
        log.department ? log.department.abbreviation : "",
        `Puesto:`,
        log.job ? log.job.description : ""
      ])

      if(log.logs.length > 0) csv.push([]);

      csv.push([
        `Fecha`,
        `Movimiento`,
        `Días disponibles`,
        `Días solicitados`,
        `Saldo`,
      ]);

      log.logs.forEach((holidayLog) => {
        csv.push([
          `${holidayLog.type !== "NOW" ? formatISODate(holidayLog.date) : ""}`,
          function(){
            if(holidayLog.type === "INITIAL") 
              return "Saldo inicial";
            if(holidayLog.type === "MOVEMENT") 
              return `Movimiento de salgo por antigüedad (${moment(holidayLog.date).diff(moment(log.admission), 'years')} años)`;
            if(holidayLog.type === "REQUEST")
              return `Solicitud folio ${holidayLog.incidence_request.folio}, ${formatISODate(holidayLog.incidence_request.start_date)} - ${formatISODate(holidayLog.incidence_request.end_date)}`;
            if(holidayLog.type === "NOW")
              return "Saldo actual";
          }(),
          holidayLog.type === "INITIAL" || holidayLog.type === "MOVEMENT" 
            ? (
                sessionStorage.getItem("hour_holidays") == "true" 
                  ? formatHolidaysHours(holidayLog.available.hours) 
                  : formatHolidaysDays(holidayLog.available.days)
              ) 
            : "",
          holidayLog.type === "REQUEST" 
            ? (
                sessionStorage.getItem("hour_holidays") == "true" 
                  ? formatHolidaysHours(holidayLog.requested.hours) 
                  : formatHolidaysDays(holidayLog.requested.days) 
              )
            : "",
          holidayLog.type === "MOVEMENT" || holidayLog.type === "REQUEST" || holidayLog.type === "NOW" 
            ? (
                sessionStorage.getItem("hour_holidays") == "true" 
                  ? formatHolidaysHours(holidayLog.balance.hours) 
                  : formatHolidaysDays(holidayLog.balance.days)
              )
            : ""
        ]);
      });

      csv.push([]);
      csv.push([]);

    });

    return csv;

  }

  const onDownloadCSV = () => {
    downloadCSV({
      user,
      date: false,
      cells: generateCells(),
      report: "estado_de_cuenta_de_vacaciones",
    });
  }

  const onDownloadXlsx = async () => {
    downloadExcel({
      user,
      date: false,
      cells: generateCells("xlsx"),
      report: "estado_de_cuenta_de_vacaciones",
    })
  }

  useEffect(() => {
    getUser(id).then((response) => {
      setUser(response.data)
    });
  }, [id]);

  useEffect(() => {

    setLoading(true);

    fetchPost("/permissions/check", { table: "HOLIDAYS_MANAGE_REPORT", permission: "read" }).then((response) => {

      if(response.data) {
        
        const data = response.data;

        if(data.isAllowed === true) {

          fetchPost("/permissions/check", { table: "HOLIDAYS_MANAGE_REPORT", permission: "create" }).then((response) => {
            if(response.data) setPermissionCreate(response.data.isAllowed);
          });
          
          fetchPost("/permissions/check", { table: "HOLIDAYS_MANAGE_REPORT", permission: "excel" }).then((response) => {
            if(response.data) setPermissionExcel(response.data.isAllowed);
          });

          fetchPost("/permissions/check", { table: "HOLIDAYS_MANAGE_REPORT", permission: "pdf" }).then((response) => {
            if(response.data) setPermissionPdf(response.data.isAllowed);
          });

          callHolidayLogs({ search, ...filters })

        } 
        else {
          if(!toast.isActive("toast-create-admin_repoort-unauthorized")) {
            toast.error("Error, no tienes los permisos para consultar el reporte de administrador", { toastId: "toast-create-admin_repoort-unauthorized" })
          }
        }

      }

    })

    
  }, [search, filters]);

  useEffect(() => {
    callOffices();
    callDepartments();
    callJobs();
    callPayrolls();
  }, []);

  const onLoadDataModal = () => {
    setLmIsVisible(true);
  }

  const addDetailModal = (id) => {
    setIsVisible(true);
    setModalTitle("Movimientos");
    setModalContent(<div></div>
      // <Detail
      //   id={id}
      //   setIsVisible={setIsVisible}
      //   theme={theme}
      // />
    );
  }

  const onChangeSearch = (event) => {
    if (event.target.value === '') {
      setSearch(event.target.value);
    }
  }

  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      setSearch(event.target.value);
    }
  }

  if (loading) return <Loading />

  return (
    <View
      offices={offices}
      departments={departments}
      jobs={jobs}
      payrolls={payrolls}
      filters={filters}
      setFilters={setFilters}
      loading={loading}
      permissionCreate={permissionCreate}
      search={search}
      onChangeSearch={onChangeSearch}
      handleKeyUp={handleKeyUp}
      onLoadDataModal={onLoadDataModal}
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      modalTitle={modalTitle}
      modalContent={modalContent}
      addDetailModal={addDetailModal}
      theme={theme}
      lmIsVisible={lmIsVisible}
      setLmIsVisible={setLmIsVisible}
      logsRef={logsRef}
      logs={logs}
      onDownloadCSV={onDownloadCSV}
      onDownloadXlsx={onDownloadXlsx}
      user={user}
      permissionExcel={permissionExcel}
      permissionPdf={permissionPdf}
    />
  );

}

export default HolidayLogs;