import React from "react";
import Modal from "helpers/Modal";
import ListTime from "components/home/tables/ListTime.js";
import "resources/styles/Home.scss";

function SummaryView({
    filters, 
    setFilters,
    shifts,
    employees,
    meanEntryExit,
    isEntryVisible,
    isExitVisible,
    setIsEntryVisible,
    setIsExitVisible,
    loading
}) {
    return (
        <>

            <div style={{ position: "absolute", top: "5px", right: "10px" }}>
                { loading && <div className="spinner-border spinner-border-sm text-primary" role="status"></div> }
            </div>

            <div className="d-flex align-items-center justify-content-between pt-1">
                <div className="h6 fw-bold mt-1 text-primary w-100">Resúmen</div>
                <select 
                    className="text-primary form-select border-0 py-0" 
                    style={{ fontSize: "0.7rem" }}
                    value={filters.shift_id || ""}
                    onChange={(e) => {
                        setFilters({
                            ...filters,
                            shift_id: e.target.value
                        })
                    }}
                >
                    <option value="">Turno</option>
                    {
                        shifts.map((shift, index) => (
                            <option key={index} value={shift.id}>{shift.description}</option>
                        ))
                    }
                </select>
            </div>

            <div className="border rounded px-2 pt-2 pb-1 my-2 d-flex w-100">
                <div className="d-flex align-items-start p-0">
                    <div className="bg-primary rounded text-center align-middle mt-1" style={{ width: "30px", height: "30px" }}>
                        <i className="bi bi-person text-white" style={{ fontSize: "1.4rem" }}></i>
                    </div>
                    <div className="d-flex flex-column ms-2">
                        <div className="text-muted" style={{fontSize: "0.75em"}}>Empleados registrados</div>
                        <div className="h5 fw-bold text-dark" style={{fontSize: "0.9em"}}>
                            {function(){
                                if(meanEntryExit && meanEntryExit.total_employees) 
                                    return meanEntryExit.total_employees
                                return 0
                            }()}
                        </div>
                    </div>
                </div>
            </div>

            <div className="border rounded px-2 pt-2 pb-1 my-2 d-flex w-100">
                <div className="d-flex align-items-start">
                    <div className="bg-primary rounded text-center align-middle mt-1" style={{ width: "30px", height: "30px" }}>
                        <i className="bi bi-arrow-down-left-square text-white" style={{ fontSize: "1.3rem" }}></i>
                    </div>
                    <div className="d-flex flex-column ms-2" onClick={() => setIsEntryVisible(true)} style={{ cursor: "pointer" }}>
                        <div className="text-muted" style={{fontSize: "0.75em"}}>Promedio hora entrada</div>
                        <div className="h5 fw-bold text-dark" style={{fontSize: "0.9em"}}>
                            {function(){

                                const getHour = () => {
                                    if(meanEntryExit && meanEntryExit.mean_entry) 
                                        return meanEntryExit.mean_entry.split(":")[0] + ":" + meanEntryExit.mean_entry.split(":")[1]
                                    return "00:00"
                                }

                                return getHour() !== "00:00" ? getHour() : "Sin datos"
                                
                            }()}
                        </div>
                    </div>
                </div>
            </div>

            <div className="border rounded px-2 pt-2 pb-1 my-2 d-flex w-100">
                <div className="d-flex align-items-start">
                    <div className="bg-primary rounded text-center align-middle mt-1" style={{ width: "30px", height: "30px" }}>
                        <i className="bi bi-arrow-up-right-square text-white" style={{ fontSize: "1.3rem" }}></i>
                    </div>
                    <div className="d-flex flex-column ms-2" onClick={() => setIsExitVisible(true)} style={{ cursor: "pointer" }}>
                        <div className="text-muted" style={{fontSize: "0.75em"}}>Promedio hora salida</div>
                        <div className="h5 fw-bold text-dark" style={{fontSize: "0.9em"}}>
                            {function(){
                                
                                const getHour = () => {
                                    if(meanEntryExit && meanEntryExit.mean_exit) 
                                        return meanEntryExit.mean_exit.split(":")[0] + ":" + meanEntryExit.mean_exit.split(":")[1]
                                    return "00:00"
                                }

                                return getHour() !== "00:00" ? getHour() : "Sin datos"
                                
                            }()}
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                title={"Hora de entrada"}
                isVisible={isEntryVisible}
                setIsVisible={setIsEntryVisible}
            >
                <ListTime
                    employees={employees??[]}
                    incidences={meanEntryExit?.reports??[]}
                    setIsVisible={setIsEntryVisible}
                    dataKey="entry"
                    label="Hora de entrada"
                />
            </Modal>

            <Modal
                title={"Hora de salida"}
                isVisible={isExitVisible}
                setIsVisible={setIsExitVisible}
            >
                <ListTime
                    employees={employees??[]}
                    incidences={meanEntryExit?.reports??[]}
                    setIsVisible={setIsExitVisible}
                    dataKey="exit"
                    label="Hora de salida"
                />
            </Modal>

        </>
    )
}

export default SummaryView