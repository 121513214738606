import React, { useState, useEffect, useRef } from "react";
import { usePost, usePut } from "utils/api";
import { getUsers } from "api/users";
import { toast } from "react-toastify";
import View from "components/crh/permissions/FormUsers.view";


function FormSet({ id, setIsVisible, refresh }) {

    const formikRef = useRef();
    const searchRef = useRef();
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [search, setSearch] = useState("");
    const [selectAll, setSelecAll] = useState(false);
    const [refreshValue, setRefreshValue] = useState(null);


    if (refresh !== refreshValue) {
        setRefreshValue(refresh);
    }

    const [callAssign, reqAssign] = usePut("/permissions/assign", {
        onCompleted: () => {
            if (!toast.isActive("permission-assigned")) {
                toast.success("Usuarios con perfil actualizados correctamente", { toastId: "permission-assigned" })
            }
            setSelecAll(false);
            setSelectedUsers([]);
            setUsers([]);
            setSearch("");
            searchRef.current.value = "";
            setIsVisible(false);
        },
        onError: (error) => {
            console.error(error);
            if (!toast.isActive("permission-assigned-error")) {
                toast.error("Error al asignar el perfil a los usuarios", { toastId: "permission-assigned-error" })
            }
        }
    })

    useEffect(() => {
        getUsers({ me: true, search: search }).then((response) => {
            if (response.data) {
                const data = response.data;
                const newSelectedUsers = [];
                for (let user of data) {
                    if (user.profileData && user.profileData.id === id) {
                        newSelectedUsers.push(user.id);
                    }
                }
                setSelectedUsers([...newSelectedUsers]);
                setUsers(data);
            } else {
                toast.error("Error al cargar los usuarios");
            }
        });
    }, [id, search, refreshValue])

    const onSubmit = () => {

        if (selectedUsers.length === 0) {
            toast.error("No se seleccionó ningún usuario");
            return;
        }

        callAssign({
            id: id,
            users: selectedUsers
        })

    }

    const onCancel = () => {
        setSelecAll(false);
        setSelectedUsers([]);
        setUsers([]);
        setSearch("");
        searchRef.current.value = "";
        setIsVisible(false);
    }

    const onChangeSearch = (event) => {
        if (event.target.value === '') {
            setSearch(event.target.value);
        }
    }

    const handleKeyUp = (event) => {
        if (event.key === "Enter") {
            setSearch(event.target.value);
        }
    }

    const selectUser = (id) => {
        const newSelectedUsers = [...selectedUsers];
        const index = newSelectedUsers.findIndex((item) => item === id);

        if (index === -1) {
            newSelectedUsers.push(id);
        } else {
            newSelectedUsers.splice(index, 1);
        }

        setSelectedUsers([...newSelectedUsers]);
    }

    return (
        <View
            users={users}
            searchRef={searchRef}
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
            selectAll={selectAll}
            setSelecAll={setSelecAll}
            formikRef={formikRef}
            onSubmit={onSubmit}
            onCancel={onCancel}
            selectUser={selectUser}
            handleKeyUp={handleKeyUp}
            onChangeSearch={onChangeSearch}
        />
    )


}

export default FormSet;
