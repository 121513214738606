import React from 'react';
import { toast } from 'react-toastify';
import { usePost } from 'utils/api';
import { IS_TEST } from "settings";
import View from "components/auth/Restore.view";

function Restore({ location }) {

  const urlParams = new URLSearchParams(location.search);
  const token = urlParams.get('token');

  const superAdmin = location.pathname.includes("admin");

	const [callRestore, reqRestore] = usePost("/auth/restore", {
		onCompleted: ({ data }) => {

			if(!toast.isActive("restore-success"))
				toast.success("Se ha reestablecido la contraseña, redirigiendo...", { toastId: "restore-success" });

      setTimeout(() => {
        if (superAdmin) {
          window.location.href = (IS_TEST ? "/test" : "") + "/admin/login";
        } else {
          window.location.href = (IS_TEST ? "/test" : "") + "/login";
        }
      }, 2000);

		},
		onError: (error) => {
			console.error(error);
			if(!toast.isActive("restore-error"))
				toast.error("Ha ocurrido un error.", { toastId: "restore-error" });
		},
		includeAuth: false
	});

	const onSubmit = (values) => {

		const password = values.password;
    const confirmPassword = values.confirmPassword;

		if(!password || !confirmPassword || password !== confirmPassword) {
      toast.error("Las contraseñas no coinciden.");
			return;
		}

		callRestore({
      token,
      password,
      type: superAdmin ? "SUPERADMIN" : "CLIENT"
		})

	}

	return <View onSubmit={onSubmit} superAdmin={superAdmin} />
}

export default Restore;