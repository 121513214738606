import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { useGetCall } from "utils/api";
import Loading from "helpers/Loading";
import { showResponseMessage } from "utils/message";
import { LoadingContext } from "helpers/LoadingContext";
import View from "components/crh/plannings/List.view";

function List({
	ref,
	theme,
	searchFilters,
	refresh,
	onDownloadXlsx,
	addEditPlanningModal,
	addDetailsModal,
}) {

	const { loading, setLoading } = useContext(LoadingContext);

	const [plannings, setPlannings] = useState([]);
	const [refreshValue, setRefreshValue] = useState(null);

	if (refresh !== refreshValue) {
		setRefreshValue(refresh);
	}

	const [callPlannings, reqRegimens] = useGetCall("/plannings", {
		onCompleted: (response) => {
			setLoading(false);
			setPlannings(response.data)
		},
		onError: (error) => {
			setLoading(false);
			showResponseMessage(error.status, "regimes", "regimens", "read");
		}
	})

	useEffect(() => {
		setLoading(true);
		callPlannings({ ...searchFilters });
	}, [refreshValue, searchFilters]);

	const onEdit = (id) => {
		addEditPlanningModal(id);
	}

	if (loading) return <Loading />

	return (
		<View 
			plannings={plannings} 
			onDownloadXlsx={onDownloadXlsx}
			addEditPlanningModal={addEditPlanningModal} 
			onDetails={addDetailsModal}
			onEdit={onEdit} 
		/>
	);
}

export default List;