import React from "react";

function getTypeUserName(type) {
	switch (type) {
		case "ADMIN":
			return "Administrador";
		case "NORMAL":
			return "Estandar";
		default:
			return "";
	}
}

function getProfileName(profile) {
	switch (profile) {
		case "BOSS":
			return "Jefe";
		case "RH":
			return "RH";
		default:
			return "";
	}
}

function ListView({ users, onDelete, onEdit }) {

	return (
		<div class="table-responsive datatable-custom position-relative" >
			<div class="dataTables_wrapper no-footer pt-2 pl-3 pr-3" style={{ maxHeight: "60vh" }}>
				<table class="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
					<thead>
						<tr role="row">
							<th>Usuario</th>
							<th>ID empleado</th>
							<th>Nombre</th>
							<th>Correo</th>
							<th>Puesto</th>
							<th>Departamento</th>
							<th>Perfil autorización</th>
							<th>Tipo de usuario</th>
							<th>Acciones</th>
						</tr>
					</thead>
					<tbody>
						{
							users.map((user) => (
								<tr key={user.id} role="row">
									<td>{user.username}</td>
									<td>{user?.employee?.key ? user.employee.key : "N/A"}</td>
									<td>{user.firstname} {user.lastname}</td>
									<td>{user.email}</td>
									<td>{user.job ? user.job.abbreviation : ''}</td>
									<td>{user.department ? user.department.abbreviation : ''}</td>
									<td>{getProfileName(user.profile)}</td>
									<td>
										<div> { getTypeUserName(user.type) } </div>
										{
											user.employee && user.employee.geolocation && <div class="badge text-white bg-success">Geolocalización</div>
										}
									</td>
									<td>
										<button className="btn btn-warning text-center" style={{ marginRight: "10px" }} onClick={() => onEdit(user.id)}>
											<i class="bi bi-pencil"></i>
										</button>
										<button className="btn btn-danger text-center" onClick={() => onDelete(user.id)}>
											<i class="bi bi-trash"></i>
										</button>
									</td>
								</tr>
							))
						}
					</tbody>
				</table>
			</div>
		</div>

	);
}

export default ListView;