import React, { useState, useEffect, useLayoutEffect, useRef } from "react"
import { useGetCall } from "utils/api"
import { toast } from "react-toastify"
import View from "components/home/dashboard/IncidencesChart.view"
import { getJSON } from "utils/sessionStorage";

function IncidencesChart({ filters, refreshValue, globalLoading, setGlobalLoading }) {

	const incidencesChartRef = useRef(null)
	const [loading, setLoading] = useState(false)
	const [employees, setEmployees] = useState([])
	const [incidencesData, setIncidencesData] = useState(getJSON("incidencesData", []))
	const [incidencesChart, setIncidencesChart] = useState({ datasets: [], })

	const [isIncidencesVisible, setIsIncidencesVisible] = useState(false)

	const [callDashboard, reqDashboard] = useGetCall("/statistics/incidences_chart", {
		onCompleted: (response) => {
			setIncidencesData(response?.data??[])
			setLoading(false)
			setGlobalLoading({ ...globalLoading, incidencesChart: false })
		},
		onError: (error) => {
			setLoading(false);
			if (error.status === 403) {
				if (!toast.isActive("toast-read-xxxxx-unauthorized")) {
					toast.error("Error, no tienes los permisos para consultar xxxxxxxx", { toastId: "toast-read-xxxxx-unauthorized" })
				}
			} else {
				if (!toast.isActive("toast-xxxxx-error")) {
					toast.error("Error al obtener ", { toastId: "toast-xxxxx-error" })
				}
			}
		}
	})

	const loadIncidencesChart = () => {

		if(incidencesChartRef != null && incidencesChartRef.current != null && incidencesChartRef.current.canvas != null && incidencesChartRef.current.ctx != null) {

			const chart = incidencesChartRef.current
			
			const canvas = chart.canvas
			const context = chart.ctx
			
			const canvasWidth = canvas.width
			const canvasHeight = canvas.height

			const gradient = context.createRadialGradient(
				canvasWidth / 2,
				canvasHeight / 2,
				canvasWidth / 3,
				canvasWidth / 2,
				canvasHeight / 2,
				1
			)

			const gradientGray = context.createRadialGradient(
				canvasWidth / 2,
				canvasHeight / 2,
				canvasWidth / 3,
				canvasWidth / 2,
				canvasHeight / 2,
				1
			)

			const gradientDark = context.createRadialGradient(
				canvasWidth / 2,
				canvasHeight / 2,
				canvasWidth / 3,
				canvasWidth / 2,
				canvasHeight / 2,
				1
			)

			gradient.addColorStop(1, "rgba(54, 162, 235, 0.45)");
			gradientGray.addColorStop(1, "rgba(64, 64, 64, 0.45)");
			gradientDark.addColorStop(1, "rgba(0, 0, 0, 0.45)");
			
			if(incidencesData) {

				const gradients = [
					gradientDark,
					gradientGray,
					gradient,
				]
	
				setIncidencesChart({
					labels: ['V', 'L', 'PSG', 'F', 'TE', 'R', 'PCG'],
					datasets: incidencesData.map((data, idx) => ({
						data: [
							data.holdaysCount,
							data.licenseCount,
							data.permissionWithoutPayCount,
							data.foulsCount,
							data.extraTimeCount,
							data.retardmentCount,
							data.permissionWithPayCount,
						],
						backgroundColor: gradients[idx],
						borderWidth: 0,
					}))
				})

			}

		}

	}

	useLayoutEffect(() => {
		loadIncidencesChart()
	}, [])

	useEffect(() => {
		loadIncidencesChart()
		try{ sessionStorage.setItem("incidencesData", JSON.stringify(incidencesData)) }
		catch(e) { console.log(e) }
	}, [incidencesData])

    useEffect(() => {
			setLoading(true)
			setGlobalLoading({ ...globalLoading, incidencesChart: true })
			callDashboard({ ...filters })
    }, [refreshValue])

	return (
		<View
			loading={loading}
			employees={employees}
			incidencesData={incidencesData}
			incidencesChart={incidencesChart}
			isIncidencesVisible={isIncidencesVisible}
			incidencesChartRef={incidencesChartRef}
			setIsIncidencesVisible={setIsIncidencesVisible}
		/>
	)

}

export default IncidencesChart