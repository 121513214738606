import React from "react";
import moment from "moment";
import { formatISODate } from "utils/date";
import { getNameIncidence } from "utils/constants";
import "moment/locale/es-mx";
import ReactToPrint from "react-to-print";
moment.locale('es');


const getAvailableRequests = (incidencesRequests, user) => {

	const availableRequests = [];
	for (const incidenceRequest of incidencesRequests) {
		const status = incidenceRequest.status_request;
		if (status === "CANCELED") continue;
		if (status === "AUTH_RH") continue;

		const userProfile = user.profile;
		if (userProfile === "" || userProfile == null) continue;

		if (status === "AUTH_BOSS") {
			if (userProfile === "RH") {
				availableRequests.push(incidenceRequest);
			}
			continue;
		}

		if (status === "ACTIVE") {
			availableRequests.push(incidenceRequest);
		}
	}

	return availableRequests;
}

const getButtons = (incidenceRequest, user, onAuth, onEdit, onCancel, onDownloadReport) => {

	if (incidenceRequest.status_request === "ACTIVE") {
		return (
			<>
				<button className="btn btn-success" style={{ marginRight: "10px" }} onClick={() => onAuth(incidenceRequest.id)}>
					<i class="bi bi-fingerprint"></i>
				</button>
				<button className="btn btn-warning" style={{ marginRight: "10px" }} onClick={() => onEdit(incidenceRequest.id)}>
					<i class="bi bi-pencil"></i>
				</button>
				<button className="btn btn-danger" style={{ marginRight: "10px" }} onClick={() => onCancel(incidenceRequest.id)}>
					<i class="bi bi-x-circle"></i>
				</button>
			</>
		)

	} else if (incidenceRequest.status_request === "CANCELED") {

		return (<></>)

	} else if (incidenceRequest.status_request === "AUTH_BOSS") {

		if (user.profile === "RH") {
			return (
				<>
					<button className="btn btn-success" style={{ marginRight: "10px" }} onClick={() => onAuth(incidenceRequest.id)}>
						<i class="bi bi-fingerprint"></i>
					</button>
					<button className="btn btn-warning" style={{ marginRight: "10px" }} onClick={() => onEdit(incidenceRequest.id)}>
						<i class="bi bi-pencil"></i>
					</button>
					<button className="btn btn-danger" style={{ marginRight: "10px" }} onClick={() => onCancel(incidenceRequest.id)}>
						<i class="bi bi-x-circle"></i>
					</button>
					<button className="btn btn-success" style={{ marginRight: "10px" }} onClick={() => onDownloadReport(incidenceRequest.id)}>
						<i class="bi bi-file-earmark-bar-graph-fill"></i>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="btn btn-warning" style={{ marginRight: "10px" }} onClick={() => onEdit(incidenceRequest.id)}>
						<i class="bi bi-pencil"></i>
					</button>
					<button className="btn btn-danger" style={{ marginRight: "10px" }} onClick={() => onCancel(incidenceRequest.id)}>
						<i class="bi bi-x-circle"></i>
					</button>
					<button className="btn btn-success" style={{ marginRight: "10px" }} onClick={() => onDownloadReport(incidenceRequest.id)}>
						<i class="bi bi-file-earmark-bar-graph-fill"></i>
					</button>
				</>
			);
		}

	} else if (incidenceRequest.status_request === "AUTH_RH") {
		return (
			<>
				<button className="btn btn-warning" style={{ marginRight: "10px" }} onClick={() => onEdit(incidenceRequest.id)}>
					<i class="bi bi-pencil"></i>
				</button>
				<button className="btn btn-danger" style={{ marginRight: "10px" }} onClick={() => onCancel(incidenceRequest.id)}>
					<i class="bi bi-x-circle"></i>
				</button>
				<button className="btn btn-success" style={{ marginRight: "10px" }} onClick={() => onDownloadReport(incidenceRequest.id)}>
					<i class="bi bi-file-earmark-bar-graph-fill"></i>
				</button>
			</>
		)
	}

}

function ListView({ addModal, selected, setSelected, user, onAuth, onAuthGroup, onCancel, onEdit, incidencesRequests, onDownloadReport, onSelectCheckbox }) {

	return (
		<>
			<div className="page-header mb-3">
				<div className="row align-items-end">

					<div className="col-sm mb-2 mb-sm-0">
						<h1 className="page-header-title">Autorización de Incidencias</h1>
					</div>

					<div className="col-sm-auto">
						<button type="button" className="btn btn-primary"
							style={{ marginRight: "25px" }}
							onClick={addModal}
						>
							<i className="bi bi-plus-circle" style={{ marginRight: "7px" }}></i>
							Filtros de búsqueda
						</button>
						{
							(user?.profile === "BOSS" || user.profile === "RH") ? (
								<button type="button" className="btn btn-success" onClick={onAuthGroup}>
									<i className="bi bi-fingerprint" style={{ marginRight: "7px" }}></i>
									{user?.profile === "BOSS" && "Auth. Jefe"}
									{user?.profile === "RH" && "Auth. RH"}
								</button>
							) : null
						}
					</div>
				</div>
			</div>

			<div class="card">
				<div class="table-responsive datatable-custom position-relative" >
					<div class="dataTables_wrapper no-footer pt-2 pl-3 pr-3" style={{ maxHeight: "60vh" }}>
						<table class="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
							<thead>
								<tr role="row">
									<th>Folio</th>
									<th>Solicitante</th>
									<th>ID empleado</th>
									<th>Nombre empleado</th>
									<th>Incidencia</th>
									<th>Fecha inicio</th>
									<th>Fecha fin</th>
									<th>Hora inicio</th>
									<th>Hora fin</th>
									<th>Auth. Jefe</th>
									<th>Auth. RH</th>
									<th>
										<div class="text-center align-items-center">
											<input type="checkbox" class="form-check-input"
												style={{ width: "25px", height: "25px" }}
												onClick={() => {
													const items = getAvailableRequests(incidencesRequests, user);

													if (selected.length === items.length) {
														setSelected([]);
													} else {
														setSelected(items.map((item) => { return item.id }))
													}
												}}
												checked={selected.length === getAvailableRequests(incidencesRequests, user).length}
											/>
										</div>
									</th>
									<th>Acciones</th>
								</tr>
							</thead>
							<tbody>
								{
									incidencesRequests.map((incidenceRequest) => (
										<tr key={incidenceRequest.id} role="row">
											<td>{incidenceRequest.folio}</td>
											<td>{incidenceRequest?.applicant?.firstname + " " + incidenceRequest?.applicant?.lastname}</td>
											<td>{incidenceRequest?.employee?.key}</td>
											<td>{incidenceRequest?.employee?.firstname + " " + incidenceRequest?.employee?.mom_lastname + " " + incidenceRequest?.employee?.dad_lastname}</td>
											<td>{incidenceRequest?.incidence.description ?? ""}</td>
											<td>{formatISODate(incidenceRequest?.start_date, true)}</td>
											<td>{
												incidenceRequest?.incidence.type === "INSERT_MARKINGS" ? (
													"N/A"
												) : (
													formatISODate(incidenceRequest.end_date, true)
												)
											}</td>
											<td>{incidenceRequest.start_hour || "N/A"}</td>
											<td>{incidenceRequest.end_hour || "N/A"}</td>
											<td>{
												incidenceRequest?.authorizer_boss ? (
													<>
														<p>{moment(incidenceRequest?.date_auth_boss).format("DD/MM/YYYY HH:mm:ss")}</p>
														<p>{incidenceRequest?.authorizer_boss?.username}</p>
													</>
												) : "Sin autorizar"
											}</td>
											<td>{
												incidenceRequest?.authorizer_rh ? (
													<>
														<p>{moment(incidenceRequest?.date_auth_rh).format("DD/MM/YYYY HH:mm:ss")}</p>
														<p>{incidenceRequest?.authorizer_rh?.username}</p>
													</>
												) : "Sin autorizar"
											}</td>
											<td>
												{
													function () {
														const status = incidenceRequest.status_request;
														if (status === "CANCELED") {
															return (<div class="text-center">
																<i class="bi bi-x-square-fill" style={{
																	color: "red",
																	fontSize: "28px"
																}}></i>
																<p>Cancelada</p>
															</div>);
														}

														if (status === "AUTH_RH") {
															return <></>;
														}

														const userProfile = user.profile;
														if (userProfile === "" || userProfile == null) {
															return <></>;
														}

														if(status === "AUTH_BOSS") {
															if(userProfile === "RH") {
																return <div class="text-center align-items-center">
																	<input type="checkbox" class="form-check-input"
																		onClick={() => onSelectCheckbox(incidenceRequest.id)}
																		style={{ width: "25px", height: "25px" }}
																		checked={selected.findIndex((element) => element === incidenceRequest.id) !== -1}
																	/>
																</div>
															}
															return <></>;
														}

														if(status === "ACTIVE") {
															return <div class="text-center align-items-center">
																<input type="checkbox" class="form-check-input"
																	onClick={() => onSelectCheckbox(incidenceRequest.id)}
																	style={{ width: "25px", height: "25px" }}
																	checked={selected.findIndex((element) => element === incidenceRequest.id) !== -1}
																/>
															</div>
														}
													}()
												}
											</td>
											<td>
												<div class="d-flex">
													{getButtons(incidenceRequest, user, onAuth, onEdit, onCancel, onDownloadReport)}
												</div>
											</td>
										</tr>
									))
								}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>

	);
}

export default ListView;