import React, { useState } from "react";
import View from "components/crh/incidenceRequests/Details.view";

function Details({ formikRef, employee, setEmployee }) {

	const onSubmit = () => {
		formikRef.current.submitForm();
	} 

	const onCancel = () => {
		formikRef.current.resetForm({})
		setEmployee(null);
	}

	return (
		<View
			employee={employee}
			onSubmit={onSubmit}
			onCancel={onCancel}
		/>
	);
}

export default Details;