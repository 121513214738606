import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import Loading from "helpers/Loading";
import { useDelete, useGetCall, fetchPost } from "utils/api";
import { showResponseMessage } from "utils/message";
import { LoadingContext } from "helpers/LoadingContext";
import View from "components/crh/visits/documentTypes/List.view";

function DocumentTypesList({
	search,
	refresh,
	addEditModal
}) {

	const { loading, setLoading } = useContext(LoadingContext);

	const [documentTypes, setDocumentTypes] = useState([]);
	const [refreshValue, setRefreshValue] = useState(null);
	const [refreshDelete, setRefreshDelete] = useState(null);

	if (refreshValue !== refresh) {
		setRefreshValue(refresh);
	}


	const [callDocumentTypes, reqDocumentTypes] = useGetCall("/document_types", {
		onCompleted: (response) => {
			setLoading(false);
			setDocumentTypes(response.data)
		},
		onError: (error) => {
			setLoading(false);
			showResponseMessage(error.status, "document_types", "tipos de documento", "read");
		}
	})


	const [callDelete, reqDelete] = useDelete("/document_types", {
		onCompleted: () => {
			if (!toast.isActive("serie-deleted"))
				toast.success("Tipo de documento eliminado", { toastId: "serie-deleted" });

			setRefreshDelete(Math.random());
		},
		onError: (error) => {
			console.error(error)
			showResponseMessage(error.status, "document_types", "tipos de documento", "delete");
		}
	});

	useEffect(() => {
		setLoading(true);
		callDocumentTypes({ search });
	}, [search, refreshValue, refreshDelete]);

	const onDelete = (id) => {
		fetchPost("/permissions/check", { table: "SUBBRANDS", permission: "delete" }).then((response) => {
			if (response.data) {
				const data = response.data;
				if (data.isAllowed === true) {
					if (window.confirm("¿Estás seguro de eliminar la marca?")) {
						callDelete({ id: id });
					}
				} else {
					if (!toast.isActive("toast-delete-catalogs-unauthorized")) {
						toast.error("Error, no tienes los permisos para eliminar tipos de documento", { toastId: "toast-delete-document_types-unauthorized" })
					}
				}
			}
		})
	}

	const onEdit = (id) => {
		addEditModal(id);
	}

	if (loading) return <Loading />

	return (
		<View documentTypes={documentTypes} onDelete={onDelete} onEdit={onEdit} />
	);
}

export default DocumentTypesList;