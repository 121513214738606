import React, { useEffect, useState, useRef } from "react";
import View from "components/crh/visits/attendanceLogs/Attendance.view";
import { getUser } from "api/users";
import moment from "moment/moment";
import Loading from "helpers/Loading";
import { formatISODate } from "utils/date";
import { showResponseMessage } from "utils/message";
import { useGetCall, fetchPost } from "utils/api";
import { downloadCSV, downloadExcel } from "utils/files";

function VisitAttendanceLogs() {

  let savedLayout = JSON.parse(sessionStorage.getItem("layout"));
  const tab = savedLayout.tabs.find((item) => item.content === "Reporte de visitas");

  const reportRef = useRef(null);
  const id = sessionStorage.getItem("id");
  const [searchFilters, setSearchFilters] = useState({
    search: "",
    start_date: "",
    end_date: "",
    start_hour: "",
    end_hour: "",
    serial_number: "",
    no_card: "",
    department_id: "",
  });
  const [filterModal, setFilterModal] = useState(false);
  const [attendanceLogs, setAttendanceLogs] = useState([]);
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);

  const [permissionExcel, setPermissionExcel] = useState(false);
  const [permissionPdf, setPermissionPdf] = useState(false);
  const [permissionRead, setPermissionRead] = useState(false);

  const [callVisitAttendanceLogs, reqAttendanceLogs] = useGetCall("/visit_attendance_logs", {
    onCompleted: (response) => {
      setLoading(false);
      setAttendanceLogs(response.data.visitAttendanceLogs);
    },
    onError: (error) => {
      setLoading(false);
      showResponseMessage(error.status, "visit_attendance_logs", "el reporte de visitas", "read");
    }
  })

  const setFilters = (values) => {
    values.offset = moment().utcOffset();
    setSearchFilters(values);
    callVisitAttendanceLogs({ ...values })
  }

  const showModal = () => setFilterModal(true);
  const hideModal = () => setFilterModal(false);

  const generateCells = () => {

    let csv = [];

    attendanceLogs.forEach((report) => {

      let visitRequest = report.visitRequest;

      csv.push([
        `ID:`,
        visitRequest.folio??'',
        `Tarjeta:`,
        visitRequest.no_card??'',
        `Nombre:`,
        `${visitRequest.name??''}`,
        `Género:`,
        `${visitRequest.genre === "MALE" ? "Masculino" : "Femenino"}`,
        `A quién visita:`,
        visitRequest.host??'',
        `Departamento:`,
        visitRequest?.department?.description??'',
        `Teléfono:`,
        visitRequest.phone??'',
        `Documento de autenticación:`,
        visitRequest?.document_type?.description??'',
        `Observaciones:`,
        visitRequest.comments??'',
        `Automóvil:`,
        visitRequest.has_car ? "Sí" : "No",
        `Placas:`,
        visitRequest.plates??'',
        `Marca:`,
        visitRequest.brand?.description??'',
        `Submarca:`,
        visitRequest.sub_brand?.description??'',
        `Color:`,
        visitRequest.color?.description??'',
        `Vigencia:`,
        function(){
					const endDate = moment.utc(`${visitRequest?.end_date} ${visitRequest?.end_hour}`);
					const currentDate = moment.utc();
					if(endDate.isBefore(currentDate)) return "Vencida"
					else return "Vigente"
				}(),
      ])

      if(report.visitAttendanceLogs.length > 0) csv.push([]);

      csv.push([
        `Fecha`,
        `Descripción`,
        `Ubicación`,
        `Hora`,
      ]);

      report.visitAttendanceLogs.forEach((attendanceLog) => {
        csv.push([
          `${formatISODate(attendanceLog.date, true)}`,
          `${attendanceLog.device?.description}`,
          `${attendanceLog.device?.location}`,
          `${attendanceLog.time}`,
        ]);
      });

      csv.push([]);
      csv.push([]);

    });

    return csv;

  }

  const generateColumnCells = () => {

    let csv = [], idx = 1, index = 1, idx_join = 9;
    const cells_join = [];

    csv.push([
      { value: `No.`, color: "#0170c2", fontColor: "#ffffff"  },
      { value: `ID`, color: "#0170c2", fontColor: "#ffffff"  },
      { value: `Tarjeta`, color: "#0170c2", fontColor: "#ffffff"  },
      { value: `Nombre`, color: "#0170c2", fontColor: "#ffffff"  },
      { value: `Fecha`, color: "#0170c2", fontColor: "#ffffff"  },
      { value: `Hora`, color: "#0170c2", fontColor: "#ffffff"  },
      { value: `Automóvil`, color: "#0170c2", fontColor: "#ffffff"  },
      { value: `Placas`, color: "#0170c2", fontColor: "#ffffff"  },
      { value: `Marca`, color: "#0170c2", fontColor: "#ffffff"  },
      { value: `Submarca`, color: "#0170c2", fontColor: "#ffffff"  },
      { value: `Color`, color: "#0170c2", fontColor: "#ffffff"  },
      { value: `Vigencia`, color: "#0170c2", fontColor: "#ffffff"  },
    ]);

    attendanceLogs.forEach((report) => {

      let visitRequest = report.visitRequest;

      if(report.visitAttendanceLogs.length > 0) {
        csv.push([
          { 
            value: `${index} // ID: ${visitRequest.folio} // Nombre: ${visitRequest.name} // Género: ${visitRequest.genre === "MAL" ? "Masculino" : "Femenino"} // A quién visita: ${visitRequest.host}` +
                   `// Departamento: ${visitRequest?.department?.description} // Teléfono: ${visitRequest.phone} // Documento de autenticación: ${visitRequest?.document_type?.description}` + 
                   `// Observaciones: ${visitRequest.comments}  // Vigencia: ${function(){
                      const endDate = moment.utc(`${visitRequest?.end_date} ${visitRequest?.end_hour}`);
                      const currentDate = moment.utc();
                      if(endDate.isBefore(currentDate)) return "Vencida"
                      else return "Vigente"
                    }()}`, 
            color: "#4b83be", 
            fontColor: "#ffffff" 
          },
        ])
        cells_join.push(`A${idx_join}:L${idx_join}`)
        idx_join += report.visitAttendanceLogs.length + 1;
      }

      report.visitAttendanceLogs.forEach((attendanceLog) => {
        csv.push([
          idx.toString(),
          `${visitRequest.folio}`,
          `${visitRequest.no_card}`,
          `${visitRequest.name}`,
          `${formatISODate(attendanceLog.date, true)}`,
          `${attendanceLog.time}`,
          `${visitRequest.has_car ? "Sí" : "No"}`,
          `${visitRequest.plates??''}`,
          `${visitRequest.brand?.description??''}`,
          `${visitRequest.sub_brand?.description??''}`,
          `${visitRequest.color?.description??''}`,
        ]);

        idx += 1;

      });

      index += 1;

    });

    return [csv, cells_join];

  }

  const onDownloadCSV = () => {
    downloadCSV({
      user,
      start_date: searchFilters.start_date,
      end_date: searchFilters.end_date,
      cells: generateCells(),
      report: "marcajes de visitas",
    });
  }

  const onDownloadXlsx = async () => {
    downloadExcel({
      user,
      start_date: searchFilters.start_date,
      end_date: searchFilters.end_date,
      cells: generateCells("xlsx"),
      report: "marcajes de visitas",
    })
  }

  const onDownloadXlsxColumns = async () => {
    const [cells, cells_join] = generateColumnCells();
    downloadExcel({
      user,
      start_date: searchFilters.start_date,
      end_date: searchFilters.end_date,
      cells: cells,
      report: "marcajes de visitas",
      cells_to_join: cells_join,
    })
  }

  useEffect(() => {
    fetchPost("/permissions/check", { table: "VISIT_ATTENDACE_REGISTER_REPORT", permission: "excel" }).then((response) => {
      if (response.data) setPermissionExcel(response.data.isAllowed);
    });
    fetchPost("/permissions/check", { table: "VISIT_ATTENDACE_REGISTER_REPORT", permission: "pdf" }).then((response) => {
      if (response.data) setPermissionPdf(response.data.isAllowed);
    });
    fetchPost("/permissions/check", { table: "VISIT_ATTENDACE_REGISTER_REPORT", permission: "read" }).then((response) => {
      if (response.data) {
        setPermissionRead(response.data.isAllowed)
        if (response.data.isAllowed) setFilterModal(tab ? tab.active : false);
      };
    });
  }, []);

  useEffect(() => {
    getUser(id).then((response) => {
      setUser(response.data)
    });
  }, [id]);

  const onChangeSearch = (event) => {
    if(event.target.value === '')
      setFilters({ ...searchFilters, search: event.target.value });
  }

  const handleKeyUp = (event) => {
    if(event.key === "Enter")
      setFilters({ ...searchFilters, search: event.target.value });
  }

  const refreshAttendanceLogs = () =>
    callVisitAttendanceLogs({ ...searchFilters });

  if(loading) return <Loading />

  return (
    <View
      user={user}
      permissionExcel={permissionExcel}
      permissionPdf={permissionPdf}
      permissionRead={permissionRead}
      setLoading={setLoading}
      searchFilters={searchFilters}
      onChangeSearch={onChangeSearch}
      handleKeyUp={handleKeyUp}
      onDownloadCSV={onDownloadCSV}
      onDownloadXlsx={onDownloadXlsx}
      onDownloadXlsxColumns={onDownloadXlsxColumns}
      reportRef={reportRef}
      attendanceLogs={attendanceLogs}
      filters={searchFilters}
      setFilters={setFilters}
      hideModal={hideModal}
      showModal={showModal}
      setFilterModal={setFilterModal}
      filterModal={filterModal}
      refreshAttendanceLogs={refreshAttendanceLogs}
    />
  );

}

export default VisitAttendanceLogs;