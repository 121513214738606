import React from "react";
import moment from "moment";
import "resources/styles/Home.scss";

function EntriesExitsView({
    attendanceLogs,
    employees,
    loading
}) {
    return (
        <>

            <div style={{ position: "absolute", top: "5px", right: "10px" }}>
                { loading && <div className="spinner-border spinner-border-sm text-primary" role="status"></div> }
            </div>

            <div className="w-100 px-3 py-1 border-bottom">
                <span className="h6 fw-bold text-primary">Entrada / Salida</span>
            </div>

            <div className="w-100 h6 px-3 py-2 border-bottom d-flex mb-0" style={{ fontSize: "0.9rem" }}>
                <div className="" style={{ width: "55%" }}>
                    Empleado
                </div>
                <div className="" style={{ width: "22.5%" }}>
                    Hora
                </div>
                <div className="" style={{ width: "22.5%" }}>
                    Estado
                </div>
            </div>
            
            {
                attendanceLogs.map((attendanceLog, index) => (
                    <div className="w-100 h6 px-3 py-2 border-bottom d-flex align-items-center mb-0" style={{ fontSize: "0.7rem" }}>
                        <div className="" style={{ width: "55%" }}>
                            <div className="d-flex flex-column">
                                <div className="pe-3">
                                    {employees[attendanceLog.employee_id]?.firstname} {employees[attendanceLog.employee_id]?.dad_lastname} {employees[attendanceLog.employee_id]?.mom_firstname}
                                </div>
                                <div className="text-muted">
                                    {employees[attendanceLog.employee_id]?.job?.description || "Sin puesto"}
                                </div>
                                <div className="text-muted">
                                    {employees[attendanceLog.employee_id]?.department?.description || "Sin departamento"}
                                </div>
                            </div>
                        </div>
                        <div className="" style={{ width: "22.5%" }}>
                            <span className="text-muted"> 
                                {moment.utc(attendanceLog.date + " " + attendanceLog.time, "YYYY-MM-DD HH:mm:ss").format("hh:mm")} 
                            </span>
                            <span className="fw-bold ms-1">
                                {moment.utc(attendanceLog.date + " " + attendanceLog.time, "YYYY-MM-DD HH:mm:ss").format("a")} 
                            </span>
                        </div>
                        <div className="" style={{ width: "22.5%" }}>
                            {function(){
                                if(attendanceLog.tag === "BREAK_OUT") return <div className="badge bg-danger">Salida descanso</div>
                                if(attendanceLog.tag === "BREAK_IN") return <div className="badge bg-success">Entrada descanso</div>
                                if(attendanceLog.tag === "IN") return <div className="badge bg-success">Entrada</div>
                                if(attendanceLog.tag === "OUT") return <div className="badge bg-danger">Salida</div>
                                if(attendanceLog.tag === "NO_ACCESS") return <div className="badge text-white bg-dark">Sin acceso</div>
                            }()}
                        </div>
                    </div>
                ))
            }
        </>
    )
}

export default EntriesExitsView