import React, { useState, useEffect, useContext } from "react";
import { useGetCall } from "utils/api";
import { toast } from "react-toastify";
import Loading from "helpers/Loading";
import { LoadingContext } from "helpers/LoadingContext";
import View from "components/crh/timetables/List.view";

function List({
	search,
	refresh,
	addEditModal
}) {

	const { loading, setLoading } = useContext(LoadingContext);

	const [shifts, setShifts] = useState([]);
	const [refreshValue, setRefreshValue] = useState(null);

	if (refresh !== refreshValue) {
		setRefreshValue(refresh);
	}

	const [callShifts, reqShifts] = useGetCall("/timetables/list_shifts", {
		onCompleted: (response) => {
			setLoading(false);
			setShifts(response.data)
		},
		onError: (error) => {
			setLoading(false);
			if (error.status === 403) {
				if (!toast.isActive("toast-read-timetables-unauthorized")) {
					toast.error("Error, no tienes los permisos para consultar los turnos/horarios", { toastId: "toast-read-timetables-unauthorized" })
				}
			} else {
				if (!toast.isActive("toast-timetables-error")) {
					toast.error("Error al obtener los turnos", { toastId: "toast-timetables-error" })
				}
			}
		}
	})

	useEffect(() => {
		setLoading(true);
		callShifts({ search });
	}, [refreshValue, search]);

	const onEdit = (id) => {
		addEditModal(id);
	}

	if (loading) return <Loading />

	return (
		<View shifts={shifts} onEdit={onEdit} />
	);
}

export default List;