import React from "react";

function ListView({ contracts = [], onDelete, onEdit }) {
	return (
		<div class="table-responsive datatable-custom position-relative" >
			<div class="dataTables_wrapper no-footer pt-2 pl-3 pr-3" style={{maxHeight: "60vh"}}>
				<table class="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
					<thead>
						<tr role="row">
							<th>Clave</th>
							<th>Descripción</th>
							<th>Abreviación</th>
							<th>Acciones</th>
						</tr>
					</thead>
					<tbody>
						{
							contracts.map((contract) => (
								<tr key={contract.id} role="row">
									<td>{contract.key}</td>
									<td>{contract.description}</td>
									<td>{contract.abbreviation}</td>
									<td>
										<button className="btn btn-warning" style={{ marginRight: "10px" }} onClick={() => onEdit(contract.id)}>
											<i class="bi bi-pencil" style={{marginRight: "7px"}}></i>
											Editar
										</button>
										<button className="btn btn-danger" onClick={() => onDelete(contract.id)}>
											<i class="bi bi-trash" style={{marginRight: "7px"}}></i>
											Eliminar
										</button>
									</td>
								</tr>
							))
						}
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default ListView;