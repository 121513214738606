import React from 'react';
import { Formik, Form, Field } from 'formik';


function FormView({ 
  formikRef = () => { }, 
  edit = false, 
  subBrand = {}, 
  onSubmit, 
  devices = [],
  profileDevices = {},
  setProfileDevices,
  error, 
  setError, 
  onCancel
}) {

  return (
    <div className='container mt-3'>
      <Formik
        innerRef={formikRef}
        initialValues={subBrand}
        onSubmit={onSubmit}>
        {({ values, setFieldValue, resetForm }) =>
          <Form>
            {/* Key */}
            <div class="mb-3">
              <div class="form-group">
                <label class="input">
                  <Field type="text" name="key" class="form-control input__field" placeholder=" " required value={values.key || ''} />
                  <span class="input__label">
                    Clave <span className='text-danger fw-bold'>*</span>
                  </span>
                </label>
              </div>
            </div>

            {/* Description */}
            <div class="mb-3">
              <div class="form-group">
                <label class="input">
                  <Field type="text" name="description" class="form-control input__field" placeholder=" " required value={values.description || ''} />
                  <span class="input__label">
                    Descripción <span className='text-danger fw-bold'>*</span>
                  </span>
                </label>
              </div>
            </div>

            {/* Abbreviation */}
            <div class="mb-3">
              <div class="form-group">
                <label class="input">
                  <Field type="text" name="abbreviation" class="form-control input__field" placeholder=" " required value={values.abbreviation || ''} />
                  <span class="input__label">
                    Abreviación <span className='text-danger fw-bold'>*</span>
                  </span>
                </label>
              </div>
            </div>

            <div className='table table-bordered mb-0'>
              <div className='d-flex fw-bold justify-content-center w-100 py-2 mb-0' style={{backgroundColor: "#04B5FC"}}>
                <span className='text-white text-center'>Lectores</span>
              </div>

              <div class="table-responsive datatable-custom position-relative mb-0 mt-0">
                <div class="dataTables_wrapper no-footer pl-3 pr-3" style={{maxHeight: "60vh"}}>
                  <table class="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
                    <thead>
                      <tr role="row">
                        <th>Conexión</th>
                        <th>Número de serie</th>
                        <th>Sucursal</th>
                        <th>Descripción</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        devices.map((device) => (
                          <tr key={device.id} role="row">
                            <td>
                              {device.connected ? <span className="badge bg-info">Conectado</span> : <span className="badge bg-warning">Desconectado</span>}
                            </td>
                            <td>
                              {device.serial_number}
                            </td>
                            <td>
                              {device.office ? device.office.key : ""}
                            </td>
                            <td>
                              {device.description}
                            </td>
                            <td>
                              <div className="form-check form-switch ms-5" id="status">
                                <Field
                                  class="form-check-input" 
                                  type="checkbox" 
                                  id="status-switch"
                                  onChange={(e) => setProfileDevices({ ...profileDevices, [device.id]: e.target.checked })}
                                  checked={profileDevices[device.id] || false}
                                />
                              </div>
                            </td>

                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {error ? (
              <div class="alert alert-danger alert-dismissible fade show d-flex justify-content-between" role="alert">
                <p className='text-justify' style={{ width: "90%" }}>
                  {error}
                </p>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close" onClick={() => setError(null)}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            ) : null}

            <div class="pt-3">
              <button type="submit" class="btn btn-primary">
                <i class="bi bi-plus" style={{ marginRight: "10px" }}></i>
                {edit ? "Editar perfil" : "Guardar perfil"}
              </button>

              <button type="button" class="btn btn-danger" style={{ marginLeft: "20px" }} onClick={onCancel.bind(null, resetForm)}>
                <i class="bi bi-x" style={{ marginRight: "10px" }}></i>
                Cancelar
              </button>
            </div>

          </Form>
        }</Formik>

    </div >
  )
}

export default FormView;