import React, { useState, useEffect, useRef } from "react";
import { usePut } from "utils/api";
import { getSerie } from "api/series";
import { getOffices } from "api/offices";
import { toast } from "react-toastify";
import { showResponseMessage } from "utils/message";
import View from "components/crh/series/Form.view";

function FormSet({ id, setIsVisible, refresh }) {

    const formikRef = useRef();
    const [error, setError] = useState(null);
    const [serie, setSerie] = useState({});
    const [offices, setOffices] = useState([]);
    const [reset, setReset] = useState(() => { });
    const [refreshValue, setRefreshValue] = useState(null);

    if (refreshValue !== refresh) {
        setRefreshValue(refresh);
    }

    const [callUpdate, reqUpdate] = usePut("/series", {
        onCompleted: () => {
            if (!toast.isActive("serie-updated"))
                toast.success("Serie actualizada", { toastId: "serie-updated" });

            setIsVisible(false);
            reset();
        },
        onError: (err) => {
            console.error(err)
            showResponseMessage(error.status, "series", "series", "edit");

        }
    })

    useEffect(() => {
        getSerie(id).then((response) => {
            if (response.data) {
                if (formikRef.current)
                    formikRef.current.setValues(response.data);
                setSerie(response.data);
            }
        });
        getOffices().then((response) => {
            if (response.data) {
                setOffices(response.data)
            }
        })
        formikRef.current.setValues({});
    }, [id, refreshValue])


    const onSubmit = (values, { resetForm }) => {

        for (let key in values)
            if (values[key] === "")
                values[key] = null

        const { serie, folio, description, office } = values
        if (!serie || !folio || !description) {
            toast.error("Todos los campos son obligatorios");
            return;
        }

        values.id = id;
        setReset(() => () => resetForm({}));
        callUpdate(values);

    }

    const onCancel = (resetForm) => {
        setIsVisible(false);
        resetForm({});
    }

    return (
        <View
            formikRef={formikRef}
            error={error}
            edit={true}
            serie={serie}
            offices={offices}
            onSubmit={onSubmit}
            onCancel={onCancel}
        />
    )


}

export default FormSet;
