import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { useDelete, useGetCall, fetchPost } from "utils/api";
import Loading from "helpers/Loading";
import { showResponseMessage } from "utils/message";
import { LoadingContext } from "helpers/LoadingContext";
import View from "components/crh/costCenters/List.view";

function List({
	addEditModal,
	refresh,
	search
}) {

	const { loading, setLoading } = useContext(LoadingContext);

	const [costCenters, setCostCenters] = useState([]);
	const [refreshValue, setRefreshValue] = useState(null);
	const [refreshDelete, setRefreshDelete] = useState(null);

	if (refresh !== refreshValue) {
		setRefreshValue(refresh);
	}

	const [callCostCenters, reqCostCenters] = useGetCall("/cost_centers", {
		onCompleted: (response) => {
			setLoading(false);
			setCostCenters(response.data)
		},
		onError: (error) => {
			setLoading(false);
			showResponseMessage(error.status, "cost_centers", "centros de costos", "read")
		}
	})

	const [callDelete, reqDelete] = useDelete("/cost_centers", {
		onCompleted: () => {
			if (!toast.isActive("cost_center-deleted")) {
				toast.success("Centro de costos eliminado", { toastId: "cost_center-deleted" });
			}
			setRefreshDelete(Math.random());
		},
		onError: (error) => {
			console.error(error)
			showResponseMessage(error.status, "cost_centers", "centros de costos", "delete")
		}
	});

	useEffect(() => {
		setLoading(true);
		callCostCenters({ search });
	}, [refreshDelete, refreshValue, search]);

	const onDelete = (id) => {
		fetchPost("/permissions/check", { table: "COST_CENTER", permission: "delete" }).then((response) => {
			if (response.data) {
				const data = response.data;
				if (data.isAllowed === true) {
					if (window.confirm("¿Estás seguro de eliminar el centro de costos?")) {
						callDelete({ id: id });
					}
				} else {
					if (!toast.isActive("toast-delete-office-unauthorized")) {
						toast.error("Error, no tienes los permisos para eliminar sucursales", { toastId: "toast-delete-office-unauthorized" })
					}
				}
			}
		})
	}

	const onEdit = (id) => {
		addEditModal(id);
	}

	if (loading) return <Loading />

	return (
		<View costCenters={costCenters} onDelete={onDelete} onEdit={onEdit} />
	);
}

export default List;