import React, { useState, useEffect, useRef } from "react"
import { useGetCall, usePut } from "utils/api"
import { getDeviceEmployees } from "api/deviceEmployees"
import { toast } from "react-toastify"
import { showResponseMessage } from "utils/message"
import View from "components/crh/deviceEmployees/Form.view"

function DeviceEmployeesForm({ id, refresh, setIsVisible, reloadDevice, setReloadDevice, setReloadDevices, theme }) {

    const [error, setError] = useState(null)
    const [deviceEmployees, setDeviceEmployees] = useState([])
    const [refreshValue, setRefreshValue] = useState(null)
    const [absStatus, setAbsStatus] = useState(false)

    if (refresh !== refreshValue) setRefreshValue(refresh)

    const [callDeviceEmployee, reqDeviceEmployee] = useGetCall(`/device_employees`, {
        onCompleted: (response) => {
            if (response.data !== null) {
                const data = response.data;
                setDeviceEmployees(() => {
                    setAbsStatus(data.reduce((acc, deviceEmployee) => acc && deviceEmployee.new_status, true))
                    return data
                })
            }
        }, onError: (error) => {
            showResponseMessage(error.status, "device_employees", "dispositivos-empleados", "read")
        }
    })

    const [callUpdate, reqUpdate] = usePut("/device_employees", {
        onCompleted: () => {

            if (!toast.isActive("toast-update-device"))
                toast.success("Empleados actualizados", { toastId: "toast-update-device" })

            setIsVisible(false)
            setReloadDevices(true)

        },
        onError: (error) => {
            showResponseMessage(error.status, "device_employees", "dispositivos-empleados", "edit")
        }
    })

    useEffect(() => {

        callDeviceEmployee({
            id: id,
            type: "device_to_employee",
            user_id: sessionStorage.getItem("id")
        })
        setReloadDevice(false)

    }, [id, reloadDevice, setReloadDevice, refreshValue])

    const onChangeAll = (value) => {
        setDeviceEmployees(deviceEmployees.map((deviceEmployee) => {
            deviceEmployee.new_status = value
            return deviceEmployee
        }))
        setAbsStatus(value)
    }

    const onUpdate = (id, value) => {
        setDeviceEmployees(deviceEmployees.map((deviceEmployee) => {
            if (deviceEmployee.employee.id === id)
                deviceEmployee.new_status = value
            return deviceEmployee
        }))
        setAbsStatus(deviceEmployees.reduce((acc, deviceEmployee) => acc && deviceEmployee.new_status, true))
    }

    const onSubmit = () =>
        callUpdate({
            device_id: id,
            data: deviceEmployees,
            type: 'device_to_employee'
        })

    const onCancel = (resetForm) =>
        setIsVisible(false)

    return (
        <View
            absStatus={absStatus}
            onChangeAll={onChangeAll}
            onUpdate={onUpdate}
            error={error}
            edit={true}
            deviceEmployees={deviceEmployees}
            onSubmit={onSubmit}
            onCancel={onCancel}
        />
    )

}

export default DeviceEmployeesForm
