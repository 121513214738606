import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import { usePost, useGetCall } from "utils/api";
import { showResponseMessage } from "utils/message";
import View from "components/preregister/Preregister.view";
import moment from "moment";
import { toast } from "react-toastify";

function Preregister() {
  
  const formikRef = useRef();
  const [sent, setSent] = useState(false);
  const [company, setCompany] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [brands, setBrands] = useState([]);
  const [subBrands, setSubBrands] = useState([]);
  const [colors, setColors] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);

  let token = window.location.search.match(/token=([^&]*)/);
  token = token ? token[1] : null;

  const [callCompany, reqCallCompany] = usePost("/companies/parse_token", {
    onCompleted: (data) => {
      setCompany(data.data.company);
    },
    onError: (error) => {
    }
  });

  const [callCreateRequest, reqCreateRequest] = usePost("/visit_requests", {
    onCompleted: (data) => {
      setSent(true);
    },
    onError: (error) => {
    }
  });

  const [callDepartments, reqDepartments] = useGetCall("/departments", {
		onCompleted: (response) => {
			setDepartments(response.data)
		},
		onError: (error) => {
			showResponseMessage(error.status, "departments", "departamentos", "read")
		}
	})

  const [callBrands, reqBrands] = useGetCall("/brands", {
    onCompleted: (response) => {
      setBrands(response.data)
    },
    onError: (error) => {
      showResponseMessage(error.status, "brands", "marcas", "read")
    }
  })

  const [callSubBrands, reqSubBrands] = useGetCall("/sub_brands", {
    onCompleted: (response) => {
      setSubBrands(response.data)
    },
    onError: (error) => {
      showResponseMessage(error.status, "sub_brands", "sub marcas", "read")
    }
  })

  const [callColors, reqColors] = useGetCall("/colors", {
    onCompleted: (response) => {
      setColors(response.data)
    },
    onError: (error) => {
      showResponseMessage(error.status, "colors", "colores", "read")
    }
  })

  const [callDocumentTypes, reqDocumentTypes] = useGetCall("/document_types", {
    onCompleted: (response) => {
      setDocumentTypes(response.data)
    },
    onError: (error) => {
      showResponseMessage(error.status, "document_types", "tipos de documento", "read")
    }
  })

  const onGenerateNoCard = () =>
    formikRef.current.setFieldValue("no_card", moment().format("YYMMDDHHmmss"));

  const onSubmit = (values) => {

    if(!values.no_card) {
      if (!toast.isActive(`no-card`)) 
        toast.error(`Genere un QR para continuar`, { toastId: `no-card` });
      return;
    }

    callCreateRequest({
      ...values,
      company_id: company.id,
      status: "PENDING",
      origin: "PREREGISTER",
    });
  }

  useEffect(() => {
    callCompany({ token });
  }, []);

  useEffect(() => {
    if(!company) return;
    callDepartments({ company: company.id });
    callBrands({ company: company.id });
    callSubBrands({ company: company.id });
    callColors({ company: company.id });
    callDocumentTypes({ company: company.id });
  }, [company]);

  return <View
    sent={sent}
    company={company}
    departments={departments}
    brands={brands}
    subBrands={subBrands}
    colors={colors}
    documentTypes={documentTypes}
    formikRef={formikRef}
    onGenerateNoCard={onGenerateNoCard}
    onSubmit={onSubmit}
  />;

}

export default Preregister;