import React, { useState, useEffect } from "react";
import { usePost, useGetCall } from "utils/api";
import { toast } from "react-toastify";
import { showResponseMessage } from "utils/message";
import View from "components/crh/incidenceRequests/RequestForm.view";


function RequestForm({ formikRef, refresh, setEmployee }) {

	const incidencesWithHour = [
		"PERMISSION_RELEASE_EARLY",
		"PERMISSION_ARRIVE_LATE",
		"PERMISSION_LEAVE_HOURS",
		"HOURLY_VACATIONS"
	]
	const [refreshValue, setRefreshValue] = useState(null);
	const [incidences, setIncidences] = useState([]);
	const [devices, setDevices] = useState([]);
	const [employees, setEmployees] = useState([]);
	const [startHour, setStartHour] = useState(null);
	const [endHour, setEndHour] = useState(null);

	if (refreshValue !== refresh) {
		setRefreshValue(refresh)
	}

	const [callSave, reqSave] = usePost("/incidences_requests", {
		onCompleted: (data) => {

			if (data.data?.folio != null && data.data?.folio !== undefined) {
				if (!toast.isActive("incidence_request-duplicated"))
					toast.error(`Error, ya existe una solicitud de incidencia SI - ${data.data.folio} de ese tipo y en ese rango de fechas/horas`, { toastId: "incidence_request-duplicated" })

			} else {
				if (!toast.isActive("incidence_request-created"))
					toast.success("Solicitud de incidencia creada correctamente", { toastId: "incidence_request-created" })

				formikRef.current.setValues({})
				setStartHour(null)
				setEndHour(null)
				setEmployee(null)

			}
		},
		onError: (err) => {
			console.error(err)
			showResponseMessage(err.status, "incidence_request", "solicitudes de incidencias", "create");
		}
	})

	const [callIncidences, reqIncidences] = useGetCall('/incidences', {
		onCompleted: (response) => {
			setIncidences(response.data);
		}, onError: (error) => {
			showResponseMessage(error.status, "incidences", "incidencias", "read")
		}
	})

	const [callEmployees, reqEmployees] = useGetCall('/employees', {
		onCompleted: (response) => {
			setEmployees(response.data);
		}, onError: (error) => {
			showResponseMessage(error.status, "employees", "empleados", "read")
		}
	})

	const [callDevices, reqDevices] = useGetCall('/devices', {
		onCompleted: (response) => {
			setDevices(response.data);
		}, onError: (error) => {
			showResponseMessage(error.status, "devices", "lectores", "read")
		}
	})

	useEffect(() => {

		callIncidences({ request_rh: true });
		callDevices();
		callEmployees();

	}, [refreshValue])

	const onSubmit = (values) => {

		const { incidence } = values;
		const employee_key = values?.employee?.key ? values.employee.key : null;

		if (!employee_key || !incidence || incidence.id === '') {
			toast.error("Rellene los campos obligatorios")
			return;
		}

		const incidenceType = incidences.find((item) => item.id === parseInt(incidence.id)).type;

		if (incidenceType === "INSERT_MARKINGS") {
			const { start_date, device } = values;
			if (!start_date || !startHour || !device || device.id === '') {
				toast.error("Rellene los campos obligatorios")
				return;
			}

		} else if (incidencesWithHour.find(item => item === incidenceType)) {
			const { start_date, end_date } = values;
			if (!endHour || !startHour || !start_date || !end_date) {
				toast.error("Los campos de fecha son obligatorios para esta incidencia")
				return;
			}
		} else {
			const { start_date, end_date } = values;
			if (!start_date || !end_date) {
				toast.error("Los campos de fecha son obligatorios para esta incidencia")
				return;
			}
		}

		callSave({
			...values,
			start_hour: startHour,
			end_hour: endHour
		})

	}

	return (
		<View
			setEmployee={setEmployee}
			formikRef={formikRef}
			devices={devices}
			employees={employees}
			incidences={incidences}
			onSubmit={onSubmit}
			setStartHour={setStartHour}
			setEndHour={setEndHour}
			endHour={endHour}
			startHour={startHour}
		/>
	);
}

export default RequestForm;
