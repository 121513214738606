import React, {useState, useEffect} from "react";
import { usePost } from "utils/api";
import { toast } from "react-toastify";
import { getPayrolls } from "api/payrolls";
import { showResponseMessage } from "utils/message";
import View from "components/crh/payrollPeriods/Form.view";

function FormSave({ setIsVisible, refresh }) {

    const [payrolls, setPayrolls] = useState([]);
    const [error, setError] = useState(null);
    const [reset, setReset] = useState(() => { });
    const [refreshValue, setRefreshValue] = useState(null);

    if (refresh !== refreshValue) {
        setRefreshValue(refresh);
    }

    const [callSave, reqSave] = usePost("/payroll_periods", {
        onCompleted: () => {
            if (!toast.isActive("payrollPeriod-created"))
                toast.success("Periodo creado correctamente", {toastId : "payrollPeriod-created"});
            
            setIsVisible(false);
            reset();
        },
        onError: (err) => {
            console.error(err)
            showResponseMessage(error.status, "payroll_periods", "periodos de nómina", "create")
        }
    })

    useEffect(() => {
        getPayrolls().then(response => {
            if (response.data)
                setPayrolls(response.data);
        });
    }, [refreshValue])

    const onSubmit = (values, {resetForm}) => {

        for(let key in values)
            if(values[key] === "")
                values[key] = null
    
        const {key, description, start, end, payment} = values
        if(!key || !description || !values.payroll || values.payroll.id === "" || !start || !end || !payment) {
            toast.error("Todos los campos son obligatorios");
            return;
        }

        values.start += " 10:00:00";
        values.end += " 10:00:00";
        values.payment += " 10:00:00";

        setReset(() => () => resetForm({}));
        callSave(values);
        
    }

    const onCancel = (resetForm) => {
        setIsVisible(false);
        resetForm({});
    }


    return (
        <View 
            onSubmit={onSubmit}
            onCancel={onCancel}
            error={error}
            payrolls={payrolls}
            setIsVisible={setIsVisible} 
            setError={setError}
        />
    )
}

export default FormSave;