import React, { useState, useEffect, useRef } from "react";
import { useGetCall, usePut } from "utils/api";
import { getCostCenter } from "api/costCenters";
import { toast } from "react-toastify";
import { showResponseMessage } from "utils/message";
import View from "components/crh/costCenters/Form.view";

function FormSet({ id, setIsVisible, refresh }) {

    const formikRef = useRef();
    const [error, setError] = useState(null);
    const [costCenter, setCostCenter] = useState({});
    const [reset, setReset] = useState(() => { });
    const [refreshValue, setRefreshValue] = useState(null);

    if (refresh !== refreshValue) {
        setRefreshValue(refresh);
    }

    const [callCostCenter, reqCostCenter] = useGetCall(`/cost_centers/${id}`, {
        onCompleted: (response) => {
            if (formikRef.current) {
                formikRef.current.setValues(response.data);
            }
            setCostCenter(response.data);
        }, onError: (error) => {
            console.error(error)
            showResponseMessage(error.status, "cost_centers", "centros de costos", "edit")
        }
    })

    const [callUpdate, reqUpdate] = usePut("/cost_centers", {
        onCompleted: () => {
            if (!toast.isActive("cost_center-updated"))
                toast.success("Centro de costos actualizado", { toastId: "cost_center-updated" });

            setIsVisible(false);
            reset();
        },
        onError: (error) => {
            console.error(error)
            showResponseMessage(error.status, "cost_centers", "centros de costos", "edit")
        }
    })

    useEffect(() => {
        callCostCenter();
        formikRef.current.setValues({});
    }, [id, refreshValue])


    const onSubmit = (values, { resetForm }) => {

        for (let key in values)
            if (values[key] === "")
                values[key] = null

        const { key, description, abbreviation } = values
        if (!key || !description || !abbreviation) {
            toast.error("Todos los campos son obligatorios");
            return;
        }

        values.id = id;
        setReset(() => () => resetForm({}));
        callUpdate(values);

    }

    const onCancel = (resetForm) => {
        setIsVisible(false);
        resetForm({});
    }

    return (
        <View
            formikRef={formikRef}
            error={error}
            edit={true}
            costCenter={costCenter}
            onSubmit={onSubmit}
            onCancel={onCancel}
        />
    )


}

export default FormSet;
