import React from "react";

function DevicesView({
    selectedDevices,
    setSelectedDevices,
    devices = [],
    search,
	onChangeSearch,
	handleKeyUp,
}) {
	return (
		<>

            <div className="content container-fluid p-5">

                <div className="page-header mb-3">
                    <div className="row align-items-end">
                        <div className="col-sm mb-2 mb-sm-0">
                            <h1 className="page-header-title">Dispositivos</h1>
                        </div>
                    </div>
                </div>

                <div class="card">

                    <div class="card-header card-header-content-md-between">
                        <div class="mb-2 mb-md-0">
                            <div class="input-group input-group-merge input-group-flush" style={{width: "50%"}}>
                                <div class="input-group-prepend input-group-text" >
                                    <i class="bi-search"></i>
                                </div>
                                <input 
                                    type="search" 
                                    class="form-control" 
                                    placeholder="Buscar dispositivo" 
									onChange={onChangeSearch}
									onKeyUp={handleKeyUp}
                                />
                            </div>
                        </div>
                    </div>

                    <div class="table-responsive datatable-custom position-relative" >
                        <div class="dataTables_wrapper no-footer pt-2 pl-3 pr-3" style={{maxHeight: "60vh"}}>
                            <table class="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
                                <thead>
                                    <tr role="row">
                                        <th>Número de serie</th>
                                        <th>Descripción</th>
                                        <th>Estatus</th>
                                        <th className="d-flex justify-content-center">Seleccionar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        devices.map((device) => (
                                            <tr key={device.id} role="row">                         
                                                <td>
                                                    {device.serial_number}
                                                </td>
                                                <td>
                                                    {device.description}
                                                </td>
                                                <td>
                                                    {device.connected ? <span className="badge bg-info">Conectado</span> : <span className="badge bg-warning">Desconectado</span>}
                                                </td>
                                                <td className="d-flex justify-content-center">
                                                    <div class="form-check form-switch">
                                                        <input 
                                                            style={{transform: "scale(1.4)"}}
                                                            class="form-check-input"
                                                            type="checkbox" 
                                                            id={device.id + "check"}
                                                            checked={selectedDevices[device.serial_number] || false}
                                                            onChange={() => setSelectedDevices({...selectedDevices, [device.serial_number]: !selectedDevices[device.serial_number]}) }
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>

            </div>

		</>
	);
}

export default DevicesView;