import React, { useState } from 'react';
import { ToastContainer } from "react-toastify";
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import AuthProvider from 'components/context/AuthProvider';

import Login from 'components/auth/Login';
import LoginCrh from "components/auth/LoginCrh";
import LoginGeo from 'components/auth/LoginGeo';
import Logout from "components/auth/Logout";
import Layout from 'components/layout/Layout';
import LayoutCrh from "components/layout/LayoutCrh";
import LayoutGeo from 'components/layout/LayoutGeo';
import Landing from 'components/landing/Landing';
import Terms from "components/landing/Terms"
import Recover from 'components/auth/Recover';
import Restore from 'components/auth/Restore';
import RecoverGeo from 'components/auth/RecoverGeo';
import RestoreGeo from 'components/auth/RestoreGeo';
import Preregister from 'components/preregister/Preregister';

function App() {
  return (
    <AuthProvider>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <BrowserRouter basename='/'>
        <Switch>

          <Route path="/login" component={LoginCrh} />
          <Route path="/recover" component={Recover} />
          <Route path="/restore" component={Restore} />

          <Route path="/login_admin" component={Login} />
          <Route path="/admin/login" component={Login} />
          <Route path="/admin/recover" component={Recover} />
          <Route path="/admin/restore" component={Restore} />

          <Route path="/geo/login" component={LoginGeo} />
          <Route path="/geo/recover" component={RecoverGeo} />
          <Route path="/geo/restore" component={RestoreGeo} />
          <Route path="/logout" component={Logout} />

          <Route path="/crh" component={LayoutCrh} />
          <Route path="/admin" component={Layout} />
          <Route path="/geo" component={LayoutGeo} />

          <Route path="/preregister" component={Preregister} />
          
          <Route path="/terms" component={Terms} />

          {/* Load browser router with all routes */}
          <Route path="/test" component={() => 
            <BrowserRouter basename='/test'>
              <Switch>

                <Route path="/login" component={LoginCrh} />
                <Route path="/recover" component={Recover} />
                <Route path="/restore" component={Restore} />

                <Route path="/login_admin" component={Login} />
                <Route path="/admin/login" component={Login} />
                <Route path="/admin/recover" component={Recover} />
                <Route path="/admin/restore" component={Restore} />

                <Route path="/geo/login" component={LoginGeo} />
                <Route path="/geo/recover" component={RecoverGeo} />
                <Route path="/geo/restore" component={RestoreGeo} />
                <Route path="/logout" component={Logout} />

                <Route path="/crh" component={LayoutCrh} />
                <Route path="/admin" component={Layout} />
                <Route path="/geo" component={LayoutGeo} />

                <Route path="/preregister" component={Preregister} />
                
                <Route path="/terms" component={Terms} />

                <Route path="/" component={Landing} />
                <Redirect to="/" />

              </Switch>
            </BrowserRouter>
          } />

          <Route path="/" component={Landing} />
          <Redirect to="/" />
          
        </Switch>
      </BrowserRouter>

    </AuthProvider>
  );
}


export default App;
