import React from "react";
import Filters from "components/crh/reports/incidences/Filters";
import IncidenceRequest from "components/crh/reports/incidences/IncidenceRequest";
import Modal from "helpers/Modal";
import moment from "moment";
import Request from "components/crh/reports/incidences/Request";
import { getAbbreviationIncidence, getWeekDayName } from "utils/constants";
import { getTotalTime, formatTime } from "utils/reports"
import ReactToPrint from "react-to-print";
import NoImage from 'resources/images/no-avatar.png';
import AttendanceLogs from "./AttendanceLogs";

const showIncidences = (incidences, onShowIncidenceRequest) => {

	let components = [];

	for (let item of incidences) {

		const incidence = item.incidence;
		const request = item?.request;

		if (request) {
			components.push(<>
				<a class="text-decoration-underline" style={{ cursor: "pointer" }} onClick={() => onShowIncidenceRequest(request.id)}>
					SI {request.folio} - {getAbbreviationIncidence(incidence)}
				</a> /
			</>);
		} else {
			components.push(getAbbreviationIncidence(incidence) + " / ");
		}

	}

	return <p>{components.map((item) => item)}</p>;

}

function IncidencesReportView({
	user = {},
	permissionExcel,
	permissionPdf, 
	permissionRead,
	selectedDate,
	setLoading,
	onDownloadXlsx,
	onDownloadCSV,
	onDownloadXlsxColumns,
	reportRef,
	reports,
	attendanceLogsEmployee, 
	setAttendanceLogsEmployee,
	attendanceLogsKey, 
	setAttendanceLogsKey,
	attendanceLogsDate, 
	setAttendanceLogsDate,
	requestModal,
	filterModal,
	filters,
	setFilters,
	showRequestModal,
	showFilterModal,
	onSubmitRequest,
	hideRequestModal,
	hideFilterModal,
	onChangeSearch,
	handleKeyUp,
	searchFilters,
	attendanceLogsModalVisible, 
	setAttendanceLogsModalVisible,
	showIncidenceRequest,
	hideIncidenceModal,
	selectedIncidenceRequest,
	onShowIncidenceRequest
}) {

	let reportsFiltered = reports.filter((employeeReport) => (
		employeeReport.employee.leave != null &&
			moment.utc(employeeReport.employee.leave).startOf('day') < moment.utc(filters.start_date).startOf('day') 
		? false : true
	));

	return (
		<>

			<div className="content container-fluid p-5">


				<div className="page-header mb-3">
					<div className="row align-items-end">

						<div className="col-sm mb-2 mb-sm-0">
							<h1 className="page-header-title">Reporte de incidencias</h1>
						</div>

						<div className="col-sm-auto">
							{
								permissionPdf && (
									<ReactToPrint
										debug={true}
										content={() => reportRef.current}
										trigger={() => (
											<button className="btn bg-danger text-white mx-1">
												<i className="bi bi-file-earmark-pdf" style={{ marginRight: "7px" }}></i>
												Descargar PDF
											</button>
										)}
									/>
								)
							}
							{
								permissionExcel && (
									<>
										<button type="button" className="btn bg-success text-white mx-1" onClick={onDownloadXlsx}>
											<i className="bi bi-file-earmark-excel" style={{ marginRight: "7px" }}></i>
											Descargar Excel
										</button>
										<button type="button" className="btn bg-success text-white mx-1" onClick={onDownloadXlsxColumns}>
											<i className="bi bi-file-earmark-excel" style={{ marginRight: "7px" }}></i>
											Descargar Excel Columnas
										</button>
										<button type="button" className="btn bg-primary text-white mx-1" onClick={onDownloadCSV}>
											<i className="bi bi-filetype-csv" style={{ marginRight: "7px" }}></i>
											Descargar CSV
										</button>
									</>
								)
							}
							{
								permissionRead && (
									<button type="button" className="btn btn-primary" onClick={showFilterModal}>
										<i className="bi bi-plus-circle" style={{ marginRight: "7px" }}></i>
										Filtrar resultados
									</button>
								)
							}
						</div>
					</div>
				</div>

				<div class="card mb-3">
					<div class="card-header card-header-content-md-between">
						<div class="d-flex justify-content-between mb-2 mb-md-0">
							<div class="input-group input-group-merge input-group-flush" style={{ width: "50%" }}>
								<div class="input-group-prepend input-group-text" >
									<i class="bi-search"></i>
								</div>
								<input
									type="search"
									class="form-control"
									placeholder="Buscar empleado"
									onChange={onChangeSearch}
									onKeyUp={handleKeyUp}
								/>
							</div>
							<div class="d-flex justify-content-right mx-3">
								<span class="pt-2 mx-1">Total empleados: </span>
								<span class="pt-2 border-0">{reportsFiltered.length}</span>
							</div>
						</div>
					</div>
				</div>

				<div ref={reportRef}>

					<div className="report-header">

						<div className="d-flex justify-content-center">
							<div className="logo mt-6">
								{
									user?.company?.logo?.url ? (
										<img crossorigin="anonymous" src={user.company.logo.url} height="300" alt="Logo empresa" style={{ borderRadius: "50%" }} />
									) : (
										<img src={NoImage} alt="" style={{ borderRadius: "50%" }} height="300" />
									)
								}
							</div>
						</div>

						<div className="text-center">
							<h1 className="mb-3">{user?.company?.name}</h1>
						</div>

						<div className="text-center">
							<h2 className="mb-3">Reporte de incidencias</h2>
						</div>

						<div className="text-center">
							<h4 className="">
								{
									!filters.start_date && !filters.end_date
										? "Todos los registros"
										: moment.utc(filters.start_date).format("DD [de] MMMM [de] YYYY") + " - " + moment.utc(filters.end_date).format("DD [de] MMMM [de] YYYY")
								}
							</h4>
						</div>

					</div>

					<div className="pagebreak"></div>

					<div class="row">
						{
							reportsFiltered.map((employeeReport) => (
									<div className="col mb-5">

										<table class="table bg-dark p-3" style={{ color: "white", margin: "0", zIndex: 2 }}>
											<tr>
												<td class="p-3">ID</td>
												<td>{employeeReport.employee.key}</td>
												<td>Nombre</td>
												<td>{employeeReport.employee.firstname + " " + employeeReport.employee.dad_lastname + " " + employeeReport.employee.mom_lastname}</td>
												<td>Puesto</td>
												<td>{employeeReport.employee.job ? employeeReport.employee.job.description : ""}</td>
												<td>Departamento</td>
												<td>{employeeReport.employee.department ? employeeReport.employee.department.description : ""}</td>
											</tr>
										</table>

										<div class="table-responsive datatable-custom report-content" style={{ marginTop: "-5px", overflow: "auto" }}>
											<table class="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
												<thead style={{ position: "sticky", top: "0", zIndex: 1 }}>
													<tr role="row">
														<th>Fecha</th>
														<th>Día</th>
														<th>Horario</th>
														<th>Entrada</th>
														<th>S. Descanso</th>
														<th>E. Descanso</th>
														<th>Salida</th>
														<th>Horas trab.</th>
														<th>Horas desc.</th>
														<th>Horas trab. reales</th>
														<th>Retardo</th>
														<th>S. Anticipada</th>
														<th>T. Extra</th>
														<th>T. Ext. Red.</th>
														<th>Resumen de Incidencias</th>
														<th className="add-incidence-request">Agregar SI</th>
													</tr>
												</thead>
												<tbody>
													{function () {

														let components = [];

														for (let record of employeeReport.reports) {

															if (employeeReport.employee.leave != null &&
																(
																	moment.utc(record.date).format("YYYY-MM-DD") === moment.utc(employeeReport.employee.leave).format("YYYY-MM-DD") ||
																	moment.utc(record.date) > moment.utc(employeeReport.employee.leave)
																)
															) {
																components.push(
																	<tr key={record.id} role="row">
																		<td style={{ whiteSpace: "nowrap" }}>{moment(record.date).format("DD/MM/YYYY")}</td>
																		<td style={{ whiteSpace: "nowrap" }}>{getWeekDayName(record.week_day)}</td>
																		<td style={{ whiteSpace: "nowrap" }}></td>
																		<td style={{ whiteSpace: "nowrap" }}></td>
																		<td style={{ whiteSpace: "nowrap" }}></td>
																		<td style={{ whiteSpace: "nowrap" }}></td>
																		<td style={{ whiteSpace: "nowrap" }}></td>
																		<td style={{ whiteSpace: "nowrap" }}></td>
																		<td style={{ whiteSpace: "nowrap" }}></td>
																		<td style={{ whiteSpace: "nowrap" }}></td>
																		<td style={{ whiteSpace: "nowrap" }}></td>
																		<td style={{ whiteSpace: "nowrap" }}></td>
																		<td style={{ whiteSpace: "nowrap" }}></td>
																		<td style={{ whiteSpace: "nowrap" }}></td>
																		<td style={{ whiteSpace: "nowrap" }}>B.</td>
																		<td style={{ whiteSpace: "nowrap" }}></td>
																	</tr>
																)
																break;
															}

															components.push(
																<tr key={record.id} role="row">
																	<td 
																		style={{ whiteSpace: "nowrap", cursor: "pointer" }} 
																		onClick={() => {
																			setAttendanceLogsEmployee(employeeReport.employee);
																			setAttendanceLogsKey(employeeReport.employee.key);
																			setAttendanceLogsDate(record.date);
																			setAttendanceLogsModalVisible(true);
																		}}
																	>
																		{moment(record.date).format("DD/MM/YYYY")}
																	</td>
																	<td style={{ whiteSpace: "nowrap" }}>{getWeekDayName(record.week_day)}</td>
																	<td style={{ whiteSpace: "nowrap" }}>
																		{
																			function () {
																				try {
																					const schedule = JSON.parse(record.schedule);
																					if (schedule) {
																						if (schedule.has_break) {
																							return "Descanso";
																						} else {
																							return `${schedule?.entry_start.substring(0, 5)} - ${schedule?.exit_start.substring(0, 5)}`;
																						}
																					} else {
																						return "";
																					}
																				} catch (e) {
																					console.log(record.schedule)
																					return "";
																				}
																			}()
																		}
																	</td>
																	<td style={{ whiteSpace: "nowrap" }}>{record.entry ? record.entry.substring(0, 5) : "-"}</td>
																	<td style={{ whiteSpace: "nowrap" }}>{record.exit_break ? record.exit_break.substring(0, 5) : "-"}</td>
																	<td style={{ whiteSpace: "nowrap" }}>{record.return_break ? record.return_break.substring(0, 5) : "-"}</td>
																	<td style={{ whiteSpace: "nowrap" }}>{record.exit ? record.exit.substring(0, 5) : "-"}</td>
																	<td style={{ whiteSpace: "nowrap" }}>{record?.worked_hours ? formatTime(record.worked_hours) : "-"}</td>
																	<td style={{ whiteSpace: "nowrap" }}>{record?.rest_hours ? formatTime(record.rest_hours) : "-"}</td>
																	<td style={{ whiteSpace: "nowrap" }}>{record?.real_worked_hours ? formatTime(record.real_worked_hours) : "-"}</td>
																	<td style={{ whiteSpace: "nowrap" }}>{record?.retardment ? formatTime(record.retardment) : "-"}</td>
																	<td style={{ whiteSpace: "nowrap" }}>{record?.early_exit ? formatTime(record.early_exit) : "-"}</td>
																	<td style={{ whiteSpace: "nowrap" }}>{record?.extra_time ? formatTime(record.extra_time) : "-"}</td>
																	<td style={{ whiteSpace: "nowrap" }}>{record?.extra_time_round ? formatTime(record.extra_time_round) : "-"}</td>
																	<td style={{ whiteSpace: "nowrap" }}>{showIncidences(JSON.parse(record.incidences), onShowIncidenceRequest)}</td>
																	<td style={{ whiteSpace: "nowrap" }} class="text-center add-incidence-request">
																		<i class="bi bi-plus-circle-fill" onClick={() => showRequestModal(employeeReport.employee.id, record.date)}
																			style={{ color: "green", fontSize: "25px", cursor: "pointer" }}></i>
																	</td>
																</tr>
															)

														}

														return components;

													}()}
													<tr style={{ borderTop: "solid 3px black" }}>
														<td></td>
														<td></td>
														<td></td>
														<td></td>
														<td></td>
														<td></td>
														<td><b>Total</b></td>
														<td style={{ whiteSpace: "nowrap" }}><b>
															{getTotalTime(
																employeeReport.reports.filter((record) =>
																	employeeReport.employee.leave == null || moment.utc(record.date) < moment.utc(employeeReport.employee.leave)
																),
																"worked_hours"
															)}
														</b></td>
														<td style={{ whiteSpace: "nowrap" }}><b>
															{getTotalTime(
																employeeReport.reports.filter((record) =>
																	employeeReport.employee.leave == null || moment.utc(record.date) < moment.utc(employeeReport.employee.leave)
																),
																"rest_hours"
															)}
														</b></td>
														<td style={{ whiteSpace: "nowrap" }}><b>
															{getTotalTime(
																employeeReport.reports.filter((record) =>
																	employeeReport.employee.leave == null || moment.utc(record.date) < moment.utc(employeeReport.employee.leave)
																),
																"real_worked_hours"
															)}
														</b></td>
														<td style={{ whiteSpace: "nowrap" }}><b>
															{getTotalTime(
																employeeReport.reports.filter((record) =>
																	employeeReport.employee.leave == null || moment.utc(record.date) < moment.utc(employeeReport.employee.leave)
																),
																"retardment"
															)}
														</b></td>
														<td style={{ whiteSpace: "nowrap" }}><b>
															{getTotalTime(
																employeeReport.reports.filter((record) =>
																	employeeReport.employee.leave == null || moment.utc(record.date) < moment.utc(employeeReport.employee.leave)
																),
																"early_exit"
															)}
														</b></td>
														<td style={{ whiteSpace: "nowrap" }}><b>
															{getTotalTime(
																employeeReport.reports.filter((record) =>
																	employeeReport.employee.leave == null || moment.utc(record.date) < moment.utc(employeeReport.employee.leave)
																),
																"extra_time"
															)}
														</b></td>
														<td style={{ whiteSpace: "nowrap" }}><b>
															{getTotalTime(
																employeeReport.reports.filter((record) =>
																	employeeReport.employee.leave == null || moment.utc(record.date) < moment.utc(employeeReport.employee.leave)
																),
																"extra_time_round"
															)}
														</b></td>
													</tr>
												</tbody>
											</table>
										</div>

									</div>
							))
						}
					</div>
				</div>

			</div>

			<Modal
				title={"Filtros de búsqueda"}
				isVisible={filterModal}
				setIsVisible={hideFilterModal}
			>
				<Filters
					setLoading={setLoading}
					hideModal={hideFilterModal}
					filters={filters}
					setFilters={setFilters}
				/>
			</Modal>

			<Modal
				title={"Agregar incidencias"}
				isVisible={requestModal}
				setIsVisible={hideRequestModal}
			>
				<Request
					hideModal={hideRequestModal}
					selectedDate={selectedDate}
					onSubmitRequest={onSubmitRequest}
				/>
			</Modal>

			<Modal
				title={"Solicitud de incidencias"}
				isVisible={showIncidenceRequest}
				setIsVisible={hideIncidenceModal}
			>
				<IncidenceRequest
					refresh={Math.random()}
					id={selectedIncidenceRequest}
					hideModal={hideIncidenceModal}
				/>
			</Modal>

			<Modal
				title={attendanceLogsEmployee?.firstname + " " + attendanceLogsEmployee?.dad_lastname + " " + attendanceLogsEmployee?.mom_lastname}
				isVisible={attendanceLogsModalVisible}
				setIsVisible={setAttendanceLogsModalVisible}
			>
				<AttendanceLogs
					isVisible={attendanceLogsModalVisible}
					closeModal={() => setAttendanceLogsModalVisible(false)}
					date={attendanceLogsDate}
					employeeKey={attendanceLogsKey}
				/>
			</Modal>

		</>
	)

}

export default IncidencesReportView;