import React from "react";
import Modal from "helpers/Modal";
import FileField from "helpers/FileField";
import { SERVER_URL } from '../../../settings';

function DocumentEmployeesView({
  onChangeSearch,
  handleKeyUp,
  isVisible,
  setIsVisible,
  modalTitle,
  modalContent,
  addModal,
	jobs = [],
	departments = [],
	setJob,
	setDepartment,
	documentsEmployees = [],
  onSelectDocumentEmployee,
  documentEmployee,
  onUploadFile,
  uploadedDocuments,
  onSubmit,
  onDownload,
  setStatus
}) {
	return (
		<>

			<div className="content container-fluid p-5">

				<div className="page-header mb-3">
					<div className="row align-items-end">

						<div className="col-sm mb-2 mb-sm-0">
							<h1 className="page-header-title">Expediente de empleados</h1>
						</div>

						<div className="col-sm-auto">
							<button type="button" className="btn btn-primary" onClick={addModal}>
								<i className="bi bi-plus-circle" style={{marginRight: "7px"}}></i>
								Configurar expediente
							</button>
						</div>

					</div>
				</div>
				
				<div class="card">
					<div class="card-header">
						<div class="mb-2 mb-md-0 row">
							<div className="col-md-4">
								<div class="input-group input-group-merge input-group-flush">
									<div class="input-group-prepend input-group-text" >
										<i class="bi-search"></i>
									</div>
									<input
										type="search"
										class="form-control"
										placeholder="Buscar empleado"
										onChange={onChangeSearch}
										onKeyUp={handleKeyUp}
									/>
								</div>
							</div>
							<div className='col-md-2 form-group'>
								<label className="input">
									<select className="input__field form-control" onChange={e => setJob(e.target.value)}>
										<option value="">Todos</option>
										{
											jobs.map((job) =>
												<option value={job.id}>{job.description}</option>
											)
										}
									</select>
									<span class="input__label">
										Puesto
									</span>
								</label>
							</div>
							<div className='col-md-2 form-group'>
								<label className="input">
									<select className="input__field form-control" onChange={e => setDepartment(e.target.value)}>
										<option value="">Todos</option>
										{
											departments.map((department) =>
												<option value={department.id}>{department.description}</option>
											)
										}
									</select>
									<span class="input__label">
										Departamento
									</span>
								</label>
							</div>
              <div className='col-md-2 form-group'>
								<label className="input">
									<select className="input__field form-control" onChange={e => setStatus(e.target.value)}>
										<option value="ACTIVE">Activos</option>
                    <option value="LEAVE">Inactivos</option>
                    <option value="BOTH">Todos</option>
									</select>
									<span class="input__label">
										Estado
									</span>
								</label>
							</div>
              <div className="col-md-2 d-flex justify-content-end">
								<span className="pt-2 mx-1">Total de empleados: </span>
								<span className="pt-2 border-0"> {documentsEmployees.length} </span>
							</div>
						</div>
					</div>

          <div className="row">
						<div className="col-md-6">
              <div class={"table-responsive datatable-custom position-relative"}>
                <div class="dataTables_wrapper no-footer pt-2 pl-3 pr-3" style={{ maxHeight: "60vh" }}>
                  <table class="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
                    <thead>
                      <tr role="row">
                        <th className="text-center" style={{width: "10%"}}></th>
                        <th className="" style={{width: "15%"}}>ID</th>
                        <th style={{width: "55%"}}>Nombre</th>
                        <th style={{width: "20%"}} className="report-actions text-center">Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        documentsEmployees.map((doc) => (
                          <tr key={doc.employee.id} role="row">
                            <td className="text-center">
                            {doc.documents.reduce((acc, d) => acc + (d.document_employee != null), 0)} / {doc.documents.length}
                              {/* { doc.valid 
                                ? <i class="bi bi-check-circle-fill text-success mx-2"></i> 
                                : <i class="bi bi-x-circle-fill text-danger mx-2"></i>
                              } */}
                            </td>
                            <td className="">
                              {doc.employee.key}
                            </td>
                            <td>
                              {doc.employee.firstname} {doc.employee.dad_lastname} {doc.employee.mom_lastname}
                            </td>
                            <td className="report-actions text-center">
                              <div class="d-flex justify-content-center">                                
                                <button className={`btn ${documentEmployee && documentEmployee.employee.id === doc.employee.id ? "btn-primary" : "btn-warning" } py-1`} style={{ marginRight: "10px" }} onClick={() => onSelectDocumentEmployee(doc)}>
                                  <i class="bi bi-pencil"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </div>
						</div>
            <div className="col-md-6">
              <div class={"table-responsive datatable-custom position-relative"}>
                <div class="dataTables_wrapper no-footer pt-2 pl-3 pr-3" style={{ maxHeight: "60vh" }}>
                  <table class="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
                    <thead>
                      <tr role="row">
                        <th style={{width: "60%"}}>Documento</th>
                        <th style={{width: "40%"}} className="report-actions text-center">Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        uploadedDocuments ? uploadedDocuments.map((doc, idx) => (
                          <tr key={doc.document.id} role="row">
                            <td className="align-middle">
                              {doc.document.name}
                            </td>
                            <td className="report-actions text-center">
                              <div class="d-flex justify-content-center align-items-center">
                                <button 
                                  className={function(){
                                    
                                    let className = "btn btn-danger py-1";

                                    if(doc.document_employee && doc.document_employee.file)
                                      if(doc.document_employee.file.path || doc.document_employee.file.url)
                                        className = "btn btn-success py-1";

                                    return className;

                                  }()}
                                  style={{ marginRight: "10px" }}
                                  onClick={() => {
                                    if(doc.document_employee && doc.document_employee.file) {
                                      if(doc.document_employee.file.path) 
                                        window.open(SERVER_URL + "/" + doc.document_employee.file.path, '_blank');
                                      else
                                        window.open(doc.document_employee.file.url, '_blank');
                                    }
                                  }}
                                >
                                  <i class="bi bi-eye"></i>
                                </button>
                                <FileField 
                                  accept=".jpg,.jpeg,.png,.pdf" 
                                  name="photo"
                                  onChange={(files) => onUploadFile(idx, files)}
                                  multiple={false}
                                />
                              </div>
                            </td>
                          </tr>
                        )) : null
                      }
                      {
                        uploadedDocuments ? <tr>
                          <td colSpan="2" className="text-center">
                            <div className="d-flex justify-content-center">
                              <button className="btn btn-warning w-25" onClick={onDownload} style={{ marginRight: "75px" }}>
                                Descargar
                              </button>
                              <button className="btn btn-primary w-25" onClick={onSubmit}>
                                Guardar
                              </button>
                            </div>
                          </td>
                        </tr> : null
                      }
                      
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

				</div>

			</div>

			<Modal
				title={modalTitle}
				isVisible={isVisible}
				setIsVisible={setIsVisible}
			>
				{modalContent}
			</Modal>

		</>
	);
}

export default DocumentEmployeesView;