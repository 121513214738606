import React from "react";

function ListView({ 
	companies = [], 
	onDelete, 
	onEdit, 
	onShowUsers, 
	onShowDevices, 
	onShowEmployees,
	onChangeStatus, 
	onExport 
}) {
	return (
		<div class="table-responsive datatable-custom position-relative" >
			<div class="dataTables_wrapper no-footer pt-2 pl-3 pr-3" style={{ maxHeight: "60vh" }}>
				<table class="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
					<thead>
						<tr role="row">
							<th>Clave</th>
							<th>Foto</th>
							<th>Nombre</th>
							<th>RFC</th>
							<th>Activo</th>
							<th>Acciones</th>
						</tr>
					</thead>
					<tbody>
						{
							companies.map((company) => (
								<tr key={company.id} role="row">
									<td>{company.key}</td>
									<td>
										{
											company?.logo?.url ?
												<img crossorigin="anonymous" src={company.logo.url} alt="Logo" style={{ width: "50px", height: "50px", borderRadius: "50%" }} />
												: <i class="bi bi-person-circle" style={{ fontSize: "50px" }}></i>
										}
									</td>
									<td>{company.name}</td>
									<td>{company.rfc}</td>
									<td>
										<div class="form-group d-flex">
											<div className="form-check form-switch">
												<label for="switchStatus" style={{ marginRight: "10px" }}>
													{company.active ? "Activo" : "Inactivo"}
												</label>
												<input class="form-check-input" type="checkbox" id="switchStatus"
													checked={company.active} onChange={() => { onChangeStatus(company.id) }} />
											</div>
										</div>
									</td>
									{/* <td>{company.active ? "Si" : "No"}</td> */}
									<td>
										<button className="btn btn-success" style={{ marginRight: "10px" }} onClick={() => onExport(company.id)}>
											<i class="bi bi-box-arrow-in-down" style={{ marginRight: "7px" }}></i>
											Exportar
										</button>
										<button className="btn btn-warning" style={{ marginRight: "10px" }} onClick={() => onEdit(company.id)}>
											<i class="bi bi-pencil" style={{ marginRight: "7px" }}></i>
											Editar
										</button>
										<button className="btn btn-danger" style={{ marginRight: "10px" }} onClick={() => onDelete(company.id)}>
											<i class="bi bi-trash" style={{ marginRight: "7px" }}></i>
											Eliminar
										</button>
										<button className="btn btn-primary" style={{ marginRight: "10px" }} onClick={() => onShowUsers(company.id, company.key, company.name)}>
											<i class="bi bi-people" style={{ marginRight: "7px" }}></i>
											Usuarios
										</button>
										<button className="btn btn-primary" style={{ marginRight: "10px" }} onClick={() => onShowDevices(company.id, company.key, company.name)}>
											<i class="bi bi-device-ssd-fill" style={{ marginRight: "7px" }}></i>
											Lectores
										</button>
										<button className="btn btn-primary" style={{ marginRight: "10px" }} onClick={() => onShowEmployees(company.id, company.key, company.name)}>
											<i class="bi bi-person" style={{ marginRight: "7px" }}></i>
											Empleados
										</button>
									</td>
								</tr>
							))
						}
					</tbody>
				</table>
			</div>
		</div>
	)
}

export default ListView;