import React, { useState, useEffect, useRef } from "react";
import View from "components/crh/documentEmployees/Config.view";
import { showResponseMessage } from "utils/message"
import { usePost, useGetCall } from "utils/api";
import { toast } from "react-toastify";

function Config({ setIsVisible, refresh }) {

  const [refreshValue, setRefreshValue] = useState(null)
  const [documents, setDocuments] = useState([]);

  if (refresh !== refreshValue)
    setRefreshValue(refresh)

  const [callDocuments, setCallDocuments] = useGetCall("/documents", {
    onCompleted: (response) => {
      setDocuments(response.data.documents ?? [])
    }, onError: (error) => {
      showResponseMessage(error.status, "documents", "documentos", "read")
    }
  })

  useEffect(() => {
    callDocuments();
  }, [refreshValue]);

  const [callSave, reqSave] = usePost("/documents", {
    onCompleted: () => {
      if (!toast.isActive("toast-save-documents"))
        toast.success("Documentos creados correctamente", { toastId: "toast-save-documents" })
      setIsVisible(false)
      setDocuments([])
    },
    onError: (error) => {
      console.error(error)
      showResponseMessage(error.status, "documents", "documentos", "create")
    }
  })

  const onAddDocument = () => {
    setDocuments([...documents, {
      id: -1,
      name: 'Documento ' + (documents.length + 1)
    }]);
  }

  const onRemoveDocument = (idx) =>
    setDocuments(documents.filter((_, index) => index !== idx));

  const onChangeDocument = (idx, name) => {
    setDocuments(documents.map((document, index) => {
      if (index === idx) {
        return {
          ...document,
          name: name
        }
      }
      return document;
    }));
  }

  const onCancel = () => setIsVisible(false);
  const onSubmit = () => callSave({ documents });

  return (
    <View
      documents={documents}
      onAddDocument={onAddDocument}
      onRemoveDocument={onRemoveDocument}
      onChangeDocument={onChangeDocument}
      onCancel={onCancel}
      onSubmit={onSubmit}
    />
  );

}

export default Config;