import React, { useState, useRef, useEffect, useContext } from "react";
import View from "components/crh/reports/attendanceReport/AttendanceReport.view";
import { getUser } from "api/users";
import Loading from "helpers/Loading";
import { toast } from "react-toastify";
import { useGetCall, fetchPost } from "utils/api";
import { showResponseMessage } from "utils/message";
import { LoadingContext } from "helpers/LoadingContext";
import { getAbbreviationIncidence, getNameIncidence, getColorIncidence } from "utils/constants";
import { downloadCSV, downloadExcel } from "utils/files";
import moment from "moment";

function AttendanceReport() {

	let savedLayout = JSON.parse(sessionStorage.getItem("layout"));
	const tab = savedLayout.tabs.find((item) => item.content === "Reporte Asistencia");

	const { loading, setLoading } = useContext(LoadingContext);

	const reportRef = useRef(null);
	const id = sessionStorage.getItem("id");
	const [searchFilters, setSearchFilters] = useState({
		search: "",
		payroll_id: "",
		payroll_period_id: "",
		start_date: "",
		end_date: "",
		office_id: "",
		job_id: "",
		department_id: ""
	});

	const [filterModal, setFilterModal] = useState(false);
	const [reports, setReports] = useState([]);
	const [user, setUser] = useState({});

	const [permissionExcel, setPermissionExcel] = useState(false);
	const [permissionPdf, setPermissionPdf] = useState(false);
	const [permissionRead, setPermissionRead] = useState(false);

	const [callReports, reqReports] = useGetCall("/reports/attendance", {
		onCompleted: (response) => {
			setLoading(false);
			console.log(response.data)
			setReports(response.data);
		},
		onError: (error) => {
			setLoading(false);
			showResponseMessage(error.status, "attendance_reports", "reportes de asistencia", "read");
		}
	})

	const setFilters = (values) => {
		setLoading(true);
		setSearchFilters(values);
		callReports(values);
	}

	const showFilterModal = () => setFilterModal(true);
	const hideFilterModal = () => setFilterModal(false);

	const getWeekDayName = (weekDay) => {
		if (weekDay === "lunes") return "Lun";
		if (weekDay === "martes") return "Mar";
		if (weekDay === "miércoles") return "Mie";
		if (weekDay === "jueves") return "Jue";
		if (weekDay === "viernes") return "Vie";
		if (weekDay === "sábado") return "Sab";
		if (weekDay === "domingo") return "Dom";
		return "";
	}
	
	const getMonthName = (month) => {
		if (month === "01") return "Ene";
		if (month === "02") return "Feb";
		if (month === "03") return "Mar";
		if (month === "04") return "Abr";
		if (month === "05") return "May";
		if (month === "06") return "Jun";
		if (month === "07") return "Jul";
		if (month === "08") return "Ago";
		if (month === "09") return "Sep";
		if (month === "10") return "Oct";
		if (month === "11") return "Nov";
		if (month === "12") return "Dic";
		return "";
	}
	
	const getHeaders = (startDate, endDate) => {

		const dates = [];
		let actualDate = moment.utc(startDate);

		while(actualDate <= moment.utc(endDate)) {

			const weekDay = actualDate.format("dddd");
			const monthDay = actualDate.format("DD");
			const month = actualDate.format("MM");
	
			dates.push({
				weekDay: getWeekDayName(weekDay),
				monthDay,
				month: getMonthName(month)
			})

			actualDate.add(1, 'days');
		}

		return dates;

	}

	const generateDetailedCells = () => {
	
		let csv = [];
		const dateHeaders = getHeaders(searchFilters.start_date, searchFilters.end_date);
		const count = dateHeaders.length;

		csv.push([
			{}, {}, {}, {}, {},
			...dateHeaders.map((date) => {
				return {
					value: `${date.weekDay}`,
					color: "#04b5fc",
					fontColor: "#FFFFFF",
					border: true,
				}
			}),
			{}, {}, {}, {}, {}, {}, {}, {}, {}
		]);

		csv.push([
			{
				value: `ID`,
				color: "#04b5fc",
				fontColor: "#FFFFFF",
				border: true,
			},
			{
				value: `Sucursal`,
				color: "#04b5fc",
				fontColor: "#FFFFFF",
				border: true,
			},
			{
				value: `Departamento`,
				color: "#04b5fc",
				fontColor: "#FFFFFF",
				border: true,
			},
			{
				value: `Puesto`,
				color: "#04b5fc",
				fontColor: "#FFFFFF",
				border: true,
			},
			{
				value: `Nombre`,
				color: "#04b5fc",
				fontColor: "#FFFFFF",
				border: true,
			},
			...dateHeaders.map((date) => {
				return {
					value: `${date.weekDay} ${date.monthDay}`,
					color: "#04b5fc",
					fontColor: "#FFFFFF",
					border: true,
				}
			}),
			{
				value: `Horas trabajadas`,
				color: "#04b5fc",
				fontColor: "#FFFFFF",
				border: true,
			},
			{
				value: `Retardos`,
				color: "#04b5fc",
				fontColor: "#FFFFFF",
				border: true,
			},
			{
				value: `Tiempo extra`,
				color: "#04b5fc",
				fontColor: "#FFFFFF",
				border: true,
			},
			{
				value: `Faltas`,
				color: "#04b5fc",
				fontColor: "#FFFFFF",
				border: true,
			},
			{
				value: `Incapacidad`,
				color: "#04b5fc",
				fontColor: "#FFFFFF",
				border: true,
			},
			{
				value: `Prima dominical`,
				color: "#04b5fc",
				fontColor: "#FFFFFF",
				border: true,
			},
			{
				value: `Días festivos`,
				color: "#04b5fc",
				fontColor: "#FFFFFF",
				border: true,
			},
			{
				value: `Vacaciones`,
				color: "#04b5fc",
				fontColor: "#FFFFFF",
				border: true,
			},
			{
				value: `Comentarios`,
				color: "#04b5fc",
				fontColor: "#FFFFFF",
				border: true,
			},
		]);

		reports.forEach((report) => {

			const currentReports = report.logs.map((log) => {
				return {
					value: function(){

						const incidences = JSON.parse(log?.report?.incidences ?? '[]');

						if(incidences.find(incidence => incidence.incidence === 'ATTENDANCE')) {

							let entryMessage = log?.report?.entry?.substring(0, 5);
							let exitMessage = log?.report?.exit?.substring(0, 5);

							if(incidences.find(incidence => incidence.incidence === 'EXIT_OMISSION')) exitMessage = getNameIncidence('EXIT_OMISSION');
							if(incidences.find(incidence => incidence.incidence === 'ENTRY_OMISSION')) entryMessage = getNameIncidence('ENTRY_OMISSION');

							return `${entryMessage} - ${exitMessage}`;

						}

						return getNameIncidence(log.type);

					}(),
					color: log.color,
					fontColor: (log.color) ? getColorIncidence(log.color) : "#000000",
					border: true,
				}
			});

			// Fill the rest of the days with empty cells at start
			if(currentReports.length < count) {
				const emptyCells = Array(count - currentReports.length).fill({
					value: "",
					color: null,
					border: true,
				});
				currentReports.unshift(...emptyCells);
			}

			csv.push([
				{
					value: report.employee.key,
					color: null,
					border: true,
				},
				{
					value: report.employee.office?.description,
					color: null,
					border: true,
				},
				{
					value: report.employee.department?.description,
					color: null,
					border: true,
				},
				{
					value: report.employee.job?.description,
					color: null,
					border: true,
				},
				{
					value: `${report.employee.firstname} ${report.employee.dad_lastname} ${report.employee.mom_lastname}`,
					color: null,
					border: true,
				},
				...currentReports,
				{
					value: report.workedHours,
					color: null,
					border: true,
				},
				{
					value: report.amountRetardments + " - " + report.hoursRetardments,
					color: null,
					border: true,
				},
				{
					value: report.amountExtraTime + " - " + report.hoursExtraTime,
					color: null,
					border: true,
				},
				{
					value: report.amountFouls,
					color: null,
					border: true,
				},
				{
					value: report.amountLicenses,
					color: null,
					border: true,
				},
				{
					value: report.amountVacationBonuses,
					color: null,
					border: true,
				},
				{
					value: report.amountBankHolidays,
					color: null,
					border: true,
				},
				{
					value: report.amountHolidays,
					color: null,
					border: true,
				},
				{
					value: report.comments,
					color: null,
					border: true,
				}
			]);

		});

		return csv;

	}

	const generateCells = () => {

		let csv = [];
		const dateHeaders = getHeaders(searchFilters.start_date, searchFilters.end_date);

		csv.push([
			{
				value: `ID`,
				color: null,
			},
			{
				value: `Sucursal`,
				color: null,
			},
			{
				value: `Departamento`,
				color: null,
			},
			{
				value: `Puesto`,
				color: null,
			},
			{
				value: `Nombre`,
				color: null,
			},
			
			...dateHeaders.map((date) => {
				return {
					value: `${date.weekDay} ${date.monthDay} - ${date.month}`,
					color: null,
				}
			}),
			{
				value: `Horas trabajadas`,
				color: null,
			},
			{
				value: `Retardos`,
				color: null,
			},
			{
				value: `Tiempo extra`,
				color: null,
			}
		]);

		reports.forEach((report) => {

			csv.push([
				{
					value: report.employee.key,
					color: null,
				},
				{
					value: report.employee.office?.description,
					color: null,
				},
				{
					value: report.employee.department?.description,
					color: null,
				},
				{
					value: report.employee.job?.description,
					color: null,
				},
				{
					value: `${report.employee.firstname} ${report.employee.mom_lastname} ${report.employee.dad_lastname}`,
					color: null,
				},
				...report.logs.map((log) => {
					return {
						value: getAbbreviationIncidence(log.type),
						color: log.color,
					}
				}),
				{
					value: report.workedHours,
					color: null,
				},
				{
					value: report.amountRetardments + " - " + report.hoursRetardments,
					color: null,
				},
				{
					value: report.amountExtraTime + " - " + report.hoursExtraTime,
				}
			]);

		});

		return csv;

	}

	const onDownloadCSV = () => {

		const cells = generateCells();
		const csvCells = cells.map((row) => row.map((cell) => cell.value));

		downloadCSV({
			user,
			start_date: searchFilters.start_date,
			end_date: searchFilters.end_date,
			cells: csvCells,
			report: "asistencia",
		});
		
	}

	const onDownloadXlsx = () => {
		downloadExcel({
			user,
			start_date: searchFilters.start_date,
			end_date: searchFilters.end_date,
			cells: generateCells(),
			report: "asistencia"
		})
	}

	const onDownloadDetailedXlsx = () => {
		downloadExcel({
			user,
			start_date: searchFilters.start_date,
			end_date: searchFilters.end_date,
			cells: generateDetailedCells(),
			report: "asistencia_detalles"
		})
	}

	const onChangeSearch = (event) => {
		if (event.target.value === '') {
			setFilters({ ...searchFilters, search: event.target.value });
		}
	}

	const handleKeyUp = (event) => {
		if (event.key === "Enter") {
			setFilters({ ...searchFilters, search: event.target.value });
		}
	}

	useEffect(() => {
		getUser(id).then((response) => {
			setUser(response.data)
		});
	}, [id]);

	useEffect(() => {
		fetchPost("/permissions/check", { table: "ATTENDACE_REPORT", permission: "excel" }).then((response) => {
			if (response.data) setPermissionExcel(response.data.isAllowed);
		});
		fetchPost("/permissions/check", { table: "ATTENDACE_REPORT", permission: "pdf" }).then((response) => {
			if (response.data) setPermissionPdf(response.data.isAllowed);
		});
		fetchPost("/permissions/check", { table: "ATTENDACE_REPORT", permission: "read" }).then((response) => {
			if (response.data) {
				setPermissionRead(response.data.isAllowed)
				if (response.data.isAllowed) {
					setFilterModal(tab ? tab.active : false)
				} else {
					if (!toast.isActive(`toast-read-attendance_reports-unauthorized`)) {
						toast.error(`Error, no tienes los permisos para consultar reportes de asistencia`, { toastId: `toast-read-attendance_reports-unauthorized` })
					}
				}
			}
		});
	}, []);

	if (loading) return <Loading />;

	return (
		<View
			getHeaders={getHeaders}
			user={user}
			permissionExcel={permissionExcel}
			permissionPdf={permissionPdf}
			permissionRead={permissionRead}
			handleKeyUp={handleKeyUp}
			onChangeSearch={onChangeSearch}
			searchFilters={searchFilters}
			setLoading={setLoading}
			onDownloadDetailedXlsx={onDownloadDetailedXlsx}
			onDownloadXlsx={onDownloadXlsx}
			onDownloadCSV={onDownloadCSV}
			reportRef={reportRef}
			reports={reports}
			filterModal={filterModal}
			filters={searchFilters}
			setFilters={setFilters}
			showFilterModal={showFilterModal}
			hideFilterModal={hideFilterModal}
		/>
	)


}

export default AttendanceReport;