import React from "react";
import { Formik, Form, Field } from 'formik';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
const format = 'HH:mm';

function FiltersView({
	profiles,
	filters,
	onSubmit,
	formikRef
}) {
	return (
		<div className='container mt-3'>
			<Formik
				innerRef={formikRef}
				initialValues={filters}
				onSubmit={onSubmit}>
				{({ values, setFieldValue }) =>
					<Form>

						<div className="row my-2">
							<div className="col-md-4">
								<h4>Perfil</h4>
							</div>
							<div className="col-md-8">
								<Field as="select" name="visit_profile_id" className="form-control" value={values.visit_profile_id || ''}>
									<option value="">Seleccione una opción</option>
									{
										profiles.map(profile =>
											<option key={profile.id} value={profile.id}>{profile.key} - {profile.description}</option>
										)
									}
								</Field>
							</div>
						</div>

						<div className="row my-2">
							<div className="col-md-4">
								<h4>Estatus</h4>
							</div>
							<div className="col-md-8">
								<Field as="select" name="active" className="form-control" value={values.active || ''}>
									<option value="">Seleccione una opción</option>
									<option value="true">Activo</option>
									<option value="false">Inactivo</option>
								</Field>
							</div>
						</div>

						<div className="row my-2">
							<div className="col-md-4">
								<h4>Fecha inicial</h4>
							</div>
							<div className="col-md-8">
								<Field type="date" name="start_date" class="form-control input__field" value={values.start_date || ''} />
							</div>
						</div>

						<div className="row my-2">
							<div className="col-md-4">
								<h4>Fecha final</h4>
							</div>
							<div className="col-md-8">
								<Field type="date" name="end_date" class="form-control input__field" value={values.end_date || ''} />
							</div>
						</div>

						<div className="row my-2">
							<div className="col-md-4">
								<h4>Hora inicial</h4>
							</div>
							<div className="col-md-8">
								<TimePicker className="w-100 py-2" format={format} onChange={time => setFieldValue("start_hour", time.format(format))} value={values.start_hour ? dayjs(values.start_hour, format) : ''} />
							</div>
						</div>

						<div className="row my-2">
							<div className="col-md-4">
								<h4>Hora final</h4>
							</div>
							<div className="col-md-8">
								<TimePicker className="w-100 py-2" format={format} onChange={time => setFieldValue("end_hour", time.format(format))} value={values.end_hour ? dayjs(values.end_hour, format) : ''} />
							</div>
						</div>

						<div class="pt-3">
							<button type="submit" class="btn btn-primary">
								<i class="bi bi-plus" style={{ marginRight: "10px" }}></i>
								Aceptar
							</button>
						</div>

					</Form>
				}</Formik>

		</div >
	)
}

export default FiltersView;