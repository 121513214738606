import React from "react";
import View from "components/home/tables/ListComparativeTime.view";

function List({ employees, incidences = [], setIsVisible, key1, key2, label1, label2 }) {
	return (
		<View 
            employees={employees}
            incidences={incidences} 
            setIsVisible={setIsVisible} 
            key1={key1}
            key2={key2}
            label1={label1}
            label2={label2}
        />
	);
}

export default List;