import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import Loading from "helpers/Loading";
import { useDelete, useGetCall, fetchPost } from "utils/api";
import { showResponseMessage } from "utils/message";
import { LoadingContext } from "helpers/LoadingContext";
import View from "components/crh/visits/profiles/List.view";

function ProfilesList({
	search,
	refresh,
	addEditModal
}) {

	const { loading, setLoading } = useContext(LoadingContext);

	const [profiles, setProfiles] = useState([]);	
	const [refreshValue, setRefreshValue] = useState(null);
	const [refreshDelete, setRefreshDelete] = useState(null);

	if(refreshValue !== refresh) {
		setRefreshValue(refresh);
	}

	const [callProfiles, reqProfiles] = useGetCall("/visit_profiles", {
		onCompleted: (response) => {
			setLoading(false);
			setProfiles(response.data)
		},
		onError: (error) => {
			setLoading(false);
			showResponseMessage(error.status, "visit_profiles", "submarcas", "read");
		}
	});

	const [callDelete, reqDelete] = useDelete("/visit_profiles", {
		onCompleted: () => {
			if (!toast.isActive("serie-deleted"))
				toast.success("Perfil eliminado", { toastId: "serie-deleted" });

			setRefreshDelete(Math.random());
		},
		onError: (error) => {
			console.error(error)
			showResponseMessage(error.status, "visit_profiles", "submarcas", "delete");
		}
	});

	useEffect(() => {
		setLoading(true);
		callProfiles({ search });
	}, [search, refreshValue, refreshDelete]);

	const onDelete = (id) => {
		fetchPost("/permissions/check", { table: "VISIT_PROFILES", permission: "delete" }).then((response) => {
			if (response.data) {
				const data = response.data;
				if (data.isAllowed === true) {
					if (window.confirm("¿Estás seguro de eliminar el perfil?")) {
						callDelete({ id: id });
					}
				} else {
					if(!toast.isActive("toast-delete-visit_profiles-unauthorized")) {
						toast.error("Error, no tienes los permisos para eliminar perfiles", { toastId: "toast-delete-visit_profiles-unauthorized" })
					}
				}
			}
		})
	}

	const onEdit = (id) => {
		addEditModal(id);
	}

	if (loading) return <Loading />

	return (
		<View profiles={profiles} onDelete={onDelete} onEdit={onEdit} />
	);
}

export default ProfilesList;