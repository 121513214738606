import React from "react";
import moment from "moment";
import { formatISODate } from "utils/date";
import "moment/locale/es-mx";
moment.locale('es');

function ListView({ payrollPeriods, onDelete, onEdit }) {

	return (
		<div class="table-responsive datatable-custom position-relative" >
			<div class="dataTables_wrapper no-footer pt-2 pl-3 pr-3" style={{maxHeight: "60vh"}}>
				<table class="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
					<thead>
						<tr role="row">
							<th>Clave</th>
							<th>Descripción</th>
							<th>Nómina</th>
							<th>Fecha inicial</th>
							<th>Fecha final</th>
							<th>Fecha de pago</th>
							<th>Acciones</th>
						</tr>
					</thead>
					<tbody>
						{
							payrollPeriods.map((payrollPeriod) => (
								<tr key={payrollPeriod.id} role="row">
									<td>{payrollPeriod.key}</td>
									<td>{payrollPeriod.description}</td>
									<td>{payrollPeriod.payroll.description}</td>
									<td>{formatISODate(payrollPeriod.start)}</td>
									<td>{formatISODate(payrollPeriod.end)}</td>
									<td>{formatISODate(payrollPeriod.payment)}</td>
									<td>
										<button className="btn btn-warning" style={{ marginRight: "10px" }} onClick={() => onEdit(payrollPeriod.id)}>
											<i class="bi bi-pencil" style={{marginRight: "7px"}}></i>
											Editar
										</button>
										<button className="btn btn-danger" onClick={() => onDelete(payrollPeriod.id)}>
											<i class="bi bi-trash" style={{marginRight: "7px"}}></i>
											Eliminar
										</button>
									</td>
								</tr>
							))
						}
					</tbody>
				</table>
			</div>
		</div>

	);
}

export default ListView;