import React from "react";
import NoImage from 'resources/images/no-avatar.png';
import { permissionTables } from "utils/constants";
import { ReactComponent as Tablero } from "resources/svg/chart-line-solid.svg";
import { ReactComponent as Libro } from "resources/svg/book-open-solid.svg";
import { ReactComponent as Flecha } from "resources/svg/play-solid.svg";
import { ReactComponent as Lector } from "resources/svg/fingerprint-solid.svg";
import { ReactComponent as Control } from "resources/svg/gamepad-solid.svg";
import { ReactComponent as Reporte } from "resources/svg/file-lines-solid.svg";
import { ReactComponent as Incidencia } from "resources/svg/calendar-day-solid.svg";
import { ReactComponent as Admin } from "resources/svg/user-gear-solid.svg";
import { Link } from 'react-router-dom';
import 'resources/styles/Sidebar.scss';

const hasPermissionModule = (table, permissions, user) => {
	if (user?.type === "ADMIN") return true;
	const permission = permissions.find((item) => item.table === table);
	if (!permission) return false;
	return permission.read;
}

const hasPermissionChunk = (category, permissions, user) => {
	if (user?.type === "ADMIN") return true;
	const modules = permissionTables.find((item) => item.category === category).tables;
	for (let module of modules) {
		if (hasPermissionModule(module, permissions, user)) return true;
	}
	return false;
}

const hasPermissionSubModule = (submodule, permissions, user) => {

	let modules = [];

	if (submodule === "EMPLOYEES") {
		modules = ["EMPLOYEE", "JOB", "DEPARTMENT", "OFFICE", "COST_CENTER", "AREA", "BOSS", "SUBAREA", "RECORD"];
	} else if (submodule === "SHIFTS") {
		modules = ["SHIFT", "SCHEDULE", "TIMETABLE", "TIMETABLE_HISTORY"];
	} else if (submodule === "PAYROLLS") {
		modules = ["PAYROLL", "PAYROLL_PERIOD", "REGIME", "CONTRACT", "REASON_LEAVE"];
	}
	else if (submodule === "VISITS") {
		modules = ["CATALOGS"];
	}

	for (let module of modules) {
		if (hasPermissionModule(module, permissions, user)) return true;
	}
}

function SideBarView({
	permissions,
	onAddTab,
	setTabs,
	theme,
	user,
	button,
	isCrh
}) {
	return (
		<div className={button === false ? 'sidebar' : 'sidebar_reducer'}
			style={{ background: theme === "light" ? "#282454" : "#111111", zIndex: 100 }}>


			<div className="empresa">
				<div className="logo">
					{
						user?.company?.logo?.url ? (
							<img crossorigin="anonymous" src={user.company.logo.url} alt="Logo empresa" style={{ borderRadius: "50%" }} />
						) : (
							<img src={NoImage} alt="" style={{ borderRadius: "50%" }} />
						)
					}
				</div>

				{
					!isCrh ? (
						<div className="nombre">
							<p>{user?.company?.name ?? 'No se agrego nombre de empresa'}</p>
						</div>
					) : null
				}
			</div>

			{
				!isCrh ? (
					<>
					
						{hasPermissionModule("HOME", permissions, user) && (
							<div className="graficas" style={{ marginTop: "0px", marginBottom: "0px", paddingTop: "0px", paddingBottom: "0px", zIndex: 500 }}>
								<span className="m-0 p-0 row" onClick={() => onAddTab(0, "Home", { onAddTab })}>
									<div className="col-md-3 d-flex align-items-center justify-content-center mx-auto">
										<i class="bi bi-graph-up m-0 p-0" style={{ fontSize: "1.7rem" }}></i>	
									</div>
									<div className="col-md-9 d-flex align-items-center justify-content-center mx-auto">
										<p className="">Tablero</p>
									</div>
								</span>
							</div>
						)}

						{hasPermissionChunk("CATALOGUES", permissions, user) && (
							<div className="opciones mt-2" style={{ marginTop: "0px", marginBottom: "0px", paddingTop: "0px", paddingBottom: "0px", zIndex: 500 }}>
								<div className="descripcion">
									<i class="bi bi-book-fill" style={{ fontSize: "1.7rem", width: "2rem" }}></i> <p className="mx-2">Catálogo</p>
								</div>
								<Flecha className='arrow' />
								<ul 
									className="sub-menu"
									style={{
										background: theme === "light" ? "#282454" : "#111111",
										width: "120px"
									}}
								>
									{hasPermissionSubModule("EMPLOYEES", permissions, user) && (
											<li className='sub-menus'>
												<Link to="#">Empleados <Flecha /></Link>
												<ul className="nominas" style={theme === "light" ? { background: "#282454" } : { background: "#111111" }}>
													{hasPermissionModule("EMPLOYEE", permissions, user) && (
														<li><Link to="#" onClick={() => onAddTab(1, "Empleados")}>Empleados</Link></li>
													)}
													{hasPermissionModule("JOB", permissions, user) && (
														<li><Link to="#" onClick={() => onAddTab(2, "Puestos")}>Puestos</Link></li>
													)}
													{hasPermissionModule("DEPARTMENT", permissions, user) && (
														<li><Link to="#" onClick={() => onAddTab(3, "Departamentos")}>Departamento</Link></li>
													)}
													{hasPermissionModule("OFFICE", permissions, user) && (
														<li><Link to="#" onClick={() => onAddTab(4, "Sucursales")}>Sucursales</Link></li>
													)}
													{hasPermissionModule("COST_CENTER", permissions, user) && (
														<li><Link to="#" onClick={() => onAddTab(5, "Centros costos")}>Centros de Costos</Link></li>
													)}
													{hasPermissionModule("AREA", permissions, user) && (
														<li><Link to="#" onClick={() => onAddTab(6, "Areas")}>Area</Link></li>
													)}
													{hasPermissionModule("BOSS", permissions, user) && (
														<li><Link to="#" onClick={() => onAddTab(7, "Jefes directos")}>Jefe directo</Link></li>
													)}
													{hasPermissionModule("SUBAREA", permissions, user) && (
														<li><Link to="#" onClick={() => onAddTab(8, "Sub áreas")}>Sub área</Link></li>
													)}
													{hasPermissionModule("RECORD", permissions, user) && (
														<li><Link to="#" onClick={() => onAddTab(41, "Expediente")}>Expediente</Link></li>
													)}
												</ul>
											</li>
									)}
									{hasPermissionSubModule("SHIFTS", permissions, user) && (
											<li className='sub-menus'><Link to="#">Turnos <Flecha /></Link>
												<ul className="nominas" style={theme === "light" ? { background: "#282454" } : { background: "#111111" }}>
													{hasPermissionModule("SHIFT", permissions, user) && (
														<li><Link to="#" onClick={() => onAddTab(9, "Turnos")}>Turnos</Link></li>
													)}
													{hasPermissionModule("SCHEDULE", permissions, user) && (
														<li><Link to="#" onClick={() => onAddTab(10, "Horarios")}>Horarios</Link></li>
													)}
													{hasPermissionModule("TIMETABLE", permissions, user) && (
														<li><Link to="#" onClick={() => onAddTab(11, "Turnos/Horarios")}>Turnos/Horarios</Link></li>
													)}
													{hasPermissionModule("TIMETABLE_HISTORY", permissions, user) && (
														<li><Link to="#" onClick={() => onAddTab(42, "Histórico turnos")}>Histórico de turnos</Link></li>
													)}
													{hasPermissionModule("PLANNING", permissions, user) && (
														<li><Link to="#" onClick={() => onAddTab(61, "Planeación semanal")}>Planeación semanal</Link></li>
													)}
												</ul>
											</li>
									)}
									{hasPermissionSubModule("PAYROLLS", permissions, user) && (
											<li className='sub-menus'><Link to="#" className="nominas-menu">Nóminas <Flecha /></Link>
												<ul className="nominas" style={theme === "light" ? { background: "#282454" } : { background: "#111111" }}>
													{hasPermissionModule("PAYROLL", permissions, user) && (
														<li><Link to="#" onClick={() => onAddTab(12, "Nóminas")}>Tipo de nómina</Link></li>
													)}
													{hasPermissionModule("PAYROLL_PERIOD", permissions, user) && (
														<li><Link to="#" onClick={() => onAddTab(13, "Periodos nómina")}>Periodos de nómina</Link></li>
													)}
													{hasPermissionModule("REGIME", permissions, user) && (
														<li><Link to="#" onClick={() => onAddTab(14, "Régimen")}>Tipos de Régimen</Link></li>
													)}
													{hasPermissionModule("CONTRACT", permissions, user) && (
														<li><Link to="#" onClick={() => onAddTab(15, "Contratos")}>Tipos de Contrato</Link></li>
													)}
													{hasPermissionModule("REASON_LEAVE", permissions, user) && (
														<li><Link to="#" onClick={() => onAddTab(16, "Motivos de baja")}>Motivos de baja</Link></li>
													)}
												</ul>
											</li>
									)}
								</ul>
							</div>
						)}

						{hasPermissionChunk("DEVICES", permissions, user) && (
							<div className="opciones my-1" style={{ marginTop: "0px", marginBottom: "0px", paddingTop: "0px", paddingBottom: "0px", zIndex: 500 }}>
								<div className="descripcion">
									<i class="bi bi-fingerprint" style={{ fontSize: "1.7rem", width: "2rem" }}></i> <p className="mx-2">Lectores</p>
								</div>
								<Flecha className='arrow' />
								<ul className='sub-menu' style={theme === "light" ? { background: "#282454" } : { background: "#111111" }}>
									{hasPermissionModule("DEVICE", permissions, user) && (
										<li onClick={() => onAddTab(17, "Lectores")}>Lectores biométricos</li>
									)}
									{hasPermissionModule("EMPLOYEE_DEVICE", permissions, user) && (
										<li onClick={() => onAddTab(18, "Emple - lectores")}>Empleados a Lectores</li>
									)}
									{hasPermissionModule("DEVICE_EMPLOYEE", permissions, user) && (
										<li onClick={() => onAddTab(19, "Lect - empleados")}>Lectores a empleados</li>
									)}
									{hasPermissionModule("ADMIN_REPORT", permissions, user) && (
										<li onClick={() => onAddTab(20, "Reporte admins")}>Reporte de administradores </li>
									)}
									{hasPermissionModule("DEVICE_MANAGER", permissions, user) && (
										<li onClick={() => onAddTab(21, "Gestión de lectores")}>Gestión de lectores </li>
									)}
								</ul>
							</div>
						)}

						{hasPermissionChunk("PARAMETERS", permissions, user) && (
							<div className="opciones my-1" style={{ marginTop: "0px", marginBottom: "0px", paddingTop: "0px", paddingBottom: "0px", zIndex: 500 }}>
								<div className="descripcion" onClick={() => onAddTab(35, "Params. generales")}>
									<i class="bi bi-controller" style={{ fontSize: "1.7rem", width: "2rem" }}></i> <p className="mx-2">Parámetros</p>
								</div>
							</div>
						)}

						{hasPermissionChunk("REPORTS", permissions, user) && (
							<div className="opciones" style={{ marginTop: "0px", marginBottom: "0px", paddingTop: "0px", paddingBottom: "0px", zIndex: 500 }}>
								<div className="descripcion">
									<i class="bi bi-file-earmark-text-fill" style={{ fontSize: "1.7rem", width: "2rem" }}></i> <p className="mx-2">Reportes</p>
								</div>
								<Flecha className='arrow' />
								<ul className='sub-menu mt-5' style={theme === "light" ? { background: "#282454" } : { background: "#111111" }}>
									{hasPermissionModule("ATTENDACE_REGISTER_REPORT", permissions, user) && (
										<li onClick={() => onAddTab(22, "Asis. registrados")}>Asistencia empleados registrados</li>
									)}
									{hasPermissionModule("ATTENDACE_NO_REGISTER_REPORT", permissions, user) && (
										<li onClick={() => onAddTab(23, "Asis. no registrados")}>Asistencia empleados no registrados &nbsp;&nbsp;&nbsp;</li>
									)}
									{hasPermissionModule("HOLIDAYS_MANAGE_REPORT", permissions, user) && (
										<li onClick={() => onAddTab(30, "Estado Vacaciones")}>Estado de cuenta de vacaciones</li>
									)}
									{hasPermissionModule("INCIDENCES_REPORT", permissions, user) && (
										<li onClick={() => onAddTab(37, "Reporte Incidencias")}>Reporte de incidencias</li>
									)}
									{hasPermissionModule("ATTENDACE_REPORT", permissions, user) && (
										<li onClick={() => onAddTab(39, "Reporte Asistencia")}>Reporte de asistencia</li>
									)}
									{hasPermissionModule("PUNTUALITY_REPORT", permissions, user) && (
										<li onClick={() => onAddTab(38, "Reporte Puntualidad")}>Reporte de puntualidad</li>
									)}
									{hasPermissionModule("FOUL_REPORT", permissions, user) && (
										<li onClick={() => onAddTab(45, "Reporte Faltas")}>Reporte de faltas</li>
									)}
									{hasPermissionModule("RETARDMENT_REPORT", permissions, user) && (
										<li onClick={() => onAddTab(46, "Reporte Retardos")}>Reporte de retardos</li>
									)}
									{hasPermissionModule("ENTRY_OMISSION_REPORT", permissions, user) && (
										<li onClick={() => onAddTab(50, "Reporte O. Entrada")}>Reporte de omisión de entrada</li>
									)}
									{hasPermissionModule("EXIT_OMISSION_REPORT", permissions, user) && (
										<li onClick={() => onAddTab(51, "Reporte O. Salida")}>Reporte de omisión de salida</li>
									)}
									{hasPermissionModule("EARLY_DEPARTURE_REPORT", permissions, user) && (
										<li onClick={() => onAddTab(52, "Reporte S. Anticipada")}>Reporte de salida anticipada</li>
									)}
									{hasPermissionModule("EXTRA_TIME_REPORT", permissions, user) && (
										<li onClick={() => onAddTab(53, "Reporte T. Extra")}>Reporte de tiempo extra</li>
									)}
									{hasPermissionModule("WORKED_REST_DAYS_REPORT", permissions, user) && (
										<li onClick={() => onAddTab(54, "Reporte D. Trabajados")}>Reporte de descansos trabajados</li>
									)}
									{hasPermissionModule("WORKED_BANK_HOLIDAYS_REPORT", permissions, user) && (
										<li onClick={() => onAddTab(55, "Reporte D.F. Trabajados")}>Reporte de días festivos trabajados</li>
									)}
									{hasPermissionModule("WORKED_SUNDAY_REPORT", permissions, user) && (
										<li onClick={() => onAddTab(56, "Reporte Domingos T.")}>Reporte de domingos trabajados</li>
									)}
									{hasPermissionModule("INCIDENCE_REQUESTS_REPORT", permissions, user) && (
										<li onClick={() => onAddTab(57, "Reporte Soli. Inciden.")}>Reporte de solicitud de incidencias</li>
									)}
								</ul>
							</div>
						)}

						{hasPermissionChunk("INCIDENCES", permissions, user) && (
							<div className="opciones my-1" style={{ marginTop: "0px", marginBottom: "0px", paddingTop: "0px", paddingBottom: "0px", zIndex: 500 }}>
								<div className="descripcion">
									<i class="bi bi-calendar-event-fill" style={{ fontSize: "1.7rem", width: "2rem" }}></i> <p className="mx-2">Incidencias</p>
								</div>
								<Flecha className='arrow' />
								<ul className='sub-menu' style={theme === "light" ? { background: "#282454" } : { background: "#111111" }}>
									{hasPermissionModule("INCIDENCES", permissions, user) && (
										<li onClick={() => onAddTab(24, "Incidencias")}>Incidencias</li>
									)}
									{hasPermissionModule("INCIDENCE_REQUESTS", permissions, user) && (
										<li onClick={() => onAddTab(25, "Soli. incidencias")}>Solicitud de incidencias</li>
									)}
									{hasPermissionModule("AUTH_INCIDENCE_REQUESTS", permissions, user) && (
										<li onClick={() => onAddTab(26, "Auto. incidencias")}>Autorización de incidencias</li>
									)}
									{hasPermissionModule("HOLDAY_TABLE", permissions, user) && (
										<li onClick={() => onAddTab(27, "Tabla Vac.")}>Tablas de vacaciones</li>
									)}
									{hasPermissionModule("BANK_HOLIDAYS", permissions, user) && (
										<li onClick={() => onAddTab(36, "Días festivos")}>Días festivos</li>
									)}
									{hasPermissionModule("INCIDENCE_PROCESS", permissions, user) && (
										<li onClick={() => onAddTab(34, "Proc. incidencias")}>Proceso de inicidencias</li>
									)}
								</ul>
							</div>
						)}

						{hasPermissionChunk("ADMIN", permissions, user) && (
							<div className="opciones my-1" style={{ marginTop: "0px", marginBottom: "0px", paddingTop: "0px", paddingBottom: "0px", zIndex: 500 }}>
								<div className="descripcion">
									<i class="bi bi-person-vcard-fill" style={{ fontSize: "1.7rem", width: "2rem" }}></i> <p className="mx-2">Admin.</p>
								</div>
								<Flecha className='arrow' />
								<ul className='sub-menu' style={theme === "light" ? { background: "#282454" } : { background: "#111111" }}>
									{hasPermissionModule("SERIE", permissions, user) && (
										<li onClick={() => onAddTab(31, "Series")}>Series</li>
									)}
									{hasPermissionModule("USER", permissions, user) && (
										<li onClick={() => onAddTab(28, "Usuarios")}>Usuarios </li>
									)}
									{hasPermissionModule("PERMISSION", permissions, user) && (
										<li onClick={() => onAddTab(58, "Permisos")}>Permisos de usuarios</li>
									)}
									{hasPermissionModule("WORK_GROUPS", permissions, user) && (
										<li onClick={() => onAddTab(40, "Grupos de trabajo")}>Grupos de trabajo</li>
									)}
								</ul>
							</div>
						)}

					</>
				) : (
					<>
						<div className="opciones">
							<div className="descripcion" onClick={() => onAddTab(29, "Entornos", { setTabs: setTabs })}>
								<Admin /> <p>Gestión de entornos</p>
							</div>
						</div>
						<div className="opciones">
							<div className="descripcion" onClick={() => onAddTab(32, "Super usuarios")}>
								<Admin /> <p>Super usuarios</p>
							</div>
						</div>
					</>
				)
			}

			{hasPermissionChunk("VISITS", permissions, user) && (
				<div className="opciones my-1" style={{ marginTop: "0px", marginBottom: "0px", paddingTop: "0px", paddingBottom: "0px", zIndex: 500 }}>
					<div className="descripcion">
						<i class="bi bi-geo-alt-fill" style={{ fontSize: "1.7rem", width: "2rem" }}></i> <p className="mx-2">Visitas</p>
					</div>
					<Flecha className='arrow' />
					<ul className='sub-menu' style={theme === "light" ? { background: "#282454" } : { background: "#111111" }}>
						{hasPermissionModule("BRANDS", permissions, user) && (
							<li onClick={() => onAddTab(62, "Marcas")}>Marcas</li>
						)}
						{hasPermissionModule("SUB_BRANDS", permissions, user) && (
							<li onClick={() => onAddTab(64, "Submarcas")}>Submarcas</li>
						)}
						{hasPermissionModule("COLORS", permissions, user) && (
							<li onClick={() => onAddTab(63, "Colores")}>Colores</li>
						)}
						{hasPermissionModule("DOCUMENT_TYPES", permissions, user) && (
							<li onClick={() => onAddTab(65, "Tipos de documento")}>Tipos de documento</li>
						)}
						{hasPermissionModule("VISIT_PROFILES", permissions, user) && (
							<li onClick={() => onAddTab(66, "Perfiles")}>Perfiles</li>
						)}
						{hasPermissionModule("VISIT_REQUESTS", permissions, user) && (
							<li onClick={() => onAddTab(67, "Solicitudes")}>Solicitudes</li>
						)}
						{hasPermissionModule("VISIT_REGISTER", permissions, user) && (
							<li onClick={() => onAddTab(68, "Registro de visitas")}>Registro de visitas</li>
						)}
						{hasPermissionModule("VISIT_ATTENDACE_REGISTER_REPORT", permissions, user) && (
							<li onClick={() => onAddTab(69, "Reporte de visitas")}>Reporte de visitas</li>
						)}
					</ul>
				</div>
			)}


		</div>
	)
}

export default SideBarView;