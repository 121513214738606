import React from "react";
import moment from "moment";
import { getAbbreviationIncidence, getColorIncidence } from "utils/constants";
import Filters from "components/crh/reports/attendanceReport/Filters";
import Modal from "helpers/Modal";
import NoImage from 'resources/images/no-avatar.png';

function getEmployeeName(employee) {

	const firstname = employee.firstname;
	const dadLastname = employee?.dad_lastname || "";
	const momLastname = employee?.mom_lastname || "";

	return `${firstname} ${dadLastname} ${momLastname}`;

}

function getBody(logs = [], startDate, endDate) {
	const rows = [];

	let actualDate = moment.utc(startDate);
	while (actualDate <= moment.utc(endDate)) {

		const log = logs.find((item) => item.date === actualDate.format("YYYY-MM-DD"));

		if (log) {
			console.log(log)
			const colorLetter = (log.color) ? getColorIncidence(log.color) : "#000000";
			rows.push(
				<td class="text-center" style={{ backgroundColor: log.color, color: colorLetter }}>
					{getAbbreviationIncidence(log.type)}
				</td>
			)
		} else {
			rows.push(<td></td>)
		}

		actualDate.add(1, 'days');
	}
	return rows;
}


function AttendanceReportView({
	getHeaders,
	permissionExcel,
	permissionPdf,
	permissionRead,
	user = {},
	onChangeSearch,
	handleKeyUp,
	setLoading,
	onDownloadDetailedXlsx,
	onDownloadXlsx,
	onDownloadCSV,
	reportRef,
	reports,
	filterModal,
	filters,
	setFilters,
	showFilterModal,
	hideFilterModal,
	searchFilters,
}) {

	return (
		<>

			<div className="content container-fluid p-5">
				<div className="page-header mb-3">
					<div className="row align-items-end">

						<div className="col-sm mb-2 mb-sm-0">
							<h1 className="page-header-title">Reporte de asistencia</h1>
						</div>

						<div className="col-sm-auto">
							{/* <ReactToPrint
								debug={true}
								content={() => reportRef.current}
								trigger={() => (
									<button className="btn bg-danger text-white mx-1">
										<i className="bi bi-file-earmark-pdf" style={{ marginRight: "7px" }}></i>
										Descargar PDF
									</button>
								)}
							/> */}
							{
								permissionExcel && (
									<>
										<button type="button" className="btn bg-success text-white mx-1" onClick={onDownloadDetailedXlsx}>
											<i className="bi bi-file-earmark-excel" style={{ marginRight: "7px" }}></i>
											Descargar Excel detallado
										</button>
										<button type="button" className="btn bg-success text-white mx-1" onClick={onDownloadXlsx}>
											<i className="bi bi-file-earmark-excel" style={{ marginRight: "7px" }}></i>
											Descargar Excel
										</button>
										<button type="button" className="btn bg-primary text-white mx-1" onClick={onDownloadCSV}>
											<i className="bi bi-filetype-csv" style={{ marginRight: "7px" }}></i>
											Descargar CSV
										</button>
									</>
								)
							}
							{
								permissionRead && (
									<button type="button" className="btn btn-primary" onClick={showFilterModal}>
										<i className="bi bi-plus-circle" style={{ marginRight: "7px" }}></i>
										Filtrar resultados
									</button>
								)
							}
						</div>
					</div>
				</div>

				<div class="card mb-3">
					<div class="card-header card-header-content-md-between">
						<div class="d-flex justify-content-between mb-2 mb-md-0">
							<div class="input-group input-group-merge input-group-flush" style={{ width: "50%" }}>
								<div class="input-group-prepend input-group-text" >
									<i class="bi-search"></i>
								</div>
								<input
									type="search"
									class="form-control"
									placeholder="Buscar empleado"
									onChange={onChangeSearch}
									onKeyUp={handleKeyUp}
								/>
							</div>
							<div class="d-flex justify-content-right mx-3">
								<span class="pt-2 mx-1">Total empleados: </span>
								<span class="pt-2 border-0">{reports.length}</span>
							</div>
						</div>
					</div>
				</div>

				<div ref={reportRef}>

					<div className="report-header">

						<div className="d-flex justify-content-center">
							<div className="logo mt-6">
								{
									user?.company?.logo?.url ? (
										<img crossorigin="anonymous" height="300" src={user.company.logo.url} alt="Logo empresa" style={{ borderRadius: "50%" }} />
									) : (
										<img src={NoImage} alt="" height="300" style={{ borderRadius: "50%" }} />
									)
								}
							</div>
						</div>

						<div className="text-center">
							<h1 className="mb-3">{user?.company?.name}</h1>
						</div>

						<div className="text-center">
							<h2 className="mb-3">Reporte de asistencia</h2>
						</div>

						<div className="text-center">
							<h4 className="">
								{
									!filters.start_date && !filters.end_date
										? "Todos los registros"
										: moment.utc(searchFilters.start_date).format("DD [de] MMMM [de] YYYY") + " - " + moment.utc(searchFilters.end_date).format("DD [de] MMMM [de] YYYY")
								}
							</h4>
						</div>

					</div>

					<div className="pagebreak"></div>

					<div class="row" style={{ marginTop: "30px" }}>
						<div className="col-md-12">

							<div class="table-responsive datatable-custom bg-white report-content" style={{ marginTop: "-12px" }}>
								<table class="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
									<thead style={{ position: "sticky", top: "-1px", zIndex: 1 }}>
										<tr role="row">
											<th>ID empleado</th>
											<th>Sucursal</th>
											<th>Departamento</th>
											<th>Puesto</th>
											<th>Nombre</th>
											{
												getHeaders(filters.start_date, filters.end_date).map((dateHeader) => {
													return <th class="text-center">
														{dateHeader.weekDay}<br />
														{dateHeader.monthDay}<br />
														{dateHeader.month}
													</th>
												})
											}
											<th>Horas trabajadas</th>
											<th>Retardos</th>
											<th>Tiempo extra</th>
										</tr>
									</thead>
									<tbody>
										{
											reports.map((report, index) => (
												<tr key={index} role="row">
													<td style={{ whiteSpace: "nowrap" }}>{report.employee.key}</td>
													<td style={{ whiteSpace: "nowrap" }}>{report.employee?.office?.description}</td>
													<td style={{ whiteSpace: "nowrap" }}>{report.employee?.department?.description}</td>
													<td style={{ whiteSpace: "nowrap" }}>{report.employee?.job?.description}</td>
													<td style={{ whiteSpace: "nowrap" }}>{getEmployeeName(report.employee)}</td>
													{getBody(report.logs, filters.start_date, filters.end_date)}
													<td style={{ whiteSpace: "nowrap" }}>{report.workedHours}</td>
													<td style={{ whiteSpace: "nowrap" }}>{report.amountRetardments} - {report.hoursRetardments}</td>
													<td style={{ whiteSpace: "nowrap" }}>{report.amountExtraTime} - {report.hoursExtraTime}</td>
												</tr>
											))
										}
									</tbody>
								</table>
							</div>

						</div>
					</div>
				</div>

			</div>

			<Modal
				title={"Filtros de búsqueda"}
				isVisible={filterModal}
				setIsVisible={hideFilterModal}
			>
				<Filters
					setLoading={setLoading}
					hideModal={hideFilterModal}
					filters={filters}
					setFilters={setFilters}
				/>
			</Modal>

		</>
	)

}

export default AttendanceReportView;