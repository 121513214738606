import React, { useState, useRef } from "react";
import { usePost } from "utils/api";
import { toast } from "react-toastify";
import View from "components/crh/schedules/Form.view";

const format = 'HH:mm';

function FormSave({ setIsVisible }) {

    const formikRef = useRef();
    const [hasBreak, setHasBreak] = useState(false);
    const [nightly, setNightly] = useState(false);
    const [totalHours, setTotalHours] = useState(false);
    const [nextDay, setNextDay] = useState(false);
    const [error, setError] = useState(null);

    const [hours, setHours] = useState({
        hours: null,
        entry_from: null,
        entry_start: null,
        entry_until: null,
        break_from: null,
        break_start: null,
        break_until: null,
        return_from: null,
        return_start: null,
        return_until: null,
        exit_from: null,
        exit_start: null,
        exit_until: null,
    })

    const [callSave, reqSave] = usePost("/schedules", {
        onCompleted: () => {
            if (!toast.isActive("schedule-created"))
                toast.success("Horario creado correctamente", { toastId: "schedule-created" });

            setIsVisible(false);
            setHours({
                hours: null,
                entry_from: null,
                entry_start: null,
                entry_until: null,
                break_from: null,
                break_start: null,
                break_until: null,
                return_from: null,
                return_start: null,
                return_until: null,
                exit_from: null,
                exit_start: null,
                exit_until: null,
            })
            formikRef.current.setValues({});
        },
        onError: (err) => {
            console.error(err)
            if (err.status === 400) {
                if (!toast.isActive("schedule-error-400")) {
                    toast.error("Campos obligatorios requeridos", { toastId: "schedule-error-400" });
                }
            } else if (err.status === 403) {
                if (!toast.isActive("toast-create-schedule-unauthorized")) {
                    toast.error("Error, no tienes los permisos para crear horarios", { toastId: "toast-create-schedule-unauthorized" })
                }
            } else if (err.status === 409) {
                if (!toast.isActive("schedule-error-409")) {
                    toast.error("Ya existe un horario con esa clave, por favor ingrese otra", { toastId: "schedule-error-409" });
                }
            } else {
                if (!toast.isActive("schedule-error-500")) {
                    toast.error("Error interno del servidor", { toastId: "schedule-error-500" });
                }
            }
        }
    })

    const onSubmit = (values) => {

        for (let key in values)
            if (values[key] === "")
                values[key] = null

        values.userId = sessionStorage.getItem("id");

        const { key, description } = values

        const {
            entry_from, entry_start, entry_until,
            exit_from, exit_start, exit_until,
        } = hours;

        if (!key || !description || !entry_from || !entry_start || !entry_until || !exit_from || !exit_start || !exit_until) {
            toast.error("Rellene los campos obligatorios");
            return;
        }

        values.has_break = hasBreak;
        values.is_nightly = nightly;
        values.total_hours = totalHours;
        values.next_day = nextDay;

        callSave({ ...values, ...hours });

    }

    const onCancel = (resetForm) => {
        setIsVisible(false);
        resetForm({});
    }

    const handleOnChange = (time, type) => {
        const newHours = { ...hours };
        if (time) {
            newHours[type] = time.format(format);
        } else {
            newHours[type] = null;
        }
        setHours(newHours);
    }

    return (
        <View
            hours={hours}
            onSubmit={onSubmit}
            onCancel={onCancel}
            error={error}
            setIsVisible={setIsVisible}
            setError={setError}
            formikRef={formikRef}
            hasBreak={hasBreak}
            nightly={nightly}
            totalHours={totalHours}
            nextDay={nextDay}
            setHasBreak={setHasBreak}
            setNightly={setNightly}
            setTotalHours={setTotalHours}
            setNextDay={setNextDay}
            handleOnChange={handleOnChange}
        />
    )
}

export default FormSave;