import React, { useRef, useState, useEffect } from "react"
import View from "components/geo/attendance/Form.view"
import { getUser } from 'api/users'
import { usePost } from 'utils/api'
import { uploadFile } from "utils/files"
import { toast } from "react-toastify"
import { useGeolocation } from "utils/geolocation"
import moment from "moment"

function GeoAttendance() {

  const id = sessionStorage.getItem("id")
  const advanceGeo = sessionStorage.getItem("advance_geo") === "true"
  const takeRegisterPhoto = sessionStorage.getItem("take_register_photo") === "true"
  const takeGeolocationPhoto = sessionStorage.getItem("take_geolocation_photo") === "true"
  const coords = useGeolocation()
  const [user, setUser] = useState({})
  const [attendancePhoto, setAttendancePhoto] = useState(null)
  const [registerPhoto, setRegisterPhoto] = useState(null)
  const [event, setEvent] = useState(null)
  const [showPhotoCapture, setShowPhotoCapture] = useState(false)

  const [callRegisterPhoto, reqRegisterPhoto] = usePost("/geolocation/register", {
    onCompleted: (data) => {

      if(!toast.isActive("register-success"))
        toast.success("Se ha realizado el registro correctamente", { toastId: "register-success" });

      sessionStorage.setItem("take_register_photo", "false")
      setRegisterPhoto(true)

    },
    onError: (error) => {

      if(error.status === 404) {
        if(!toast.isActive("register-error-404"))
          toast.error("No se logró identificar un rostro, inténtelo de nuevo", { toastId: "register-error-404" });
      } 
      else {
        if(!toast.isActive("attendance-error"))
          toast.error("No ha sido posible realizar el registro, inténtelo más tarde", { toastId: "attendance-error" });
      }

      setRegisterPhoto(false)

    }
  })

  const [callAttendance, reqAttendance] = usePost("/geolocation/attendance", {
    onCompleted: (data) => {
      
      if(!toast.isActive("attendance-success"))
        toast.success("Se ha realizado el marcaje correctamente", { toastId: "attendance-success" });

      setAttendancePhoto(null)

    },
    onError: (error) => {

      if(error.status === 404 || error.status === 400) {
        
        if(!toast.isActive("attendance-error-404"))
          toast.error("No se logró identificar el rostro del usuario, inténtelo de nuevo", { toastId: "attendance-error-404" });
        
        setAttendancePhoto(null)

      }
      else {
        if(!toast.isActive("attendance-error"))
          toast.error("No ha sido posible realizar el marcaje, inténtelo más tarde", { toastId: "attendance-error" });
      }
        
    }
  })

  const getDeviceType = () => {
    const ua = navigator.userAgent;
    if(/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) return "tablet";
    if(/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) return "mobile";
    return "desktop";
  };

  const handleTakePhoto = (eventType) => {

    const deviceType = getDeviceType();
    
    if(deviceType === "desktop") {
      setShowPhotoCapture(true);
      setEvent({
        type: eventType,
        photo: null
      })
    }
    else {

      const input = document.createElement("input")
      input.style.display = "none"
      input.type = "file"
      input.accept = "image/*"
      input.capture = "camera"

      document.body.appendChild(input)

      input.addEventListener("change", (event) => {
        if(event.target.files.length > 0) {
          setEvent({
            type: eventType,
            photo: event.target.files[0]
          })
        }
      })

      input.click()

    }

  }

  const handleRegisterPhoto = async (photo) => {

    const response = await uploadFile(photo);

    if(response.ok && response.body.id) {
      const data = { photo_id: response.body.id }
      callRegisterPhoto(data);
    }
    else if(!toast.isActive("photo-error-400")) {
      toast.error("No ha sido posible subir la foto", { toastId: "photo-error-400" });
      return;
    }

  }

  const handleSubmit = async () => {

    if(takeGeolocationPhoto && !attendancePhoto) {
      
      if(!toast.isActive("photo-error-400"))
        toast.error("Es necesario que tomes una foto", { toastId: "photo-error-400" });

      return;

    }

    if(takeRegisterPhoto && !registerPhoto) {

      if(!toast.isActive("register-error-400"))
        toast.error("Es necesario que registres una foto", { toastId: "register-error-400" });

      return;

    }

    if(!(coords?.latitude) || !(coords?.longitude)) {
      
      if(!toast.isActive("coords-error-400"))
        toast.error("No ha sido posible obtener tu ubicación", { toastId: "coords-error-400" });

      return;

    }

    const data = {
      lat: coords?.latitude,
      lng: coords?.longitude,
      date: moment().format("YYYY-MM-DD"),
      time: moment().format("HH:mm:ss"),
    }

    if(attendancePhoto) {

      const response = await uploadFile(attendancePhoto);

      if(response.ok && response.body.id) {
        data.photo_id = response.body.id;
      }
      else if(!toast.isActive("photo-error-400")) {
        toast.error("No ha sido posible subir la foto", { toastId: "photo-error-400" });
        return;
      }

    }

    callAttendance(data);

  }

  useEffect(() => {
		getUser(id).then((response) => {
			setUser(response.data)
		})
	}, [id])

  return (
    <View
      showPhotoCapture={showPhotoCapture}
      user={user}
      coords={coords}
      takeGeolocationPhoto={takeGeolocationPhoto}
      takeRegisterPhoto={takeRegisterPhoto}
      attendancePhoto={attendancePhoto}
      event={event}
      setEvent={setEvent}
      setAttendancePhoto={setAttendancePhoto}
      registerPhoto={registerPhoto}
      setShowPhotoCapture={setShowPhotoCapture}
      handleRegisterPhoto={handleRegisterPhoto}
      handleTakePhoto={handleTakePhoto}
      handleSubmit={handleSubmit}
    />
  )

}

export default GeoAttendance