import React, { useState, useEffect, useRef } from "react";
import { usePut, useGetCall } from "utils/api";
import { getBankHoliday } from "api/bankHolidays";
import { getPayrolls } from "api/payrolls";
import { toast } from "react-toastify";
import { showResponseMessage } from "utils/message";
import View from "components/crh/bankHolidays/Form.view";

function FormSet({ id, setIsVisible, refresh }) {

    const formikRef = useRef();
    const [error, setError] = useState(null);
    const [reset, setReset] = useState(() => { });
    const [bankHoliday, setBankHoliday] = useState({});
    const [payrolls, setPayrolls] = useState([]);
    const [refreshValue, setRefreshValue] = useState(null);

    if (refresh !== refreshValue) setRefreshValue(refresh);

    const [callPayrolls, reqPayrolls] = useGetCall('/payrolls', {
        onCompleted: (response) => {
            setPayrolls(response.data);
        }, onError: (error) => {
            showResponseMessage(error.status, "payrolls", "nóminas", "read")
        }
    });

    const [callBankHoliday, reqBankHoliday] = useGetCall(`/bank_holidays/${id}`, {
        onCompleted: (response) => {
            if (formikRef.current) {
                formikRef.current.setValues(response.data);
            }
            setBankHoliday(response.data);
        }, onError: (error) => {
            showResponseMessage(error.status, "bank_holidays", "días festivos", "read")
        }
    });

    const [callUpdate, reqUpdate] = usePut("/bank_holidays", {
        onCompleted: () => {

            if (!toast.isActive("bank-holiday-updated"))
                toast.success("Día festivo actualizado", { toastId: "bank-holiday-updated" });

            setIsVisible(false);
            reset();

        },
        onError: (err) => {
            console.error(err)
            showResponseMessage(err.status, "bank_holidays", "dias festivos", "edit")
        }
    })

    useEffect(() => {

        callPayrolls();
        callBankHoliday();
        formikRef.current.setValues({});

    }, [id, refreshValue])

    const onSubmit = (values, { resetForm }) => {

        for (let key in values)
            if (values[key] === "")
                values[key] = null

        const { key, description, date, apply_date } = values;
        if (!key || !description || !date || !apply_date || !bankHoliday) {
            toast.error("Todos los campos son obligatorios");
            return;
        }

        values.id = id;
        setReset(() => () => resetForm({}));
        callUpdate(values);

    }

    const onCancel = (resetForm) => {
        setIsVisible(false);
        resetForm({});
    }

    return (
        <View
            payrolls={payrolls}
            bankHoliday={bankHoliday}
            formikRef={formikRef}
            error={error}
            edit={true}
            onSubmit={onSubmit}
            onCancel={onCancel}
        />
    )

}

export default FormSet;
