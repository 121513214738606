import React, { useState, useEffect, useRef } from "react";
import { usePut } from "utils/api";
import { getPayrollPeriod } from "api/payrollPeriods";
import { getPayrolls } from "api/payrolls";
import { toast } from "react-toastify";
import { formatISODate } from "utils/date";
import View from "components/crh/payrollPeriods/Form.view";
import moment from "moment";
import "moment/locale/es-mx";
moment.locale('es');

function FormSet({ id, setIsVisible, refresh }) {

    const formikRef = useRef();
    const [error, setError] = useState(null);
    const [payrollPeriod, setPayrollPeriod] = useState({});
    const [payrolls, setPayrolls] = useState([]);
    const [reset, setReset] = useState(() => { });
    const [refreshValue, setRefreshValue] = useState(null);

    if (refresh !== refreshValue) {
        setRefreshValue(refresh);
    }

    const [callUpdate, reqUpdate] = usePut("/payroll_periods", {
        onCompleted: () => {
            if (!toast.isActive("payrollPeriod-updated"))
                toast.success("Periodo actualizado", { toastId: "payrollPeriod-updated" });

            setIsVisible(false);
            reset();
        },
        onError: (err) => {
            console.error(err)
            if (err.status === 400) {
                if (!toast.isActive("payroll-period-error-400")) {
                    toast.error("Campos obligatorios requeridos", { toastId: "payroll-period-error-400" });
                }
            } else if (err.status === 403) {
                if (!toast.isActive("toast-edit-payroll_period-unauthorized")) {
                    toast.error("Error, no tienes los permisos para editar periodos de nómina", { toastId: "toast-edit-payroll_period-unauthorized" })
                }
            } else if (err.status === 409) {
                if (!toast.isActive("payroll-period-error-409")) {
                    toast.error("Ya existe un periodo de nómina con esa clave, por favor ingrese otra", { toastId: "payroll-period-error-409" });
                }
            } else {
                if (!toast.isActive("payroll-period-error-500")) {
                    toast.error("Error interno del servidor", { toastId: "payroll-period-error-500" });
                }
            }
        }
    })

    useEffect(() => {
        getPayrolls().then(response => {
            if (response.data)
                setPayrolls(response.data);
        });
        getPayrollPeriod(id).then((response) => {
            if (response.data) {

                response.data.start = formatISODate(response.data.start, false, true);
                response.data.end = formatISODate(response.data.end, false, true);
                response.data.payment = formatISODate(response.data.payment, false, true);

                if (formikRef.current)
                    formikRef.current.setValues(response.data);

                setPayrollPeriod(response.data);
            }
        });
        formikRef.current.setValues({});
    }, [id, refreshValue])


    const onSubmit = (values, { resetForm }) => {

        for (let key in values)
            if (values[key] === "")
                values[key] = null

        const { key, description, start, end, payment } = values
        if (!key || !description || !values.payroll || values.payroll.id == "" || !start || !end || !payment) {
            toast.error("Todos los campos son obligatorios");
            return;
        }

        values.id = id;
        setReset(() => () => resetForm({}));
        callUpdate(values);

    }

    const onCancel = (resetForm) => {
        setIsVisible(false);
        resetForm({});
    }

    return (
        <View
            payrolls={payrolls}
            formikRef={formikRef}
            error={error}
            edit={true}
            payrollPeriod={payrollPeriod}
            onSubmit={onSubmit}
            onCancel={onCancel}
        />
    )


}

export default FormSet;
