import React, { useState, useRef } from "react";
import { usePost } from "utils/api";
import { toast } from "react-toastify";
import View from "components/crh/holidayLogs/LoadData.view";
import { downloadFile } from "utils/files";

function LoadData({ isVisible, setIsVisible, theme }) {

    const [csvData, setCsvData] = useState([]);
    const inputFileRef = useRef(null);

    const [callSave, reqSave] = usePost("/holiday_logs", {
        onCompleted: () => {

            if(!toast.isActive("toast-upload"));
                toast.success("Saldos cargados correctamente", { toastId: "toast-upload" });
            
            setIsVisible(false);
            inputFileRef.current.value = "";
            setCsvData([]);

        },
        onError: (err) => {
            console.error(err)
            if(!toast.isActive("toast-upload-error"));
                toast.error("Error interno del servidor", { toastId: "toast-upload-error" });
        }
    });

    const onCancel = () => {
        inputFileRef.current.value = "";
        setCsvData([]);
        setIsVisible(false);
    }

    const onDownloadExampleFile = () => {
        const exampleFile = `ID de empleado, Saldo de vacaciones, Fecha de saldo (DD/MM/YYYY)`;
        downloadFile({ data: exampleFile, filename: "template.csv", type: ".csv"});
    }

    const onLoadFile = (data) => {
        if(data.length === 1) setCsvData(data[0]);
    }

    const onSubmit = () => {
        callSave({ data: csvData });
    }

	return (
		<View
            theme={theme}
            inputFileRef={inputFileRef}
            isVisible={isVisible}
            data={csvData}
            onCancel={onCancel}
            onLoadFile={onLoadFile}
            onDownloadExampleFile={onDownloadExampleFile}
            onSubmit={onSubmit}
		/>
	);

}

export default LoadData;