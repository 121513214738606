import React from "react";
import { Formik, Form, Field } from 'formik';
import { getNameIncidence } from "utils/constants";

function FiltersView({
	offices,
	jobs,
	departments,
	payrolls,
	payrollPeriods,
	incidences,
	filters,
	setFilters,
	onSubmit,
	formikRef,
	onChangePayroll,
	onChangePayrollPeriod
}) {
	return (
		<div className='container mt-3'>
			<Formik
				innerRef={formikRef}
				initialValues={filters}
				onSubmit={onSubmit}>
				{({ values, setFieldValue, resetForm }) =>
					<Form>
						{/* Payrolls type */}
						<div className="row my-2">
							<div className="col-md-4">
								<h4>Tipo de nómina</h4>
							</div>
							<div className="col-md-8">
								<Field as="select" name="payroll_id" className="form-control" value={values.payroll_id || ''} onChange={onChangePayroll}>
									<option value="">Seleccione una opción</option>
									{
										payrolls.map(payroll =>
											<option key={payroll.id} value={payroll.id}>{payroll.description}</option>
										)
									}
								</Field>
							</div>
						</div>

						{/* Payroll periods */}
						<div className="row my-2">
							<div className="col-md-4">
								<h4>Periodo de nómina</h4>
							</div>
							<div className="col-md-8">
								<Field as="select" name="payroll_period_id" className="form-control" value={values.payroll_period_id || ''} onChange={onChangePayrollPeriod}>
									<option value="">Seleccione una opción</option>
									{
										payrollPeriods.map(payrollPeriod =>
											<option key={payrollPeriod.id} value={payrollPeriod.id}>{payrollPeriod.description}</option>
										)
									}
								</Field>
							</div>
						</div>

						{/* Date range of payroll period */}
						<div className="row my-2">
							<div className="col-md-4">
								<h4>Rango de fechas <span className='text-danger fw-bold'>*</span></h4>
							</div>
							<div className="col-md-4">
								<Field type="date" name="start_date" className="form-control" value={values.start_date || ''} required/>
							</div>
							<div className="col-md-4">
								<Field type="date" name="end_date" className="form-control" value={values.end_date || ''} required/>
							</div>
						</div>

						{/* Offices */}
						<div className="row my-2">
							<div className="col-md-4">
								<h4>Sucursal</h4>
							</div>
							<div className="col-md-8">
								<Field as="select" name="office_id" className="form-control" value={values.office_id || ''}>
									<option value="">Seleccione una opción</option>
									{
										offices.map(office =>
											<option key={office.id} value={office.id}>{office.description}</option>
										)
									}
								</Field>
							</div>
						</div>

						{/* Jobs */}
						<div className="row my-2">
							<div className="col-md-4">
								<h4>Puesto</h4>
							</div>
							<div className="col-md-8">
								<Field as="select" name="job_id" className="form-control" value={values.job_id || ''}>
									<option value="">Seleccione una opción</option>
									{
										jobs.map(job =>
											<option key={job.id} value={job.id}>{job.description}</option>
										)
									}
								</Field>
							</div>
						</div>

						{/* Departments */}
						<div className="row my-2">
							<div className="col-md-4">
								<h4>Departamento</h4>
							</div>
							<div className="col-md-8">
								<Field as="select" name="department_id" className="form-control" value={values.department_id || ''}>
									<option value="">Seleccione una opción</option>
									{
										departments.map(department =>
											<option key={department.id} value={department.id}>{department.description}</option>
										)
									}
								</Field>
							</div>
						</div>

						{/* Incidence */}
						<div className="row my-2">
							<div className="col-md-4">
								<h4>Incidencia</h4>
							</div>
							<div className="col-md-8">
								<Field as="select" name="incidence" className="form-control" value={values.incidence || ''}>
									<option value="">Seleccione una opción</option>
									{
										incidences.map((incidence, index) =>
											<option key={index} value={incidence}>{getNameIncidence(incidence)}</option>
										)
									}
								</Field>
							</div>
						</div>

						<div class="pt-3">
							<button type="submit" class="btn btn-primary">
								<i class="bi bi-plus" style={{ marginRight: "10px" }}></i>
								Aceptar
							</button>
						</div>

					</Form>
				}</Formik>

		</div >
	)
}

export default FiltersView;