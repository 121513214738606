import React, { useState, useEffect } from "react"
import View from "components/crh/reports/attendance/Filters.view"
import { toast } from "react-toastify"
import { getJobs } from "api/jobs"
import { getDepartments } from "api/departments"
import { getPayrolls } from "api/payrolls"
import { getGroups } from "api/groups"
import { getDevices } from "api/devices"
import { getOffices } from "api/offices";

function Filters({ filters, setFilters, hideModal, setLoading }) {

    const [jobs, setJobs] = useState([])
    const [departments, setDepartments] = useState([])
    const [devices, setDevices] = useState([])
    const [groups, setGroups] = useState([])
    const [payrolls, setPayrolls] = useState([])
    const [offices, setOffices] = useState([]);

    const onSubmit = (values) => {
        setFilters(values)
        setLoading(true)
        hideModal()
    }

    useEffect(() => {

        getOffices().then((response) => {
			if (response.errors.length > 0) {
				if (!toast.isActive("toast-offices-eror"))
					toast.error("Error al obtener las sucursales", { toastId: "toast-jobs-eror" })
			} else {
				setOffices(response.data)
			}
		})

        getJobs().then((data) => {
            if(data.errors.length > 0) {
                if(!toast.isActive("toast-jobs-eror"))
                    toast.error("Error al obtener los puestos", { toastId: "toast-jobs-eror" })
            }
            else {
                setJobs(data.data)
            }
        })

        getDepartments().then((data) => {
            if(data.errors.length > 0) {
                if(!toast.isActive("toast-departments-eror"))
                    toast.error("Error al obtener los departamentos", { toastId: "toast-departments-eror" })
            }
            else {
                setDepartments(data.data)
            }
        })

        getPayrolls().then((data) => {
            if(data.errors.length > 0) {
                if(!toast.isActive("toast-payrolls-eror"))
                    toast.error("Error al obtener los tipos de nómina", { toastId: "toast-payrolls-eror" })
            }
            else {
                setPayrolls(data.data)
            }
        })

        getGroups().then((data) => {
            if(data.errors.length > 0) {
                if(!toast.isActive("toast-areas-eror"))
                    toast.error("Error al obtener las áreas", { toastId: "toast-areas-eror" })
            }
            else {
                setGroups(data.data)
            }
        })

        getDevices().then((data) => {
            if(data.errors.length > 0) {
                if(!toast.isActive("toast-devices-eror"))
                    toast.error("Error al obtener los dispositivos", { toastId: "toast-devices-eror" })
            }
            else {
                setDevices(data.data)
            }
        })

    }, [])
        
    return (
        <View 
            offices={offices}
            jobs={jobs}
            departments={departments}
            devices={devices}
            groups={groups}
            payrolls={payrolls}
            filters={filters}
            setFilters={setFilters}
            onSubmit={onSubmit}
        />
    );

}

export default Filters;