import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDelete } from "utils/api";
import { getAdminUsers } from "api/users";
import View from "components/admin/users/List.view";

function List({
    search,
    refresh,
    addEditModal
}) {

    const [refreshValue, setRefreshValue] = useState(null);
    const [refreshDelete, setRefreshDelete] = useState(null);
    const [users, setUsers] = useState([]);

    if (refresh !== refreshValue) {
        setRefreshValue(refresh);
    }

    const [callDelete, reqDelete] = useDelete("/users/admin", {
        onCompleted: () => {
            if (!toast.isActive("user-deleted"))
                toast.success("Usuario eliminado", { toastId: "user-deleted" });

            setRefreshDelete(Math.random())
        },
        onError: (error) => {
            console.error(error)
            if (!toast.isActive("user-error"))
                toast.error("Error al eliminar el usuario", { toastId: "user-error" });
        }
    });


    useEffect(() => {
        getAdminUsers({ search }).then((response) => {
            if (response.data) {
                setUsers(response.data);
            } else {
                toast.error("Error al obtener los usuarios de entorno")
            }
        })
    }, [refreshDelete, refreshValue, search]);


    const onDelete = (id) => {
        if (window.confirm("¿Estás seguro de eliminar el usuario?"))
            callDelete({ id: id });
    }

    const onEdit = (id) => {
        addEditModal(id);
    }


    return (
        <View 
            users={users} 
            onDelete={onDelete} 
            onEdit={onEdit} 
        />
    );

}

export default List;
