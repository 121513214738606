
const getTotalTime = (records, attribute) => {

	let totalHours = 0;
	let totalMinutes = 0;

	for (let record of records) {
		const time = record[attribute];
		if (time) {
			const hours = parseInt(time.substring(0, 2));
			const minutes = parseInt(time.substring(3, 5));

			totalHours += hours;
			totalMinutes += minutes;
		}

	}

	totalHours += Math.floor(totalMinutes / 60);
	totalMinutes = totalMinutes % 60;

	return `${totalHours}h ${totalMinutes}m`;

}

const formatTime = (time) => {
	const hours = parseInt(time.substring(0, 2));
	const minutes = parseInt(time.substring(3, 5));
	return `${hours}h ${minutes}m`;
}

export {
    getTotalTime,
	formatTime
}