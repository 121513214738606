import React, { useState, useEffect } from "react";
import { usePost, useGetCall } from "utils/api";
import { toast } from "react-toastify";
import { showResponseMessage } from "utils/message";
import View from "components/crh/bankHolidays/Form.view";

function FormSave({ setIsVisible, refresh }) {

    const [payrolls, setPayrolls] = useState([]);
    const [error, setError] = useState(null);
    const [reset, setReset] = useState(() => { });
    const [refreshValue, setRefreshValue] = useState(null);

    if (refresh !== refreshValue) setRefreshValue(refresh);

    const [callPayrolls, reqPayrolls] = useGetCall('/payrolls', {
        onCompleted: (response) => {
            setPayrolls(response.data);
        }, onError: (error) => {
            showResponseMessage(error.status, "payrolls", "nóminas", "read")
        }
    });

    const [callSave, reqSave] = usePost("/bank_holidays", {
        onCompleted: () => {
            if (!toast.isActive("bank-holiday-created"))
                toast.success("Día festivo creado correctamente", { toastId: "bank-holiday-created" });

            setIsVisible(false);
            reset();
        },
        onError: (err) => {
            console.error(err)
            showResponseMessage(err.status, "bank_holidays", "dias festivos", "create")
        }
    });

    useEffect(() => {
        callPayrolls();
    }, [refreshValue])

    const onSubmit = (values, { resetForm }) => {

        for (let key in values)
            if (values[key] === "")
                values[key] = null

        const { key, description, date, apply_date } = values
        if (!key || !description || !date || !apply_date) {
            toast.error("Todos los campos son obligatorios");
            return;
        }

        setReset(() => () => resetForm({}));
        callSave(values);

    }

    const onCancel = (resetForm) => {
        setIsVisible(false);
        resetForm({});
    }

    return (
        <View
            payrolls={payrolls}
            onSubmit={onSubmit}
            onCancel={onCancel}
            error={error}
            setIsVisible={setIsVisible}
            setError={setError}
        />
    )
}

export default FormSave;