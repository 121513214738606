import React, { useState } from "react";
import View from "components/crh/visits/requests/Requests.view";

function Requests() {

  const [search, setSearch] = useState("");

  const onChangeSearch = (event) => {
    if (event.target.value === '') {
      setSearch(event.target.value);
    }
  }

  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      setSearch(event.target.value);
    }
  }

  return (
    <View
      search={search}
      onChangeSearch={onChangeSearch}
      handleKeyUp={handleKeyUp}
    />
  );
}

export default Requests;