import React, { useState, useEffect } from "react"
import { useGetCall } from "utils/api"
import { showResponseMessage } from "utils/message";
import View from "components/crh/groups/Detail.view"

function DetailGroup({ id, setIsVisible }) {

    const [group, setGroup] = useState({})
    const [groupEmployees, setGroupEmployees] = useState([])
    const [groupGroups, setGroupGroups] = useState([])

    const [callGroup, reqGroup] = useGetCall(`/groups/${id}`, {
        onCompleted: (data) => {
            let group = data.data.group
            let groupEmployees = data.data.groupEmployees
            let groupGroups = data.data.groupGroups

            if (groupEmployees) setGroupEmployees(groupEmployees)
            if (groupGroups) setGroupGroups(groupGroups)
            setGroup(group)
        }, onError: (error) => {
            showResponseMessage(error.status, "groups", "grupos de trabajo", "read")
        }
    })

    useEffect(() => {
        callGroup();
    }, [id])

    return (
        <View
            group={group}
            groupEmployees={groupEmployees}
            groupGroups={groupGroups}
            setIsVisible={setIsVisible}
        />
    )

}

export default DetailGroup;