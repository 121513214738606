import React, { useEffect, useState, useRef } from "react";
import { SERVER_URL } from '../../../settings';

const PhotoTaked = ({ detailsData, setIsDetailsVisible }) => {
	return (
		<div className="d-flex justify-content-center align-items-center w-100 h-100" style={{ width: "100%", position: "absolute", zIndex: 9999, top: "0", left: "0", backgroundColor: "#fff", overflowY: "hidden" }}>
			<button type="button" className="rounded-circle btn btn-danger" style={{ position: "absolute", top: "10px", right: "10px", zIndex: 9999 }} onClick={() => setIsDetailsVisible(false)}>
                <i className="bi bi-x text-white"></i>
            </button>
			<img className="" src={detailsData.photo.path ? (SERVER_URL + "/" + detailsData.photo.path) : detailsData.photo.url} alt="Foto" style={{ maxHeight: "100%", maxWidth: "100%"}}/>
		</div>
	);
}

function GeoAttendanceLogs({
    attendanceLogs = [],
    isDetailsVisible, 
    setIsDetailsVisible,
    detailsData = {},
    setDetailsData
}) {
    return (
        isDetailsVisible 
            ?   <PhotoTaked detailsData={detailsData} setIsDetailsVisible={setIsDetailsVisible} />
            :   <div className="content container-fluid py-4">
                    <div className="page-header mt-5 m-0 p-0 w-100">
                        <div className="pt-3">
                            <table class="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
                                <thead style={{ position: "sticky", top: "-3px", zIndex: 1 }}>
                                    <tr role="row">
                                        <th>Fecha</th>
                                        <th>Hora</th>
                                        <th>Ubicación</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                        attendanceLogs.map((attendanceLog, index) =>
                                            <tr>
                                                {console.log(attendanceLog)}
                                                <td> {attendanceLog.date} </td>
                                                <td> {attendanceLog.time} </td>
                                                <td> {attendanceLog.location??(parseFloat(attendanceLog.lat).toFixed(3) + ", " + parseFloat(attendanceLog.lng).toFixed(3))} </td>
                                                <td> 
                                                    {
                                                        attendanceLog.photo && (attendanceLog.photo.url || attendanceLog.photo.path) &&
                                                        <button 
                                                            type="button" 
                                                            class="btn btn-sm btn-primary text-center" 
                                                            style={{ marginLeft: "20px" }} 
                                                            onClick={() => {
                                                                setIsDetailsVisible(true)
                                                                setDetailsData(attendanceLog)
                                                            }}
                                                        >
                                                            <i class="bi bi-eye"></i>
                                                        </button> 
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
    )
}

export default GeoAttendanceLogs;