import React, { useState, useEffect } from "react";
import { validateAuth } from "api/auth";
import { IS_TEST } from "settings";
import View from "components/layout/Layout.view";

function Layout() {

	// let savedLayout = JSON.parse(sessionStorage.getItem("layout"));
	const [theme, setTheme] = useState("light");
	const [button, setButton] = useState(false);
	const [btnTab, setBtnTab] = useState(true);

	const [activeTabId, setActiveTabId] = useState(1);

	const onAddTab = (id, name, props = {}) => {

		const result = tabs.findIndex(tab => tab.content === name && tab.componentId === id);
		const newTabs = [...tabs];

		if(result !== -1) return setActiveTabId(newTabs[result].id);

		const tabId = new Date().getTime().toString().substring(7) + Math.random().toString().substring(2, 5);

		newTabs.push({
			id: tabId,
			componentId: id,
			props: props,
			content: name
		});

		setTabs(newTabs);
		setActiveTabId(tabId);

	}

	const [tabs, setTabs] = useState([
		{
			id: 1,
			componentId: 60,
			props: {
				onAddTab
			},
			content: "Home"
		}
	]);

	// savedLayout = {tabs, theme, button, btnTab}
	// sessionStorage.setItem("layout", JSON.stringify(savedLayout));


	useEffect(() => {
		validateAuth({
			id: sessionStorage.getItem("id"),
			type: sessionStorage.getItem("type"),
		}, "SUPERADMIN").then(response => {
			if (!response)
				window.location.href = (IS_TEST ? "/test" : "") + "/login";
		})

	})

	return (
		<View
			activeTabId={activeTabId}
			setActiveTabId={setActiveTabId}
			button={button}
			setButton={setButton}
			onAddTab={onAddTab}
			tabs={tabs.map(tab => ({
				...tab,
				props: {
					...tab.props,
					onAddTab
				}
			}))}
			setTabs={setTabs}
			theme={theme}
			setTheme={setTheme}

		/>
	);
}

export default Layout;