import React, { useState, useEffect, useRef } from "react"
import { usePost } from "utils/api"
import { toast } from "react-toastify"
import { getOffices } from "api/offices"
import { testConnection } from "api/devices"
import View from "components/crh/devices/Form.view"
import { useMap, useMapEvent } from "react-leaflet"

function FormSave({ setIsVisible, refresh, company }) {

    const formikRef = useRef()
    const [error, setError] = useState(null)
    const [offices, setOffices] = useState([])
    const [reset, setReset] = useState(() => { })
    const [connected, setConnected] = useState(null)
    const [refreshValue, setRefreshValue] = useState(null)
    const [coordinates, setCoordinates] = useState({})
    const [locations, setLocations] = useState([])

    const [callDecode, reqDecode] = usePost("/geolocation/decode", {
        onCompleted: (data) => {
            setLocations(data.data)
            if(data.data.length === 1) {
                formikRef.current.setFieldValue("location", data.data[0].formatted_address || "")
            }
        },
        onError: (err) => {}
    })

    const [callEncode, reqEncode] = usePost("/geolocation/encode", {
        onCompleted: (data) => {
            setLocations(data.data)
            if(data.data.length === 1) {
                setCoordinates(data.data[0].geometry.location)
            }
        },
        onError: (err) => {}
    })

    if(refresh !== refreshValue) setRefreshValue(refresh)

    const callGetCoordinates = async (address) => callEncode({ address })

    const callTestConnection = async (serial_number) => {
        const response = await testConnection(serial_number)
        if(response != null && response.errors.length === 0 && response.data.connected) {
            formikRef.current.setFieldValue("model", response.data.model || "")
            toast.success("Conexión exitosa", { toastId: "toast-test-connection" })
            setConnected(true)
        }
        else {
            toast.error("Error al conectar", { toastId: "toast-test-connection" })
            setConnected(false)
        }
    }

    const [callSave, reqSave] = usePost("/devices", {
        onCompleted: () => {
            if(!toast.isActive("toast-save-device"))
                toast.success("Dispositivo creado correctamente", { toastId: "toast-save-device" })
            formikRef.current.resetForm()
            setIsVisible(false)
        },
        onError: (err) => {
            if(!toast.isActive("toast-save-device-error") && err.status === 500)
                toast.error("Error interno del servidor", { toastId: "toast-save-device-error" })
            else if(!toast.isActive("toast-validation-error") && err.status === 400)
                toast.error("Llene todos los campos necesarios", { toastId: "toast-validation-error" })
            else if(!toast.isActive("toast-save-device-error") && err.status === 409)
                toast.error("Ya existe un dispositivo con ese número de serie", { toastId: "toast-save-device-error" })
            else if(!toast.isActive("toast-create-device-unauthorized") && err.status === 403)
                toast.error("Error, no tienes los permisos para crear dispositivos", { toastId: "toast-create-device-unauthorized" })
        }
    })

    const onSubmit = (values, {resetForm}) => {

        for(let key in values)
            if(values[key] === "")
                values[key] = null
    
        const { serial_number, time_zone } = values

        if(!serial_number || !time_zone) {
            toast.error("Todos los campos son obligatorios")
            return
        }

        if(!coordinates.lat || !coordinates.lng) {
            toast.error("Revise la dirección ingresada aparezca en el mapa")
            return
        }

        setReset(() => () => resetForm)

        values.user_id = sessionStorage.getItem("id")
        callSave({ ...values, company, coordinates })

    }

    const onCancel = (resetForm) => {
        setIsVisible(false)
        resetForm({})
    }

    const RecenterAutomatically = ({ lat, lng }) => {

        const map = useMap();
        const events = useMapEvent({
            moveend: () => {},
            click: (e) => {
                setCoordinates(e.latlng)
                callDecode({ lat: e.latlng.lat, lng: e.latlng.lng })
            },
        });

        useEffect(() => { map.setView([lat, lng]); }, [lat, lng]);

        return null;
    }

    useEffect(() => {
        getOffices({ company }).then((data) => {
            if(data.errors.length > 0) {
                if(!toast.isActive("toast-offices-eror"))
                    toast.error("Error al obtener las sucursales", { toastId: "toast-offices-eror" })
            }
            else {
                setOffices(data.data)
            }
        })
    }, [refreshValue])

    return (
        <View 
            formikRef={formikRef}
            onSubmit={onSubmit}
            onCancel={onCancel}
            error={error}
            offices={offices}
            setIsVisible={setIsVisible} 
            setError={setError}
            callTestConnection={callTestConnection}
            callGetCoordinates={callGetCoordinates}
            setCoordinates={setCoordinates}
            coordinates={coordinates}
            locations={locations}
            connected={connected}
            RecenterAutomatically={RecenterAutomatically}
        />
    )

}

export default FormSave