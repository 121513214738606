import React, { useState, useEffect } from "react";
import { useGetCall } from "utils/api";
import { getUsers } from "api/users";
import { toast } from "react-toastify";
import { showResponseMessage } from "utils/message";
import View from "components/crh/reports/incidenceRequest/Filters.view";

function FormSave({ hideModal, filters, setFilters, refresh }) {

    const [users, setUsers] = useState([]);
    const [refreshValue, setRefreshValue] = useState(null);
    const [incidences, setIncidences] = useState([]);

    if (refreshValue !== refresh) {
        setRefreshValue(refresh)
    }

    const [callIncidences, reqIncidences] = useGetCall(`/incidences`, {
        onCompleted: (response) => {
            setIncidences(response.data);
        }, onError: (error) => {
            showResponseMessage(error.status, "incidences", "incidencias", "read");
        }
    })

    useEffect(() => {
        getUsers({ me: true }).then(response => {
            if (response.data) {
                setUsers(response.data)
            } else {
                toast.error("Error al obtener los usuarios")
            }
        })
        callIncidences({request_rh: true})
    }, [refreshValue]);

    const onSubmit = (values) => {
        for (let key in values)
            if (values[key] === "")
                values[key] = null

        setFilters(values)
        hideModal();
    }

    return (
        <View
            filters={filters}
            incidences={incidences}
            users={users}
            onSubmit={onSubmit}
        />
    )
}

export default FormSave;