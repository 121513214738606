import React, { useState, useEffect, useRef } from "react";
import { getIncidences } from "api/incidences";
import { getDevices } from "api/devices";
import { toast } from "react-toastify";
import moment from "moment";
import View from "components/crh/reports/incidences/Request.view";

function Request({ hideModal, onSubmitRequest, selectedDate }) {

	const incidencesWithHour = [
		"PERMISSION_RELEASE_EARLY",
		"PERMISSION_ARRIVE_LATE",
		"PERMISSION_LEAVE_HOURS",
		"HOURLY_VACATIONS"
	]

	const formikRef = useRef();
	const [incidences, setIncidences] = useState([]);
	const [startHour, setStartHour] = useState(null);
	const [endHour, setEndHour] = useState(null);
	const [devices, setDevices] = useState([]);

	useEffect(() => {
		getIncidences({ request_rh: true }).then(response => {
			if (response.data) {
				setIncidences(response.data);
			} else {
				toast.error("Error al cargar las incidencias")
			}
		})
		getDevices().then(response => {
			if (response.data) {
				setDevices(response.data)
			} else {
				toast.error("Error al cargar los lectores")
			}
		})
		if (formikRef.current) {
			formikRef.current.setFieldValue("start_date", selectedDate);
			formikRef.current.setFieldValue("end_date", selectedDate);
		}
	}, [selectedDate])

	const onSubmit = (values) => {

		const { incidence } = values;

		if (!incidence || incidence.id === '') {
			toast.error("Debe llenar todos los campos obligatorios");
			return;
		}

		const incidenceType = incidences.find((item) => item.id === parseInt(incidence.id)).type;

		if (incidenceType === "INSERT_MARKINGS") {
			const { start_date, device } = values;
			if (!start_date || !startHour || !device || device.id === '') {
				toast.error("Rellene los campos obligatorios")
				return;
			}

		} else if (incidencesWithHour.find(item => item === incidenceType)) {
			const { start_date, end_date } = values;
			if (!endHour || !startHour || !start_date || !end_date) {
				toast.error("Los campos de fecha son obligatorios para esta incidencia")
				return;
			}
		} else {
			const { start_date, end_date } = values;
			if (!start_date || !end_date) {
				toast.error("Los campos de fecha son obligatorios para esta incidencia")
				return;
			}
		}

		values.start_hour = startHour;
		values.end_hour = endHour;

		onSubmitRequest(values);
		formikRef.current.setValues({});
		hideModal();

	}

	const onCancel = () => {
		hideModal();
		formikRef.current.setValues({});
	}

	return (
		<View
			startHour={startHour}
			endHour={endHour}
			devices={devices}
			setStartHour={setStartHour}
			setEndHour={setEndHour}
			incidencesWithHour={incidencesWithHour}
			formikRef={formikRef}
			incidences={incidences}
			onCancel={onCancel}
			onSubmit={onSubmit}
		/>
	);

}

export default Request;