import React, { useState, useEffect } from "react";
import Config from "components/crh/documentEmployees/Config";
import View from "components/crh/documentEmployees/DocumentEmployees.view";
import { toast } from "react-toastify";
import { showResponseMessage } from "utils/message";
import { downloadDocumentsEmployee } from "api/documentEmployees";
import { usePost, useGetCall, fetchPost } from "utils/api";
import { downloadFile } from "utils/files";

function DocumentEmployees() {

  const [refresh, setRefresh] = useState(0);

  const [isVisible, setIsVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [search, setSearch] = useState("");

  const [jobs, setJobs] = useState([]);
  const [departments, setDepartments] = useState([]);

  const [job, setJob] = useState(null);
  const [department, setDepartment] = useState(null);
  const [documentsEmployees, setDocumentsEmployees] = useState([]);
  const [documentEmployee, setDocumentEmployee] = useState(null);
  const [uploadedDocuments, setUploadedDocuments] = useState(null);

  const [status, setStatus] = useState("ACTIVE");

  const [callUpdate, reqUpdate] = usePost("/document_employees", {
    onCompleted: () => {
      if (!toast.isActive("toast-update-documents"))
        toast.success("Expediente del empleado actualizado", { toastId: "toast-update-documents" })
      setRefresh(Math.random());
    },
    onError: (error) => {
      console.error(error);
      showResponseMessage(error.status, "document_employees", "documetos", "create")
    }
  })

  const [callDocuments, reqDocuments] = useGetCall('/document_employees', {
    onCompleted: (response) => {
      setDocumentsEmployees(response.data);
    },
    onError: (error) => {
      console.error(error)
      showResponseMessage(error.status, "document_employee", "expedientes de empleados", "read");
    }
  });

  const [callJobs, reqJobs] = useGetCall('/jobs', {
    onCompleted: (response) => {
      setJobs(response.data);
    }, onError: (error) => {
      console.error(error)
      showResponseMessage(error.status, "job", "puestos", "read");
    }
  });

  const [callDepartments, reqDepartments] = useGetCall('/departments', {
    onCompleted: (response) => {
      setDepartments(response.data);
    }, onError: (error) => {
      console.error(error)
      showResponseMessage(error.status, "department", "departamentos", "read");
    }
  });

  useEffect(() => {
    
    callJobs();
    callDepartments();

  }, []);

  useEffect(() => {

    callDocuments({
      job_id: job,
      department_id: department,
      search: search,
      status: status
    });

  }, [refresh, job, department, search, status]);

  const addModal = () => {
    fetchPost("/permissions/check", { table: "RECORD", permission: "create" }).then((response) => {
      if (response.data) {
        const data = response.data;
        if (data.isAllowed === true) {
          setIsVisible(true);
          setModalTitle("Configurar expediente");
          setModalContent(
            <Config
              setIsVisible={setIsVisible}
              refresh={Math.random()}
            />
          );
        } else {
          if (!toast.isActive("toast-create-document_employee-unauthorized")) {
            toast.error("Error, no tienes los permisos para crear expedientes de empleados", { toastId: "toast-create-document_employee-unauthorized" })
          }
        }
      }
    })
  }
  const onChangeSearch = (event) => {
    if (event.target.value === '') {
      setSearch(event.target.value);
    }
  }

  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      setSearch(event.target.value);
    }
  }

  const onSelectDocumentEmployee = (documentEmployee) => {

    fetchPost("/permissions/check", { table: "RECORD", permission: "edit" }).then((response) => {
      if (response.data) {
        const data = response.data;
        if (data.isAllowed === true) {
          setDocumentEmployee(documentEmployee);
          setUploadedDocuments(documentEmployee.documents);
        } else {
          if (!toast.isActive("toast-edit-document_employee-unauthorized")) {
            toast.error("Error, no tienes los permisos para editar expedientes de empleados", { toastId: "toast-edit-document_employee-unauthorized" })
          }
        }
      }
    })

  }

  const onUploadFile = (idx, files) => {
    if (files.length === 1) {
      let uploadedDocumentsCopy = [...uploadedDocuments];
      uploadedDocumentsCopy[idx] = {
        document: uploadedDocumentsCopy[idx].document,
        document_employee: {
          id: -1,
          file: files[0],
        }
      };
      setUploadedDocuments(uploadedDocumentsCopy);
    }
  }

  const onSubmit = () => {
    callUpdate({
      employee: documentEmployee.employee,
      docs: uploadedDocuments
    })
  }

  const onDownload = async () => {

    const res = await downloadDocumentsEmployee(documentEmployee.employee.id);
    const blob = await res.blob();

    downloadFile({
      blob: blob,
      filename:
        documentEmployee.employee.key + "_" +
        documentEmployee.employee.firstname + "_" +
        documentEmployee.employee.dad_lastname + "_" +
        documentEmployee.employee.mom_lastname + ".zip",
      type: "application/zip"
    })

  }

  return (
    <View
      search={search}
      onChangeSearch={onChangeSearch}
      handleKeyUp={handleKeyUp}
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      modalTitle={modalTitle}
      modalContent={modalContent}
      addModal={addModal}
      jobs={jobs}
      departments={departments}
      job={job}
      setJob={setJob}
      department={department}
      setDepartment={setDepartment}
      documentsEmployees={documentsEmployees}
      documentEmployee={documentEmployee}
      onSelectDocumentEmployee={onSelectDocumentEmployee}
      onUploadFile={onUploadFile}
      uploadedDocuments={uploadedDocuments}
      onSubmit={onSubmit}
      onDownload={onDownload}
      setStatus={setStatus}
    />
  );

}

export default DocumentEmployees;