import React from 'react';
import { Formik, Form, Field } from 'formik';
import { TimePicker } from 'antd';
import moment from "moment";
import dayjs from 'dayjs';
import Modal from "helpers/Modal";
import { QRCodeCanvas } from 'qrcode.react';
import { APP_URL } from 'settings';

function FormView({ 
    token,
    parameters, 
    onUpdate, 
    onGenerateToken,
    hideModal 
}) {
    return (
        <>
            <Formik
                initialValues={parameters}
                onSubmit={() => {}}>
                {({ values, setFieldValue, resetForm }) =>
                    <Form>

                        <div className='table table-bordered'>
                            <div className='d-flex fw-bold justify-content-center w-100 py-2' style={{backgroundColor: "#04B5FC"}}>
                                <span className='text-white text-center'>Vacaciones</span>
                            </div>
                            <div className='d-flex py-2 flex-wrap'>
                                <div className='d-flex'>
                                    <span className='ms-1'><label for="status-switch">Reiniciar vacaciones:</label></span>
                                    <div className="form-check form-switch ms-5" id="status">
                                        <Field
                                            class="form-check-input" 
                                            type="checkbox" 
                                            id="status-switch"
                                            onChange={(e) => {
                                                setFieldValue("accumulate_holidays", !e.target.checked);
                                                onUpdate({ ...parameters, restart_holidays: e.target.checked, accumulate_holidays: !e.target.checked })
                                            }}
                                            checked={parameters["restart_holidays"]} 
                                        />
                                    </div>
                                </div>
                                <div className='d-flex'>
                                    <span><label for="status-switch">Acumular vacaciones:</label></span>
                                    <div className="form-check form-switch ms-5" id="status">
                                        <Field
                                            class="form-check-input" 
                                            type="checkbox" 
                                            id="status-switch"
                                            onChange={(e) => {
                                                setFieldValue("restart_holidays", !e.target.checked);
                                                onUpdate({ ...parameters, accumulate_holidays: e.target.checked, restart_holidays: !e.target.checked })
                                            } }
                                            checked={parameters["accumulate_holidays"]} 
                                        />
                                    </div>
                                </div>
                                <div className='d-flex'>
                                    <span><label for="status-switch">Vacaciones por hora:</label></span>
                                    <div className="form-check form-switch ms-5" id="status">
                                        <Field
                                            class="form-check-input" 
                                            type="checkbox" 
                                            id="status-switch"
                                            onChange={(e) => {
                                                setFieldValue("hour_holidays", !e.target.checked);
                                                onUpdate({ ...parameters, hour_holidays: e.target.checked })
                                            } }
                                            checked={parameters["hour_holidays"]} 
                                        />
                                    </div>
                                </div>
                                <div className='d-flex'>
                                    <span><label for="status-switch">Vacaciones negativas:</label></span>
                                    <div className="form-check form-switch ms-5" id="status">
                                        <Field
                                            class="form-check-input" 
                                            type="checkbox" 
                                            id="status-switch"
                                            onChange={(e) => {
                                                setFieldValue("negative_holidays", !e.target.checked);
                                                onUpdate({ ...parameters, negative_holidays: e.target.checked })
                                            } }
                                            checked={parameters["negative_holidays"]} 
                                        />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                </div>
                            </div>
                        </div>

                        <div className='table table-bordered'>
                            <div className='d-flex fw-bold justify-content-center w-100 py-2' style={{backgroundColor: "#04B5FC"}}>
                                <span className='text-white text-center'>Reporde de asistencia de empleados registrados</span>
                            </div>
                            <div className='d-flex py-2 flex-wrap'>
                                <div className='d-flex'>
                                    <span className='ms-1'><label for="status-switch">Mostrar temperatura:</label></span>
                                    <div className="form-check form-switch ms-5" id="status">
                                        <Field
                                            class="form-check-input" 
                                            type="checkbox" 
                                            id="status-switch"
                                            onChange={(e) => onUpdate({ ...parameters, show_temperature: e.target.checked }) }
                                            checked={parameters["show_temperature"]} 
                                        />
                                    </div>
                                </div>
                                <div className='d-flex'>
                                    <span><label for="status-switch">Mostrar ubicación:</label></span>
                                    <div className="form-check form-switch ms-5" id="status">
                                        <Field
                                            class="form-check-input" 
                                            type="checkbox" 
                                            id="status-switch"
                                            onChange={(e) => onUpdate({ ...parameters, show_location: e.target.checked }) }
                                            checked={parameters["show_location"]} 
                                        />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                </div>
                            </div>
                        </div>

                        <div className='table table-bordered'>
                            <div className='d-flex fw-bold justify-content-center w-100 py-2' style={{backgroundColor: "#04B5FC"}}>
                                <span className='text-white text-center'>Procesos automáticos</span>
                            </div>
                            <div className='d-flex py-2 flex-wrap'>
                                <div className='d-flex'>
                                    <span className='ms-1'><label for="status-switch">Cálculo de vacaciones: </label></span>
                                    <div className="form-check form-switch ms-5" id="status">
                                        <Field
                                            class="form-check-input" 
                                            type="checkbox" 
                                            id="status-switch"
                                            onChange={(e) => onUpdate({ ...parameters, calculate_holidays: e.target.checked })}
                                            checked={parameters["calculate_holidays"]} 
                                        />
                                    </div>
                                </div>
                                <div className='d-flex'>
                                    <span><label for="status-switch">Proceso de incidencias: </label></span>
                                    <div className="form-check form-switch ms-5" id="status">
                                        <Field
                                            class="form-check-input" 
                                            type="checkbox" 
                                            id="status-switch"
                                            onChange={(e) => onUpdate({ ...parameters, auto_incidences: e.target.checked }) } 
                                            checked={parameters["auto_incidences"]} 
                                        />
                                    </div>
                                </div>
                                <div className='d-flex'>
                                    <span>Hora de ejecución: </span>
                                    <TimePicker 
                                        className="ms-1"
                                        placeholder="Seleccione"
                                        format="HH:mm"
                                        style={{ height: "40px", width: "80px" }}
                                        onChange={(date) => onUpdate({ ...parameters, time_incidences: moment(date.toISOString()).local().format("HH:mm:ss") })}
                                        value={parameters.time_incidences ? dayjs(parameters.time_incidences, 'HH:mm') : ''}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='table table-bordered'>
                            <div className='d-flex fw-bold justify-content-center w-100 py-2' style={{backgroundColor: "#04B5FC"}}>
                                <span className='text-white text-center'>Tiempo extra</span>
                            </div>
                            <div className='d-flex py-2 flex-wrap'>
                                <div className='d-flex'>
                                    <span className='ms-1'><label for="status-switch">Descanso trabajado = T.E.:</label></span>
                                    <div className="form-check form-switch ms-5" id="status">
                                        <Field
                                            class="form-check-input" 
                                            type="checkbox" 
                                            id="status-switch"
                                            onChange={(e) => onUpdate({ ...parameters, work_in_rest: e.target.checked }) }
                                            checked={parameters["work_in_rest"]} 
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='table table-bordered'>
                            <div className='d-flex fw-bold justify-content-center w-100 py-2' style={{backgroundColor: "#04B5FC"}}>
                                <span className='text-white text-center'>Geolocalización</span>
                            </div>
                            <div className='d-flex py-2 flex-wrap'>
                                <div className='d-flex'>
                                    <span className='ms-1'><label for="status-switch">Geolocalización Avanzada:</label></span>
                                    <div className="form-check form-switch ms-5" id="status">
                                        <Field
                                            class="form-check-input" 
                                            type="checkbox" 
                                            id="status-switch"
                                            onChange={(e) => onUpdate({ ...parameters, advance_geo: e.target.checked }) }
                                            checked={parameters["advance_geo"]} 
                                        />
                                    </div>
                                </div>
                                <div className='col-md-8'>
                                </div>
                            </div>
                        </div>

                        <div className='table table-bordered'>
                            <div className='d-flex fw-bold justify-content-center w-100 py-2' style={{backgroundColor: "#04B5FC"}}>
                                <span className='text-white text-center'>Sin acceso</span>
                            </div>
                            <div className='d-flex py-2 flex-wrap'>
                                <div className='d-flex'>
                                    <span className='ms-1'><label for="status-switch">Activar:</label></span>
                                    <div className="form-check form-switch ms-5" id="status">
                                        <Field
                                            class="form-check-input" 
                                            type="checkbox" 
                                            id="status-switch"
                                            onChange={(e) => onUpdate({ ...parameters, no_access: e.target.checked }) }
                                            checked={parameters["no_access"]} 
                                        />
                                    </div>
                                </div>
                                <div className='col-md-8'>
                                </div>
                            </div>
                        </div>

                        <div className='table table-bordered'>
                            <div className='d-flex fw-bold justify-content-center w-100 py-2' style={{backgroundColor: "#04B5FC"}}>
                                <span className='text-white text-center'>Reporte de incidencias (WhatsApp)</span>
                            </div>
                            <div className='d-flex align-items-start pt-2 flex-wrap'>
                                <div className='d-flex align-items-center mt-2'>
                                    <span className='ms-2'><label for="status-switch">Enviar:</label></span>
                                    <div className="form-check form-switch ms-5" id="status">
                                        <Field
                                            class="form-check-input" 
                                            type="checkbox" 
                                            id="status-switch"
                                            onChange={(e) => onUpdate({ ...parameters, wa_report: e.target.checked }) }
                                            checked={parameters["wa_report"]} 
                                        />
                                    </div>
                                </div>
                                <div className='d-flex flex-column align-items-start' style={{ width: "18rem" }}>
                                    <div className='d-flex align-items-center'>
                                        <span className='ms-1 me-3'>Número (s):</span>
                                        <div className='' style={{ width: "60%" }}>
                                            <input 
                                                type="email" 
                                                name="number" 
                                                class="input__field form-control"
                                                placeholder=" "
                                                onKeyDown={(e) => {
                                                    if(e.key === 'Enter' || e.key === ' ') {
                                                        e.preventDefault()
                                                        onUpdate({ ...parameters, wa_report_numbers: parameters.wa_report_numbers ? parameters.wa_report_numbers + ';' + e.target.value : e.target.value })
                                                        setFieldValue('wa_report_numbers', parameters.wa_report_numbers ? parameters.wa_report_numbers + ';' + e.target.value : e.target.value)
                                                        e.target.value = ''
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className='d-flex' style={{ width: "18rem" }}>
                                        <div className='d-flex' style={{ width: "38%" }}> &nbsp; </div>
                                        <div style={{ width: "60%" }}>
                                            {
                                                parameters.wa_report_numbers 
                                                    ?   parameters.wa_report_numbers.split(';').map((email, index) => 
                                                            <span className='badge bg-primary me-1 mt-1'>
                                                            {email}
                                                            <i 
                                                                style={{ cursor: 'pointer' }}
                                                                class="bi bi-x" 
                                                                onClick={() => { 
                                                                    onUpdate({ ...parameters, wa_report_numbers: parameters.wa_report_numbers.split(';').filter((_, i) => i !== index).join(';') })
                                                                    setFieldValue('wa_report_numbers', parameters.wa_report_numbers.split(';').filter((_, i) => i !== index).join(';')) 
                                                                }}
                                                            />
                                                            </span>
                                                        )
                                                    :   null
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex align-items-start'>
                                    <div className='d-flex align-items-center'>
                                        <span className='me-2'>Hora de envío:</span>
                                        <TimePicker 
                                            className="ms-1 me-3"
                                            placeholder="Seleccione"
                                            format="HH:mm"
                                            style={{ width: "80px", height: "40px" }}
                                            onChange={(date) => onUpdate({ ...parameters, wa_report_hour: moment(date.toISOString()).local().format("HH:mm:ss") })}
                                            value={parameters.wa_report_hour ? dayjs(parameters.wa_report_hour, 'HH:mm') : ''}
                                        />
                                    </div>
                                </div>
                                <div className='d-flex align-items-start'>
                                    <div className='d-flex align-items-center'>
                                        <span className='me-2'>Periodicidad:</span>
                                        <Field 
                                            required 
                                            as="select" 
                                            name="wa_report_period" 
                                            class="input__field form-control" 
                                            value={parameters.wa_report_period || ''}
                                            onChange={(e) => {
                                                onUpdate({ ...parameters, wa_report_period: e.target.value })
                                                setFieldValue('wa_report_period', e.target.value)
                                            }}
                                        >
                                            <option value="DAILY">Diario</option>
                                            <option value="MONDAY">Lunes</option>
                                            <option value="TUESDAY">Martes</option>
                                            <option value="WEDNESDAY">Miércoles</option>
                                            <option value="THURSDAY">Jueves</option>
                                            <option value="FRIDAY">Viernes</option>
                                            <option value="SATURDAY">Sábado</option>
                                            <option value="SUNDAY">Domingo</option>
                                        </Field>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center mt-2'>
                                    <span className='ms-2'><label for="status-switch">Fin de mes:</label></span>
                                    <div className="form-check form-switch ms-5" id="status">
                                        <Field
                                            class="form-check-input" 
                                            type="checkbox" 
                                            id="status-switch"
                                            onChange={(e) => onUpdate({ ...parameters, wa_report_monthly: e.target.checked }) }
                                            checked={parameters["wa_report_monthly"]} 
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='table table-bordered'>
                            <div className='d-flex fw-bold justify-content-center w-100 py-2' style={{backgroundColor: "#04B5FC"}}>
                                <span className='text-white text-center'>Visitas</span>
                            </div>
                            <div className='d-flex py-2 flex-wrap'>
                                <div className='d-flex'>
                                    <span className='ms-1'><label for="status-switch">Habilitar pre-registro:</label></span>
                                    <div className="form-check form-switch ms-5" id="status">
                                        <Field
                                            class="form-check-input" 
                                            type="checkbox" 
                                            id="status-switch"
                                            onChange={(e) => onUpdate({ ...parameters, vi_enable_preregister: e.target.checked }) }
                                            checked={parameters["vi_enable_preregister"]} 
                                        />
                                        {
                                            parameters["vi_enable_preregister"] 
                                                ?   <div className='d-flex'>
                                                        <div className="form-check form-switch" id="status">
                                                            <button className='btn btn-primary' onClick={onGenerateToken} type='button'>
                                                                Generar QR
                                                            </button>
                                                            <Modal
                                                                title={"Token de acceso"}
                                                                isVisible={token != null}
                                                                setIsVisible={hideModal}
                                                            >
                                                                <div className='d-flex justify-content-center'>
                                                                    <QRCodeCanvas 
                                                                        value={APP_URL + "/preregister?token=" + token}
                                                                        size={500} 
                                                                        style={{ marginLeft: "20px" }}
                                                                        onClick={() => {
                                                                            const canvas = document.querySelector('canvas');
                                                                            const dataURL = canvas.toDataURL('image/png');
                                                                            const newTab = window.open('about:blank', 'image from canvas');
                                                                            newTab.document.write("<img src='" + dataURL + "' alt='from canvas'/>");
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className='d-flex justify-content-end'>
                                                                    <button className='btn btn-danger' onClick={hideModal}>
                                                                        Cerrar
                                                                    </button>
                                                                    <button className='btn btn-primary ms-2' onClick={() => {    
                                                                        const canvas = document.querySelector('canvas');
                                                                        const dataURL = canvas.toDataURL('image/png');
                                                                        const a = document.createElement('a');
                                                                        a.href = dataURL;
                                                                        a.download = 'QR.png';
                                                                        a.click();
                                                                    }
                                                                    }>
                                                                        Descargar
                                                                    </button>
                                                                </div>
                                                            </Modal>
                                                        </div>
                                                    </div>
                                                :   null
                                        }
                                    </div>
                                </div>
                                <div className='d-flex'>
                                    <span className='ms-1'><label for="status-switch">Enviar alertas:</label></span>
                                    <div className="form-check form-switch ms-5" id="status">
                                        <Field
                                            class="form-check-input" 
                                            type="checkbox" 
                                            id="status-switch"
                                            onChange={(e) => onUpdate({ ...parameters, vi_send_alerts: e.target.checked }) }
                                            checked={parameters["vi_send_alerts"]} 
                                        />
                                    </div>
                                </div>
                                <div className='d-flex'>
                                    <span className='ms-1'><label for="status-switch">Autorizar por whatsapp:</label></span>
                                    <div className="form-check form-switch ms-5" id="status">
                                        <Field
                                            class="form-check-input" 
                                            type="checkbox" 
                                            id="status-switch"
                                            onChange={(e) => onUpdate({ ...parameters, vi_whatsapp_auth: e.target.checked }) }
                                            checked={parameters["vi_whatsapp_auth"]} 
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Form>
                }</Formik>
        </>
    )
}

export default FormView;