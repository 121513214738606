import io from "socket.io-client";
import { SERVER_URL } from "settings";
import { useEffect, useState } from "react";

export const useSocketIO = ({ query }) => {

    const [socket, setSocket] = useState(null);
    const [connected, setConnected] = useState(false);
    const [data, setData] = useState({});
    const [_, setId] = useState(0);

    useEffect(() => {
        
        const socket = io(
            SERVER_URL, 
            { 
                query,
            }
        );

        setSocket(socket);

        socket.on("error", (err) => {
            console.error(err);
        });

        socket.on("connect", () => {
            setConnected(true);
        });

        socket.on("disconnect", () => {
            setConnected(false);
        });

        socket.on("data", (data) => {
            setId(prev => {
                setData({ ...data, id: prev + 1 });
                return prev + 1;
            });
        });

        socket.connect();

        return () => {
            socket.disconnect();
        };
    
    }, []);

    return { socket, connected, data };

}
