import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { useDelete, useGetCall, fetchPost } from "utils/api";
import Loading from "helpers/Loading";
import { LoadingContext } from "helpers/LoadingContext";
import View from "components/crh/payrolls/List.view";

function List({
	addEditModal,
	refresh,
	search
}) {

	const { loading, setLoading } = useContext(LoadingContext);

	const [payrolls, setPayrolls] = useState([]);
	const [refreshValue, setRefreshValue] = useState(null);
	const [refreshDelete, setRefreshDelete] = useState(null);

	if (refresh !== refreshValue) {
		setRefreshValue(refresh);
	}

	const [callPayrolls, reqPayrolls] = useGetCall("/payrolls", {
		onCompleted: (response) => {
			setLoading(false);
			setPayrolls(response.data)
		},
		onError: (error) => {
			setLoading(false);
			if (error.status === 403) {
				if (!toast.isActive("toast-read-payroll-unauthorized")) {
					toast.error("Error, no tienes los permisos para consultar nóminas", { toastId: "toast-read-payroll-unauthorized" })
				}
			} else {
				if (!toast.isActive("toast-payroll-error")) {
					toast.error("Error al obtener las nóminas", { toastId: "toast-payroll-error" })
				}
			}
		}
	})

	const [callDelete, reqDelete] = useDelete("/payrolls", {
		onCompleted: () => {
			if (!toast.isActive("payroll-deleted"))
				toast.success("Nómina eliminada", { toastId: "payroll-deleted" });

			setRefreshDelete(Math.random());
		},
		onError: (error) => {
			console.error(error)
			if (error.status === 403) {
				if (!toast.isActive("toast-delete-payroll-unauthorized")) {
					toast.error("Error, no tienes los permisos para eliminar nóminas", { toastId: "toast-delete-payroll-unauthorized" })
				}
			} else {
				if (!toast.isActive("toast-payrolls-error")) {
					toast.error("Error al eliminar la nómina", { toastId: "toast-payrolls-error" })
				}
			}
		}
	});

	useEffect(() => {
		setLoading(true);
		callPayrolls({ search });
	}, [refreshDelete, refreshValue, search]);

	const onDelete = (id) => {
		fetchPost("/payrolls/check_permission", { type: "delete" }).then((response) => {
			if (response.data) {
				const data = response.data;
				if (data.isAllowed === true) {
					if (window.confirm("¿Estás seguro de eliminar la nómina?")) {
						callDelete({ id: id });
					}
				} else {
					if (!toast.isActive("toast-delete-payroll-unauthorized")) {
						toast.error("Error, no tienes los permisos para eliminar nóminas", { toastId: "toast-delete-payroll-unauthorized" })
					}
				}
			}
		})
	}

	const onEdit = (id) => {
		addEditModal(id);
	}

	if (loading) return <Loading />

	return (
		<View payrolls={payrolls} onDelete={onDelete} onEdit={onEdit} />
	);
}

export default List;