import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { useDelete, useGetCall, fetchPost } from "utils/api";
import Loading from "helpers/Loading";
import { showResponseMessage } from "utils/message";
import { LoadingContext } from "helpers/LoadingContext";
import View from "components/crh/bosses/List.view";

function List({
	addEditModal,
	refresh,
	search
}) {

	const { loading, setLoading } = useContext(LoadingContext);

	const [bosses, setBosses] = useState([]);
	const [refreshValue, setRefreshValue] = useState(null);
	const [refreshDelete, setRefreshDelete] = useState(null);

	if (refresh !== refreshValue) {
		setRefreshValue(refresh);
	}

	const [callBosses, reqBosses] = useGetCall("/bosses", {
		onCompleted: (response) => {
			setLoading(false);
			setBosses(response.data)
		},
		onError: (error) => {
			setLoading(false);
			showResponseMessage(error.status, "bosses", "jefes directos", "read")
		}
	})

	const [callDelete, reqDelete] = useDelete("/bosses", {
		onCompleted: () => {
			if (!toast.isActive("boss-deleted"))
				toast.success("Jefe directo eliminado", { toastId: "boss-deleted" });

			setRefreshDelete(Math.random());
		},
		onError: (error) => {
			console.error(error)
			showResponseMessage(error.status, "bosses", "jefes directos", "delete")
		}
	});

	useEffect(() => {
		setLoading(true);
		callBosses({ search });
	}, [refreshValue, refreshDelete, search]);

	const onDelete = (id) => {
		fetchPost("/bosses/check_permission", { type: "delete" }).then((response) => {
			if (response.data) {
				const data = response.data;
				if (data.isAllowed === true) {
					if (window.confirm("¿Estás seguro de eliminar el jefe directo?")) {
						callDelete({ id: id });
					}
				} else {
					if (!toast.isActive("toast-delete-boss-unauthorized")) {
						toast.error("Error, no tienes los permisos para eliminar jefes directos", { toastId: "toast-delete-boss-unauthorized" })
					}
				}
			}
		})
	}

	const onEdit = (id) => {
		addEditModal(id);
	}

	if (loading) return <Loading />

	return (
		<View bosses={bosses} onDelete={onDelete} onEdit={onEdit} />
	);
}

export default List;