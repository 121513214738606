import React from "react";

function ListView({ schedules, onDelete, onEdit }) {

	return (
		<div class="table-responsive datatable-custom position-relative" >
			<div class="dataTables_wrapper no-footer pt-2 pl-3 pr-3" style={{ maxHeight: "70vh" }}>
				<table class="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
					<thead>
						<tr role="row">
							<th>Clave</th>
							<th>Descripción</th>
							<th>Horas</th>
							<th>Descanso</th>
							<th>Turno nocturno</th>
							<th>Cálculo total horas</th>
							<th>Horario de entrada</th>
							<th>Salida a comer</th>
							<th>Regreso de comer</th>
							<th>Horario de salida</th>
							<th>Calcular siguiente día</th>
							<th>Acciones</th>
						</tr>
					</thead>
					<tbody>
						{
							schedules.map((schedule) => (
								<tr key={schedule.id} role="row">
									<td>{schedule.key}</td>
									<td>{schedule.description}</td>
									<td>{schedule.hours || "NA"}</td>
									<td>{schedule.has_break ? "Si" : "No"}</td>
									<td>{schedule.is_nightly ? "Si" : "No"}</td>
									<td>{schedule.total_hours ? "Si" : "No"}</td>
									<td>
										<ul>
											<li style={{ whiteSpace: "nowrap" }}>Desde: {schedule.entry_from}</li>
											<li style={{ whiteSpace: "nowrap" }}>Entrada: {schedule.entry_start}</li>
											<li style={{ whiteSpace: "nowrap" }}>Hasta: {schedule.entry_until}</li>
										</ul>
									</td>
									<td>
										<ul>
											<li style={{ whiteSpace: "nowrap" }}>Desde: {schedule?.break_from ? schedule.break_from : "N/A"}</li>
											<li style={{ whiteSpace: "nowrap" }}>Entrada: {schedule?.break_start ? schedule.break_start : "N/A"}</li>
											<li style={{ whiteSpace: "nowrap" }}>Hasta: {schedule?.break_until ? schedule.break_until : "N/A"}</li>
										</ul>
									</td>
									<td>
										<ul>
											<li style={{ whiteSpace: "nowrap" }}>Desde: {schedule?.return_from ? schedule.return_from : "N/A"}</li>
											<li style={{ whiteSpace: "nowrap" }}>Entrada: {schedule?.return_start ? schedule.return_start : "N/A"}</li>
											<li style={{ whiteSpace: "nowrap" }}>Hasta: {schedule?.return_until ? schedule.return_until : "N/A"}</li>
										</ul>
									</td>
									<td>
										<ul>
											<li style={{ whiteSpace: "nowrap" }}>Desde: {schedule.exit_from}</li>
											<li style={{ whiteSpace: "nowrap" }}>Entrada: {schedule.exit_start}</li>
											<li style={{ whiteSpace: "nowrap" }}>Hasta: {schedule.exit_until}</li>
										</ul>
									</td>
									<td>{schedule.next_day ? "Si" : "No"}</td>
									<td>
										<div class="d-flex">
											<button className="btn btn-warning" style={{ marginRight: "10px" }} onClick={() => onEdit(schedule.id)}>
												<i class="bi bi-pencil"></i>
											</button>
											<button className="btn btn-danger" onClick={() => onDelete(schedule.id)}>
												<i class="bi bi-trash"></i>
											</button>
										</div>
									</td>
								</tr>
							))
						}
					</tbody>
				</table>
			</div>
		</div>

	);
}

export default ListView;