import React from 'react';
import { Formik, Form, Field } from 'formik';


function FormView({
    formikRef = () => { },
    edit = false,
    shift = {},
    onSubmit,
    error,
    setError,
    onCancel,
    extra,
    round,
    automatic,
    rotating,
    setExtra,
    setRound,
    setAutomatic,
    setRotating
}) {

    return (
        <div className='container mt-3'>
            <Formik
                innerRef={formikRef}
                initialValues={shift}
                onSubmit={onSubmit}>
                {({ values, setFieldValue, resetForm }) =>
                    <Form>
                        {/* Key */}
                        <div class="mb-3">
                            <div class="form-group">
                                <label class="input">
                                    <Field type="text" name="key" class="form-control input__field" placeholder=" " required value={values.key || ''} />
                                    <span class="input__label">
                                        Clave <span className='text-danger fw-bold'>*</span>
                                    </span>
                                </label>
                            </div>
                        </div>

                        {/* Description */}
                        <div class="mb-3">
                            <div class="form-group">
                                <label class="input">
                                    <Field type="text" name="description" class="form-control input__field" placeholder=" " required value={values.description || ''} />
                                    <span class="input__label">
                                        Descripción <span className='text-danger fw-bold'>*</span>
                                    </span>
                                </label>
                            </div>
                        </div>

                        {/* Abbreviation */}
                        <div class="mb-3">
                            <div class="form-group">
                                <label class="input">
                                    <Field type="text" name="abbreviation" class="form-control input__field" placeholder=" " required value={values.abbreviation || ''} />
                                    <span class="input__label">
                                        Abreviación <span className='text-danger fw-bold'>*</span>
                                    </span>
                                </label>
                            </div>
                        </div>

                        {/* Tolerance */}
                        <div class="mb-3">
                            <div class="row form-group">
                                <div class="col-md-6">
                                    <h5><label for="tolerance">
                                        Tolerancia <span className='text-danger fw-bold'>*</span>
                                    </label></h5>
                                </div>
                                <div class="col-md-6">
                                    <Field as="select" name="tolerance" class="form-control input__field" required value={values.tolerance || ''}>
                                        <option value="">Selecciona una tolerancia</option>
                                        {
                                            Array.from(Array(101), (_, index) => index).map((minute) =>
                                                <option key={minute} value={minute}>{minute} minutos</option>
                                            )
                                        }
                                    </Field>
                                </div>
                            </div>
                        </div>

                        {/* Extra time */}
                        <div class="mb-3">
                            <div class="form-group d-flex bg-crh">
                                <div class="pt-1" style={{ width: "90%" }}>
                                    <h5 class="text-center"><label for="switchExtraTime">Tiempo extra</label></h5>
                                </div>
                                <div class="text-center" style={{ width: "10%" }}>
                                    <div className="form-check form-switch pt-1" id="extra-time-switch">
                                        <input class="form-check-input" type="checkbox" id="switchExtraTime"
                                            onChange={() => { setExtra(!extra) }} checked={extra} />
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/* Type extra */}
                        {
                            extra ? (
                                <div class="mb-3">
                                    <div class="form-group">
                                        <Field as="select" name="type_extra" class="form-control input__field" required value={values.type_extra || ''}>
                                            <option value="">Selecciona el momento del cálculo</option>
                                            <option value="BEFORE">Calcular antes de la jornada</option>
                                            <option value="AFTER">Calcular después de la jornada</option>
                                            <option value="BOTH">Calcular antes y después de la jornada</option>
                                        </Field>
                                    </div>
                                </div>
                            ) : null
                        }


                        {/* Round extra time */}
                        <div class="mb-3">
                            <div class="form-group d-flex bg-crh">
                                <div class="pt-1" style={{ width: "90%" }}>
                                    <h5 class="text-center"><label for="switchRoundExtraTime">Redondear tiempo extra</label></h5>
                                </div>
                                <div class="text-center" style={{ width: "10%" }}>
                                    <div className="form-check form-switch pt-1" id="roundExtraTimeSwitch">
                                        <input class="form-check-input" type="checkbox" id="switchRoundExtraTime"
                                            onChange={() => { setRound(!round) }} checked={round} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Type round and distribution round */}
                        {
                            round ? (
                                <div class="mb-3">
                                    <div class="form-group row">
                                        <div class="col-md-6">
                                            <Field as="select" name="type_round" class="form-control input__field" required value={values.type_round || ''}>
                                                <option value="">Selecciona el tipo de redondeo</option>
                                                <option value="COMPLETE_HOURS">Horas completas</option>
                                                <option value="HALF_HOURS">Medias horas</option>
                                            </Field>
                                        </div>
                                        <div class="col-md-6">
                                            <Field as="select" name="distribution_round" class="form-control input__field" required value={values.distribution_round || ''}>
                                                <option value="">Selecciona la distribución del redondeo</option>
                                                <option value="UP">Arriba (15 minutos)</option>
                                                <option value="DOWN">Abajo (15 minutos)</option>
                                                <option value="BOTH">Ambos (15 minutos)</option>
                                            </Field>
                                        </div>
                                    </div>
                                </div>
                            ) : null
                        }

                        {/* Automatic and rotative turn */}
                        <div class="row mb-3">
                            <div className="col-md-6">
                                <div class="form-group d-flex">
                                    <h5 style={{ marginRight: "10px" }}><label for="switchAutomaticTurn">Turno automático</label></h5>
                                    <div className="form-check form-switch">
                                        <input class="form-check-input" type="checkbox" id="switchAutomaticTurn"
                                            onChange={() => { setAutomatic(!automatic) }} checked={automatic} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div class="form-group d-flex">
                                    <h5 style={{ marginRight: "10px" }}><label for="switchRotatingTurn">Turno rotativo</label></h5>
                                    <div className="form-check form-switch">
                                        <input class="form-check-input" type="checkbox" id="switchRotatingTurn"
                                            onChange={() => { setRotating(!rotating) }} checked={rotating} />
                                    </div>
                                </div>

                                {
                                    rotating && (
                                        <div class="form-group row mt-3">
                                            <div class="col-md-4 mt-1">
                                                <h5><label for="">Fecha inicial <span className='text-danger fw-bold'>*</span></label></h5>
                                            </div>
                                            <div class="col-md-8">
                                                <Field type="date" name="start_date" class="form-control input__field" required value={values.start_date || ''} />
                                            </div>
                                        </div>
                                    )
                                }

                            </div>
                        </div>
                        {error ? (
                            <div class="alert alert-danger alert-dismissible fade show d-flex justify-content-between" role="alert">
                                <p className='text-justify' style={{ width: "90%" }}>
                                    {error}
                                </p>
                                <button type="button" class="close" data-dismiss="alert" aria-label="Close" onClick={() => setError(null)}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        ) : null}

                        <div class="pt-3">
                            <button type="submit" class="btn btn-primary">
                                <i class="bi bi-plus" style={{ marginRight: "10px" }}></i>
                                {edit ? "Editar turno" : "Guardar turno"}
                            </button>

                            <button type="button" class="btn btn-danger" style={{ marginLeft: "20px" }} onClick={onCancel.bind(null, resetForm)}>
                                <i class="bi bi-x" style={{ marginRight: "10px" }}></i>
                                Cancelar
                            </button>
                        </div>

                    </Form>
                }</Formik>

        </div >
    )
}

export default FormView;