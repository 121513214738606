import React, { useState, useEffect, useRef } from "react";
import View from "components/crh/reports/workedBankHoliday/Filters.view";
import { toast } from "react-toastify";
import { getOffices } from "api/offices";
import { getJobs } from "api/jobs";
import { getDepartments } from "api/departments";
import { getPayrolls } from "api/payrolls";
import { getPayollPeriods } from "api/payrolls";
import moment from "moment";

function Filters({ setLoading, filters, setFilters, hideModal }) {

	const formikRef = useRef();

	const [offices, setOffices] = useState([]);
	const [jobs, setJobs] = useState([]);
	const [departments, setDepartments] = useState([]);
	const [payrolls, setPayrolls] = useState([]);
	const [payrollPeriods, setPayrollPeriods] = useState([]);
	const [selectedPayroll, setSelectedPayroll] = useState(null);

	useEffect(() => {

		getOffices().then((response) => {
			if (response.errors.length > 0) {
				if (!toast.isActive("toast-offices-eror"))
					toast.error("Error al obtener las sucursales", { toastId: "toast-jobs-eror" })
			} else {
				setOffices(response.data)
			}
		})

		getJobs().then((response) => {
			if (response.errors.length > 0) {
				if (!toast.isActive("toast-jobs-eror"))
					toast.error("Error al obtener los puestos", { toastId: "toast-jobs-eror" })
			} else {
				setJobs(response.data)
			}
		})

		getDepartments().then((response) => {
			if (response.errors.length > 0) {
				if (!toast.isActive("toast-departments-eror"))
					toast.error("Error al obtener los departamentos", { toastId: "toast-departments-eror" })
			} else {
				setDepartments(response.data)
			}
		})

		getPayrolls().then((response) => {
			if (response.errors.length > 0) {
				if (!toast.isActive("toast-payrolls-eror"))
					toast.error("Error al obtener los tipos de nómina", { toastId: "toast-payrolls-eror" })
			} else {
				setPayrolls(response.data)
			}
		})

	}, [])

	const onSubmit = (values) => {

		const { start_date, end_date } = values;

		if (!start_date || !end_date || start_date === '' || end_date === '') {
			toast.error("Debe rellenar los campos obligatorios");
			return;
		}

		setFilters(values)
		setLoading(true);
		hideModal()
	}

	const onChangePayroll = (event) => {
		const payroll = payrolls.find((payroll) => payroll.id.toString() === event.target.value);
		setSelectedPayroll(payroll);
		formikRef.current.setFieldValue("payroll_id", event.target.value);

		getPayollPeriods(payroll.id).then((response) => {
			if (response.errors.length > 0) {
				if (!toast.isActive("toast-payrollPeriods-eror"))
					toast.error("Error al obtener los periodos de nómina", { toastId: "toast-payrollPeriods-eror" })
			}
			else {
				setPayrollPeriods(response.data);
			}
		})
	}

	const onChangePayrollPeriod = (event) => {
		formikRef.current.setFieldValue("payroll_period_id", event.target.value);

		const payrollPeriod = payrollPeriods.find((payrollPeriod) => payrollPeriod.id.toString() === event.target.value);
		const startDate = moment(payrollPeriod.start);
		const endDate = moment(payrollPeriod.end);

		if (formikRef.current) {
			formikRef.current.setFieldValue("start_date", startDate.format("YYYY-MM-DD"));
			formikRef.current.setFieldValue("end_date", endDate.format("YYYY-MM-DD"));
		}
	}

	return (
		<View
			offices={offices}
			jobs={jobs}
			departments={departments}
			payrolls={payrolls}
			payrollPeriods={payrollPeriods}
			filters={filters}
			onSubmit={onSubmit}
			formikRef={formikRef}
			onChangePayroll={onChangePayroll}
			onChangePayrollPeriod={onChangePayrollPeriod}
		/>
	);

}

export default Filters;