import React, { useState, useEffect, useRef } from "react";
import { usePut } from "utils/api";
import { getAdminUser } from "api/users";
import { toast } from "react-toastify";
import View from "components/admin/users/Form.view";

function FormSet({ id, setIsVisible, refresh }) {

    const formikRef = useRef();
    const [user, setUser] = useState({});
    const [refreshValue, setRefreshValue] = useState(null);

    if (refreshValue !== refresh) {
        setRefreshValue(refresh);
    }

    const [callUpdate, reqUpdate] = usePut("/users/admin", {
        onCompleted: () => {
            if(!toast.isActive("user-updated"))
                toast.success("super usuario actualizado", {toastId: "user-updated"});

            formikRef.current.setValues({});
            setIsVisible(false);
        },
        onError: (err) => {
            console.error(err)
            if (!toast.isActive("user-error"))
                toast.error("Error interno del servidor", {toastId: "user-error"});
        }
    })

    useEffect(() => {
        getAdminUser(id).then((response) => {
            if (response.data) {
                if (formikRef.current)
                    formikRef.current.setValues(response.data);
                setUser(response.data);
            }
        });
    }, [id, refreshValue])


    const onSubmit = (values) => {

        for(let key in values)
            if(values[key] === "")
                values[key] = null

        const { username, firstname, lastname } = values
        if(!username || !firstname || !lastname) {
            toast.error("Llene todos los campos obligatorios");
            return;
        }

        values.id = id;
        callUpdate(values);
        
    }

    const onCancel = (resetForm) => {
        setIsVisible(false);
        resetForm({});
    }

    return (
        <View
            formikRef={formikRef}
            edit={true}
            user={user}
            onSubmit={onSubmit}
            onCancel={onCancel}
        />
    )


}

export default FormSet;
