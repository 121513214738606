import React, {useState, useEffect, useContext} from "react";
import { toast } from "react-toastify";
import { useDelete, useGetCall, fetchPost } from "utils/api";
import { showResponseMessage } from "utils/message";
import Loading from "helpers/Loading";
import { LoadingContext } from "helpers/LoadingContext";
import View from "components/crh/holidaysTables/List.view";

function List({
	addEditModal,
	refresh,
	search
}) {
	
	const { loading, setLoading } = useContext(LoadingContext);

	const [tables, setTables] = useState([]);
	const [refreshValue, setRefreshValue] = useState(null);
	const [refreshDelete, setRefreshDelete] = useState(null);

	if(refresh !== refreshValue) {
		setRefreshValue(refresh);
	}

	const [callTables, reqTables] = useGetCall("/holiday_tables", {
		onCompleted: (response) => {
			setLoading(false);
			setTables(response.data)
		},
		onError: (error) => {
			setLoading(false);
			showResponseMessage(error.status, "holiday_tables", "tablas de vacaciones", "read");
		}
	})

	const [callDelete, reqDelete] = useDelete("/holiday_tables", {
		onCompleted: () => {
			if (!toast.isActive("table-deleted"))
				toast.success("Tabla eliminada", { toastId: "table-deleted" });

			setRefreshDelete(Math.random());
		},
		onError: (error) => {
			console.error(error)
			showResponseMessage(error.status, "holiday_tables", "tablas de vacaciones", "delete");
		}
	});

	useEffect(() => {
		setLoading(true);
		callTables({ search });
	}, [refreshDelete, refreshValue, search]);

	const onDelete = (id) => {
		fetchPost("/permissions/check", { table: "HOLDAY_TABLE", permission: "delete" }).then((response) => {
			if (response.data) {
				const data = response.data;
				if (data.isAllowed === true) {
					if (window.confirm("¿Estás seguro de eliminar el area?")) {
						callDelete({ id: id });
					}
				} else {
					if (!toast.isActive("toast-delete-holiday_tables-unauthorized")) {
						toast.error("Error, no tienes los permisos para eliminar tablas de vacaciones", { toastId: "toast-delete-holiday_tables-unauthorized" })
					}
				}
			}
		})
	}

	const onEdit = (id) => {
		addEditModal(id);
	}

	if (loading) return <Loading />

	return (
		<View 
            tables={tables}
            onDelete={onDelete} 
            onEdit={onEdit}
        />
	);
}

export default List;