import { toast } from "react-toastify";

const showResponseMessage = (status, schema, message = "", type = "", duplicated = "clave") => {
    if (status === 400) {
        if (!toast.isActive(`${schema}-error-400`)) {
            toast.error("Campos obligatorios requeridos", { toastId: `${schema}-error-400` });
        }
    } else if (status === 403) {
        if (!toast.isActive(`toast-${type}-${schema}-unauthorized`)) {
            toast.error(`Error, no tienes los permisos para ${getTypePermission(type)} ${message}`, { toastId: `toast-${type}-${schema}-unauthorized` })
        }
    } else if (status === 409) {
        if (!toast.isActive(`${schema}-error-409`)) {
            toast.error(`Ya existe un registro de ${message} con esa ${duplicated}, por favor ingrese otra ${duplicated}`, { toastId: `${schema}-error-409` });
        }
    } else {
        if (!toast.isActive(`${schema}-error-500`)) {
            toast.error("Error interno del servidor", { toastId: `${schema}-error-500` });
        }
    }
}

const getTypePermission = (type) => {
    if (type === "create") return "crear";
    if (type === "read") return "consultar";
    if (type === "delete") return "eliminar";
    if (type === "edit") return "editar";
    if (type === "load") return "carga masiva de";
    return "";
}

export { showResponseMessage };