import React, { useState, useEffect, useRef } from "react"
import { usePut } from "utils/api"
import { getDeviceEmployees } from "api/deviceEmployees"
import { toast } from "react-toastify"
import View from "components/crh/employeeDevices/Form.view"

function EmployeeDevicesForm({ 
    id, 
    refresh, 
    setIsVisible, 
    reloadEmployee, 
    setReloadEmployee, 
    setReloadEmployees, 
    theme
}) {

    const [error, setError] = useState(null)
    const [deviceEmployees, setDeviceEmployees] = useState([])
    const [refreshValue, setRefreshValue] = useState(null)
    const [absStatus, setAbsStatus] = useState(false)

    if(refresh !== refreshValue) setRefreshValue(refresh)

    const [callUpdate, reqUpdate] = usePut("/device_employees", {
        onCompleted: () => {
            
            if(!toast.isActive("toast-update-device"))
                toast.success("Empleados actualizados", { toastId: "toast-update-device" })

            setIsVisible(false)
            setReloadEmployees(true)

        },
        onError: (err) => {
            setError("Error interno del servidor")
        }
    })

    useEffect(() => {
    
        getDeviceEmployees(id, "employee_to_device").then((data) => {
            if(data.data)
                setDeviceEmployees(() => {
                    setAbsStatus(data.data.reduce((acc, deviceEmployee) => acc && deviceEmployee.new_status, true))
                    return data.data
                })
        })

        setReloadEmployee(false)

    }, [id, reloadEmployee, setReloadEmployee, refreshValue])

    const onChangeAll = (value) => {
        setDeviceEmployees(deviceEmployees.map((deviceEmployee) => {
            deviceEmployee.new_status = value
            return deviceEmployee
        }))
        setAbsStatus(value)
    }

    const onUpdate = (id, value) => {
        setDeviceEmployees(deviceEmployees.map((deviceEmployee) => {
            if(deviceEmployee.device.id === id)
                deviceEmployee.new_status = value
            return deviceEmployee
        }))
        setAbsStatus(deviceEmployees.reduce((acc, deviceEmployee) => acc && deviceEmployee.new_status, true))
    }

    const onSubmit = () => 
        callUpdate({
            employee_id: id,
            data: deviceEmployees,
            type: 'employee_to_device'
        })
    
    const onCancel = (resetForm) =>
        setIsVisible(false)    

    return (
        <View
            absStatus={absStatus}
            onChangeAll={onChangeAll}
            onUpdate={onUpdate}
            error={error}
            edit={true}
            deviceEmployees={deviceEmployees}
            onSubmit={onSubmit}
            onCancel={onCancel}
        />
    )

}

export default EmployeeDevicesForm
