import React, { useState, useEffect } from "react";
import { usePost } from "utils/api";
import { toast } from "react-toastify";
import { getParameters } from "api/companies";
import View from "components/crh/parameters/Form.view";
import moment from "moment";

function Form() {

    const [parameters, setParameters] = useState({});
    const [token, setToken] = useState(null);

    const [callUpdate, reqUpdate] = usePost("/companies/parameters", {
        onCompleted: () => {
        },
        onError: (err) => {
            console.error(err)
            if (!toast.isActive("company-error"))
                toast.error("Error interno del servidor", { toastId: "company-error" });
        }
    })

    const [callGenToken, reqGenToken] = usePost("/companies/generate_token", {
        onCompleted: (data) => {
            
            setToken(data.data.token);
        },
        onError: (err) => {
            console.error(err)
            if (!toast.isActive("company-error"))
                toast.error("Error interno del servidor", { toastId: "company-error" });
        }
    })

    const onUpdate = (values) => {

        sessionStorage.setItem("hour_holidays", values.hour_holidays);

        values.offset = moment().utcOffset();
        setParameters(values);
        callUpdate(values);
        
    }

    const onGenerateToken = () => 
        callGenToken({});

    const hideModal = () =>
        setToken(null);

    useEffect(() => {
        getParameters().then((data) => {
            if(data.data) setParameters(data.data);
            else console.log(data);
        })
    }, []);

    return (
        <View
            token={token}
            parameters={parameters}
            onUpdate={onUpdate}
            onGenerateToken={onGenerateToken}
            hideModal={hideModal}
        />
    )

}

export default Form;