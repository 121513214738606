import React from "react";
import PropTypes from 'prop-types';

class LoadCsvField extends React.Component {

  render() {
    const { className = "", accept, multiple = false, inputRef = null, style = {} } = this.props;
    return (
        <input name="file" type="file" className={className} style={style}
          accept={accept} onChange={this.onFileChange} multiple={multiple} ref={inputRef}></input>
    );
  }

  constructor(props) {
    super(props);
    this.onFileChange = this.onFileChange.bind(this);
    this.chargedFiles = [];
  }

  async onFileChange(e) {

    const { onLoad } = this.props;

    const files = e.target.files;
    const data = []

    for(let i = 0; i < files.length; i++) {

      const fileText = await this.readFile(files[i]);
      const rows = fileText.replace(/\r/g, '').trim().split('\n');
      const fileData = {
        headers: [],
        rows: []
      };

      for(let j = 0; j < rows.length; j++) {

        if(rows[j] === "") continue;

        const cols = rows[j].split(',');
        if(j === 0) fileData.headers = cols
        else fileData.rows.push(cols)
        
      }

      data.push(fileData);

    }

    onLoad(data)

  }

  async readFile(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
    
      reader.onload = (e) => {
        let data = e.target.result;
        resolve(data);
      }

      reader.onerror = (error) => {
        reject(error)
      }

      reader.readAsBinaryString(file);

    });
  }

}

LoadCsvField.propTypes = {
  className: PropTypes.string,
  accept: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  setUploadedFiles: PropTypes.func,
  setTotalFiles: PropTypes.func
};

export default LoadCsvField;