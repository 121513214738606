import React from "react";
import { Formik, Form, Field } from "formik";

function TimetableHistoryView({ 
    loading,
    searchFilters,
    employees,
    shifts,
    departments,
    jobs,
    employeeTimetableHistories,
    selectedEmployees,
    selectedShift,
    setSelectedEmployees,
    setSelectedShift,
    setSearchFilters,
    handleSubmit,
    handleCancel,
    handleDelete,
    handleChangeSearch,
    handleKeyUp,
    formikRef,
}) {
	return (
    <div>
      <div className="row mx-3">
        <div className="col-md-8 my-3 d-flex">

            <div class="input-group input-group-merge input-group-flush" style={{ width: "40%" }}>
              <div class="input-group-prepend input-group-text" >
                <i class="bi-search"></i>
              </div>
              <input
                type="search"
                class="form-control"
                placeholder="Buscar empleado"
                onChange={e => handleChangeSearch(e, 'programming_search')}
                onKeyUp={e => handleKeyUp(e, 'programming_search')}
              />
            </div>

            <label className="ms-2 input" style={{ width: "20%" }}>
              <select className="input__field form-control" onChange={e => setSearchFilters({ ...searchFilters, department_id: e.target.value })}>
                <option value="">Todos</option>
                {
                  departments.map((department) =>
                    <option value={department.id}>{department.description}</option>
                  )
                }
              </select>
              <span class="input__label">
                Departamento
              </span>
            </label>

            <label className="ms-2 input" style={{ width: "20%" }}>
              <select className="input__field form-control" onChange={e => setSearchFilters({ ...searchFilters, job_id: e.target.value })}>
                <option value="">Todos</option>
                {
                  jobs.map((job) =>
                    <option value={job.id}>{job.description}</option>
                  )
                }
              </select>
              <span class="input__label">
                Puesto
              </span>
            </label>

        </div>
      </div>
      <div className="row mx-3 mt-1 d-flex justify-content-center">
        <div className="col-5">
          <div className="border" style={{ height: "35vh", overflowY: "auto", overflowX: "hidden" }}>
            
            <div className="row px-3 py-2 text-white fw-bold"style={{ backgroundColor: "#04b5fc" }}>
              <div className="col-md-2">
                <span>Id</span>
              </div>
              <div className="col-md-7">
                <span>Nombre</span>
              </div>
              <div className="col-md-2">
                <span>Acciones</span>
              </div>
            </div>

            {
              employees.map((employee) => (
                <div className="row px-3 py-2 bg-white border-left border-right">
                  <div className="col-md-2">
                    <span>{employee.key}</span>
                  </div>
                  <div className="col-md-7">
                    {employee.firstname} {employee.dad_lastname} {employee.mom_lastname}
                  </div>
                  <div className="col-md-2">
                    <input 
                        style={{width: "25px", height: "25px"}}
                        onChange={() => setSelectedEmployees({ ...selectedEmployees, [employee.id]: !selectedEmployees[employee.id] })}
                        checked={selectedEmployees[employee.id]}
                        value={selectedEmployees[employee.id]}
                        type="checkbox" 
                      />
                  </div>
                </div>
              ))
            }

          </div>
        </div>
        <div className="col-md-4">
          <div className="border" style={{ height: "35vh", overflowY: "auto", overflowX: "hidden" }}>
            
            <div className="row px-3 py-2 text-white fw-bold" style={{ backgroundColor: "#04b5fc" }}>
              <div className="col-md-9">
                <span>Turno</span>
              </div>
              <div className="col-md-2">
                <span>Acciones</span>
              </div>
            </div>

            {
              shifts.map((shift) => (
                <div className="row px-3 py-2 bg-white border-left border-right">
                  <div className="col-md-9">
                    {shift.key} - {shift.description}
                  </div>
                  <div className="col-md-2">
                    <input
                      style={{width: "25px", height: "25px"}}
                      onChange={() => setSelectedShift(shift)}
                      checked={selectedShift.id === shift.id}
                      value={selectedShift.id === shift.id}
                      type="checkbox"
                    />
                  </div>
                </div>
              ))
            }

          </div>
        </div>
        <div className="col-md-3" style={{ height: "35vh", overflowY: "auto" }}>
          <Formik
            innerRef={formikRef}
            initialValues={{
              startDate: "",
              endDate: "",
            }}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange, handleBlur, handleSubmit }) => (
              <Form>
                <div className="border" style={{ height: "35vh", overflowY: "auto", overflowX: "hidden" }}>
                            
                  <div className="row px-3 py-2 text-white fw-bold" style={{ backgroundColor: "#04b5fc" }}>
                    <div className="col-md-12">
                      <span>Programar cambio de turno</span>
                    </div>
                  </div>

                  <div className="row px-3 py-2 bg-white">
                    <div className="col-md-5">
                      <label className="mt-1 align-middle">Fecha inicio</label>
                    </div>
                    <div className="col-md-7">
                      <Field
                        required
                        type="date"
                        className="form-control"
                        name="startDate"
                      />
                    </div>
                  </div>

                  <div className="row px-3 py-2 bg-white">
                    <div className="col-md-5">
                      <label className="mt-1 align-middle">Fecha fin</label>
                    </div>
                    <div className="col-md-7">
                      <Field
                        required
                        type="date"
                        className="form-control"
                        name="endDate"
                      />
                    </div>
                  </div>

                  <div className="row px-3 py-2 bg-white">
                    <div className="col-md-12">
                      <button type="button" className="btn btn-danger" onClick={handleCancel}>
                        Cancelar
                      </button>
                      <button type="submit" className="btn btn-primary ms-3">
                        Aceptar
                      </button>
                    </div>
                  </div>

                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <div className="row mx-3 mt-1">
        <div className="col-md-8 my-3 d-flex">
          <div class="input-group input-group-merge input-group-flush" style={{ width: "40%" }}>
            <div class="input-group-prepend input-group-text" >
              <i class="bi-search"></i>
            </div>
            <input
              type="search"
              class="form-control"
              placeholder="Buscar empleado"
              onChange={e => handleChangeSearch(e, 'history_search')}
              onKeyUp={e => handleKeyUp(e, 'history_search')}
            />
          </div>
        </div>
      </div>

      <div className="row mx-3 d-flex justify-content-center">
        <div className="col-md-12">

          <div class={"table-responsive datatable-custom position-relative report-content"}>
            <div class="dataTables_wrapper no-footer pt-2 pl-3 pr-3">
              <table class="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
                <thead>
                  <tr role="row">
                    <th>ID</th>
                    <th>Nombre</th>
                    <th>Turno</th>
                    <th>Fecha inicio</th>
                    <th>Fecha fin</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    employeeTimetableHistories.map((timetableHistory) => (
                      <>
                        <tr>
                          <td>
                            <span>{timetableHistory.employee.key}</span>
                          </td>
                          <td>
                            {timetableHistory.employee.firstname} {timetableHistory.employee.dad_lastname} {timetableHistory.employee.mom_lastname}
                          </td>
                          <td>
                            { timetableHistory.shift.description }
                          </td>
                          <td>
                            {timetableHistory.start_date}
                          </td>
                          <td>
                            {timetableHistory.end_date}
                          </td>
                          <td>
                            <button type="button" className="btn btn-danger ms-2" onClick={() => handleDelete(timetableHistory)}>
                              <i className="bi bi-trash"></i>
                            </button>
                          </td>
                        </tr>
                      </>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>

        </div>
      </div>
    </div>
	);
}

export default TimetableHistoryView;