import React from "react";
import { Formik, Form, Field } from 'formik';

function InstructionsView({ onSubmit }) {
	return (
		<>

			<div className="content container-fluid p-5">

				<div className="page-header mb-3">
					<div className="row align-items-end">
						<div className="col-sm mb-2 mb-sm-0">
							<h1 className="page-header-title">Instrucciones</h1>
						</div>
					</div>
				</div>

				<div class="card p-4">
					<Formik 
					initialValues={{}}
					onSubmit={onSubmit}>
					{({ values, setFieldValue, resetForm }) =>
						<Form>

							<div className="row my-2">
								<div className="col-md-8">
									<h4>Recuperar marcajes</h4>
								</div>
								<div className="col-md-4">
									<div class="form-check form-switch">
										<Field 
											type="checkbox"
											style={{transform: "scale(1.4)"}}
											class="form-check-input"
											name="get_attendance"
										/>
									</div>
								</div>
							</div>

							<div className="row my-2">
								<div className="col-md-6">
									<Field type="date" name="start_date" className="form-control" />
								</div>
								<div className="col-md-6">
									<Field type="date" name="end_date" className="form-control" />
								</div>
							</div>

							<div className="row my-2">
								<div className="col-md-8">
									<h4>Reiniciar</h4>
								</div>
								<div className="col-md-4">
									<div class="form-check form-switch">
										<Field 
											type="checkbox"
											style={{transform: "scale(1.4)"}}
											class="form-check-input"
											name="reboot"
										/>
									</div>
								</div>
							</div>

							<div className="row my-2">
								<div className="col-md-8">
									<h4>Borrar usuarios</h4>
								</div>
								<div className="col-md-4">
									<div class="form-check form-switch">
										<Field 
											type="checkbox"
											style={{transform: "scale(1.4)"}}
											class="form-check-input"
											name="delete_users"
										/>
									</div>
								</div>
							</div>

							<div className="row my-2">
								<div className="col-md-8">
									<h4>Borrar marcajes</h4>
								</div>
								<div className="col-md-4">
									<div class="form-check form-switch">
										<Field 
											type="checkbox"
											style={{transform: "scale(1.4)"}}
											class="form-check-input"
											name="delete_attendance"
										/>
									</div>
								</div>
							</div>

							<div className="row my-2">
								<div className="col-md-8">
									<h4>Borrar administradores</h4>
								</div>
								<div className="col-md-4">
									<div class="form-check form-switch">
										<Field 
											type="checkbox"
											style={{transform: "scale(1.4)"}}
											class="form-check-input"
											name="delete_admins"
										/>
									</div>
								</div>
							</div>

							<div className="row my-2 d-none">
								<div className="col-md-8">
									<h4>Comando crudo</h4>
								</div>
								<div className="col-md-4">
									<div class="">
										<Field 
											type="text"
											class="form-control"
											name="raw_command"
										/>
									</div>
								</div>
							</div>

							<div class="pt-3">
								<button type="submit" class="btn btn-primary">
									Ejecutar instrucciones
								</button>
							</div>

						</Form>
					}</Formik>
				</div>

			</div>

		</>
	);
}

export default InstructionsView;