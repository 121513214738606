import React from "react";
import { getNameIncidence } from "utils/constants";
import "resources/styles/Home.scss";

function AlertsView({
    incidencesCount,
    employees,
    loading
}) {
    return (
        <>

            <div style={{ position: "absolute", top: "5px", right: "10px" }}>
                { loading && <div className="spinner-border spinner-border-sm text-primary" role="status"></div> }
            </div>

            <div className="w-100 px-3 py-1 mb-0 border-bottom">
                <span className="h6 fw-bold text-primary">Alertas</span>
            </div>

            {
                incidencesCount.map((incidenceCount, index) => (
                    <div className="w-100 h6 px-3 py-2 d-flex align-items-center mb-0">
                        <i className={`bi bi-circle me-3 ${incidenceCount.justified ? "text-warning" : (incidenceCount.count >= 3 ? "text-danger" : "text-warning")}`} style={{ fontSize: "1.2em" }}/>
                        <div className="d-flex flex-column align-items-start" style={{ width: "70%", fontSize: "0.8em" }}>
                            <span className="text-dark">
                                {employees[incidenceCount.employee_id]?.firstname} {employees[incidenceCount.employee_id]?.dad_lastname} {employees[incidenceCount.employee_id]?.mom_firstname}
                            </span>
                            <span className="text-muted">
                                { getNameIncidence(incidenceCount.type) }
                                { incidenceCount.justified ? " con justificación" : " sin justificación" }
                            </span>
                        </div>
                        <div className="text-center" style={{ width: "15%", fontSize: "0.75rem" }}>
                            <span className="text-muted">
                                { "+ " + incidenceCount.count }
                            </span>
                        </div>
                    </div>
                ))
            }   
        </>
    )
}

export default AlertsView