import React, { useState, useRef } from "react";
import View from "components/crh/incidenceRequests/IncidenceRequests.view";

function IncidenceRequests() {

  const formikRef = useRef();
  const [employee, setEmployee] = useState(null);

  return (
    <View 
      formikRef={formikRef}
      employee={employee}
      setEmployee={setEmployee}
    />
  );
}

export default IncidenceRequests;