import React, { useEffect } from "react";
import { MapContainer, TileLayer, Circle } from "react-leaflet";
import { useMap } from "react-leaflet";
import { SERVER_URL } from '../../../../settings';

const RePaint = () => {
    const map = useMap();
    useEffect(() => { map.invalidateSize(); }, []);
    return null;
}

function AttendanceDetailsView({ data, locations, getLocation, closeModal }) {
    return (
        <>

            <div className="row d-flex align-items-center mb-3">
                <div className="col-md-4">
                    <div class="form-group">
                        <label class="input">
                            <input type="text" class="form-control bg-white input__field" placeholder=" " required value={data.log.lat || ''} />
                            <span class="input__label"> Latitud </span>
                        </label>
                    </div>
                </div>
                <div className="col-md-4">
                    <div class="form-group">
                        <label class="input">
                            <input type="text" class="form-control bg-white input__field" placeholder=" " required value={data.log.lng || ''} />
                            <span class="input__label"> Longitud </span>
                        </label>
                    </div>
                </div>

                <div className="col-md-4">
                    <button className="btn btn-primary w-100" onClick={() => getLocation(data.log)}>
                        Buscar dirección 
                    </button>
                </div>
            </div>
            
            <div className="row mb-3">
                <div className="col-md-12">
                    <input type="text" className="form-control bg-white" placeholder="Dirección" value={data.log.location??(locations.length > 0 ? locations[0].formatted_address : "")} disabled />
                </div>
            </div>

            <div className="row">
                <div className={`col-md-${data.log.photo && (data.log.photo.url || data.log.photo.path) ? "6" : "12"}`} style={{ height: "40vh" }}>
                    <div className="d-flex justify-content-center h-100 mx-3">
                        <MapContainer center={[data.log.lat, data.log.lng]} zoom={17} scrollWheelZoom={true} style={{ height: "100%", width: "100%", zIndex: 0 }}>
                            <TileLayer
                                // url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png?api_key="
                                // url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png?api_key="
                                url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
                                attribution='&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
                            />
                            <Circle 
                                center={[data.log.lat, data.log.lng]}
                                radius={15} 
                                color={"rgba(54, 162, 235, 1)"} 
                                stroke={false}
                            />
                            <RePaint />
                        </MapContainer>
                    </div>
                </div>
                {
                    data.log.photo && (data.log.photo.url || data.log.photo.path) && 
                    <div className="col-md-6 d-flex justify-content-center" style={{ height: "40vh" }}>
                        { 
                            data.log.photo && (data.log.photo.url || data.log.photo.path)
                                ?   <img src={ data.log.photo.path ? (SERVER_URL + "/" + data.log.photo.path) : data.log.photo.url } className="img-fluid h-100" alt="Foto" /> 
                                :   <div className="d-flex justify-content-center align-items-center w-100 h-100">
                                        <span className="text-muted">Sin foto</span>
                                    </div>
                        }
                    </div>
                }
            </div>

            <div class="pt-3">
                <button type="button" class="btn btn-danger" onClick={closeModal}>
                    <i class="bi bi-x" style={{marginRight: "10px"}}></i>
                    Cerrar
                </button>
            </div>

        </>
    )
}

export default AttendanceDetailsView;