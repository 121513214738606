import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDelete, usePut, usePost } from "utils/api";
import { downloadFile } from "utils/files";
import { getCompanies } from "api/companies";
import View from "components/admin/enviroments/List.view";
import Users from "components/crh/users/Users";
import Devices from "components/crh/devices/Devices";

function List({
    search,
    refresh,
    setTabs,
    addEditEnviromentModal
}) {

    const [refreshValue, setRefreshValue] = useState(null);
    const [refreshDelete, setRefreshDelete] = useState(null);
    const [companies, setCompanies] = useState([]);
    const [exportData, setExportData] = useState(null);

    if(refresh !== refreshValue) setRefreshValue(refresh);

    const [callExport, reqExport] = usePost("/companies/export", {
        onCompleted: ({ data }) => {
            setExportData(data);
        },
        onError: (error) => {
            console.error(error)
            if(!toast.isActive("company-error"))
                toast.error("Error al exportar el entorno de empresa", { toastId: "company-error" });
        }
    });

    const [callDelete, reqDelete] = useDelete("/companies", {
        onCompleted: () => {
            if (!toast.isActive("company-deleted"))
                toast.success("Entorno de empresa eliminado", { toastId: "company-deleted" });

            setRefreshDelete(Math.random())
        },
        onError: (error) => {
            console.error(error)
            if (!toast.isActive("company-error"))
                toast.error("Error al eliminar el entorno de empresa", { toastId: "company-error" });
        }
    });

    const [callChangeStatus, reqChangeStatus] = usePut("/companies/change_status", {
        onCompleted: ({ data }) => {

            const status = data.status ? "activo" : "inactivo";
            const name = data.name;
            
            if (!toast.isActive(`company-${status}-${name}`))
                toast.success(`Entorno ${name} cambiado a ${status}`, { toastId: `company-${status}-${name}` });

            setRefreshDelete(Math.random());
        },
        onError: (error) => {
            console.error(error)
            if (!toast.isActive("company-error"))
                toast.error("Error al cambiar el estado del entorno", { toastId: "company-error" });
        }

    });

    useEffect(() => {
        getCompanies({ search }).then((response) => {
            if (response.data)
                setCompanies(response.data);
        })
    }, [refreshDelete, refreshValue, search]);

    useEffect(() => {
        if(exportData) {
            downloadFile({
                data: JSON.stringify(exportData, null, 2),
                filename: exportData.Company.name + ".json",
                type: "application/json"
            })
        }
    }, [exportData]);

    const onExport = (id) =>
        callExport({ id });

    const onDelete = (id) => {
        if (window.confirm("¿Estás seguro de eliminar el entorno de la empresa?"))
            callDelete({ id });
    }

    const onEdit = (id) =>
        addEditEnviromentModal(id);

    const onChangeStatus = (id) => {
        if (window.confirm("¿Estás seguro de cambiar el estado del entorno?")) {
            callChangeStatus({ id });
        }
    }

    const onShowUsers = (id, key, nameCompany) => {

        const name = `Usua. ${key}`;
        setTabs(tabs => {
            const result = tabs.findIndex(tab => tab.content === name && tab.componentId === 28);

            const newTabs = [...tabs];
            newTabs.forEach(tab => {
                tab.active = false;
            });

            if (result !== -1) {
                newTabs[result].active = true;
                return newTabs;
            }

            newTabs.push({
                id: newTabs.length + 1,
                componentId: 28,
                content: name,
                active: true,
                props: {company: id, name: nameCompany}
            });

            return newTabs;
        })
    }

    const onShowDevices = (id, key, nameCompany) => {

        const name = `Lect. ${key}`;
        setTabs(tabs => {
            const result = tabs.findIndex(tab => tab.content === name && tab.componentId === 17);

            const newTabs = [...tabs];
            newTabs.forEach(tab => {
                tab.active = false;
            });

            if (result !== -1) {
                newTabs[result].active = true;
                return newTabs;
            }

            newTabs.push({
                id: newTabs.length + 1,
                componentId: 17,
                content: name,
                active: true,
                props: {company: id, name: nameCompany}
            });

            return newTabs;
        })
    }

    const onShowEmployees = (id, key, nameCompany) => {
            
        const name = `Emp. ${key}`;
        setTabs(tabs => {
            const result = tabs.findIndex(tab => tab.content === name && tab.componentId === 27);

            const newTabs = [...tabs];
            newTabs.forEach(tab => {
                tab.active = false;
            });

            if (result !== -1) {
                newTabs[result].active = true;
                return newTabs;
            }

            newTabs.push({
                id: newTabs.length + 1,
                componentId: 1,
                content: name,
                active: true,
                props: {company: id, name: nameCompany}
            });

            return newTabs;
        })


    }

    return (
        <View 
            companies={companies} 
            onDelete={onDelete} 
            onEdit={onEdit} 
            onShowUsers={onShowUsers} 
            onShowDevices={onShowDevices} 
            onShowEmployees={onShowEmployees}
            onChangeStatus={onChangeStatus}
            onExport={onExport}
        />
    );

}

export default List;
