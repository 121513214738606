import React from "react";
import Logo from "resources/images/control_logo.png";
import LogoDark from 'resources/images/control_logo_blanco_flecha_azul.png';
import { ReactComponent as Open } from "resources/svg/bars-solid.svg";
import { ReactComponent as User } from "resources/svg/user.svg";
import { ReactComponent as Bell } from "resources/svg/bell-solid.svg";
import { ReactComponent as Options } from "resources/svg/ellipsis-vertical-solid.svg";
import { ReactComponent as UserEdit } from "resources/svg/user-edit.svg";
import { ReactComponent as PowerOff } from "resources/svg/power-off-solid.svg";
import { ReactComponent as Light } from 'resources/svg/sun-solid.svg';
import { ReactComponent as Dark } from 'resources/svg/moon-solid.svg';
import "resources/styles/Navbar.scss";
import moment from 'moment';

const getUserType = (type, profile) => {
	if (type === "SUPERADMIN") return "CRH Administrador";
	if (type === "ADMIN") {
		if (profile === "BOSS") return "Administrador - Jefe";
		if (profile === "RH") return "Administrador - RH";
		return "Administrador";
	}
	if (type === "NORMAL") {
		if (profile === "BOSS") return "Normal - Jefe";
		if (profile === "RH") return "Normal - RH";
		return "Normal";
	}
	return ""
}

function NavbarView({
	notificationsOpen,
	onOpenNotifications,
	setNotificationsOpen,
	onAddTab,
	type = "",
	theme,
	setTheme,
	user,
	navToggle,
	menuOpen,
	setMenuOpen,
	onLogout,
	notifications = []
}) {
	return (
		<div className='container-fluid navbar_top theme'
			style={theme === "light" ? { background: "whitesmoke", transition: "all 0.3s ease-in", zIndex: 101 }
				: { background: "#111111", transition: "all 0.3s ease-in", zIndex: 101 }}>

			<div className='container_interior'>
				<div className="section__logo">
					{theme === "light" ? <img src={Logo} alt="" /> : <img src={LogoDark} alt="" />}
					<div className={theme === "light" ? "menu_burguer" : "menu_burguer_dark"} id="menu_burguer" onClick={navToggle}>
						<Open />
					</div>
				</div>
				<div className='Contaier_navBar_principal' style={{ zIndex: 101 }}>
					<div className="datauser">
						<p><span></span>{user?.firstname + " " + user?.lastname}</p>
						<span>{getUserType(user.type, user?.profile??"")}</span>
					</div>
					{user?.photo?.url ? <img src={user.photo.url} alt="" /> : <User />}
					<div className="notifications"  onClick={onOpenNotifications}>
						<Bell />
						{
							notifications && notifications.length > 0
								? <span className="badge rounded-pill bg-danger" style={{ position: "fixed", right: "7.5em" }}>
									{notifications.length}</span>
								: null
						}
					</div>
					<div 
						className={theme === "light" ? "menu" : "menu__dark"}
						style={	notificationsOpen === false 
							? { display: "none" } 
							: { 
								display: "block", 
								transition: "all 0.3s ease-in", 
								width: "287px",
								marginTop: function(){
									if(notifications.length > 3) return "425px"
									if(notifications.length === 0) return "125px"
									return (notifications.length * 160) + "px"
								}()
							}
						}
					>
						<div className="triangulo" style={{marginLeft: "12em"}}></div>
						{
							notifications && notifications.length > 0
								? <ul style={{ maxHeight: "375px", overflowY: "auto"}}>
									{
										notifications.map((notification, idx) =>
											<li key={notification.id}>
												<div className="">
													<div className="">
														<div className='d-flex align-items-center m-0 p-0' style={{lineHeight: "normal"}}>
															<span className='fs-5'>{notification.title}</span>
															<i className="bi bi-dot fw-bold text-white fs-1"></i>
														</div>
														<span>{notification.content}</span>
													</div>
													<small>
														{
															moment(notification.createdAt).fromNow()
														}
													</small>
												</div>
											</li>
										)
									}
								</ul>
								: <ul>
									<li className="p-3">
										<span className="text-white">No hay notificaciones</span>
									</li>
								</ul>
						}
					</div>
					<div 
						className="options"
						onClick={() => {
							setNotificationsOpen(false)
							setMenuOpen(menuOpen === false ? true : false)
						}}
					>
						<Options />
					</div>
					<div className={theme === "light" ? "menu" : "menu__dark"}
						style={menuOpen === false ? { display: "none", transition: "all 0.3s ease-in" } :
							{ display: "block", transition: "all 0.3s ease-in" }}
					>
						<div className="triangulo" style={{marginLeft: "8em"}}></div>
						<ul>
							<li>
								{theme === "light" ? <Light className='sun' /> : <Dark className='moon' />}
								<span>
									<div className="form-check form-switch"
										id="custom-switch"
									>
										<input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" onChange={() => setTheme(theme === "light" ? "dark" : "light")} />
										<label class="form-check-label" for="flexSwitchCheckDefault">
											{theme === "light" ? "Claro" : "Obscuro"}
										</label>

									</div>
								</span>
							</li>
							<li style={{ cursor: "pointer" }} onClick={() => onAddTab(33, "Mi perfil")}>
								<UserEdit />
								<span>Editar Usuario</span>
							</li>
							<li onClick={onLogout} style={{ cursor: "pointer" }}>
								<PowerOff />
								<span>Cerrar Sesión</span>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div >
	)

}


export default NavbarView;