import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { usePut, useGetCall } from "utils/api";
import { showResponseMessage } from "utils/message";
import { getEmployees } from "api/employees";
import { toast } from "react-toastify";
import View from "components/crh/incidencesAuth/RequestForm.view";


function RequestForm({ refresh, id, setIsVisible }) {

	const formikRef = useRef();
	const incidencesWithHour = [
		"PERMISSION_RELEASE_EARLY",
		"PERMISSION_ARRIVE_LATE",
		"PERMISSION_LEAVE_HOURS",
		"HOURLY_VACATIONS"
	]
	const [incidenceRequest, setIncidencesRequest] = useState({});
	const [refreshValue, setRefreshValue] = useState(null);
	const [keyEmployee, setKeyEmployee] = useState("");
	const [incidences, setIncidences] = useState([]);
	const [devices, setDevices] = useState([]);
	const [startHour, setStartHour] = useState(null);
	const [endHour, setEndHour] = useState(null);
	const [isValidEmployee, setIsValidEmployee] = useState(false);

	if (refreshValue !== refresh) {
		setRefreshValue(refresh)
	}

	const [callEdit, reqEdit] = usePut("/incidences_requests", {
		onCompleted: () => {
			if (!toast.isActive("incidence_request-updated"))
				toast.success("Solicitud de incidencia actualizada correctamente", { toastId: "incidence_request-updated" })

			formikRef.current.setValues({})
			setStartHour(null)
			setEndHour(null)
			setKeyEmployee("")
			setIsVisible(false);
		},
		onError: (error) => {
			console.error(error)
			showResponseMessage(error.status, "incidence_requests", "solicitudes de incidencias", "edit")
		}
	})

	const [callIncidences, reqIncidences] = useGetCall('/incidences', {
		onCompleted: (response) => {
			setIncidences(response.data);
		}, onError: (error) => {
			showResponseMessage(error.status, "incidences", "incidencias", "read")
		}
	})

	const [callIncidenceRequest, reqIncidenceRequest] = useGetCall(`/incidences_requests/${id}`, {
		onCompleted: (response) => {
			console.log("response", response)
			let data = response.data;
			if (data.start_date) data.start_date = moment.utc(data.start_date).format("YYYY-MM-DD")
			if (data.end_date) data.end_date = moment.utc(data.end_date).format("YYYY-MM-DD")
			if (data.start_hour) setStartHour(data.start_hour);
			if (data.end_hour) setEndHour(data.end_hour);

			if (formikRef.current)
				formikRef.current.setValues(data);
			setIncidencesRequest(data);
			setKeyEmployee(data?.employee?.key);
			setIsValidEmployee(true);
		}, onError: (error) => {
			showResponseMessage(error.status, "incidence_requests", "solicitudes de incidencias", "read");
		}
	})

	const [callDevices, reqDevices] = useGetCall('/devices', {
		onCompleted: (response) => {
			setDevices(response.data);
		}, onError: (error) => {
			showResponseMessage(error.status, "devices", "lectores", "read")
		}
	})

	useEffect(() => {
		callIncidences({ request_rh: true })
		callIncidenceRequest();
		callDevices();
	}, [refreshValue, id])

	const onSearchEmployee = () => {
		getEmployees({ key: keyEmployee }).then(response => {
			if (response.data) {
				const data = response.data;
				if (data.length === 1) {
					setIsValidEmployee(true);
					toast.success("Empleado encontrado");
				} else {
					setIsValidEmployee(false);
					toast.error("No se encontró el empleado");
				}
			} else {
				toast.error("Error del servidor");
			}
		});
	}

	const onSubmit = (values) => {

		const { incidence } = values;
		const employee_key = values?.employee?.key ? values.employee.key : null;

		if (!isValidEmployee) {
			toast.error("No es posible encontrar al empleado, ingrese su ID de empleado y de click en 'Buscar empleado'");
			return;
		}

		if (!employee_key || !incidence || incidence.id === '') {
			toast.error("Rellene los campos obligatorios")
			return;
		}

		const incidenceType = incidences.find((item) => item.id === parseInt(incidence.id)).type;

		if (incidenceType === "INSERT_MARKINGS") {
			const { start_date, device_id } = values;
			if (!start_date || !startHour || !device_id || device_id === '') {
				toast.error("Rellene los campos obligatorios")
				return;
			}

		} else if (incidencesWithHour.find(item => item === incidenceType)) {
			const { start_date, end_date } = values;
			if (!endHour || !startHour || !start_date || !end_date) {
				toast.error("Los campos de fecha son obligatorios para esta incidencia")
				return;
			}
		} else {
			const { start_date, end_date } = values;
			if (!start_date || !end_date) {
				toast.error("Los campos de fecha son obligatorios para esta incidencia")
				return;
			}
		}

		callEdit({
			...values,
			id: id,
			start_hour: startHour,
			end_hour: endHour
		})

	}

	const onCancel = () => {
		formikRef.current.setValues({})
		setStartHour(null);
		setEndHour(null);
		setIncidencesRequest({});
		setKeyEmployee("")
		setIsVisible(false);
	}

	return (
		<View
			setIsValidEmployee={setIsValidEmployee}
			incidencesWithHour={incidencesWithHour}
			formikRef={formikRef}
			devices={devices}
			incidences={incidences}
			onSearchEmployee={onSearchEmployee}
			onSubmit={onSubmit}
			onCancel={onCancel}
			setKeyEmployee={setKeyEmployee}
			setStartHour={setStartHour}
			setEndHour={setEndHour}
			endHour={endHour}
			startHour={startHour}
			incidenceRequest={incidenceRequest}
		/>
	);
}

export default RequestForm;