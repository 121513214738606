import React from "react"

function DeviceEmployeeListView({ devices = [], onEdit, theme }) {
	return (
		<div class={"table-responsive datatable-custom position-relative"}>
			<div class="dataTables_wrapper no-footer pt-2 pl-3 pr-3" style={{maxHeight: "60vh"}}>
				<table class="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
					<thead>
						<tr role="row">
                            <th>Estado</th>
                            <th>Sucursal</th>
							<th>Número de serie</th>
							<th>Descripción</th>
							<th>Usuarios</th>
                            <th>Huellas</th>
                            <th>Rostros</th>
                            <th>FaceAI</th>
                            <th>Tarjetas</th>
                            <th>Acciones</th>
						</tr>
					</thead>
					<tbody>
                        {
                            devices.map((device) => 
                                <tr key={device.id} role="row">
                                    <td>
                                        {device.connected ? <span className="badge bg-success">Conectado</span> : <span className="badge bg-danger">Desconectado</span>}
                                    </td>
                                    <td>
                                        {device.office ? device.office.key : "N/A"}
                                    </td>
                                    <td>
                                        {device.serial_number}
                                    </td>
                                    
                                    <td>
                                        {device.description}
                                    </td>
                                    <td>
                                        {device.employee_count}
                                    </td>
                                    <td>
                                        {device.fingerprint_count}
                                    </td>
                                    <td>
                                        {device.face_count}
                                    </td>
                                    <td>
                                        {device.faceai_count}
                                    </td>
                                    <td>
                                        {device.card_count}
                                    </td>
                                    <td>
                                        <button className="btn btn-warning" style={{ marginRight: "10px" }} onClick={() => onEdit(device.id)}>
                                            <i class="bi bi-pencil" style={{marginRight: "7px"}}></i>
                                            Editar
                                        </button>
                                    </td>
                                </tr>
                            )
                        }
					</tbody>
				</table>
			</div>
		</div>
	)
}

export default DeviceEmployeeListView