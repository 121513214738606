import React, { useRef, useState, useEffect } from "react";
import View from "components/crh/plannings/Plannings.view";
import { downloadCSV, downloadExcel } from "utils/files";
import { fetchPost } from "utils/api";
import { toast } from "react-toastify";
import { getUser } from "api/users";
import FormSave from "components/crh/plannings/FormSave";
import FormSet from "components/crh/plannings/FormSet";
import Details from "components/crh/plannings/Details";
import moment from "moment";
import { weekDays } from 'utils/constants';

function Plannings({ theme }) {

  const id = sessionStorage.getItem("id");
  const listRef = useRef(null);
  const reportRef = useRef(null);

  const [isVisible, setIsVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const [isVisibleEdit, setIsVisibleEdit] = useState(false);
  const [modalContentEdit, setModalContentEdit] = useState(null);

  const [isVisibleDetails, setIsVisibleDetails] = useState(false);
  const [modalContentDetails, setModalContentDetails] = useState(null);

  const [isVisibleLoad, setIsVisibleLoad] = useState(false);

  const [excel, setExcel] = useState(false);
  const [read, setRead] = useState(false);
  const [load, setLoad] = useState(false);
  const [create, setCreate] = useState(false);

  const [filterModal, setFilterModal] = useState(false);
  const [searchFilters, setSearchFilters] = useState({
    search: "",
    payroll_id: "",
  });

  const [user, setUser] = useState({});

  const setFilters = (values) => setSearchFilters(values);
  const showFilterModal = () => setFilterModal(true);
  const hideFilterModal = () => setFilterModal(false);

  const generateCells = (planning) => {

		let csv = [
			[
        {
          value: "ID Empleado",
          style: { bold: true },
        },
        {
          value: "ID Lector",
          style: { bold: true },
        },
        {
          value: "Nombre",
          style: { bold: true },
        },
        {
          value: "Apellido paterno",
          style: { bold: true },
        },
        {
          value: "Apellido materno",
          style: { bold: true },
        },
        {
          value: "Sucursal",
          style: { bold: true },
        },
        {
          value: "Departamento",
          style: { bold: true },
        },
        {
          value: "Puesto",
          style: { bold: true },
        },
        "",
        {
          value: "Lunes",
          style: { bold: true },
          color: "#04b5fc",
          fontColor: "#ffffff"
        },
        {
          value: "Martes",
          style: { bold: true },
          color: "#04b5fc",
          fontColor: "#ffffff"
        },
        {
          value: "Miércoles",
          style: { bold: true },
          color: "#04b5fc",
          fontColor: "#ffffff"
        },
        {
          value: "Jueves",
          style: { bold: true },
          color: "#04b5fc",
          fontColor: "#ffffff"
        },
        {
          value: "Viernes",
          style: { bold: true },
          color: "#04b5fc",
          fontColor: "#ffffff"
        },
        {
          value: "Sábado",
          style: { bold: true },
          color: "#04b5fc",
          fontColor: "#ffffff"
        },
        {
          value: "Domingo",
          style: { bold: true },
          color: "#04b5fc",
          fontColor: "#ffffff"
        }
			]
		];

    planning?.personal_planning.forEach((personalPlanning) => {

      let auxRow = [];
      auxRow.push(personalPlanning.employee.key);
      auxRow.push(personalPlanning.employee.pin);
      auxRow.push(personalPlanning.employee.firstname);
      auxRow.push(personalPlanning.employee.dad_lastname);
      auxRow.push(personalPlanning.employee.mom_lastname ? personalPlanning.employee.mom_lastname : "");
      auxRow.push(personalPlanning.employee.office ? personalPlanning.employee.office.description : "");
      auxRow.push(personalPlanning.employee.department ? personalPlanning.employee.department.description : "");
      auxRow.push(personalPlanning.employee.job ? personalPlanning.employee.job.description : "");
      auxRow.push("");

      weekDays.forEach((weekDay) => {
          
        const dayPlanning = personalPlanning.day_planning.find(dayPlanning => dayPlanning.week_day === weekDay);

        if(dayPlanning?.schedule)
          auxRow.push(dayPlanning.schedule.description);
        else
          auxRow.push("-");

      });

      csv.push(auxRow);

    });

    return csv;

	}

  const onDownloadXlsx = async (planning) => {

    fetchPost("/plannings/check_permission", { type: "excel" }).then((response) => {

      if(response.data) {

        const data = response.data;

        if(data.isAllowed === true) {
          const cells = generateCells(planning);
          return downloadExcel({
            user,
            cells: cells,
            sheetName: "Planeación semanal",
            report: `Planeacion semanal`,
            start_date: planning.payroll_period.start,
            end_date: planning.payroll_period.end
          });
        }

      }

      if (!toast.isActive("toast-excel-plannings-unauthorized")) {
        toast.error("Error, no tienes los permisos para descargar el excel", { toastId: "toast-excel-plannings-unauthorized" })
      }

    })

  }

  const addPlanningModal = () => {

    fetchPost("/plannings/check_permission", { type: "create" }).then((response) => {

      if(response.data) {

        const data = response.data;

        if(data.isAllowed === true) {
          setIsVisible(true);
          setModalContent(
            <FormSave
              setIsVisible={setIsVisible}
              refresh={Math.random()}
              theme={theme}
            />
          );
        } 
        else if(!toast.isActive("toast-create-plannings-unauthorized")) {
            toast.error("Error, no tienes los permisos para crear planeaciones semanales", { toastId: "toast-create-plannings-unauthorized" })
        }

      }

    })

  }

  const addBulkLoadModal = () => {
    fetchPost("/plannings/check_permission", { type: "load" }).then((response) => {
      if (response.data) {
        const data = response.data;
        if (data.isAllowed === true) {
          setIsVisibleLoad(true);
        } else {
          if (!toast.isActive("toast-load-plannings-unauthorized")) {
            toast.error("Error, no tienes los permisos para hacer carga masiva empleados", { toastId: "toast-load-plannings-unauthorized" })
          }
        }
      }
    })
  }

  const addEditPlanningModal = (id) => {
    setIsVisibleEdit(true);
    setModalContentEdit(
      <FormSet
        id={id}
        theme={theme}
        refresh={Math.random()}
        setIsVisible={setIsVisibleEdit}
      />
    );
  }

  const addDetailsModal = (id) => {
    setIsVisibleDetails(true);
    setModalContentDetails(
      <Details
        id={id}
        theme={theme}
        refresh={Math.random()}
        setIsVisible={setIsVisibleDetails}
        addEditPlanningModal={addEditPlanningModal}
      />
    );
  }

  const onChangeSearch = (event) => {
    if (event.target.value === '') {
      setFilters({ ...searchFilters, search: event.target.value });
    }
  }

  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      setFilters({ ...searchFilters, search: event.target.value });
    }
  }

  useEffect(() => {

    getUser(id).then((response) => {
      setUser(response.data)
    });

    fetchPost("/permissions/check", { table: "PLANNING", permission: "create" }).then((response) => {
      if (response.data) {
        const data = response.data;
        if (data.isAllowed === true) {
          setCreate(true);
        }
      }
    })

    fetchPost("/permissions/check", { table: "PLANNING", permission: "load" }).then((response) => {
      if (response.data) {
        const data = response.data;
        if (data.isAllowed === true) {
          setLoad(true);
        }
      }
    })

    fetchPost("/permissions/check", { table: "PLANNING", permission: "read" }).then((response) => {
      if (response.data) {
        const data = response.data;
        if (data.isAllowed === true) {
          setRead(true);
        }
      }
    })

    fetchPost("/permissions/check", { table: "PLANNING", permission: "excel" }).then((response) => {
      if (response.data) {
        const data = response.data;
        if (data.isAllowed === true) {
          setExcel(true);
        }
      }
    })

  }, [id]);

  return (
    <View
      excel={excel}
      read={read}
      load={load}
      create={create}
      user={user}
      reportRef={reportRef}
      onDownloadXlsx={onDownloadXlsx}
      listRef={listRef}
      showFilterModal={showFilterModal}
      hideFilterModal={hideFilterModal}
      filterModal={filterModal}
      setFilterModal={setFilterModal}
      setFilters={setFilters}
      searchFilters={searchFilters}
      onChangeSearch={onChangeSearch}
      handleKeyUp={handleKeyUp}
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      modalContent={modalContent}
      addPlanningModal={addPlanningModal}
      addEditPlanningModal={addEditPlanningModal}
      isVisibleEdit={isVisibleEdit}
      setIsVisibleEdit={setIsVisibleEdit}
      isVisibleDetails={isVisibleDetails}
      setIsVisibleDetails={setIsVisibleDetails}
      modalContentDetails={modalContentDetails}
      modalContentEdit={modalContentEdit}
      addDetailsModal={addDetailsModal}
      theme={theme}
      isVisibleLoad={isVisibleLoad}
      setIsVisibleLoad={setIsVisibleLoad}
      addBulkLoadModal={addBulkLoadModal}
    />
  );

}

export default Plannings;