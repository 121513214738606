import React from "react";

function ListView({ profiles, onDelete, onEdit, onAsignUsers }) {

	return (
		<div class="table-responsive datatable-custom position-relative" >
			<div class="dataTables_wrapper no-footer pt-2 pl-3 pr-3" style={{ maxHeight: "60vh" }}>
				<table class="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
					<thead>
						<tr role="row">
							<th>Clave</th>
							<th>Descripción</th>
							<th>Observaciones</th>
							<th>Acciones</th>
						</tr>
					</thead>
					<tbody>
						{
							profiles.map((profile) => (
								<tr key={profile.id} role="row">
									<td>{profile.key}</td>
									<td>{profile.description}</td>
									<td>{profile.observation}</td>
									<td>
										<div class="d-flex">
											<button className="btn btn-primary py-1" style={{ marginRight: "10px" }} onClick={() => onAsignUsers(profile.id)}>
												<i class="bi bi-person-circle"></i>
											</button>
											<button className="btn btn-warning py-1" style={{ marginRight: "10px" }} onClick={() => onEdit(profile.id)}>
												<i class="bi bi-pencil"></i>
											</button>
											<button className="btn btn-danger py-1" onClick={() => onDelete(profile.id)}>
												<i class="bi bi-trash"></i>
											</button>
										</div>
									</td>
								</tr>
							))
						}
					</tbody>
				</table>
			</div>
		</div>

	);
}

export default ListView;