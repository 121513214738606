import React from "react";
import "resources/styles/Home.scss";
import AboutToday from "./dashboard/AboutToday";
import Summary from "./dashboard/Summary";
import AttendanceMap from "./dashboard/AttendanceMap";
import EntriesExits from "./dashboard/EntriesExits";
import WorkedHours from "./dashboard/WorkedHours";
import IncidencesChart from "./dashboard/IncidencesChart";
import AttendanceRanking from "./dashboard/AttendanceRanking";
import AttendanceChart from "./dashboard/AttendanceChart";
import Alerts from "./dashboard/Alerts";

function HomeView({
	setFilters,
	payrollPeriods,
	payrolls,
	offices,
	jobs,
	departments,
	shifts,
	loading,
	handleRefresh,
	filters = {},
	lastUpdate,
	refreshValue,
	globalLoading,
	setGlobalLoading
}) {
	return (<>

		<div className="m-3 mb-5">
			<div className="d-flex mb-4">
				<div className="d-flex me-4" style={{ width: "45%" }}>
					<div className="card card-body p-0 d-flex flex-column h-100">
						<AboutToday 
							lastUpdate={lastUpdate}
							filters={filters}
							refreshValue={refreshValue}
							handleRefresh={handleRefresh}
							globalLoading={globalLoading}
							setGlobalLoading={setGlobalLoading}
						/>
					</div>
				</div>
				<div className="d-flex flex-column w-100">
					<div className="row mb-4">
						<div className="col">
							<div className="card card-body p-0">
								<div className="d-flex flex-column justify-content-center align-items-center w-100 p-0">

									<div className="d-flex">
										<div className="py-1 px-1 mx-1 text-center" style={{}}>
											<span className="h6 fw-bold mt-1 text-primary">Filtros de búsqueda</span>
										</div>
									</div>

									<div className="d-flex flex-fill w-100">

										<div className="border-end border-top py-1 px-1" style={{}}>
											<select 
												className="text-primary form-select border-0" 
												style={{ fontSize: "0.7rem" }}
												value={filters.payroll_id || ""}
												onChange={(e) => {
													setFilters({
														...filters,
														payroll_id: e.target.value
													})
												}}
											>
												<option value="">Todos los tipos de nómina</option>
												{
													payrolls.map((payroll, index) => (
														<option key={index} value={payroll.id}>{payroll.description}</option>
													))
												}
											</select>
										</div>

										<div className="border-start border-end border-top py-1 px-1" style={{}}>
											<select 
												className="text-primary form-select border-0" 
												style={{ fontSize: "0.7rem" }}
												value={filters.payroll_period_id || ""}
												onChange={(e) => {
													setFilters({
														...filters,
														payroll_period_id: e.target.value
													})
												}}
											>
												<option value="">Mes en curso</option>
												{
													payrollPeriods.map((payrollPeriod, index) => (
														<option key={index} value={payrollPeriod.id}>{payrollPeriod.description}</option>
													))
												}
											</select>
										</div>

										<div className="border-start border-end border-top py-1 px-1 flex-fill" style={{}}>
											<select 
												className="text-primary form-select border-0" 
												style={{ fontSize: "0.7rem" }}
												value={filters.office_id || ""}
												onChange={(e) => {
													setFilters({
														...filters,
														office_id: e.target.value
													})
												}}
											>
												<option value="">Todas las sucursales</option>
												{
													offices.map((office, index) => (
														<option key={index} value={office.id}>{office.description}</option>
													))
												}
											</select>
										</div>

										<div className="border-start border-end border-top py-1 px-1" style={{}}>
											<select 
												className="text-primary form-select border-0" 
												style={{ fontSize: "0.7rem" }}
												value={filters.job_id || ""}
												onChange={(e) => {
													setFilters({
														...filters,
														job_id: e.target.value
													})
												}}
											>
												<option value="">Todos los puestos</option>
												{
													jobs.map((job, index) => (
														<option key={index} value={job.id}>{job.description}</option>
													))
												}
											</select>
										</div>

										<div className="border-start border-top py-1 px-1" style={{}}>
											<select 
												className="text-primary form-select border-0" 
												style={{ fontSize: "0.7rem" }}
												value={filters.department_id || ""}
												onChange={(e) => {
													setFilters({
														...filters,
														department_id: e.target.value
													})
												}}
											>
												<option value="">Todos los departamentos</option>
												{
													departments.map((department, index) => (
														<option key={index} value={department.id}>{department.description}</option>
													))
												}
											</select>
										</div>

									</div>

								</div>
							</div>
						</div>
					</div>
					<div className="d-flex h-100">
						<div className="d-flex pe-4" style={{ width: "35%" }}>
							<div className="card card-body h-100 px-3 pt-0 pb-2">
								<Summary 
									filters={filters}
									setFilters={setFilters}
									shifts={shifts}
									refreshValue={refreshValue}
									globalLoading={globalLoading}
									setGlobalLoading={setGlobalLoading}
								/>
							</div>
						</div>
						<div className="d-flex flex-fill">
							<div className="card card-body p-0 h-100 w-100">
								<AttendanceMap 
									filters={filters}
									refreshValue={refreshValue}
									globalLoading={globalLoading}
									setGlobalLoading={setGlobalLoading}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="d-flex" style={{ height: "85vh" }}>
				<div className="d-flex me-4" style={{ height: "85vh", width: "45%" }}>
					<div className="card card-body rounded p-0 d-flex flex-column mb-0" style={{ overflowY: "auto", height: "85vh" }}>
						<EntriesExits 
							filters={filters}
							refreshValue={refreshValue}
							globalLoading={globalLoading}
							setGlobalLoading={setGlobalLoading}
						/>
					</div>
				</div>
				<div className="d-flex flex-column w-100" style={{ height: "85vh" }}>
					<div className="d-flex w-100" style={{ height: "40vh" }}>
						<div className="d-flex pe-4" style={{ height: "40vh", width: "35%" }}>
							<div className="card card-body rounded p-0 d-flex flex-column h-100" style={{ overflowY: "auto", height: "40vh" }}>
								<WorkedHours
									filters={filters}
									refreshValue={refreshValue}
									globalLoading={globalLoading}
									setGlobalLoading={setGlobalLoading}
								/>
							</div>
						</div>
						<div className="d-flex" style={{ height: "40vh", width: "30%" }}>
							<div className="card card-body rounded p-0 d-flex flex-column h-100">
								<IncidencesChart
									filters={filters}
									refreshValue={refreshValue}
									globalLoading={globalLoading}
									setGlobalLoading={setGlobalLoading}
								/>
							</div>
						</div>
						<div className="d-flex ps-4" style={{ height: "40vh", width: "35%" }}>
							<div className="card card-body rounded p-0 d-flex flex-column h-100" style={{ height: "50vh", overflowY: "auto" }}>
								<AttendanceRanking
									filters={filters}
									refreshValue={refreshValue}
									globalLoading={globalLoading}
									setGlobalLoading={setGlobalLoading}
								/>
							</div>
						</div>
					</div>
					<div className="d-flex pt-3 w-100" style={{ height: "45vh" }}>
						<div className="d-flex pt-2" style={{ width: "65%" }}>
							<div className="card card-body rounded p-0" style={{ height: "100%", overflowY: "hidden" }}>
								<AttendanceChart
									filters={filters}
									refreshValue={refreshValue}
									globalLoading={globalLoading}
									setGlobalLoading={setGlobalLoading}
								/>
							</div>
						</div>
						<div className="d-flex pt-2 ps-4" style={{ width: "35%" }}>
							<div className="card card-body rounded p-0" style={{ height: "100%", overflowY: "auto" }}>
								<Alerts 
									filters={filters}
									refreshValue={refreshValue}
									globalLoading={globalLoading}
									setGlobalLoading={setGlobalLoading}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	</>)
}


export default HomeView;