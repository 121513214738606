import React from "react";
import moment from "moment";

function ListView({ employees, incidences, daysLength, currentMonths, setIsVisible }) {
	return (
		<>
			<div class="table-responsive datatable-custom" style={{ overflow: "auto", maxHeight: "70vh" }} >
				<table class="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
					<thead style={{ position: "sticky", top: "-3px", zIndex: 1 }}>
						<tr role="row">
							<th>Incidencias</th>
							{
									Array.from(Array(daysLength).keys()).map((item) => (
											<th key={item}>
												{item + 1}
											</th>
									))
							}
						</tr>
					</thead>
					<tbody>
						{
								incidences.map((item, index) => (
										<tr key={"incidence-" + index}>
												<td>{currentMonths[index]}</td>
												{
														item.map((item) => (
																<td key={item}>{item}</td>
														))
												}
										</tr>
								))
						}
					</tbody>
				</table>
			</div>
			<div class="mt-3">
				<button type="button" class="btn btn-primary" style={{ marginLeft: "20px" }} onClick={() => setIsVisible(false)}>
					<i class="bi bi-x" style={{ marginRight: "10px" }}></i>
					Cerrar
				</button>
			</div >
		</>

	);
}

export default ListView;