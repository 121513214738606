import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { getIncidences } from "api/incidences";
import { getIncidenceRequest } from "api/incidencesRequests";
import { getDevices } from "api/devices";
import { toast } from "react-toastify";
import { LoadingContext } from "helpers/LoadingContext";
import View from "components/crh/reports/incidences/IncidenceRequest.view";
import Loading from "helpers/Loading";


function IncidenceRequest({ refresh, id, hideModal }) {

	const incidencesWithHour = [
		"PERMISSION_RELEASE_EARLY",
		"PERMISSION_ARRIVE_LATE",
		"PERMISSION_LEAVE_HOURS",
		"HOURLY_VACATIONS"
	]
	const { loading, setLoading } = useContext(LoadingContext);
	const [incidenceRequest, setIncidencesRequest] = useState({});
	const [refreshValue, setRefreshValue] = useState(null);

	if (refreshValue !== refresh) {
		setRefreshValue(refresh)
	}

	useEffect(() => {
		getIncidenceRequest(id).then(response => {
			if (response.data) {
				setIncidencesRequest(response.data);
			} else {
				toast.error("Error al cargar la solicitud de incidencias")
			}
		})

	}, [refreshValue, id])

	const onCancel = () => {
		setIncidencesRequest({});
		hideModal();
	}


	return (
		<View
			incidencesWithHour={incidencesWithHour}
			incidenceRequest={incidenceRequest}
			onCancel={onCancel}
		/>
	);
}

export default IncidenceRequest;