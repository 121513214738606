const getJSON = (key, defaultValue) => {

    try {
        const jsonValue = sessionStorage.getItem(key);
        return jsonValue ? JSON.parse(jsonValue) : defaultValue;
    }
    catch (e) {
    }

    return defaultValue;

}

export {
    getJSON
}