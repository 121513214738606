import React from "react";
import View from "components/home/tables/ListIncidencesChart.view";
import moment from "moment";
import { months } from "utils/constants";

function List({ employees, incidences = [], setIsVisible }) {

    const length = 3
    const data = {}
    const currentMonths = []

    incidences.forEach((item) => {
        Object.keys(item).forEach((key) => {
            if(!data[key]) data[key] = []
            data[key].push(item[key])
        })
    })

    const currentMonth = moment().month()
    for(let i = 0; i < length; i++) 
        currentMonths.push(months[(currentMonth - i + 12) % 12])
    
    currentMonths.reverse()

	return (
		<View 
            employees={employees}
            currentMonths={currentMonths}
            incidences={data} 
            setIsVisible={setIsVisible} 
        />
	);

}

export default List;