

import React, { useEffect, useState, useRef } from "react";
import { useGetCall } from "utils/api";
import View from "components/geo/attendance/List.view";

function GeoAttendanceLogs() {

  const [isDetailsVisible, setIsDetailsVisible] = useState(false)
  const [detailsData, setDetailsData] = useState({})
  const [attendanceLogs, setAttendanceLogs] = useState([])

  const [callAttendanceLogs, reqAttendanceLogs] = useGetCall("/attendance_logs/latest/geo", {
    onCompleted: (response) => {
      setAttendanceLogs(response.data.attendanceLogs);
    },
    onError: (error) => {
    }
  })

  useEffect(() => {
    callAttendanceLogs()
  }, [])

  return (
    <View
      attendanceLogs={attendanceLogs}
      detailsData={detailsData}
      isDetailsVisible={isDetailsVisible}
      setDetailsData={setDetailsData}
      setIsDetailsVisible={setIsDetailsVisible}
    />
  );
}

export default GeoAttendanceLogs;