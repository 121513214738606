import React from "react"

function EmployeesListView({ deleteLoading, employees = [], onEdit, onDelete, theme, userType }) {
	return (
		<div class={"table-responsive datatable-custom position-relative report-content"}>
			<div class="dataTables_wrapper no-footer pt-2 pl-3 pr-3">
				<table class="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
					<thead>
						<tr role="row">
							<th>ID Empleado</th>
							<th>ID en Lector</th>
							<th>Nombre</th>
							<th>Correo</th>
							<th>Puesto</th>
							<th>Departamento</th>
							<th>Grupo</th>
							<th className="report-actions">Acciones</th>
						</tr>
					</thead>
					<tbody>
						{
							employees.map((employee) => (
								<tr key={employee.id} role="row">
									<td>
										{employee.key}
									</td>
									<td>
										{employee.pin}
									</td>
									<td>
										{employee.firstname} {employee.dad_lastname} {employee.mom_lastname}
									</td>
									<td>
										{employee.email}
									</td>
									<td>
										{employee.job ? employee.job.description : ""}
									</td>
									<td>
										{employee.department ? employee.department.description : ""}
									</td>
									<td>
										{employee.group ? employee.group.description : ""}
									</td>
									<td className="report-actions">
										<div class="d-flex">
											<button className="btn btn-warning py-1" style={{ marginRight: "10px" }} onClick={() => onEdit(employee.id)}>
												<i class="bi bi-pencil"></i>
											</button>
											{
												userType == "SUPERADMIN" && (
													<button className="btn btn-danger py-1" onClick={() => {
														if(deleteLoading) return
														onDelete(employee.id)
													}}>
														{
															deleteLoading ? (
																<div class="spinner-border spinner-border-sm" role="status">
																	<span class="visually-hidden">Loading...</span>
																</div>
															) : (
																<i class="bi bi-trash"></i>
															)
														}
													</button>
												)
											}
										</div>
									</td>

								</tr>
							))
						}
					</tbody>
				</table>
			</div>
		</div>
	)
}

export default EmployeesListView