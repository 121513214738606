import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { useDelete, useGetCall, fetchPost } from "utils/api";
import Loading from "helpers/Loading";
import { LoadingContext } from "helpers/LoadingContext";
import View from "components/crh/schedules/List.view";

function List({
	search,
	refresh,
	addEditModal
}) {

	const { loading, setLoading } = useContext(LoadingContext);

	const [schedules, setSchedules] = useState([]);
	const [refreshValue, setRefreshValue] = useState(null);
	const [refreshDelete, setRefreshDelete] = useState(null);

	if (refresh !== refreshValue) {
		setRefreshValue(refresh);
	}

	const [callSchedules, reqSchedules] = useGetCall("/schedules", {
		onCompleted: (response) => {
			setLoading(false);
			setSchedules(response.data)
		},
		onError: (error) => {
			setLoading(false);
			if (error.status === 403) {
				if (!toast.isActive("toast-read-schedules-unauthorized")) {
					toast.error("Error, no tienes los permisos para consultar horarios", { toastId: "toast-read-schedules-unauthorized" })
				}
			} else {
				if (!toast.isActive("toast-schedules-error")) {
					toast.error("Error al obtener los horarios", { toastId: "toast-schedules-error" })
				}
			}
		}
	})

	const [callDelete, reqDelete] = useDelete("/schedules", {
		onCompleted: () => {
			if (!toast.isActive("schedule-deleted")) {
				toast.success("Horario eliminado", { toastId: "schedule-deleted" });
			}
			setRefreshDelete(Math.random());
		},
		onError: (error) => {
			console.error(error)
			if (error.status === 403) {
				if (!toast.isActive("toast-delete-schedule-unauthorized")) {
					toast.error("Error, no tienes los permisos para borrar horarios", { toastId: "toast-delete-schedule-unauthorized" })
				}
			} else {
				if (!toast.isActive("toast-schedule-error")) {
					toast.error("Error al eliminar el horario", { toastId: "toast-schedule-error" })
				}
			}
		}
	});

	useEffect(() => {
		setLoading(true);
		callSchedules({ search });
	}, [refreshDelete, refreshValue, search]);

	const onDelete = (id) => {
		fetchPost("/schedules/check_permission", { type: "delete" }).then((response) => {
			if (response.data) {
				const data = response.data;
				if (data.isAllowed === true) {
					if (window.confirm("¿Estás seguro de eliminar el horario?")) {
						callDelete({ id: id });
					}
				} else {
					if (!toast.isActive("toast-delete-schedule-unauthorized")) {
						toast.error("Error, no tienes los permisos para eliminar horarios", { toastId: "toast-delete-schedule-unauthorized" })
					}
				}
			}
		})
	}

	const onEdit = (id) => {
		addEditModal(id);
	}

	if (loading) return <Loading />

	return (
		<View schedules={schedules} onDelete={onDelete} onEdit={onEdit} />
	);
}

export default List;