import React, { useState, useEffect, useContext } from "react";
import { useGetCall, usePost } from "utils/api";
import { showResponseMessage } from "utils/message";
import { LoadingContext } from "helpers/LoadingContext";
import Loading from "helpers/Loading";
import View from "components/crh/visits/requests/List.view";
import { toast } from "react-toastify";
import moment from "moment";
import { fetchPost } from "utils/api";
import FormSet from "components/crh/visits/requests/FormSet";

function RequestsList({
	search,
	refresh,
}) {

	const { loading, setLoading } = useContext(LoadingContext);

	const [requests, setRequests] = useState([]);	
	const [profiles, setProfiles] = useState([]);
	const [refreshValue, setRefreshValue] = useState(null);
	const [refreshDelete, setRefreshDelete] = useState(null);
	const [selectedRequest, setSelectedRequest] = useState(null);

	const [isVisibleEdit, setIsVisibleEdit] = useState(false);
  const [modalTitleEdit, setModalTitleEdit] = useState("");
  const [modalContentEdit, setModalContentEdit] = useState(null);

	if(refreshValue !== refresh) {
		setRefreshValue(refresh);
	}

	const [callProfiles, reqProfiles] = useGetCall("/visit_profiles", {
		onCompleted: (response) => {
			setLoading(false);
			setProfiles(response.data)
		},
		onError: (error) => {
			setLoading(false);
			showResponseMessage(error.status, "visit_profiles", "submarcas", "read");
		}
	});

	const [callRequests, reqRequests] = useGetCall("/visit_requests", {
		onCompleted: (response) => {
			setLoading(false);
			setRequests(response.data)
		},
		onError: (error) => {
			setLoading(false);
			showResponseMessage(error.status, "visit_requests", "solicitudes de visita", "read");
		}
	});

	const [callAuth, reqAuth] = usePost("/visit_requests/auth", {
		onCompleted: (response) => {
			
			if(!toast.isActive("auth") && response.data.status === "AUTH")
				toast.success("La solicitud ha sido autorizada", { toastId: "auth" });
			else if(!toast.isActive("auth") && response.data.status === "REJECTED")
				toast.success("La solicitud ha sido cancelada", { toastId: "auth" });

			callRequests({ search, origin: "PREREGISTER" });
			setSelectedRequest(null);

		},
		onError: (error) => {
			setLoading(false);
			showResponseMessage(error.status, "visit_requests", "solicitudes de visita", "read");
		}
	});

	

	useEffect(() => {
		setLoading(true);
		callRequests({ search, origin: "PREREGISTER" });
		callProfiles({});
	}, [search, refreshValue, refreshDelete]);

	const onSelectRequest = (request) =>
		setSelectedRequest(request);

	const onAuth = (id, values) => 
		callAuth({ 
			visit_request_id: id, 
			status: "AUTH", 
			auth_date: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
			...values 
		});

  const onCancel = (id) => 
		callAuth({ 
			visit_request_id: id, 
			auth_date: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
			status: "REJECTED" 
		});

		const addEditModal = (id) => {
			fetchPost("/permissions/check", { table: "VISIT_REGISTER", permission: "edit" }).then((response) => {
				if (response.data) {
					const data = response.data;
					if (data.isAllowed === true) {
						setIsVisibleEdit(true);
						setModalTitleEdit("Editar perfil");
						setModalContentEdit(
							<FormSet
								id={id}
								refresh={Math.random()}
								setIsVisible={setIsVisibleEdit}
							/>
						);
					} else {
						if (!toast.isActive("toast-edit-profile-unauthorized")) {
							toast.error("Error, no tienes los permisos para editar visitas", { toastId: "toast-edit-visit-unauthorized" })
						}
					}
				}
			})
		}

	if (loading) return <Loading />

	return (
		<View 
      requests={requests}
			profiles={profiles}
			selectedRequest={selectedRequest}
			onSelectRequest={onSelectRequest}
      onAuth={onAuth}
      onCancel={onCancel}
			addEditModal={addEditModal}
			isVisibleEdit={isVisibleEdit}
			setIsVisibleEdit={setIsVisibleEdit}
			modalTitleEdit={modalTitleEdit}
			modalContentEdit={modalContentEdit}
		/>
	);
}

export default RequestsList;