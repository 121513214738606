import React, { useState, useEffect, useRef } from "react";
import { usePost, useGetCall } from "utils/api";
import { toast } from "react-toastify";
import { showResponseMessage } from "utils/message";
import { useGeolocation } from "utils/geolocation";
import View from "components/geo/incidenceRequests/Form.view.";

function IncidenceRequests() {

    const formikRef = useRef(null);
	const incidencesWithHour = [
		"PERMISSION_RELEASE_EARLY",
		"PERMISSION_ARRIVE_LATE",
		"PERMISSION_LEAVE_HOURS"
	]
	const [refreshValue, setRefreshValue] = useState(null);
	const [incidences, setIncidences] = useState([]);
	const [devices, setDevices] = useState([]);
	const [employees, setEmployees] = useState([]);
	const [startHour, setStartHour] = useState(null);
	const [endHour, setEndHour] = useState(null);
	const coords = useGeolocation();

	const [callSave, reqSave] = usePost("/incidences_requests", {
		onCompleted: (data) => {

			if(data.data?.folio != null && data.data?.folio !== undefined) {
				if(!toast.isActive("incidence_request-duplicated"))
					toast.error(`Error, ya existe una solicitud de incidencia SI - ${data.data.folio} de ese tipo y en ese rango de fechas/horas`, { toastId: "incidence_request-duplicated" })

			} 
            else {

				if(!toast.isActive("incidence_request-created"))
					toast.success("Solicitud de incidencia creada correctamente", { toastId: "incidence_request-created" })

				formikRef.current.setValues({})
				setStartHour(null)
				setEndHour(null)

			}
		},
		onError: (err) => {
			console.error(err)
			showResponseMessage(err.status, "incidence_request", "solicitudes de incidencias", "create");
		}
	})

	const [callIncidences, reqIncidences] = useGetCall('/incidences/geo', {
		onCompleted: (response) => {
			setIncidences(response.data);
		}, onError: (error) => {
			showResponseMessage(error.status, "incidences", "incidencias", "read")
		}
	})

	const onSubmit = (values) => {

        const employee_key = sessionStorage.getItem("employee_key");
		const { incidence } = values;

		if (!employee_key || !incidence || incidence.id === '') {
			toast.error("Rellene los campos obligatorios")
			return;
		}

		const incidenceType = incidences.find((item) => item.id === parseInt(incidence.id)).type;

		if (incidenceType === "INSERT_MARKINGS") {
			const { start_date, device } = values;
			if (!start_date || !startHour || !device || device.id === '') {
				toast.error("Rellene los campos obligatorios")
				return;
			}

		} else if (incidencesWithHour.find(item => item === incidenceType)) {
			const { start_date, end_date } = values;
			if (!endHour || !startHour || !start_date || !end_date) {
				toast.error("Los campos de fecha son obligatorios para esta incidencia")
				return;
			}
		} else {
			const { start_date, end_date } = values;
			if (!start_date || !end_date) {
				toast.error("Los campos de fecha son obligatorios para esta incidencia")
				return;
			}
		}

		callSave({
			...values,
			start_hour: startHour,
			end_hour: endHour,
			lat: coords?.latitude,
      		lng: coords?.longitude,
            is_geo: true,
            employee: {
                key: employee_key
            }
		})

	}

	useEffect(() => {
		callIncidences({ request_geo: true });
	}, [refreshValue])

	return (
		<View
			incidencesWithHour={incidencesWithHour}
			formikRef={formikRef}
			devices={devices}
			employees={employees}
			incidences={incidences}
			onSubmit={onSubmit}
			setStartHour={setStartHour}
			setEndHour={setEndHour}
			endHour={endHour}
			startHour={startHour}
		/>
	);

}

export default IncidenceRequests;