import React, { useState, useEffect } from "react"
import { useGetCall } from "utils/api";
import { toast } from "react-toastify"
import View from "components/home/dashboard/WorkedHours.view"
import { getJSON } from "utils/sessionStorage";

function WorkedHours({ filters, refreshValue, globalLoading, setGlobalLoading }) {

	const [loading, setLoading] = useState(false)
	const [employees, setEmployees] = useState(getJSON("workedHoursEmployees", {}))
	const [workedHoursMean, setWorkedHoursMean] = useState(getJSON("workedHoursMean", {}))
	const [isWeeklyHoursVisible, setIsWeeklyHoursVisible] = useState(false)
	const [isMonthlyHoursVisible, setIsMonthlyHoursVisible] = useState(false)
	const [isRestHoursVisible, setIsRestHoursVisible] = useState(false)
	const [isExtraHoursVisible, setIsExtraHoursVisible] = useState(false)

	const [callDashboard, reqDashboard] = useGetCall("/statistics/worked_hours_mean", {
		onCompleted: (response) => {
			setEmployees(response?.data?.employees??{})
			setWorkedHoursMean(response?.data?.hours??{})
			setLoading(false)
			setGlobalLoading({ ...globalLoading, workedHours: false })
		},
		onError: (error) => {
			setLoading(false);
			if (error.status === 403) {
				if (!toast.isActive("toast-read-xxxxx-unauthorized")) {
					toast.error("Error, no tienes los permisos para consultar xxxxxxxx", { toastId: "toast-read-xxxxx-unauthorized" })
				}
			} else {
				if (!toast.isActive("toast-xxxxx-error")) {
					toast.error("Error al obtener ", { toastId: "toast-xxxxx-error" })
				}
			}
		}
	})

	useEffect(() => {
		try{ 
			sessionStorage.setItem("workedHoursMean", JSON.stringify(workedHoursMean)) 
			sessionStorage.setItem("workedHoursEmployees", JSON.stringify(employees))
		}
		catch(e) { console.log(e) }
	}, [workedHoursMean, employees])

    useEffect(() => {
			setLoading(true)
			setGlobalLoading({ ...globalLoading, workedHours: true })
			callDashboard({ ...filters })
    }, [refreshValue])

	return (
		<View
			loading={loading}
			employees={employees}
			workedHoursMean={workedHoursMean}
		
			isWeeklyHoursVisible={isWeeklyHoursVisible}
			isMonthlyHoursVisible={isMonthlyHoursVisible}
			isRestHoursVisible={isRestHoursVisible}
			isExtraHoursVisible={isExtraHoursVisible}
		
			setIsWeeklyHoursVisible={setIsWeeklyHoursVisible}
			setIsMonthlyHoursVisible={setIsMonthlyHoursVisible}
			setIsRestHoursVisible={setIsRestHoursVisible}
			setIsExtraHoursVisible={setIsExtraHoursVisible}
		
		/>
	)

}

export default WorkedHours