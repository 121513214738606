import React, { useState, useEffect, useRef } from "react";
import FormSave from "components/crh/devices/FormSave";
import FormSet from "components/crh/devices/FormSet";
import Biophotos from "components/crh/devices/Biophotos";
import { fetchPost } from "utils/api";
import { toast } from "react-toastify";
import View from "components/crh/devices/Devices.view";

function Devices({ company = {}, name = null }) {

  const [isVisible, setIsVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const countRef = useRef(null);
  const [create, setCreate] = useState(false);

  const [isVisibleEdit, setIsVisibleEdit] = useState(false);
  const [modalTitleEdit, setModalTitleEdit] = useState("");
  const [modalContentEdit, setModalContentEdit] = useState(null);

  const [isVisibleBiophotos, setIsVisibleBiophotos] = useState(false);
  const [modalTitleBiophotos, setModalTitleBiophotos] = useState("");
  const [modalContentBiophotos, setModalContentBiophotos] = useState(null);

  const [search, setSearch] = useState("");

  useEffect(() => {
    fetchPost("/permissions/check", { table: "DEVICE", permission: "create" }).then((response) => {
      if (response.data && response.data.isAllowed === true) {
        setCreate(true);
      }
    })
  }, [])

  const addDeviceModal = () => {
    fetchPost("/devices/check_permission", { type: "create" }).then((response) => {
      if (response.data) {
        const data = response.data;
        if (data.isAllowed === true) {
          setIsVisible(true);
          setModalTitle("Dispositivo");
          setModalContent(
            <FormSave
              company={company}
              refresh={Math.random()}
              setIsVisible={setIsVisible}
            />
          );
        } else {
          if (!toast.isActive("toast-create-device-unauthorized")) {
            toast.error("Error, no tienes los permisos para crear dispositivos", { toastId: "toast-create-device-unauthorized" })
          }
        }
      }
    })
  }

  const addEditDeviceModal = (id) => {
    fetchPost("/devices/check_permission", { type: "edit" }).then((response) => {
      if (response.data) {
        const data = response.data;
        if (data.isAllowed === true) {
          setIsVisibleEdit(true);
          setModalTitleEdit("Editar dispositivo");
          setModalContentEdit(
            <FormSet
              id={id}
              refresh={Math.random()}
              company={company}
              setIsVisible={setIsVisibleEdit}
            />
          );
        } else {
          if (!toast.isActive("toast-edit-device-unauthorized")) {
            toast.error("Error, no tienes los permisos para editar dispositivos", { toastId: "toast-edit-device-unauthorized" })
          }
        }
      }
    })
  }

  const addBiophotosModal = (id) => {
    setIsVisibleBiophotos(true);
    setModalTitleBiophotos("Fotos registradas");
    setModalContentBiophotos(
      <Biophotos
        id={id}
        refresh={Math.random()}
        setIsVisible={setIsVisibleBiophotos}
      />
    );
  }

  const onChangeSearch = (event) => {
    if (event.target.value === '') {
      setSearch(event.target.value);
    }
  }

  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      setSearch(event.target.value);
    }
  }


  return (
    <View
      countRef={countRef}
      search={search}
      create={create}
      onChangeSearch={onChangeSearch}
      handleKeyUp={handleKeyUp}
      company={company}
      name={name}
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      modalTitle={modalTitle}
      modalContent={modalContent}
      addDeviceModal={addDeviceModal}
      addEditDeviceModal={addEditDeviceModal}
      addBiophotosModal={addBiophotosModal}
      modalTitleEdit={modalTitleEdit}
      isVisibleEdit={isVisibleEdit}
      setIsVisibleEdit={setIsVisibleEdit}
      modalContentEdit={modalContentEdit}
      isVisibleBiophotos={isVisibleBiophotos}
      setIsVisibleBiophotos={setIsVisibleBiophotos}
      modalTitleBiophotos={modalTitleBiophotos}
      modalContentBiophotos={modalContentBiophotos}
    />
  );

}

export default Devices;