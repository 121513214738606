import React from "react";
import moment from "moment";
import { getNameIncidence } from "utils/constants";

function ListView({ incidencesRequests }) {
	return (
        <div className="content container-fluid py-4">
            <div className="page-header mt-5 m-0 p-0 w-100">
                <div className="pt-3">
                    <table class="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
                        <thead style={{ position: "sticky", top: "-3px", zIndex: 1 }}>
                            <tr role="row">
                                <th>Folio</th>
                                <th>Incidencia</th>
                                <th>Fecha</th>
                                <th>Estado</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                                incidencesRequests.map((incidencesRequest, index) =>
                                    <tr>
                                        <td> {incidencesRequest.folio} </td>
                                        <td> {getNameIncidence(incidencesRequest?.incidence.type??"")} </td>
                                        <td> {moment.utc(incidencesRequest.createdAt).format("DD-MM-YYYY")} </td>
                                        <td> {function(){
                                            if(incidencesRequest.status_request === "ACTIVE") return "Pendiente";
                                            if(incidencesRequest.status_request === "CANCELED") return "Cancelada";
                                            if(incidencesRequest.status_request === "AUTH_BOSS" || incidencesRequest.status_request === "AUTH_RH") return "Autorizada";
                                        }()} </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
	);
}

export default ListView;