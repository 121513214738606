import React, { useState, useEffect, useContext, useRef } from "react";
import { usePost, useGetCall, usePut } from "utils/api";
import { showResponseMessage } from "utils/message";
import View from "components/crh/visits/visitRequests/Form.view";
import moment from "moment";
import { toast } from "react-toastify";

function FormSet({
  id,
  refresh,
  setIsVisible = () => {},
}) {
  
  const formikRef = useRef();
  const [visitRequest, setVisitRequest] = useState({});
  const [departments, setDepartments] = useState([]);
  const [brands, setBrands] = useState([]);
  const [subBrands, setSubBrands] = useState([]);
  const [colors, setColors] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [reset, setReset] = useState(() => { });
  const [refreshValue, setRefreshValue] = useState(null);

  if(refresh !== refreshValue)
    setRefreshValue(refresh);

  const [callVisitRequest, reqVisitRequest] = useGetCall(`/visit_requests/${id}`, {
    onCompleted: (response) => {
      if(formikRef.current)
        formikRef.current.setValues({ ...response.data, _no_card: response.data.no_card });
      setVisitRequest(response.data);
    }, onError: (error) => {
      console.error(error)
      showResponseMessage(error.status, "requests", "solicitudes de visita", "edit")
    }
  })

  const [callUpdate, reqUpdate] = usePut("/visit_requests", {
    onCompleted: () => {

      if(!toast.isActive("request-updated"))
        toast.success("Visita actualizada correctamente", { toastId: "request-updated" });

      setIsVisible(false);
      reset();

    },
    onError: (error) => {
      console.error(error)
      showResponseMessage(error.status, "visit_requests", "solicitudes de visita", "create", "tarjeta");
    }
  })

  const [callDepartments, reqDepartments] = useGetCall("/departments", {
		onCompleted: (response) => {
			setDepartments(response.data)
		},
		onError: (error) => {
			showResponseMessage(error.status, "departments", "departamentos", "read")
		}
	})

  const [callBrands, reqBrands] = useGetCall("/brands", {
    onCompleted: (response) => {
      setBrands(response.data)
    },
    onError: (error) => {
      showResponseMessage(error.status, "brands", "marcas", "read")
    }
  })

  const [callSubBrands, reqSubBrands] = useGetCall("/sub_brands", {
    onCompleted: (response) => {
      setSubBrands(response.data)
    },
    onError: (error) => {
      showResponseMessage(error.status, "sub_brands", "submarcas", "read")
    }
  })

  const [callColors, reqColors] = useGetCall("/colors", {
    onCompleted: (response) => {
      setColors(response.data)
    },
    onError: (error) => {
      showResponseMessage(error.status, "colors", "colores", "read")
    }
  })

  const [callDocumentTypes, reqDocumentTypes] = useGetCall("/document_types", {
    onCompleted: (response) => {
      setDocumentTypes(response.data)
    },
    onError: (error) => {
      showResponseMessage(error.status, "document_types", "tipos de documento", "read")
    }
  })

  const [callProfiles, reqProfiles] = useGetCall("/visit_profiles", {
		onCompleted: (response) => {
			setProfiles(response.data)
		},
		onError: (error) => {
			showResponseMessage(error.status, "visit_profiles", "perfiles", "read");
		}
	});

  const onSubmit = (values, { resetForm }) => {

    if(!values.no_card) {
      if (!toast.isActive(`no-card`)) 
        toast.error(`Genere un QR para continuar`, { toastId: `no-card` });
      return;
    }

    values.id = id;
    setReset(() => () => resetForm({}));
    callUpdate({ ...values });

  }

  useEffect(() => {
    callVisitRequest({ });
    callDepartments({ });
    callBrands({ });
    callSubBrands({ });
    callColors({ });
    callDocumentTypes({ });
    callProfiles({ });
  }, [id, refreshValue]);

  return <View
    visitRequest={visitRequest}
    profiles={profiles}
    departments={departments}
    brands={brands}
    subBrands={subBrands}
    colors={colors}
    documentTypes={documentTypes}
    formikRef={formikRef}
    setIsVisible={setIsVisible}
    onSubmit={onSubmit}
  />;

}

export default FormSet;