import React, { useState, useEffect, useRef } from "react";
import { usePut } from "utils/api";
import { getPermission } from "api/permissions";
import { toast } from "react-toastify";
import { permissionTablesArray, permissionTypes } from "utils/constants";
import { showResponseMessage } from "utils/message";
import View from "components/crh/permissions/Form.view";

function FormSet({ id, setIsVisible, refresh }) {

    const formikRef = useRef(null);
    const [refreshValue, setRefreshValue] = useState(null);
    const [profile, setProfile] = useState({});
    const [permissions, setPermissions] = useState(permissionTablesArray.map((table) => {
        let types = {};
        permissionTypes.forEach((pType) => {
            types[pType] = false;
        });
        return {
            table: table,
            permissions: types
        }
    }));

    if (refresh !== refreshValue) {
        setRefreshValue(refresh);
    }

    const [callUpdate, reqUpdate] = usePut("/permissions", {
        onCompleted: () => {
            if (!toast.isActive("permission-updated"))
                toast.success("Perfil de permisos actualizado", { toastId: "permission-updated" });

            const newPermissions = permissionTablesArray.map((table) => {
                let types = {};
                permissionTypes.forEach((pType) => {
                    types[pType] = false;
                });
                return {
                    table: table,
                    permissions: types
                }
            })

            formikRef.current.setValues({});
            setPermissions(newPermissions)
            setIsVisible(false);
        },
        onError: (err) => {
            console.error(err)
            showResponseMessage(err.status, "permissions", "permisos", "edit");
        }
    })

    useEffect(() => {
        getPermission(id).then((response) => {
            if (response.data) {
                const data = response.data;
                setProfile(data.profile);
                setPermissions(data.data);
                formikRef.current.setValues(data.profile);
            }
        });
        formikRef.current.setValues({});
    }, [id, refreshValue])


    const onChangePermission = (table, permission) => {

        const newPermissions = [...permissions];
        let idxTableItem = newPermissions.findIndex((item) => item.table === table);
        if (idxTableItem === -1) {
            let newPermission = {};
            permissionTypes.forEach((pType) => {
                newPermission[pType] = false;
            });
            newPermissions.push({
                table: table,
                permissions: newPermission
            });
        }

        idxTableItem = newPermissions.findIndex((item) => item.table === table);

        if (permission === "access") {
            const prevValue = newPermissions[idxTableItem].permissions[permission];
            permissionTypes.forEach((type) => {
                newPermissions[idxTableItem].permissions[type] = !prevValue;
            })
        } else {
            const prevValue = newPermissions[idxTableItem].permissions[permission];
            newPermissions[idxTableItem].permissions[permission] = !prevValue;
        }

        setPermissions([...newPermissions])

    }

    const onSubmit = (values) => {

        for (let key in values)
            if (values[key] === "")
                values[key] = null

        values.data = permissions;

        const { key, description, observation } = values
        if (!key || !description || !observation) {
            toast.error("Rellene los campos obligatorios");
            return;
        }

        callUpdate(values);

    }

    const onCancel = () => {

        const newPermissions = permissionTablesArray.map((table) => {
            let types = {};
            permissionTypes.forEach((pType) => {
                types[pType] = false;
            });
            return {
                table: table,
                permissions: types
            }
        })

        formikRef.current.setValues({});
        setPermissions(newPermissions)
        setIsVisible(false);

    }

    return (
        <View
            formikRef={formikRef}
            edit={true}
            profile={profile}
            permissions={permissions}
            onSubmit={onSubmit}
            onCancel={onCancel}
            onChangePermission={onChangePermission}
        />
    )


}

export default FormSet;
