import React from "react";
import View from "components/home/tables/ListHours.view";

function List({ employees, incidences = [], setIsVisible, currentKey, totalKey, label }) {
	return (
		<View 
            employees={employees}
            incidences={incidences} 
            setIsVisible={setIsVisible} 
            currentKey={currentKey}
            totalKey={totalKey}
            label={label}
        />
	);
}

export default List;