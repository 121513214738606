import React from "react";
import "resources/styles/Home.scss"


function HomeAdminView() {
	return (
		<div>

		</div>
	)
}


export default HomeAdminView;