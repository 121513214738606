import React, { useState, useEffect, useContext, forwardRef, useImperativeHandle } from "react";
import { useGetCall, usePost } from "utils/api";
import { showResponseMessage } from "utils/message";
import { LoadingContext } from "helpers/LoadingContext";
import Loading from "helpers/Loading";
import View from "components/crh/visits/visitRequests/List.view";
import { toast } from "react-toastify";
import moment from "moment";

const RequestsList = forwardRef((props, ref) => {

	const {
		filters,
		search,
		refresh,
		addEditModal,
	} = props;

	const { loading, setLoading } = useContext(LoadingContext);

	const [requests, setRequests] = useState([]);	
	const [refreshValue, setRefreshValue] = useState(null);

	if(refreshValue !== refresh)
		setRefreshValue(refresh);

	const [callRequests, reqRequests] = useGetCall("/visit_requests", {
		onCompleted: (response) => {
			setLoading(false);
			setRequests(response.data)
		},
		onError: (error) => {
			setLoading(false);
			showResponseMessage(error.status, "visit_requests", "solicitudes de visita", "read");
		}
	});

	useImperativeHandle(ref, () => ({
		generateCells() {
			return generateCells()
		}
	}))

	const generateCells = () => {

		let csv = [
			[
				"Folio",
				"Tarjeta",
				"Nombre visitante",
				"A quién visita",
				"Departamento",
				"Perfil",
				"Documento de autenticación",
				"Teléfono",
				"Observaciones",
				"Automóvil",
				"Placas",
				"Marca",
				"Submarca",
				"Color",
				"Vigencia",
				"Estatus",
			]
		];

		requests.forEach((employee) => {
			csv.push([
				employee.folio,
				employee.no_card,
				employee.name,
				employee.host,
				employee?.department?.description,
				employee?.visit_profile?.description,
				employee?.document_type?.description,
				employee.phone,
				employee.comments,
				employee?.has_car ? "Si" : "No",
				employee.plates,
				employee?.brand?.key,
				employee?.sub_brand?.key,
				employee?.color?.key,
				`${moment.utc(`${employee?.start_date} ${employee?.start_hour}`).format("DD/MM/YYYY HH:mm")} - ${moment.utc(`${employee?.end_date} ${employee?.end_hour}`).format("DD/MM/YYYY HH:mm")}`,
				function(){
					const endDate = moment.utc(`${employee?.end_date} ${employee?.end_hour}`);
					const currentDate = moment.utc();
					if(endDate.isBefore(currentDate)) return "Vencida"
					else return "Vigente"
				}(),
			])
		})

		return csv;

	}

	useEffect(() => {
		setLoading(true);
		callRequests({ search, status: "AUTH", ...filters });
	}, [search, refreshValue]);

	if (loading) return <Loading />

	return (
		<View 
      requests={requests}
			addEditModal={addEditModal}
    />
	);

})

export default RequestsList;