import React from "react";

function TermsView() {
    return (
        <>
            <header id="header" class="header fixed-top d-flex align-items-center">
                <div class="container-fluid d-flex align-items-center justify-content-between" style={{ padding: "0 5rem" }}>

                    <a href="/" class="logo me-auto me-xl-0">
                        <img src="assets/img/control_logo_blanco_flecha_azul.png" alt="CRH Logo" style={{ width: "100%" }} />
                    </a>

                    {/* <nav id="navmenu" class="navmenu">
                        <i class="mobile-nav-toggle d-xl-none bi bi-list"></i>
                    </nav> */}

                    <a class="btn" style={{ backgroundColor: "#1aa9d0", color: "white", borderRadius: "10px" }} href="/">
                        Regresar
                    </a>

                </div>
            </header>

            <section id="hero" class="hero" style={{ color: "white" }}>
                <div class="container">
                    <h1 className="text-center">Aviso de Privacidad</h1>
                    <div className="mt-5" style={{ textAlign: "justify", textJustify: "inter-word" }}>
                        <p>
                            CHR Technologies S. de R.L. de C.V. con domicilio en (Calle Amaquemecan MZ 1 LT 286 Culhuacán
                            CTM Sección V CP: 04440 Coyoacán, CDMX, conforme a lo establecido en la Ley Federal de
                            Protección de Datos en Posesión de Particulares, pone a disposición de nuestros clientes,
                            proveedores, empleados y público en general, nuestro Aviso de Privacidad
                        </p>
                        <p>
                            Los datos personales que nos proporciona son utilizados estrictamente en la realización de
                            funciones propias de nuestra empresa y por ningún motivo serán transferidos a terceros.
                        </p>
                        <p>
                            A nuestros <b>clientes</b> les solicitamos los siguientes datos personales:
                        </p>
                        <p>
                            <ol type="I">
                                <li>Nombre, teléfono, correo electrónico y domicilio para facilitar el proceso de contratación
                                    de nuestro servicio y/o compra de nuestros productos.</li>
                                <li>Datos financieros como número de tarjeta de débito o crédito, nombre del titular, nombre
                                    de la institución bancaria, fecha de vencimiento y código de seguridad para efectuar los
                                    pagos de contratación y/o compra correspondientes.</li>
                                <li>RFC para completar los datos de facturación y realizar el proceso en cuestión.</li>
                            </ol>
                        </p>
                        <p>
                            A nuestros <b>proveedores</b> solicitamos los siguientes datos personales:
                        </p>
                        <p>
                            <ol type="I">
                                <li>Nombre, teléfono, correo electrónico y domicilio para facilitar el proceso de contratación
                                    de servicio y/o compra de productos.</li>
                            </ol>
                        </p>
                        <p>
                            A nuestros <b>empleados</b> solicitamos los siguientes datos personales:
                        </p>
                        <p>
                            <ol type="I">
                                <li>Nombre, teléfono, correo electrónico, domicilio, fecha y lugar de nacimiento.</li>
                                <li>Antecedentes laborales, puesto, sueldo y motivo de terminación laboral en los últimos cinco empleos. </li>
                            </ol>
                        </p>
                        <p>
                            En caso de realizar modificaciones al presente Aviso de Privacidad, le informaremos mediante
                            correo electrónico, nuestro sitio web oficial, medios impresos y nuestros operadores telefónicos.
                        </p>
                        <p>
                            <ul>
                                <li>Exclusiones. Este aviso de privacidad no se aplicará a ninguna información que no se haya
                                    solicitado y que usted nos proporcione a través de nuestros sitios o por otros medios</li>
                                <li>Información de contacto. Puede comunicarse con nosotros si tiene alguna pregunta
                                    relacionada con este Aviso de privacidad o con nuestras prácticas de información al teléfono
                                    5585397050, o envié un correo a admin@controlhr.com.mx, o por correo postal al
                                    domicilio: Calle Amaquemecan MZ 1 LT 286 Culhuacán CTM Sección V CP: 04440 Coyoacán,
                                    CDMX.
                                </li>
                            </ul>
                        </p>
                    </div>
                    <h3 className="mt-5 text-center">
                        Fecha de última modificación: 24 de junio de 2023
                    </h3>

                </div>

            </section>


        </>
    );
}

export default TermsView;