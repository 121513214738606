import { API_URL } from "settings";
import { getUrlencoded } from "utils/api";

function getCompany(id) {
    const url = `${API_URL}/companies/${id}`;
    const params = {
        method: "GET",
        headers: {
            "content-Type": "application/json",
            "x-auth-token": sessionStorage.getItem("token"),
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .catch((err) => {
            console.log(err);
            return null;
        });
}

function getCompanies(query = {}) {
    const url = `${API_URL}/companies?${getUrlencoded(query)}`;
    const params = {
        method: "GET",
        headers: {
            "content-Type": "application/json",
            "x-auth-token": sessionStorage.getItem("token"),
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .catch((err) => {
            console.log(err);
            return null;
        });
}

function getParameters() {

    const url = `${API_URL}/companies/parameters`;
    const params = {
        method: "GET",
        headers: {
            "content-Type": "application/json",
            "x-auth-token": sessionStorage.getItem("token"),
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .catch((err) => {
            console.log(err);
            return null;
        });

}

export { getCompany, getCompanies, getParameters };