import React, { useState, useEffect, useRef } from "react"
import { usePut, useGetCall } from "utils/api"
import { toast } from "react-toastify"
import { showResponseMessage } from "utils/message";
import View from "components/crh/holidaysTables/Form.view"

function FormSet({ id, refresh, setIsVisible }) {

    const [refreshValue, setRefreshValue] = useState(null)
    const fileInputRef = useRef()
    const formikRef = useRef()
    const [table, setTable] = useState({})
    const [contracts, setContracts] = useState([])
    const [payrolls, setPayrolls] = useState([])
    const [rows, setRows] = useState([])

    if (refresh !== refreshValue)
        setRefreshValue(refresh)

    const [callContracts, reqContracts] = useGetCall('/contracts', {
        onCompleted: (response) => {
            setContracts(response.data);
        }, onError: (error) => {
            showResponseMessage(error.status, "contracts", "contratos", "read")
        }
    })

    const [callPayrolls, reqPayrolls] = useGetCall('/payrolls', {
        onCompleted: (response) => {
            setPayrolls(response.data);
        }, onError: (error) => {
            showResponseMessage(error.status, "payrolls", "nóminas", "read")
        }
    })

    const [callHolidaysTable, reqHolidaysTable] = useGetCall(`/holiday_tables/${id}`, {
        onCompleted: (response) => {
            if (formikRef.current) formikRef.current.setValues(response.data)
            if (response.data) {
                setTable(response.data)
                setRows(response.data.rows)
            }
        }, onError: (error) => {
            showResponseMessage(error.status, "holiday_tables", "tablas de vacaciones", "read")
        }
    })

    const [callUpdate, reqUpdate] = usePut("/holiday_tables", {
        onCompleted: () => {

            if (!toast.isActive("toast-update-table"))
                toast.success("Tabla actualizada", { toastId: "toast-update-table" })

            setIsVisible(false)
            formikRef.current.setValues({})

        },
        onError: (err) => {
            console.error(err);
            showResponseMessage(err.status, "holiday_tables", "tablas de vacaciones", "edit");
        }
    })

    useEffect(() => {

        callContracts();
        callPayrolls();

    }, [refreshValue])

    useEffect(() => {

        callHolidaysTable()
        formikRef.current.setValues({})

    }, [id, refreshValue])

    const onAddRow = () => {
        setRows([...rows, {
            initial_year: 0,
            final_year: 0,
            amount: 0
        }])
    }

    const onRemoveRow = (idx) => {
        setRows(rows.filter((row, index) => index !== idx))
    }

    const onEditRow = (idx, field, value) => {
        const newRows = [...rows]
        newRows[idx][field] = value
        setRows(newRows)
    }

    const onSubmit = (values) => {
        values.id = id
        callUpdate({
            ...values,
            rows
        })
    }

    const onCancel = () => {
        setIsVisible(false)
        formikRef.current.setValues({})
    }

    return (
        <View
            fileInputRef={fileInputRef}
            formikRef={formikRef}
            edit={true}
            table={table}
            rows={rows}
            onSubmit={onSubmit}
            onCancel={onCancel}
            contracts={contracts}
            payrolls={payrolls}
            setIsVisible={setIsVisible}
            onAddRow={onAddRow}
            onRemoveRow={onRemoveRow}
            onEditRow={onEditRow}
        />
    );

}

export default FormSet
