import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { getEmployee } from "api/employees";
import { useDelete, useGetCall, fetchPost } from "utils/api";
import { showResponseMessage } from "utils/message";
import Loading from "helpers/Loading";
import { LoadingContext } from "helpers/LoadingContext";
import View from "components/crh/users/List.view";

function List({
	search,
	refresh,
	company,
	addEditModal,
	filters
}) {

	const { loading, setLoading } = useContext(LoadingContext);

	const [refreshValue, setRefreshValue] = useState(null);
	const [refreshDelete, setRefreshDelete] = useState(null);
	const [users, setUsers] = useState([]);

	if (refresh !== refreshValue) {
		setRefreshValue(refresh);
	}

	const [callUsers, reqUsers] = useGetCall("/users", {
		onCompleted: ({ data }) => {
			setLoading(false);
			if (data) {
				let newUsers = [...data];
				newUsers.forEach(user => {
					if (user.employee_id) {
						getEmployee(user.employee_id).then(({ data }) => {
							if (data) user.employee_key = data.key;
						});
					} else {
						user.employee_key = null;
					}
				});
				setUsers(newUsers);
			}
		},
		onError: (error) => {
			setLoading(false);
			showResponseMessage(error.status, "users", "usuarios", "read");
		}
	})

	const [callDelete, reqDelete] = useDelete("/users", {
		onCompleted: () => {
			if (!toast.isActive("user-deleted"))
				toast.success("Usuario eliminado", { toastId: "user-deleted" });

			setRefreshDelete(Math.random());
		},
		onError: (error) => {
			console.error(error)
			showResponseMessage(error.status, "users", "usuarios", "delete");
		}
	});

	useEffect(() => {
		setLoading(true);
		callUsers({ search, company, ...filters })
	}, [refreshDelete, search, refreshValue, filters]);

	const onDelete = (id) => {
		fetchPost("/permissions/check", { table: "USER", permission: "delete" }).then((response) => {
			if (response.data) {
				const data = response.data;
				if (data.isAllowed === true) {
					if (window.confirm("¿Estás seguro de eliminar el usuario?")) {
						callDelete({ id: id });
					}
				} else {
					if (!toast.isActive("toast-delete-series-unauthorized")) {
						toast.error("Error, no tienes los permisos para eliminar usuarios", { toastId: "toast-delete-series-unauthorized" })
					}
				}
			}
		})
	}

	const onEdit = (id) => {
		addEditModal(id);
	}

	if (loading) return <Loading />

	return (
		<View users={users} onDelete={onDelete} onEdit={onEdit} />
	);
}

export default List;