import React from 'react';
import { SERVER_URL } from '../../../settings';
import Modal from "helpers/Modal";

function BiophotosView({ 
    offices = [],
    jobs = [],
    departments = [],
    filters,
    setFilters,
    isVisibleBiophoto,
    setIsVisibleBiophoto,
    modalTitleBiophoto,
    modalContentBiophoto,
    handlePhotoClick,
    biodatas = [] 
}) {
  return (
    <div className='container'>
        <div className='d-flex justify-content-end w-100 mb-4'>
            <div className='d-flex w-100'>
                <input 
                    type="text" 
                    class="form-control me-2" 
                    placeholder="Buscar" 
                    value={filters.search || ''} 
                    onChange={(e) => setFilters({ ...filters, search: e.target.value })}
                />
                {/* Offices */}
                <div className='form-group me-2 w-100'>
                    <label class="input">
                    <select name="office_id" class="input__field form-control" value={filters.office_id || ''} onChange={(e) => setFilters({ ...filters, office_id: e.target.value })}>
                        <option value="">Seleccione una opción</option>
                        {
                            offices.map(office =>
                                <option key={office.id} value={office.id}>{office.description}</option>
                            )
                        }
                    </select>
                    <span class="input__label">Sucursal</span>
                    </label>
                </div>
                {/* Jobs */}
                <div className='form-group me-2 w-100'>
                    <label class="input">
                    <select name="job_id" class="input__field form-control" value={filters.job_id || ''} onChange={(e) => setFilters({ ...filters, job_id: e.target.value })}>
                        <option value="">Seleccione una opción</option>
                        {
                            jobs.map(job =>
                                <option key={job.id} value={job.id}>{job.description}</option>
                            )
                        }
                    </select>
                    <span class="input__label">Puesto</span>
                    </label>
                </div>
                {/* Departments */}
                <div className='form-group w-100'>
                    <label class="input">
                    <select name="department_id" class="input__field form-control" value={filters.department_id || ''} onChange={(e) => setFilters({ ...filters, department_id: e.target.value })}>
                        <option value="">Seleccione una opción</option>
                        {
                            departments.map(department =>
                                <option key={department.id} value={department.id}>{department.description}</option>
                            )
                        }
                    </select>
                    <span class="input__label">Departamento</span>
                    </label>
                </div>
            </div>
        </div>
        <div className='row'>
            {
                biodatas.map((biodata) =>
                    <div className='col-md-3 d-flex flex-column justify-content-center align-items-center'>
                        <div className='d-flex align-items-center justify-content-center flex-wrap' style={{ width: "125px", height: "125px", borderRadius: "50%", overflow: "hidden" }}>
                            {
                                biodata.bioPhoto 
                                    ? <img 
                                            crossorigin="anonymous" 
                                            src={(
                                                biodata.bioPhoto.path 
                                                    ? (SERVER_URL + "/" + biodata.bioPhoto.path + "?not-from-cache-please")
                                                    : (biodata.bioPhoto.url + "?not-from-cache-please")
                                            )} 
                                            alt="Foto"
                                            onClick={() => handlePhotoClick(biodata)}
                                            style={{ height: "125px", cursor: "pointer", objectFit: "cover" }}
                                        />
                                    : <i class="bi bi-person-circle" style={{ fontSize: "90px", color: "#6c757d" }}></i>
                            }
                        </div>
                        <div className='d-flex flex-column text-center justify-content-center align-items-center'>
                            <p className='d-flex text-center mt-2 mb-0 fw-bold'> {biodata.employee.key} </p>
                            <p className='d-flex text-center mt-2 mb-0'> {biodata.employee.firstname} {biodata.employee.dad_lastname??""} {biodata.employee.mom_lastname??""} </p>
                        </div>
                    </div>
                )
            }
        </div>

        <Modal
            title={modalTitleBiophoto}
            isVisible={isVisibleBiophoto}
            setIsVisible={setIsVisibleBiophoto}
        >
            {modalContentBiophoto}
        </Modal>

    </div>
  )
}

export default BiophotosView;