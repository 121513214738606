import React, { useState, useEffect, useLayoutEffect } from "react"
import { useGetCall } from "utils/api";
import { toast } from "react-toastify"
import View from "components/home/dashboard/AboutToday.view"
import { getJSON } from "utils/sessionStorage";

function AboutToday({ lastUpdate, filters, refreshValue, handleRefresh, globalLoading, setGlobalLoading }) {

	const [loading, setLoading] = useState(false)
	const [employees, setEmployees] = useState(getJSON("incidencesEmployees", {}))
	const [currentIncidences, setCurrentIncidences] = useState(getJSON("currentIncidences", {}))
	const [workedChart, setWorkedChart] = useState({ datasets: [], })

	const [isTodayAttendancesVisible, setIsTodayAttendancesVisible] = useState(false)
	const [isTodayFoulsVisible, setIsTodayFoulsVisible] = useState(false)
	const [isTodayPunctualVisible, setIsTodayPunctualVisible] = useState(false)
	const [isTodayRetardmentVisible, setIsTodayRetardmentVisible] = useState(false)
	const [isWorkedHoursVisible, setIsWorkedHoursVisible] = useState(false)

	const [callDashboard, reqDashboard] = useGetCall("/statistics/current_incidences", {
		onCompleted: (response) => {
			setEmployees(response?.data?.employees??{})
			setCurrentIncidences(response?.data?.incidences??{})
			setLoading(false)
			setGlobalLoading({ ...globalLoading, aboutToday: false })
		},
		onError: (error) => {
			setLoading(false);
			if (error.status === 403) {
				if (!toast.isActive("toast-read-xxxxx-unauthorized")) {
					toast.error("Error, no tienes los permisos para consultar xxxxxxxx", { toastId: "toast-read-xxxxx-unauthorized" })
				}
			} else {
				if (!toast.isActive("toast-xxxxx-error")) {
					toast.error("Error al obtener ", { toastId: "toast-xxxxx-error" })
				}
			}
		}
	})

	const loadWorkedChart = () => {

		let workedHours = 0
		let totalHours = 0
		let leftHours = 0

		if(currentIncidences && currentIncidences.total_hours && currentIncidences.worked_hours) {
			workedHours = (currentIncidences.worked_hours.split(':').reduce((acc, time) => (60 * acc) + +time) / 3600).toFixed(1)
			totalHours = (currentIncidences.total_hours.split(':').reduce((acc, time) => (60 * acc) + +time) / 3600).toFixed(1)
			leftHours = (totalHours - workedHours).toFixed(1)
		}

		setWorkedChart({
			labels: ['', ''],
			datasets: [
				{
					data: [
						leftHours,
						workedHours,
					],
					backgroundColor: [
						'rgba(140, 140, 140, 0.4)',
						'rgba(54, 162, 235, 0.4)',
					],
					borderWidth: 0,
				},
			]
		})

	}

	useLayoutEffect(() => {
		loadWorkedChart()
	}, [])

	useEffect(() => {
        loadWorkedChart()
		try{ 
			sessionStorage.setItem("currentIncidences", JSON.stringify(currentIncidences)) 
			sessionStorage.setItem("incidencesEmployees", JSON.stringify(employees))
		}
		catch(e) { console.log(e) }
	}, [currentIncidences])

    useEffect(() => {
			setLoading(true)
			setGlobalLoading({ ...globalLoading, aboutToday: true })
			callDashboard({ ...filters })
    }, [refreshValue])

	return (
		<View
			lastUpdate={lastUpdate}
			currentIncidences={currentIncidences}
			loading={loading}
			globalLoading={globalLoading}
			workedChart={workedChart}
			employees={employees}
			handleRefresh={handleRefresh}
			
			isTodayAttendancesVisible={isTodayAttendancesVisible}
			isTodayFoulsVisible={isTodayFoulsVisible}
			isTodayPunctualVisible={isTodayPunctualVisible}
			isTodayRetardmentVisible={isTodayRetardmentVisible}
			isWorkedHoursVisible={isWorkedHoursVisible}
			
			setIsWorkedHoursVisible={setIsWorkedHoursVisible}
			setIsTodayAttendancesVisible={setIsTodayAttendancesVisible}
			setIsTodayFoulsVisible={setIsTodayFoulsVisible}
			setIsTodayPunctualVisible={setIsTodayPunctualVisible}
			setIsTodayRetardmentVisible={setIsTodayRetardmentVisible}
		/>
	)

}

export default AboutToday