import { API_URL } from "settings";
import { getUrlencoded } from "utils/api";

function getDeviceEmployees(id,  type) {

    const body = {
        user_id: sessionStorage.getItem("id"),
        id,
        type,
    };

    const url = `${API_URL}/device_employees?${getUrlencoded(body)}`;
    const params = {
        method: "GET",
        headers: {
            "content-Type": "application/x-www-form-urlencoded",
            "x-auth-token": sessionStorage.getItem("token"),
        }
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .catch((err) => {
            console.log(err);
            return null;
        });
        
}

export { getDeviceEmployees };