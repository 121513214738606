import React from "react";
import DevicesList from "components/crh/devices/List";
import Modal from "helpers/Modal";

function DevicesView({
	countRef,
	company,
	name,
	create,
	search,
	onChangeSearch,
	handleKeyUp,
	isVisible,
	setIsVisible,
	modalTitle,
	modalContent,
	addDeviceModal,
	addEditDeviceModal,
	modalTitleEdit,
	isVisibleEdit,
	setIsVisibleEdit,
	modalContentEdit,
	isVisibleBiophotos,
	setIsVisibleBiophotos,
	modalTitleBiophotos,
	modalContentBiophotos,
	addBiophotosModal
}) {
	return (
		<>

			<div className="content container-fluid p-5">

				<div className="page-header mb-3">
					<div className="row align-items-end">

						<div className="col-sm mb-2 mb-sm-0">
							<h1 className="page-header-title">Dispositivos {name ? `- ${name}` : ""}</h1>
						</div>

						<div className="col-sm-auto">
							{create && (
								<button type="button" className="btn btn-primary" onClick={addDeviceModal}>
									<i className="bi bi-plus-circle" style={{ marginRight: "7px" }}></i>
									Agregar dispositivo
								</button>
							)}
						</div>

					</div>
				</div>

				<div class="card">
					<div class="card-header card-header-content-md-between">
						<div class="mb-2 mb-md-0 d-flex justify-content-between">

							<div class="input-group input-group-merge input-group-flush" style={{ width: "50%" }}>
								<div class="input-group-prepend input-group-text" >
									<i class="bi-search"></i>
								</div>
								<input
									type="search"
									class="form-control"
									placeholder="Buscar dispositivo"
									onChange={onChangeSearch}
									onKeyUp={handleKeyUp}
								/>
							</div>

							<div className="d-flex justify-content-right mx-3">
								<span className="pt-2 mx-1">Total de dispositivos: </span>
								<span className="pt-2 border-0" ref={countRef} />
							</div>

						</div>
					</div>

					<DevicesList
						countRef={countRef}
						refresh={Math.random()}
						company={company}
						search={search}
						addEditDeviceModal={addEditDeviceModal}
						addBiophotosModal={addBiophotosModal}
					/>

				</div>

			</div>

			<Modal
				title={modalTitle}
				isVisible={isVisible}
				setIsVisible={setIsVisible}
			>
				{modalContent}
			</Modal>

			<Modal
				title={modalTitleEdit}
				isVisible={isVisibleEdit}
				setIsVisible={setIsVisibleEdit}
			>
				{modalContentEdit}
			</Modal>

			<Modal
				title={modalTitleBiophotos}
				isVisible={isVisibleBiophotos}
				setIsVisible={setIsVisibleBiophotos}
			>
				{modalContentBiophotos}
			</Modal>

		</>
	);
}

export default DevicesView;