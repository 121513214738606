import React, { useState, useEffect } from "react"
import View from "components/crh/reports/attendanceNotRegistered/Filters.view"
import { toast } from "react-toastify"
import { getDevices } from "api/devices"

function Filters({ filters, setFilters, hideModal }) {

    const [devices, setDevices] = useState([])

    const onSubmit = (values) => {
        setFilters(values)
        hideModal()
    }

    useEffect(() => {

        getDevices().then((data) => {
            if(data.errors.length > 0) {
                if(!toast.isActive("toast-devices-eror"))
                    toast.error("Error al obtener los dispositivos", { toastId: "toast-devices-eror" })
            }
            else {
                setDevices(data.data)
            }
        })

    }, [])
        
    return (
        <View 
            devices={devices}
            filters={filters}
            setFilters={setFilters}
            onSubmit={onSubmit}
        />
    );

}

export default Filters;