import React, { useState } from 'react';
import FormSave from 'components/admin/users/FormSave';
import FormSet from 'components/admin/users/FormSet';
import View from "components/admin/users/Users.view";


function Users() {

    const [isVisible, setIsVisible] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);

    const [isVisibleEdit, setIsVisibleEdit] = useState(false);
    const [modalTitleEdit, setModalTitleEdit] = useState("");
    const [modalContentEdit, setModalContentEdit] = useState(null);

    const [search, setSearch] = useState("");

    const addCreateModal = () => {
        setIsVisible(true);
        setModalTitle("Crear super usuario");
        setModalContent(
            <FormSave setIsVisible={setIsVisible} />
        );
    }

    const addEditModal = (id) => {

        setIsVisibleEdit(true);
        setModalTitleEdit("Editar super usuario");
        setModalContentEdit(
            <FormSet
                id={id}
                refresh={Math.random()}
                setIsVisible={setIsVisibleEdit}
            />
        );
    }

    const onChangeSearch = (event) => {
        if (event.target.value === '') {
            setSearch(event.target.value);
        }
    }

    const handleKeyUp = (event) => {
        if (event.key === "Enter") {
            setSearch(event.target.value);
        }
    }

    return (
        <View
            search={search}
            onChangeSearch={onChangeSearch}
            handleKeyUp={handleKeyUp}
            isVisible={isVisible}
            setIsVisible={setIsVisible}
            modalTitle={modalTitle}
            modalContent={modalContent}
            addCreateModal={addCreateModal}
            addEditModal={addEditModal}
            modalTitleEdit={modalTitleEdit}
            isVisibleEdit={isVisibleEdit}
            setIsVisibleEdit={setIsVisibleEdit}
            modalContentEdit={modalContentEdit}
        />
    );
}

export default Users;