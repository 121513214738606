import React, { useState } from "react";
import View from "components/crh/deviceManager/DeviceManager.view";
import { usePost, fetchPost } from "utils/api";
import { toast } from "react-toastify"
import moment from "moment";

function DeviceManager({ company = {}, name = null }) {

    const [selectedDevices, setSelectedDevices] = useState({});

    const [callExec, qExec] = usePost("/devices/execute_instructions", {
        onCompleted: () => {
            if (!toast.isActive("toast-exec-instructions"))
                toast.success("Instrucciones enviadas correctamente", { toastId: "toast-exec-instructions" })
        },
        onError: (err) => {
            console.error(err)
            if (!toast.isActive("toast-exec-instructions-error"))
                toast.error("Error interno del servidor", { toastId: "toast-exec-instructions-error" })
        }
    })

    const onSendInstructions = (instructions) => {

        fetchPost("/permissions/check", { table: "DEVICE_MANAGER", permission: "create" }).then((response) => {
            if (response.data) {
                const data = response.data;
                if (data.isAllowed === true) {

                    const startDate = moment(instructions["start_date"]);
                    const endDate = moment(instructions["end_date"]);
                    const devices = Object.keys(selectedDevices).filter((serialNumber) => selectedDevices[serialNumber]);

                    if (devices.length === 0) {
                        toast.error("Seleccione al menos un dispositivo", { toastId: "toast-error-devices" })
                        return;
                    }

                    if (instructions["get_attendance"]) {
                        if (!instructions["start_date"]) {
                            toast.error("Seleccione una fecha inicial", { toastId: "toast-error-start-date" })
                            return;
                        }
                        if (!instructions["end_date"]) {
                            toast.error("Seleccione una fecha final", { toastId: "toast-error-end-date" })
                            return;
                        }
                    }

                    if (startDate.isAfter(endDate)) {
                        toast.error("La fecha inicial no puede ser mayor a la fecha final", { toastId: "toast-error-dates" })
                        return;
                    }

                    callExec({
                        devices,
                        instructions
                    });

                } else {
                    if (!toast.isActive("toast-exec-devices-unauthorized")) {
                        toast.error("Error, no tienes los permisos para crear comandos a un lector", { toastId: "toast-exec-devices-unauthorized" })
                    }
                }
            }
        })

    };

    return (
        <View
            selectedDevices={selectedDevices}
            setSelectedDevices={setSelectedDevices}
            onSendInstructions={onSendInstructions}
        />
    );

}

export default DeviceManager;