import React, { useState, useEffect } from "react";
import FormSave from "components/crh/groups/FormSave";
import FormSet from "components/crh/groups/FormSet";
import View from "components/crh/groups/Groups.view";
import { fetchPost } from "utils/api";
import { toast } from "react-toastify";
import DetailGroup from "components/crh/groups/Detail";

function GroupGroups({ theme }) {

  const [isVisible, setIsVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [create, setCreate] = useState(false);

  const [isVisibleEdit, setIsVisibleEdit] = useState(false);
  const [modalTitleEdit, setModalTitleEdit] = useState("");
  const [modalContentEdit, setModalContentEdit] = useState(null);

  const [isVisibleDetail, setIsVisibleDetail] = useState(false);
  const [modalTitleDetail, setModalTitleDetail] = useState("");
  const [modalContentDetail, setModalContentDetail] = useState(null);

  const [search, setSearch] = useState("");

  useEffect(() => {
    fetchPost("/permissions/check", { table: "WORK_GROUPS", permission: "create" }).then((response) => {
      if (response.data && response.data.isAllowed === true) {
        setCreate(true);
      }
    })
  }, [])

  const addGroupModal = () => {
    fetchPost("/permissions/check", { table: "WORK_GROUPS", permission: "create" }).then((response) => {
      if (response.data) {
        const data = response.data;
        if (data.isAllowed === true) {
          setIsVisible(true);
          setModalTitle("Grupos de trabajo");
          setModalContent(
            <FormSave
              setIsVisible={setIsVisible}
              refresh={Math.random()}
              theme={theme}
            />
          );
        } else {
          if (!toast.isActive("toast-create-groups-unauthorized")) {
            toast.error("Error, no tienes los permisos para crear grupos de trabajo", { toastId: "toast-create-groups-unauthorized" })
          }
        }
      }
    })
  }

  const addEditGroupModal = (id) => {
    fetchPost("/permissions/check", { table: "WORK_GROUPS", permission: "edit" }).then((response) => {
      if (response.data) {
        const data = response.data;
        if (data.isAllowed === true) {
          setIsVisibleEdit(true);
          setModalTitleEdit("Editar grupo de trabajo");
          setModalContentEdit(
            <FormSet
              id={id}
              theme={theme}
              refresh={Math.random()}
              setIsVisible={setIsVisibleEdit}
            />
          );
        } else {
          if (!toast.isActive("toast-edit-groups-unauthorized")) {
            toast.error("Error, no tienes los permisos para editar grupos de trabajo", { toastId: "toast-edit-groups-unauthorized" })
          }
        }
      }
    })
  }

  const addDetailGroupModal = (id) => {
    fetchPost("/permissions/check", { table: "WORK_GROUPS", permission: "edit" }).then((response) => {
      if (response.data) {
        const data = response.data;
        if (data.isAllowed === true) {
          setIsVisibleDetail(true);
          setModalTitleDetail("Grupo de trabajo");
          setModalContentDetail(
            <DetailGroup
              id={id}
              setIsVisible={setIsVisibleDetail}
            />
          );
        } else {
          if (!toast.isActive("toast-edit-groups-unauthorized")) {
            toast.error("Error, no tienes los permisos para editar grupos de trabajo", { toastId: "toast-edit-groups-unauthorized" })
          }
        }
      }
    })
  }

  const onChangeSearch = (event) => {
    if (event.target.value === '') {
      setSearch(event.target.value);
    }
  }

  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      setSearch(event.target.value);
    }
  }

  return (
    <View
      search={search}
      create={create}
      onChangeSearch={onChangeSearch}
      handleKeyUp={handleKeyUp}
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      modalTitle={modalTitle}
      modalContent={modalContent}
      addGroupModal={addGroupModal}
      addEditGroupModal={addEditGroupModal}
      modalTitleEdit={modalTitleEdit}
      isVisibleEdit={isVisibleEdit}
      setIsVisibleEdit={setIsVisibleEdit}
      modalContentEdit={modalContentEdit}
      theme={theme}
      addDetailGroupModal={addDetailGroupModal}
      isVisibleDetail={isVisibleDetail}
      modalTitleDetail={modalTitleDetail}
      modalContentDetail={modalContentDetail}
      setIsVisibleDetail={setIsVisibleDetail}
    />
  );

}

export default GroupGroups;