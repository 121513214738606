import React from "react";
import { Formik, Form, Field } from 'formik';
import { TimePicker } from 'antd';
import moment from "moment";
import dayjs from 'dayjs';
const format = 'HH:mm';


const showHours = (values, incidencesWithHour, incidences) => {

    if (values.incidence) {
        const incidence = incidences.find((item) => item.id === parseInt(values.incidence.id));
        if (incidencesWithHour.find(item => item === incidence.type))
            return true;
        else
            return false;
    } else {
        return false;
    }
}

const showInsertLog = (values, incidences) => {

    if (values.incidence) {
        const incidence = incidences.find((item) => item.id === parseInt(values.incidence.id));
        return incidence.type === "INSERT_MARKINGS";
    } else {
        return false;
    }

}

function RequestView({
    startHour,
    endHour,
    devices,
    setStartHour,
    setEndHour,
    incidencesWithHour,
    formikRef,
    incidences,
    onCancel,
    onSubmit
}) {
    return (
        <div className='container mt-3'>
            <Formik
                innerRef={formikRef}
                initialValues={{}}
                onSubmit={onSubmit}>
                {({ values, setFieldValue }) =>
                    <Form>

                        {/* Incidence */}
                        <div class="mb-3">
                            <div class="form-group">
                                <label class="input">
                                    <Field as="select" name="incidence.id" class="form-control input__field" placeholder=" " required value={values.incidence ? values.incidence.id : ''}>
                                        <option value="">Selecciona una incidencia</option>
                                        {
                                            incidences.map((incidence) =>
                                                <option key={incidence.id} value={incidence.id}>{incidence.description}</option>
                                            )}
                                    </Field>
                                    <span class="input__label">
                                        Incidencia <span className='text-danger fw-bold'>*</span>
                                    </span>
                                </label>
                            </div>
                        </div>

                        {/* Date range */}
                        {
                            !showInsertLog(values, incidences) ? (
                                <div class="mb-3">
                                    <div class="form-group text-center">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <h5 style={{ padding: "5px 0" }}>Rango de fechas<span className='text-danger fw-bold'>*</span></h5>
                                            </div>

                                            <div class="col-md-4">
                                                <Field type="date" name="start_date" class="form-control input__field" required value={values.start_date || ''} />
                                            </div>

                                            <div class="col-md-4">
                                                <Field type="date" name="end_date" class="form-control input__field" required value={values.end_date || ''} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null
                        }


                        {/* Hour range */}
                        {
                            showHours(values, incidencesWithHour, incidences) ? (

                                <div class="mb-3">
                                    <div class="form-group text-center">
                                        <h5 class="bg-crh" style={{ padding: "5px 0" }}>Selecciona rango de horas</h5>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <h5><label for="start_date">Desde <span className='text-danger fw-bold'>*</span></label></h5>
                                                <TimePicker style={{ width: "100%" }} format={format} onChange={time => setStartHour(time.format(format))} value={startHour ? dayjs(startHour, format) : ''} />
                                            </div>

                                            <div class="col-md-6">
                                                <h5><label for="end_date">Hasta <span className='text-danger fw-bold'>*</span></label></h5>
                                                <TimePicker style={{ width: "100%" }} format={format} onChange={time => setEndHour(time.format(format))} value={endHour ? dayjs(endHour, format) : ''} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            ) : null
                        }

                        {
                            showInsertLog(values, incidences) ? (
                                <>
                                    <div class="mb-3">
                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-md-2">
                                                    <h5 style={{ padding: "5px 0" }}>Fecha<span className='text-danger fw-bold'>*</span></h5>
                                                </div>
                                                <div class="col-md-4">
                                                    <Field type="date" name="start_date" class="form-control input__field" required value={values.start_date || ''} />
                                                </div>
                                                <div class="col-md-2">
                                                    <h5 style={{ padding: "5px 0" }}>Hora<span className='text-danger fw-bold'>*</span></h5>
                                                </div>
                                                <div class="col-md-4">
                                                    <TimePicker style={{ width: "100%" }} format={format} onChange={time => setStartHour(time.format(format))} value={startHour ? dayjs(startHour, format) : ''} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <div class="form-group">
                                            <label class="input">
                                                <Field as="select" name="device.id" class="form-control input__field" placeholder=" " required value={values.device ? values.device.id : ''}>
                                                    <option value="">Selecciona un lector</option>
                                                    {
                                                        devices.map((device) =>
                                                            <option key={device.id} value={device.id}>{device.description}</option>
                                                        )}
                                                </Field>
                                                <span class="input__label">
                                                    Lector <span className='text-danger fw-bold'>*</span>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </>
                            ) : null
                        }

                        {/* Observations */}
                        <div class="mb-3">
                            <div class="form-group text-center">
                                <h5 class="bg-crh" style={{ padding: "5px 0" }}>Observaciones</h5>
                                <div class="my-3">
                                    <div class="form-group">
                                        <label class="input">
                                            <Field type="text" name="observations" class="form-control input__field" placeholder=" " value={values.observations || ''} />
                                            <span class="input__label">Observaciones</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="pt-3">
                            <button type="submit" class="btn btn-primary">
                                <i class="bi bi-plus" style={{ marginRight: "10px" }}></i>
                                Aceptar
                            </button>

                            <button type="button" class="btn btn-danger" style={{ marginLeft: "20px" }} onClick={onCancel}>
                                <i class="bi bi-x" style={{ marginRight: "10px" }}></i>
                                Cancelar
                            </button>
                        </div>

                    </Form>
                }</Formik>

        </div >
    )
}

export default RequestView;