import React from 'react';
import { Formik, Form, Field } from 'formik';
import {
    permissionTables, permissionTypes, getPermissionTableName,
    getPermissionTypeName, getPermissionCategoryName
} from "utils/constants";

const getStatusSwitch = (data, table, permission) => {
    const idxTableItem = data.findIndex((item) => item.table === table);
    if (idxTableItem === -1) return false;
    return data[idxTableItem].permissions[permission]
}

function FormView({
    edit = false,
    formikRef,
    permissions,
    profile = {},
    onSubmit,
    onCancel,
    onChangePermission
}) {

    return (
        <div className='container mt-3'>
            <Formik
                innerRef={formikRef}
                initialValues={profile}
                onSubmit={onSubmit}>
                {({ values, setFieldValue, resetForm }) =>
                    <Form>
                        {/* Key */}
                        <div class="mb-3">
                            <div class="form-group">
                                <label class="input">
                                    <Field type="text" name="key" class="form-control input__field" placeholder=" " required value={values.key || ''} />
                                    <span class="input__label">
                                        Clave <span className='text-danger fw-bold'>*</span>
                                    </span>
                                </label>
                            </div>
                        </div>

                        {/* Description */}
                        <div class="mb-3">
                            <div class="form-group">
                                <label class="input">
                                    <Field type="text" name="description" class="form-control input__field" placeholder=" " required value={values.description || ''} />
                                    <span class="input__label">
                                        Descripción <span className='text-danger fw-bold'>*</span>
                                    </span>
                                </label>
                            </div>
                        </div>

                        {/* Observations */}
                        <div class="mb-3">
                            <div class="form-group">
                                <label class="input">
                                    <Field type="text" name="observation" class="form-control input__field" placeholder=" " value={values.observation || ''} />
                                    <span class="input__label">
                                        Observaciones <span className='text-danger fw-bold'>*</span>
                                    </span>
                                </label>
                            </div>
                        </div>

                        <div class="mb-3">

                            <div class="table-responsive datatable-custom position-relative" >
                                <div class="dataTables_wrapper no-footer pt-2 pl-3 pr-3">

                                    {
                                        permissionTables.map((permissionTable) => (
                                            <>
                                                <h5>{getPermissionCategoryName(permissionTable.category)}</h5>
                                                <table class="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">

                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Módulo</th>
                                                            {
                                                                permissionTypes.map((pType, idx) => (
                                                                    <th key={idx} scope="col">{getPermissionTypeName(pType)}</th>
                                                                ))
                                                            }
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {
                                                            permissionTable.tables.map((table, idx) => (
                                                                <tr key={`table_${idx}`}>
                                                                    <td>{getPermissionTableName(table)}</td>
                                                                    {
                                                                        permissionTypes.map((pType, idx_type) => (
                                                                            <td key={`type_${idx_type}`}>
                                                                                <div className="form-check form-switch d-flex justify-content-center">
                                                                                    <input class="form-check-input" type="checkbox" checked={getStatusSwitch(permissions, table, pType)}
                                                                                        onChange={() => onChangePermission(table, pType)} />
                                                                                </div>
                                                                            </td>
                                                                        ))
                                                                    }
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>

                                                </table>
                                            </>
                                        ))
                                    }

                                </div>
                            </div>


                        </div>



                        <div class="pt-3">
                            <button type="submit" class="btn btn-primary">
                                <i class="bi bi-plus" style={{ marginRight: "10px" }}></i>
                                {edit ? "Editar perfil de permisos" : "Guardar perfil de permisos"}
                            </button>

                            <button type="button" class="btn btn-danger" style={{ marginLeft: "20px" }} onClick={onCancel}>
                                <i class="bi bi-x" style={{ marginRight: "10px" }}></i>
                                Cancelar
                            </button>
                        </div>

                    </Form>
                }</Formik>

        </div >
    )
}

export default FormView;