import React from "react";
import Modal from "helpers/Modal";
import ListHours from "components/home/tables/ListHours.js";
import "resources/styles/Home.scss";

function WorkedHoursView({
    employees,
    workedHoursMean,

    isWeeklyHoursVisible,
    isMonthlyHoursVisible,
    isRestHoursVisible,
    isExtraHoursVisible,

    setIsWeeklyHoursVisible,
    setIsMonthlyHoursVisible,
    setIsRestHoursVisible,
    setIsExtraHoursVisible,

    loading
}) {
    return (
        <>

            <div style={{ position: "absolute", top: "5px", right: "10px" }}>
                { loading && <div className="spinner-border spinner-border-sm text-primary" role="status"></div> }
            </div>

            <div className="w-100 px-3 py-1 mb-0">
                <span className="h6 fw-bold text-primary">Horas</span>
            </div>

            <div className="d-flex flex-column justify-content-center flex-fill py-2">

                <div className="w-100 px-3 mt-0 pt-0">
                    <div className="d-flex flex-column border rounded px-3 py-2">
                        <div className="d-flex justify-content-between" style={{ fontSize: "0.7rem", cursor: "pointer" }} onClick={ () => setIsWeeklyHoursVisible(true) }>
                            <span className="text-dark fw-bold me-1">Esta semana</span>
                            <div className="text-end">
                                <span className="text-dark fw-bold">
                                    {function(){
                                        if(workedHoursMean && workedHoursMean.weekly) 
                                            return workedHoursMean.weekly.current.toFixed(1)
                                        return 0
                                    }()}
                                </span>
                                <span className="text-muted"> 
                                    {function(){
                                        if(workedHoursMean && workedHoursMean.weekly) 
                                            return " / " + workedHoursMean.weekly.total.toFixed(1) + " hrs"
                                        return 0
                                    }()}
                                </span>
                            </div>
                        </div>
                        <div class="progress" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style={{ height: "0.6rem" }}>
                            <div class="progress-bar" style={{
                                width: function(){
                                    if(workedHoursMean && workedHoursMean.weekly) 
                                        return (workedHoursMean.weekly.current / (workedHoursMean.weekly.total + 1)) * 100 + "%"
                                    return 0
                                }()
                            }}></div>
                        </div>
                    </div>
                </div>

                <div className="w-100 px-3 mt-2">
                    <div className="d-flex flex-column border rounded px-3 py-2">
                        <div className="d-flex justify-content-between" style={{ fontSize: "0.7rem", cursor: "pointer" }} onClick={ () => setIsMonthlyHoursVisible(true) }>
                            <span className="text-dark fw-bold me-1">Este mes</span>
                            <div className="text-end">
                                <span className="text-dark fw-bold">
                                    {function(){
                                        if(workedHoursMean && workedHoursMean.monthly) 
                                            return workedHoursMean.monthly.current.toFixed(1)
                                        return 0
                                    }()}
                                </span>
                                <span className="text-muted"> 
                                    {function(){
                                        if(workedHoursMean && workedHoursMean.monthly) 
                                            return " / " + workedHoursMean.monthly.total.toFixed(1) + " hrs"
                                        return 0
                                    }()}
                                </span>
                            </div>
                        </div>
                        <div class="progress" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style={{ height: "0.6rem" }}>
                            <div class="progress-bar" style={{
                                width: function(){
                                    if(workedHoursMean && workedHoursMean.monthly) 
                                        return (workedHoursMean.monthly.current / (workedHoursMean.monthly.total + 1)) * 100 + "%"
                                    return 0
                                }()
                            }}></div>
                        </div>
                    </div>
                </div>

                <div className="w-100 px-3 mt-2">
                    <div className="d-flex flex-column border rounded px-3 py-2">
                        <div className="d-flex justify-content-between" style={{ fontSize: "0.7rem", cursor: "pointer" }} onClick={ () => setIsRestHoursVisible(true) }>
                            <span className="text-dark fw-bold">Descansos</span>
                            <div>
                                <span className="text-dark fw-bold">
                                    {function(){
                                        if(workedHoursMean && workedHoursMean.rest) 
                                            return workedHoursMean.rest.current.toFixed(1)
                                        return 0
                                    }()}
                                </span>
                                <span className="text-muted"> hrs</span>
                            </div>
                        </div>
                        <div class="progress" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style={{ height: "0.6rem" }}>
                            <div class="progress-bar" style={{
                                width: function(){
                                    if(workedHoursMean && workedHoursMean.rest) 
                                        return (workedHoursMean.rest.current / (workedHoursMean.rest.total + 1)) * 100 + "%"
                                    return 0
                                }()
                            }}></div>
                        </div>
                    </div>
                </div>

                <div className="w-100 px-3 mt-2 mb-2">
                    <div className="d-flex flex-column border rounded px-3 py-2">
                        <div className="d-flex justify-content-between" style={{ fontSize: "0.7rem", cursor: "pointer" }} onClick={ () => setIsExtraHoursVisible(true) }>
                            <span className="text-dark fw-bold">Tiempo extra</span>
                            <div>
                                <span className="text-dark fw-bold">
                                    {function(){
                                        if(workedHoursMean && workedHoursMean.extra_time) 
                                            return workedHoursMean.extra_time.current.toFixed(1)
                                        return 0
                                    }()}
                                </span>
                                <span className="text-muted"> hrs</span>
                            </div>
                        </div>
                        <div class="progress" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style={{ height: "0.6rem" }}>
                            <div class="progress-bar" style={{
                                width: function(){
                                    if(workedHoursMean && workedHoursMean.extra_time) 
                                        return (workedHoursMean.extra_time.current / (workedHoursMean.extra_time.total + 1)) * 100 + "%"
                                    return 0
                                }()
                            }}></div>
                        </div>
                    </div>
                </div>

            </div>

            <Modal
                title={"Horas trabajadas de la semana"}
                isVisible={isWeeklyHoursVisible}
                setIsVisible={setIsWeeklyHoursVisible}
            >
                <ListHours
                    employees={employees??[]}
                    incidences={workedHoursMean?.weekly?.reports??[]}
                    setIsVisible={setIsWeeklyHoursVisible}
                    currentKey="worked"
                    totalKey="total"
                    label="Horas trabajadas"
                />
            </Modal>

            <Modal
                title={"Horas trabajadas del mes"}
                isVisible={isMonthlyHoursVisible}
                setIsVisible={setIsMonthlyHoursVisible}
            >
                <ListHours
                    employees={employees??[]}
                    incidences={workedHoursMean?.monthly?.reports??[]}
                    setIsVisible={setIsMonthlyHoursVisible}
                    currentKey="worked"
                    totalKey="total"
                    label="Horas trabajadas"
                />
            </Modal>

            <Modal
                title={"Descansos"}
                isVisible={isRestHoursVisible}
                setIsVisible={setIsRestHoursVisible}
            >
                <ListHours
                    employees={employees??[]}
                    incidences={workedHoursMean?.rest?.reports??[]}
                    setIsVisible={setIsRestHoursVisible}
                    currentKey="rested"
                    totalKey="total"
                    label={"Descansos"}
                />
            </Modal>

            <Modal
                title={"Tiempo extra"}
                isVisible={isExtraHoursVisible}
                setIsVisible={setIsExtraHoursVisible}
            >
                <ListHours
                    employees={employees??[]}
                    incidences={workedHoursMean?.extra_time?.reports??[]}
                    setIsVisible={setIsExtraHoursVisible}
                    currentKey="extra"
                    totalKey="total"
                    label={"Tiempo extra"}
                />
            </Modal>

        </>
    )
}

export default WorkedHoursView