import React from "react";
import Chart from "chart.js/auto";
import Modal from "helpers/Modal";
import ListIncidences from "components/home/tables/ListIncidencesChart.js";
import { ArcElement, Tooltip, Legend, CategoryScale } from 'chart.js';
import { PolarArea } from "react-chartjs-2";
import "resources/styles/Home.scss";

Chart.register(ArcElement, Tooltip, Legend, CategoryScale);

function IncidencesChartView({
    employees,
    incidencesData,
    incidencesChart,
    isIncidencesVisible,
    incidencesChartRef,
    setIsIncidencesVisible,
    loading
}) {
    return (
        <>

            <div style={{ position: "absolute", top: "5px", right: "10px" }}>
                { loading && <div className="spinner-border spinner-border-sm text-primary" role="status"></div> }
            </div>

            <div className="w-100 px-3 pt-1">
                <span className="h6 fw-bold text-primary">Incidencias</span>
            </div>

            <div className="d-flex align-items-center h-100 px-2" onClick={() => setIsIncidencesVisible(true)} style={{ cursor: "pointer" }}>
                <PolarArea
                    ref={incidencesChartRef}
                    style={{ cursor: "pointer", height: "100%", width: "100%"}}
                    data={incidencesChart}
                    options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                            r: {
                                pointLabels: {
                                    display: true,
                                    centerPointLabels: true,
                                    font: {
                                        size: 12
                                    }
                                },
                                grid: {
                                    circular: true
                                },
                                beginAtZero: true,
                            },
                        },
                        plugins: {
                            legend: {
                                display: false,
                            },
                            tooltips: {
                                enabled: false
                            }
                        }
                    }}
                />
            </div>

            <Modal
                title={"Incidencias"}
                isVisible={isIncidencesVisible}
                setIsVisible={setIsIncidencesVisible}
            >
                <ListIncidences
                    employees={employees??[]}
                    incidences={incidencesData??[]}
                    setIsVisible={setIsIncidencesVisible}
                />
            </Modal>

        </>
    )
}

export default IncidencesChartView