import React from "react";
import BrandsList from "components/crh/visits/brands/List";
import Modal from "helpers/Modal";

function BrandsView({
	search,
	onChangeSearch,
	handleKeyUp,
	isVisible,
	setIsVisible,
	isVisibleEdit,
	setIsVisibleEdit,
	modalContent,
	modalContentEdit,
	modalTitle,
	modalTitleEdit,
	addCreateModal,
	addEditModal
}) {

	return (
		<>
			<div className="content container-fluid p-5">

				<div className="page-header mb-3">
					<div className="row align-items-end">

						<div className="col-sm mb-2 mb-sm-0">
							<h1 className="page-header-title">Marcas</h1>
						</div>

						<div className="col-sm-auto">
							<button type="button" className="btn btn-primary" onClick={addCreateModal}>
								<i className="bi bi-plus-circle" style={{ marginRight: "7px" }}></i>
								Agregar marca
							</button>
						</div>
					</div>
				</div>


				<div class="card">
					<div class="card-header card-header-content-md-between">
						<div class="mb-2 mb-md-0">
							<div class="input-group input-group-merge input-group-flush" style={{ width: "50%" }}>
								<div class="input-group-prepend input-group-text" >
									<i class="bi-search"></i>
								</div>
								<input
									type="search"
									class="form-control"
									placeholder="Buscar marca"
									onChange={onChangeSearch}
									onKeyUp={handleKeyUp}
								/>
							</div>
						</div>
					</div>


					<BrandsList
						refresh={Math.random()}
						search={search}
						addEditModal={addEditModal}
					/>


				</div>
			</div>

			<Modal
				title={modalTitle}
				isVisible={isVisible}
				setIsVisible={setIsVisible}
			>
				{modalContent}
			</Modal>

			<Modal
				title={modalTitleEdit}
				isVisible={isVisibleEdit}
				setIsVisible={setIsVisibleEdit}
			>
				{modalContentEdit}
			</Modal>

		</>
	);
}

export default BrandsView;