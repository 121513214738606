import React from "react";


function getTypeExtra(type_extra) {
	if (type_extra === "BEFORE")
		return "Calcular antes de la jornada";

	if (type_extra === "AFTER")
		return "Calcular después de la jornada";

	if (type_extra === "BOTH")
		return "Calcular antes y después de la jornada";
}

function getTypeRound(type_round) {
	if (type_round === "COMPLETE_HOURS")
		return "Horas completas";

	if (type_round === "HALF_HOURS")
		return "Medias horas";
}

function getDistributionRound(distribution_round) {
	if (distribution_round === "UP")
		return "Arriba (15 minutos)";

	if (distribution_round === "DOWN")
		return "Abajo (15 minutos)";

	if (distribution_round === "BOTH")
		return "Ambos (15 minutos)";
}



function ListView({ shifts, onDelete, onEdit }) {

	return (
		<div class="table-responsive datatable-custom position-relative" >
			<div class="dataTables_wrapper no-footer pt-2 pl-3 pr-3" style={{ maxHeight: "60vh" }}>
				<table class="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
					<thead>
						<tr role="row">
							<th>Clave</th>
							<th>Descripción</th>
							<th>Abreviación</th>
							<th>Tolerancia</th>
							<th>Tiempo extra</th>
							<th>Redondear tiempo extra</th>
							<th>Automático</th>
							<th>Rotativo</th>
							<th>Acciones</th>
						</tr>
					</thead>
					<tbody>
						{
							shifts.map((shift) => (
								<tr key={shift.id} role="row">
									<td>{shift.key}</td>
									<td>{shift.description}</td>
									<td>{shift.abbreviation}</td>
									<td>{shift.tolerance} minutos</td>
									<td>{
										shift.extra ? (
											<p>Si ({getTypeExtra(shift.type_extra)})</p>
										) : "No"
									}</td>
									<td>{
										shift.round ? (
											<p>
												Si <br />
												<ul>
													<li>{getTypeRound(shift.type_round)}</li>
													<li>{getDistributionRound(shift.distribution_round)}</li>
												</ul>
											</p>
										) : "No"
									}</td>
									<td>{shift.automatic ? "Si" : "No"}</td>
									<td>{shift.rotating ? "Si" : "No"}</td>
									<td>
										<div class="d-flex">
											<button className="btn btn-warning py-1" style={{ marginRight: "10px" }} onClick={() => onEdit(shift.id)}>
												<i class="bi bi-pencil"></i>
											</button>
											<button className="btn btn-danger py-1" onClick={() => onDelete(shift.id)}>
												<i class="bi bi-trash"></i>
											</button>
										</div>
									</td>
								</tr>
							))
						}
					</tbody>
				</table>
			</div>
		</div>

	);
}

export default ListView;