import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { usePut } from "utils/api";
import { getUser } from "api/users";
import { getEmployees } from "api/employees";
import { getDepartments } from "api/departments";
import { getJobs } from "api/jobs";
import View from "components/crh/profile/Profile.view";

function Profile() {

    const fileInputRef = useRef()
    const formikRef = useRef();
    const id = sessionStorage.getItem("id");
    const type = sessionStorage.getItem("type");
    const [user, setUser] = useState({});
    const [jobs, setJobs] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [employee, setEmployee] = useState(null);
    const [keyEmployee, setKeyEmployee] = useState("");
    const [refresh, setRefresh] = useState(null);


    const [callUpdate, reqUpdate] = usePut(type === "SUPERADMIN" ? "/users/admin" : "/users", {
        onCompleted: () => {
            if (!toast.isActive("user-created"))
                toast.success("Perfil actualizado correctamente", { toastId: "user-created" });

            setKeyEmployee("");
            setEmployee(null);
            setRefresh(Math.random);
        },
        onError: (error) => {
            console.error(error)
            switch (error.status) {
                case 401:
                    if (!toast.isActive("user-employee-taken"))
                        toast.error("El empleado ya tiene un usuario asociado", { toastId: "user-employee-taken" });
                    break;

                default:
                    if (!toast.isActive("user-error"))
                        toast.error("Error interno del servidor.", { toastId: "user-error" });
                    break;
            }
        }
    })

    useEffect(() => {
        getUser(id).then(response => {
            if (response.data) {
                if (formikRef.current)
                    formikRef.current.setValues(response.data);
                setUser(response.data);
            } else {
                toast.error("Error al cargar datos del usuario");
            }
        })

        if (type !== "SUPERADMIN") {
            getJobs().then(response => {
                if (response.data) {
                    setJobs(response.data);
                } else {
                    toast.error("Error al cargar los puestos");
                }
            })

            getDepartments().then(response => {
                if (response.data) {
                    setDepartments(response.data);
                } else {
                    toast.error("Error al cargar los depatamentos");
                }
            })
        }


    }, [refresh])

    const onSearchEmployee = () => {

        getEmployees({ key: keyEmployee }).then(response => {
            if (response.data) {
                const data = response.data;
                if (data.length === 1) {
                    toast.success("Empleado encontrado");
                    data[0].lastname = `${data[0].dad_lastname} ${data[0].mom_lastname}`
                    setEmployee(data[0])

                    const { email, firstname, lastname, job, department } = data[0];
                    if (formikRef.current) {
                        formikRef.current.setFieldValue("email", email)
                        formikRef.current.setFieldValue("firstname", firstname)
                        formikRef.current.setFieldValue("lastname", lastname)
                        formikRef.current.setFieldValue("job", job)
                        formikRef.current.setFieldValue("department", department)
                    }
                } else {
                    if (formikRef.current) {
                        formikRef.current.setFieldValue("email", null)
                        formikRef.current.setFieldValue("firstname", null)
                        formikRef.current.setFieldValue("lastname", null)
                        formikRef.current.setFieldValue("job", null)
                        formikRef.current.setFieldValue("department", null)
                    }
                    setEmployee(null)
                    toast.error("No se encontró el empleado");
                }
            } else {
                toast.error("Error del servidor");
            }

        });

    }

    const onSubmit = (values) => {
        for (let key in values)
            if (values[key] === "")
                values[key] = null

        const { firstname, lastname, username } = values
        if (!firstname || !lastname || !username) {
            toast.error("Rellene los campos que son obligatorios");
            return;
        }

        values.id = parseInt(sessionStorage.getItem("id"));
        callUpdate(values);
    }

    return (
        <View
            onSearchEmployee={onSearchEmployee}
            setKeyEmployee={setKeyEmployee}
            formikRef={formikRef}
            fileInputRef={fileInputRef}
            onSubmit={onSubmit}
            user={user}
            jobs={jobs}
            employee={employee}
            departments={departments}
        />
    )

}

export default Profile;