import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { useGetCall } from "utils/api";
import Loading from "helpers/Loading";
import { showResponseMessage } from "utils/message";
import { LoadingContext } from "helpers/LoadingContext";
import View from "components/crh/plannings/Details.view";

function Details({
	ref,
	theme,
  id,
	refresh,
  setIsVisible,
	addEditPlanningModal,
}) {

	const { loading, setLoading } = useContext(LoadingContext);

	const [planning, setPlanning] = useState({});
	const [refreshValue, setRefreshValue] = useState(null);
  const [filters, setFilters] = useState({});

	if (refresh !== refreshValue) {
		setRefreshValue(refresh);
	}

	const [callPlanning, reqRegimens] = useGetCall(`/plannings/${id}`, {
		onCompleted: (response) => {
			setLoading(false);
			setPlanning(response.data)
		},
		onError: (error) => {
			setLoading(false);
			showResponseMessage(error.status, "regimes", "regimens", "read");
		}
	})

	useEffect(() => {
		setLoading(true);
		callPlanning({ id });
	}, [refreshValue, id]);

  const onClose = () => {
    setIsVisible(false);
  }

	const onEdit = () => {
    onClose();
		addEditPlanningModal(id);
	}

	if (loading) return <Loading />

	return (
		<View 
			planning={planning} 
      filters={filters}
      setFilters={setFilters}
			addEditPlanningModal={addEditPlanningModal} 
			onEdit={onEdit} 
      onClose={onClose}
		/>
	);
}

export default Details;