import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useGetCall } from "utils/api";
import View from "components/geo/incidenceRequests/List.view";

function List() {

	const [incidencesRequests, setIncidencesRequests] = useState([]);

	const [callIncidenceRequests, reqIncidenceRequests] = useGetCall("/incidences_requests", {
		onCompleted: (response) => {
			setIncidencesRequests(response.data)
		},
		onError: (error) => {
		}
	})

	useEffect(() => {
		callIncidenceRequests({
            is_geo: true,
        });
	}, []);

	return (
		<View
			incidencesRequests={incidencesRequests}
		/>
	);
}

export default List;