import React, { useState, useEffect } from "react"
import { useGetCall } from "utils/api";
import { toast } from "react-toastify"
import View from "components/home/dashboard/Summary.view"
import { getJSON } from "utils/sessionStorage";

function Summary({ filters, setFilters, shifts, refreshValue, globalLoading, setGlobalLoading }) {

	const [loading, setLoading] = useState(false)
	const [employees, setEmployees] = useState(getJSON("meanEntryExitEmployees", {}))
	const [meanEntryExit, setMeanEntryExit] = useState(getJSON("meanEntryExit", {}))
	const [isEntryVisible, setIsEntryVisible] = useState(false)
	const [isExitVisible, setIsExitVisible] = useState(false)

	const [callDashboard, reqDashboard] = useGetCall("/statistics/summary_entry_exit", {
		onCompleted: (response) => {
			setEmployees(response?.data?.employees??{})
			setMeanEntryExit(response?.data?.summary??{})
			setLoading(false)
			setGlobalLoading({ ...globalLoading, summary: false })
		},
		onError: (error) => {
			setLoading(false);
			if (error.status === 403) {
				if (!toast.isActive("toast-read-xxxxx-unauthorized")) {
					toast.error("Error, no tienes los permisos para consultar xxxxxxxx", { toastId: "toast-read-xxxxx-unauthorized" })
				}
			} else {
				if (!toast.isActive("toast-xxxxx-error")) {
					toast.error("Error al obtener ", { toastId: "toast-xxxxx-error" })
				}
			}
		}
	})

	useEffect(() => {
		try{ 
			sessionStorage.setItem("meanEntryExit", JSON.stringify(meanEntryExit)) 
			sessionStorage.setItem("meanEntryExitEmployees", JSON.stringify(employees))
		}
		catch(e) { console.log(e) }
	}, [meanEntryExit, employees])

    useEffect(() => {
			setLoading(true)
			setGlobalLoading({ ...globalLoading, summary: true })
			callDashboard({ ...filters })
		}, [refreshValue])

	return (
		<View
			loading={loading}
			filters={filters}
			setFilters={setFilters}
			shifts={shifts}
			employees={employees}
			meanEntryExit={meanEntryExit}
			isEntryVisible={isEntryVisible}
			isExitVisible={isExitVisible}
			setIsEntryVisible={setIsEntryVisible}
			setIsExitVisible={setIsExitVisible}
		/>
	)

}

export default Summary