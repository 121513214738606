import React from "react";
import RequestForm from "components/crh/incidenceRequests/RequestForm";
import Details from "components/crh/incidenceRequests/Details";

function IncidencesView({
	formikRef,
	employee,
	setEmployee
}) {

	return (
		<div className="content container-fluid p-5">

			<div className="page-header mb-3">
				<div className="row align-items-end">
					<div className="col-sm mb-2 mb-sm-0">
						<h1 className="page-header-title">Solicitud de incidencias</h1>
					</div>
				</div>
			</div>

			<div class="row">

				<div class="col-md-6">
					<RequestForm
						formikRef={formikRef}
						refresh={Math.random()}
						setEmployee={setEmployee}
					/>
				</div>

				<div class="col-md-6">
					<Details 
						formikRef={formikRef}
						employee={employee}
						setEmployee={setEmployee}
					/>
				</div>

			</div>
		</div>
	);
}

export default IncidencesView;