import React from 'react';
import { Formik, Form, Field } from 'formik';
import "resources/styles/Incidences.scss";
import { getNameIncidence } from "utils/constants";

function FormView({
    filters,
    users,
    incidences,
    onSubmit
}) {

    return (
        <div className='container mt-3'>
            <Formik
                initialValues={filters}
                onSubmit={onSubmit}>
                {({ values, setFieldValues, resetForm }) =>
                    <Form>

                        {/* Date range */}
                        <div class="mb-3">
                            <div class="form-group text-center">
                                <div class="row">
                                    <div class="col-md-4">
                                        <h5 style={{ padding: "5px 0" }}>Fecha de incidencias</h5>
                                    </div>

                                    <div class="col-md-4">
                                        <Field type="date" name="start_date" class="form-control input__field" />
                                    </div>

                                    <div class="col-md-4">
                                        <Field type="date" name="end_date" class="form-control input__field" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Incidence date range
                        <div class="mb-3">
                            <div class="form-group text-center">
                                <div class="row">
                                    <div class="col-md-4">
                                        <h5 style={{ padding: "5px 0" }}>Fecha de incidencias</h5>
                                    </div>

                                    <div class="col-md-4">
                                        <Field type="date" name="start_date" class="form-control input__field" required value={values.start_date || ''} />
                                    </div>

                                    <div class="col-md-4">
                                        <Field type="date" name="end_date" class="form-control input__field" required value={values.end_date || ''} />
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        {/* Folio and id employee */}
                        <div class="mb-3">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-6">
                                        <label class="input">
                                            <Field type="text" name="folio" class="form-control input__field" placeholder=" " />
                                            <span class="input__label">Folio</span>
                                        </label>
                                    </div>
                                    <div class="col-md-6">
                                        <label class="input">
                                            <Field type="text" name="employee_id" class="form-control input__field" placeholder=" " />
                                            <span class="input__label">ID empleado</span>
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </div>

                        {/* Type Incidence and status */}
                        <div class="mb-3">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-6">
                                        <label class="input">
                                            <Field as="select" name="incidence" class="form-control input__field" placeholder=" ">
                                                <option value="">Selecciona una incidencia</option>
                                                {
                                                    incidences.map(incidence => {
                                                        return <option key={incidence.id} value={incidence.id}>
                                                            {incidence.description}</option>
                                                    })
                                                }
                                            </Field>
                                            <span class="input__label">Incidencia</span>
                                        </label>
                                    </div>

                                    <div class="col-md-6">
                                        <label class="input">
                                            <Field as="select" name="status_request" class="form-control input__field" placeholder=" ">
                                                <option value="">Selecciona un status</option>
                                                <option value="ACTIVE">Activa</option>
                                                <option value="CANCELED">Cancelada</option>
                                                <option value="AUTH_BOSS">Autorizada por jefe</option>
                                                <option value="AUTH_RH">Autorizada por RH</option>  
                                            </Field>
                                            <span class="input__label">Status</span>
                                        </label>
                                    </div>

                                </div>
                            </div>
                        </div>

                        {/* Request User */}
                        <div class="mb-3">
                            <div class="form-group">
                                <label class="input">
                                    <Field as="select" name="applicant_id" class="form-control input__field" placeholder=" ">
                                        <option value="">Seleccione un usuario</option>
                                        {
                                            users.map((user) => (
                                                <option key={user.id} value={user.id}>
                                                    {user.firstname + " " + user.lastname}
                                                </option>
                                            ))
                                        }
                                    </Field>
                                    <span class="input__label">Usuario solicitante</span>
                                </label>
                            </div>
                        </div>

                        {/* Auth Boss User */}
                        <div class="mb-3">
                            <div class="form-group">
                                <label class="input">
                                    <Field as="select" name="authorizer_boss_id" class="form-control input__field" placeholder=" ">
                                        <option value="">Seleccione un usuario</option>
                                        {
                                            users.filter(user => user.profile === "BOSS").map((user) => (
                                                <option key={user.id} value={user.id}>
                                                    {user.firstname + " " + user.lastname}
                                                </option>
                                            ))
                                        }
                                    </Field>
                                    <span class="input__label">Usuario Jefe que autorizó</span>
                                </label>
                            </div>
                        </div>

                        {/* Auth RH User */}
                        <div class="mb-3">
                            <div class="form-group">
                                <label class="input">
                                    <Field as="select" name="authorizer_rh_id" class="form-control input__field" placeholder=" ">
                                        <option value="">Seleccione un usuario</option>
                                        {
                                            users.filter(user => user.profile === "RH").map((user) => (
                                                <option key={user.id} value={user.id}>
                                                    {user.firstname + " " + user.lastname}
                                                </option>
                                            ))
                                        }
                                    </Field>
                                    <span class="input__label">Usuario RH que autorizó</span>
                                </label>
                            </div>
                        </div>

                        <div class="pt-3">
                            <button type="submit" class="btn btn-primary">
                                <i class="bi bi-plus" style={{ marginRight: "10px" }}></i>
                                Aceptar
                            </button>
                        </div>

                    </Form>
                }</Formik>

        </div >
    )
}

export default FormView;