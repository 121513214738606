import React, { useState, useEffect } from "react";
import { usePost } from "utils/api";
import { toast } from "react-toastify";
import { getAreas } from "api/areas";
import View from "components/crh/subareas/Form.view";

function FormSave({ setIsVisible, refresh }) {

    const [areas, setAreas] = useState([]);
    const [error, setError] = useState(null);
    const [reset, setReset] = useState(() => { });
    const [refreshValue, setRefreshValue] = useState(null);

    if (refresh !== refreshValue) {
        setRefreshValue(refresh);
    }

    const [callSave, reqSave] = usePost("/subareas", {
        onCompleted: () => {
            if (!toast.isActive("subarea-created"))
                toast.success("Subarea creada correctamente", { toastId: "subarea-created" });

            setIsVisible(false);
            reset();
        },
        onError: (err) => {
            console.error(err)
            if (err.status === 400) {
                if (!toast.isActive("subarea-error-400")) {
                    toast.error("Campos obligatorios requeridos", { toastId: "subarea-error-400" });
                }
            } else if (err.status === 403) {
                if (!toast.isActive("toast-create-subarea-unauthorized")) {
                    toast.error("Error, no tienes los permisos para crear subareas", { toastId: "toast-create-subarea-unauthorized" })
                }
            } else if (err.status === 409) {
                if (!toast.isActive("subarea-error-409")) {
                    toast.error("Ya existe una subarea con esa clave, por favor ingrese otra", { toastId: "subarea-error-409" });
                }
            } else {
                if (!toast.isActive("subarea-error-500")) {
                    toast.error("Error interno del servidor", { toastId: "subarea-error-500" });
                }
            }
        }
    })

    useEffect(() => {
        getAreas().then(response => {
            if (response.data)
                setAreas(response.data);
        });
    }, [refreshValue])

    const onSubmit = (values, { resetForm }) => {

        for (let key in values)
            if (values[key] === "")
                values[key] = null

        const { key, description, abbreviation } = values
        if (!key || !description || !abbreviation || !values.area || values.area.id == "") {
            toast.error("Todos los campos son obligatorios");
            return;
        }

        setReset(() => () => resetForm({}));
        callSave(values);

    }

    const onCancel = (resetForm) => {
        setIsVisible(false);
        resetForm({});
    }


    return (
        <View
            onSubmit={onSubmit}
            onCancel={onCancel}
            error={error}
            areas={areas}
            setIsVisible={setIsVisible}
            setError={setError}
        />
    )
}

export default FormSave;