import React, { useState } from 'react';
import FormSave from 'components/admin/enviroments/FormSave';
import FormSet from 'components/admin/enviroments/FormSet';
import AttendanceLoad from 'components/admin/enviroments/AttendanceLoad';
import View from "components/admin/enviroments/Enviroments.view";

function Enviroments({ setTabs }) {

    const [isVisibleAttendance, setIsVisibleAttendance] = useState(false);
    const [modalTitleAttendance, setModalTitleAttendance] = useState("");
    const [modalContentAttendance, setModalContentAttendance] = useState(null);
    
    const [isVisible, setIsVisible] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);

    const [isVisibleEdit, setIsVisibleEdit] = useState(false);
    const [modalTitleEdit, setModalTitleEdit] = useState("");
    const [modalContentEdit, setModalContentEdit] = useState(null);

    const [search, setSearch] = useState("");

    const addAttendanceModal = () => {
        setIsVisibleAttendance(true);
        setModalTitleAttendance("Cargar asistencia");
        setModalContentAttendance(
            <AttendanceLoad setIsVisible={setIsVisibleAttendance} />
        );
    }

    const addEnviromentModal = () => {
        setIsVisible(true);
        setModalTitle("Entorno");
        setModalContent(
            <FormSave setIsVisible={setIsVisible} />
        );
    }

    const addEditEnviromentModal = (id) => {

        setIsVisibleEdit(true);
        setModalTitleEdit("Editar entorno");
        setModalContentEdit(
            <FormSet
                id={id}
                refresh={Math.random()}
                setIsVisible={setIsVisibleEdit}
            />
        );
    }

    const onChangeSearch = (event) => {
        if (event.target.value === '') {
            setSearch(event.target.value);
        }
    }

    const handleKeyUp = (event) => {
        if (event.key === "Enter") {
            setSearch(event.target.value);
        }
    }

    return (
        <View
            search={search}
            onChangeSearch={onChangeSearch}
            handleKeyUp={handleKeyUp}
            setTabs={setTabs}
            isVisibleAttendance={isVisibleAttendance}
            setIsVisibleAttendance={setIsVisibleAttendance}
            modalTitleAttendance={modalTitleAttendance}
            setModalTitleAttendance={setModalTitleAttendance}
            modalContentAttendance={modalContentAttendance}
            setModalContentAttendance={setModalContentAttendance}
            isVisible={isVisible}
            setIsVisible={setIsVisible}
            modalTitle={modalTitle}
            modalContent={modalContent}
            addEnviromentModal={addEnviromentModal}
            addEditEnviromentModal={addEditEnviromentModal}
            modalTitleEdit={modalTitleEdit}
            isVisibleEdit={isVisibleEdit}
            setIsVisibleEdit={setIsVisibleEdit}
            modalContentEdit={modalContentEdit}
            addAttendanceModal={addAttendanceModal}
        />
    );
}

export default Enviroments;