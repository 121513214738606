import React, { useState, useEffect } from "react";
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import { showResponseMessage } from "utils/message";
import { useGetCall, usePost } from "utils/api";
import { toast } from "react-toastify";

const IncidenceRequest = ({
  employee,
  onSubmit,
  onClose
}) => {

  const incidencesWithHour = [
		"PERMISSION_RELEASE_EARLY",
		"PERMISSION_ARRIVE_LATE",
		"PERMISSION_LEAVE_HOURS",
		"HOURLY_VACATIONS"
	]

  const [selectedIncidence, setSelectedIncidence] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [startHour, setStartHour] = useState(null)
  const [endHour, setEndHour] = useState(null)
  const [device, setDevice] = useState(null)
  const [observations, setObservations] = useState(null)

  const [incidences, setIncidences] = useState([]);
  const [devices, setDevices] = useState([]);
  
  const format = 'HH:mm';

  const [callSaveIncidence, reqSaveIncidence] = usePost("/incidences_requests", {
		onCompleted: (data) => {

			if(data.data?.folio != null && data.data?.folio !== undefined) {
				if(!toast.isActive("incidence_request-duplicated"))
					toast.error(`Error, ya existe una solicitud de incidencia SI - ${data.data.folio} de ese tipo y en ese rango de fechas/horas`, { toastId: "incidence_request-duplicated" })
			} 
      else {

				if(!toast.isActive("incidence_request-created"))
					toast.success("Solicitud de incidencia creada correctamente", { toastId: "incidence_request-created" })

        onSubmit(data.data.created);

			}

		},
		onError: (err) => {
			console.error(err)
			showResponseMessage(err.status, "incidence_request", "solicitudes de incidencias", "create");
		}
	})

  const [callIncidences, reqIncidences] = useGetCall('/incidences', {
		onCompleted: (response) => {
			setIncidences(response.data);
		}, onError: (error) => {
			showResponseMessage(error.status, "incidences", "incidencias", "read")
		}
	})

    const [callDevices, reqDevices] = useGetCall('/devices', {
		onCompleted: (response) => {
			setDevices(response.data);
		}, onError: (error) => {
			showResponseMessage(error.status, "devices", "lectores", "read")
		}
	})

  useEffect(() => {
    callIncidences({ request_rh: true });
    callDevices();
  }, []);

  return <div className="container p-3" style={{ width: "700px" }}>

    {/* Incidence */}
    <div class="mb-3">
      <div class="form-group">
        <label class="input">
          <select 
            class="form-control input__field" 
            placeholder=" " 
            required 
            value={selectedIncidence ? selectedIncidence.id : ''}
            onChange={(e) => setSelectedIncidence(incidences.find(incidence => incidence.id === parseInt(e.target.value)))}
          >
            <option value="">Selecciona una incidencia</option>
            {
              incidences.map((incidence) =>
                <option key={incidence.id} value={incidence.id}>{incidence.description}</option>
              )}
          </select>
          <span class="input__label">
            Incidencia <span className='text-danger fw-bold'>*</span>
          </span>
        </label>
      </div>
    </div>

    {
      function () {
        if(selectedIncidence) {

          const incidence = incidences.find((item) => item.id === parseInt(selectedIncidence.id));
          if (!incidence) return <></>;

          const incidences_dates_hours = [
            "PERMISSION_ARRIVE_LATE",
            "PERMISSION_LEAVE_HOURS",
            "PERMISSION_RELEASE_EARLY",

          ];

          const incidences_dates = [
            "PERMISSION_WITHOUT_PAY",
            "JUSTIFY_ENTRY_OMISSION",
            "JUSTIFY_EXIT_OMISSION",
            "HOLIDAYS",
            "ECONOMIC_DAY",
            "FOUL",
            "RETARDMENT",
            "EARLY_DEPARTURE",
            "ENTRY_OMISSION",
            "EXIT_OMISSION",
            "EXTRA_TIME",
            "WORKED_HOLIDAYS",
            "WORKED_BANK_HOLIDAY",
            "VACATION_BONUS",
            "REST_DAY",
            "WORKED_REST_DAY",
            "BANK_HOLIDAY",
            "LICENSE",
            "PERMISSION_WITH_PAY"
          ];

          if (incidences_dates_hours.includes(incidence.type)) {
            return (<>
              <div class="mb-3">
                <div class="form-group text-center">
                  <div class="row">
                    <div class="col-md-4">
                      <h5 style={{ padding: "5px 0" }}>Rango de fechas<span className='text-danger fw-bold'>*</span></h5>
                    </div>

                    <div class="col-md-4">
                      <input 
                        type="date" 
                        name="start_date" 
                        class="form-control input__field" 
                        required 
                        value={startDate || ''} 
                        onChange={(e) => setStartDate(e.target.value)} 
                      />
                    </div>

                    <div class="col-md-4">
                      <input 
                        type="date" 
                        name="end_date" 
                        class="form-control input__field" 
                        required 
                        value={endDate || ''} 
                        onChange={(e) => setEndDate(e.target.value)} 
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="mb-3">
                <div class="form-group text-center">
                  <div class="row">
                    <div class="col-md-4">
                      <h5 style={{ padding: "5px 0" }}>Rango de horas<span className='text-danger fw-bold'>*</span></h5>
                    </div>

                    <div class="col-md-4">
                      <TimePicker format={format} onChange={time => setStartHour(time.format(format))} value={startHour ? dayjs(startHour, format) : ''} />
                    </div>

                    <div class="col-md-4">
                      <TimePicker format={format} onChange={time => setEndHour(time.format(format))} value={endHour ? dayjs(endHour, format) : ''} />
                    </div>
                  </div>
                </div>
              </div>
            </>);

          } 
          else if (incidences_dates.includes(incidence.type)) {
            return (
              <div class="mb-3">
                <div class="form-group text-center">
                  <div class="row">
                    <div class="col-md-4">
                      <h5 style={{ padding: "5px 0" }}>Rango de fechas<span className='text-danger fw-bold'>*</span></h5>
                    </div>
                    <div class="col-md-4">
                      <input 
                        type="date" 
                        name="start_date" 
                        class="form-control input__field" 
                        required 
                        value={startDate || ''} 
                        onChange={(e) => setStartDate(e.target.value)} 
                      />
                    </div>
                    <div class="col-md-4">
                      <input 
                        type="date" 
                        name="end_date" 
                        class="form-control input__field" 
                        required 
                        value={endDate || ''} 
                        onChange={(e) => setEndDate(e.target.value)} 
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          } 
          else if (incidence.type === 'INSERT_MARKINGS') {
            return (
              <>
                <div class="mb-3">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-2">
                        <h5 style={{ padding: "5px 0" }}>Fecha<span className='text-danger fw-bold'>*</span></h5>
                      </div>
                      <div class="col-md-4">
                        <input 
                          type="date" 
                          name="start_date" 
                          class="form-control input__field" 
                          required 
                          value={startDate || ''}
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </div>
                      <div class="col-md-2">
                        <h5 style={{ padding: "5px 0" }}>Hora<span className='text-danger fw-bold'>*</span></h5>
                      </div>
                      <div class="col-md-4">
                        <TimePicker style={{ width: "100%" }} format={format} onChange={time => setStartHour(time.format(format))} value={startHour ? dayjs(startHour, format) : ''} />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="mb-3">
                  <div class="form-group">
                    <label class="input">
                      <select
                        name="device.id" 
                        class="form-control input__field" 
                        placeholder=" " 
                        required 
                        value={device ? device.id : ''}
                        onChange={(e) => setDevice(devices.find(device => device.id === parseInt(e.target.value)))}
                      >
                        <option value="">Selecciona un lector</option>
                        {
                          devices.map((device) =>
                            <option key={device.id} value={device.id}>{device.description}</option>
                          )}
                      </select>
                      <span class="input__label">
                        Lector <span className='text-danger fw-bold'>*</span>
                      </span>
                    </label>
                  </div>
                </div>
              </>
            );
          } 
          else if (incidence.type === 'HOURLY_VACATIONS') {
            return (<>
              <div class="mb-3">
                <div class="form-group text-center">
                  <div class="row">
                    <div class="col-md-3">
                      <h5 style={{ padding: "5px 0" }}>Fecha inicial<span className='text-danger fw-bold'>*</span></h5>
                    </div>

                    <div class="col-md-3">
                      <input 
                        type="date" 
                        name="start_date" 
                        class="form-control input__field" 
                        required 
                        value={startDate || ''} 
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </div>

                    <div class="col-md-3">
                      <h5 style={{ padding: "5px 0" }}>Fecha final<span className='text-danger fw-bold'>*</span></h5>
                    </div>

                    <div class="col-md-3">
                      <input 
                        type="date" 
                        name="end_date" 
                        class="form-control input__field" 
                        required 
                        value={endDate || ''} 
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="mb-3">
                <div class="form-group text-center">
                  <div class="row">
                    <div class="col-md-3">
                      <h5 style={{ padding: "5px 0" }}>Hora inicial<span className='text-danger fw-bold'>*</span></h5>
                    </div>

                    <div class="col-md-3">
                      <TimePicker format={format} onChange={time => setStartHour(time.format(format))} value={startHour ? dayjs(startHour, format) : ''} />
                    </div>

                    <div class="col-md-3">
                      <h5 style={{ padding: "5px 0" }}>Hora final<span className='text-danger fw-bold'>*</span></h5>
                    </div>

                    <div class="col-md-3">
                      <TimePicker format={format} onChange={time => setEndHour(time.format(format))} value={endHour ? dayjs(endHour, format) : ''} />
                    </div>
                  </div>
                </div>
              </div>
            </>);

          }

        }
      }()
    }

    {/* Observations */}
    <div class="mb-3">
      <div class="form-group">
        <label class="input">
          <input 
            type="text" 
            name="observations" 
            class="form-control input__field" 
            placeholder=" " 
            value={observations || ''} 
            onChange={(e) => setObservations(e.target.value)}
          />
          <span class="input__label">
            Observaciones
          </span>
        </label>
      </div>
    </div>

    <div className="d-flex">
      <button
        className="btn btn-primary"
        onClick={() => {

          if(!selectedIncidence || selectedIncidence.id === '')
            return toast.error("Rellene los campos obligatorios")

          const incidenceType = incidences.find((item) => item.id === parseInt(selectedIncidence.id)).type;

          if(incidenceType === "INSERT_MARKINGS") {
            if(!startDate || !startHour || !device || device.id === '')
              return toast.error("Rellene los campos obligatorios")
          } 
          else if(incidencesWithHour.find(item => item === incidenceType)) {
            if(!endHour || !startHour || !startDate || !endDate)
              return toast.error("Los campos de fecha son obligatorios para esta incidencia")
          } 
          else {
            if(!startDate || !endDate)
              return toast.error("Los campos de fecha son obligatorios para esta incidencia")
		      }

          callSaveIncidence({
            employee: employee,
            incidence: selectedIncidence,
            start_date: startDate,
            end_date: endDate,
            start_hour: startHour,
            end_hour: endHour,
            device: device,
            observations: observations
          });

        }}
      >
        <i className="bi bi-plus me-2"></i>
        Guardar
      </button>

      <button
        className="btn btn-danger ms-3"
        onClick={onClose}
      >
        <i className="bi bi-x me-2"></i>
        Cerrar
      </button>

    </div>

  </div>

}

export default IncidenceRequest;