import React from "react";
import RequestsList from "components/crh/visits/requests/List";

function RequestsView({
	search,
	onChangeSearch,
	handleKeyUp,
}) {

	return (
		<>
			<div className="content container-fluid p-5">

				<div className="page-header mb-3">
					<div className="row align-items-end">

						<div className="col-sm mb-2 mb-sm-0">
							<h1 className="page-header-title">Solicitudes</h1>
						</div>

					</div>
				</div>

				<div class="card">
					<div class="card-header card-header-content-md-between">
						<div class="mb-2 mb-md-0">
							<div class="input-group input-group-merge input-group-flush" style={{ width: "50%" }}>
								<div class="input-group-prepend input-group-text" >
									<i class="bi-search"></i>
								</div>
								<input
									type="search"
									class="form-control"
									placeholder="Buscar solicitud"
									onChange={onChangeSearch}
									onKeyUp={handleKeyUp}
								/>
							</div>
						</div>
					</div>

					<RequestsList
						refresh={Math.random()}
						search={search}
					/>

				</div>
			</div>

		</>
	);
}

export default RequestsView;