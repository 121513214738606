import React, { useState, useEffect, useContext } from "react";
import { getComponent } from "utils/constants";
import View from "components/tabs/Tabs.view";
import { LoadingContext } from "helpers/LoadingContext";

function Tabs({ 
	activeTabId,
	setActiveTabId,
	theme, 
	button, 
	tabs, 
	setTabs 
}) {

	const [loadedTabs, setLoadedTabs] = useState([]);
	const { loading, setLoading } = useContext(LoadingContext);

	const onSelectTab = (selectedID) => {
		if(loading) return;
		setActiveTabId(selectedID);
	}

	const onCloseTab = (removedID) => {

		if(loading) return;

		let newTabs = [...tabs];
		newTabs = newTabs.filter((tab) => tab.id !== removedID);

		setTabs(newTabs);

	}

	useEffect(() => {

		let newTabs = [...loadedTabs];

		if(loadedTabs.length !== tabs.length) {
		
			const toAddTab = tabs.filter((tab) => !loadedTabs.map((loadedTab) => loadedTab.id).includes(tab.id));
			if(toAddTab.length !== 0) {
				for(let i = 0; i < toAddTab.length; i++)
					newTabs.push({
						id: toAddTab[i].id,
						name: toAddTab[i].content,
						component: getComponent(toAddTab[i].componentId, toAddTab[i].props)
					});
			}
	
			const toRemoveTab = loadedTabs.filter((loadedTab) => !tabs.map((tab) => tab.id).includes(loadedTab.id));
			if(toRemoveTab.length !== 0) {
				for(let i = 0; i < toRemoveTab.length; i++)
					newTabs = newTabs.filter((tab) => tab.id !== toRemoveTab[i].id);
			}
	
		}

		setLoadedTabs(newTabs);

	}, [tabs]);

	useEffect(() => {
		// find if activeTabId is in tabs
		const index = tabs.findIndex((tab) => tab.id === activeTabId);
		if(index === -1) {
			if(tabs.length > 0) {
				setActiveTabId(tabs[tabs.length - 1].id);
			}
		}
	}, [tabs]);

	return (
		<View
			activeTabId={activeTabId}
			theme={theme}
			tabs={tabs}
			button={button}
			onSelectTab={onSelectTab}
			onCloseTab={onCloseTab}
			loadedTabs={loadedTabs}
		/>
	);
}

export default Tabs;