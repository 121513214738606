import React from "react";
import BulkLoad from "components/crh/departments/BulkLoad";
import DepartmentList from "components/crh/departments/List";
import Modal from "helpers/Modal";

function DepartmentsView({
	search,
	create,
	load,
	onChangeSearch,
	handleKeyUp,
	isVisible,
	setIsVisible,
	modalTitle,
	modalContent,
	addModal,
	addEditModal,
	modalTitleEdit,
	isVisibleEdit,
	setIsVisibleEdit,
	modalContentEdit,
	setIsVisibleLoad,
	isVisibleLoad,
	addBulkLoadModal
}) {

	return (
		<>
			<div className="content container-fluid p-5">

				<div className="page-header mb-3">
					<div className="row align-items-end">

						<div className="col-sm mb-2 mb-sm-0">
							<h1 className="page-header-title">Departamentos</h1>
						</div>

						<div className="col-sm-auto">
							{load && (
								<button type="button" className="btn btn-primary" onClick={addBulkLoadModal} style={{ marginRight: "10px" }}>
									<i className="bi bi-cloud-arrow-up-fill" style={{ marginRight: "7px" }}></i>
									Carga masiva
								</button>
							)}
							{create && (
								<button type="button" className="btn btn-primary" onClick={addModal}>
									<i className="bi bi-plus-circle" style={{ marginRight: "7px" }}></i>
									Agregar departamento
								</button>
							)}
						</div>
					</div>
				</div>


				<div class="card">
					<div class="card-header card-header-content-md-between">
						<div class="mb-2 mb-md-0">
							<div class="input-group input-group-merge input-group-flush" style={{ width: "50%" }}>
								<div class="input-group-prepend input-group-text" >
									<i class="bi-search"></i>
								</div>
								<input
									type="search"
									class="form-control"
									placeholder="Buscar departamento"
									onChange={onChangeSearch}
									onKeyUp={handleKeyUp}
								/>
							</div>
						</div>
					</div>

					<DepartmentList
						refresh={Math.random()}
						search={search}
						addEditModal={addEditModal}
					/>

				</div>
			</div>

			<Modal
				title={modalTitle}
				isVisible={isVisible}
				setIsVisible={setIsVisible}
			>
				{modalContent}
			</Modal>

			<Modal
				title={modalTitleEdit}
				isVisible={isVisibleEdit}
				setIsVisible={setIsVisibleEdit}
			>
				{modalContentEdit}
			</Modal>

			<Modal
				title={"Carga masiva"}
				isVisible={isVisibleLoad}
				setIsVisible={setIsVisibleLoad}
			>
				<BulkLoad setIsVisible={setIsVisibleLoad} />
			</Modal>

		</>
	);
}

export default DepartmentsView;