import React, { useState, useEffect, useRef } from "react";
import { usePut } from "utils/api";
import { getCompany } from "api/companies";
import { toast } from "react-toastify";
import View from "components/admin/enviroments/Form.view";

function FormSet({ id, setIsVisible, refresh }) {

    const formikRef = useRef();
    const [error, setError] = useState(null);
    const [reset, setReset] = useState(() => { });
    const [company, setCompany] = useState({});
    const [refreshValue, setRefreshValue] = useState(null);

    if (refreshValue !== refresh) {
        setRefreshValue(refresh);
    }

    const [callUpdate, reqUpdate] = usePut("/companies", {
        onCompleted: () => {
            if(!toast.isActive("company-updated"))
                toast.success("Empresa actualizada", {toastId: "company-updated"});

            reset();
            setIsVisible(false);
        },
        onError: (err) => {
            console.error(err)
            setError("Error interno del servidor");

            if (!toast.isActive("company-error"))
                setError("Error interno del servidor", {toastId: "company-error"});
        }
    })

    useEffect(() => {
        getCompany(id).then((response) => {
            if (response.data) {
                if (formikRef.current)
                    formikRef.current.setValues(response.data);
                setCompany(response.data);
            }
        });
    }, [id, refreshValue])

    const onSubmit = (values) => {

        for(let key in values)
            if(values[key] === "")
                values[key] = null

        const {key, name, rfc} = values
        if(!key || !name || !rfc) {
            toast.error("Llene todos los campos obligatorios");
            return;
        }

        setReset(() => () => formikRef.current.resetForm({}));
        values.id = id;
        callUpdate(values);
        
    }

    const onCancel = (resetForm) => {
        setIsVisible(false);
        resetForm({});
    }

    return (
        <View
            formikRef={formikRef}
            error={error}
            edit={true}
            company={company}
            onSubmit={onSubmit}
            onCancel={onCancel}
        />
    )


}

export default FormSet;
