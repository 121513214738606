import React, { useState } from "react";
import { usePost } from "utils/api";
import { getOffices } from "api/offices";
import { toast } from "react-toastify";
import { showResponseMessage } from "utils/message";
import View from "components/crh/series/Form.view";
import { useEffect } from "react";

function FormSave({ refresh, setIsVisible }) {

    const [error, setError] = useState(null);
    const [reset, setReset] = useState(() => { });
    const [offices, setOffices] = useState([]);
    const [refreshValue, setRefreshValue] = useState(null);

    if (refreshValue !== refresh) {
        setRefreshValue(refresh);
    }

    const [callSave, reqSave] = usePost("/series", {
        onCompleted: () => {
            if (!toast.isActive("serie-created"))
                toast.success("Serie creada correctamente", { toastId: "serie-created" });

            setIsVisible(false);
            reset();
        },
        onError: (err) => {
            console.error(err)
            showResponseMessage(error.status, "series", "series", "create");
        }
    })

    useEffect(() => {
        getOffices().then(response => {
            if (response.data) {
                setOffices(response.data);
            } else {
                toast.error("Error al obtener las sucursales");
            }
        })
    }, [refreshValue])

    const onSubmit = (values, { resetForm }) => {

        for (let key in values)
            if (values[key] === "")
                values[key] = null

        const { serie, folio, description } = values
        if (!serie || !folio || !description) {
            toast.error("Todos los campos son obligatorios");
            return;
        }
        setReset(() => () => resetForm({}));
        callSave(values);
    }

    const onCancel = (resetForm) => {
        resetForm({});
        setIsVisible(false);
    }


    return (
        <View
            offices={offices}
            onSubmit={onSubmit}
            onCancel={onCancel}
            error={error}
            setIsVisible={setIsVisible}
            setError={setError}
        />
    )
}

export default FormSave;