const IS_PROD = true
const IS_TEST = false
const IS_HTTPS = false

const SERVER_PORT = IS_TEST ? "8001" : "8000"
const APP_PORT = IS_TEST ? "3001" : "3000"
const HOST = IS_PROD ? "controlhr.com.mx" : "localhost"

let APP_URL = `${IS_HTTPS ? "https" : "http"}://${HOST + (IS_TEST ? "/test" : IS_PROD ? "" : ":" + APP_PORT)}`;
let SERVER_URL =  `${IS_HTTPS ? "https" : "http"}://${HOST + (IS_TEST ? "/test" : IS_PROD ? "" : ":" + SERVER_PORT)}`;
let API_URL = SERVER_URL + `/api`;
let GA_KEY = `UA-000000000-0`;

export { SERVER_URL, API_URL, APP_URL, GA_KEY, IS_TEST };
