import React, { useState, useEffect } from "react"
import View from "components/crh/visits/attendanceLogs/Filters.view"
import { toast } from "react-toastify"
import { getDepartments } from "api/departments"
import { getDevices } from "api/devices"

function Filters({ filters, setFilters, hideModal, setLoading }) {

  const [jobs, setJobs] = useState([])
  const [departments, setDepartments] = useState([])
  const [devices, setDevices] = useState([])
  const [groups, setGroups] = useState([])
  const [payrolls, setPayrolls] = useState([])
  const [offices, setOffices] = useState([]);

  const onSubmit = (values) => {
    setFilters(values)
    setLoading(true)
    hideModal()
  }

  useEffect(() => {

    getDepartments().then((data) => {
      if (data.errors.length > 0) {
        if (!toast.isActive("toast-departments-eror"))
          toast.error("Error al obtener los departamentos", { toastId: "toast-departments-eror" })
      }
      else {
        setDepartments(data.data)
      }
    })

    getDevices().then((data) => {
      if (data.errors.length > 0) {
        if (!toast.isActive("toast-devices-eror"))
          toast.error("Error al obtener los dispositivos", { toastId: "toast-devices-eror" })
      }
      else {
        setDevices(data.data)
      }
    })

  }, [])

  return (
    <View
      offices={offices}
      jobs={jobs}
      departments={departments}
      devices={devices}
      groups={groups}
      payrolls={payrolls}
      filters={filters}
      setFilters={setFilters}
      onSubmit={onSubmit}
    />
  );

}

export default Filters;