import React, { useState } from "react";
import FormUsers from "components/crh/permissions/FormUsers";
import FormSave from "components/crh/permissions/FormSave";
import FormSet from "components/crh/permissions/FormSet";
import { fetchPost } from "utils/api";
import { toast } from "react-toastify";
import View from "components/crh/permissions/Permissions.view";

function Permissions() {

    const [isVisible, setIsVisible] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);

    const [isVisibleEdit, setIsVisibleEdit] = useState(false);
    const [modalTitleEdit, setModalTitleEdit] = useState("");
    const [modalContentEdit, setModalContentEdit] = useState(null);

    const [isVisibleUsers, setIsVisibleUsers] = useState(false);
    const [modalTitleUsers, setModalTitleUsers] = useState("");
    const [modalContentUsers, setModalContentUsers] = useState(null);

    const [search, setSearch] = useState("");

    const addModal = () => {
        fetchPost("/permissions/check", { table: "PERMISSION", permission: "create" }).then((response) => {
            if (response.data) {
                const data = response.data;
                if (data.isAllowed === true) {
                    setIsVisible(true);
                    setModalTitle("Perfil de permisos");
                    setModalContent(
                        <FormSave setIsVisible={setIsVisible} />
                    );
                } else {
                    if (!toast.isActive("toast-create-permissions-unauthorized")) {
                        toast.error("Error, no tienes los permisos para crear permisos", { toastId: "toast-create-permissions-unauthorized" })
                    }
                }
            }
        })
    }

    const addEditModal = (id) => {
        fetchPost("/permissions/check", { table: "PERMISSION", permission: "edit" }).then((response) => {
            if (response.data) {
                const data = response.data;
                if (data.isAllowed === true) {
                    setIsVisibleEdit(true);
                    setModalTitleEdit("Editar perfil de permisos");
                    setModalContentEdit(
                        <FormSet
                            id={id}
                            refresh={Math.random()}
                            setIsVisible={setIsVisibleEdit}
                        />
                    );
                } else {
                    if (!toast.isActive("toast-edit-permissions-unauthorized")) {
                        toast.error("Error, no tienes los permisos para editar permisos", { toastId: "toast-edit-permissions-unauthorized" })
                    }
                }
            }
        })
    }

    const addUserModel = (id) => {
        fetchPost("/permissions/check", { table: "PERMISSION", permission: "edit" }).then((response) => {
            if (response.data) {
                const data = response.data;
                if (data.isAllowed === true) {
                    setIsVisibleUsers(true);
                    setModalTitleUsers("Asignar usuarios");
                    setModalContentUsers(
                        <FormUsers
                            id={id}
                            refresh={Math.random()}
                            setIsVisible={setIsVisibleUsers}
                        />
                    );
                } else {
                    if (!toast.isActive("toast-assign-permissions-unauthorized")) {
                        toast.error("Error, no tienes los permisos para asignar/editar permisos", { toastId: "toast-assign-permissions-unauthorized" })
                    }
                }
            }
        })
    }

    const onChangeSearch = (event) => {
        if (event.target.value === '') {
            setSearch(event.target.value);
        }
    }

    const handleKeyUp = (event) => {
        if (event.key === "Enter") {
            setSearch(event.target.value);
        }
    }

    return (
        <View
            search={search}
            addUserModel={addUserModel}
            onChangeSearch={onChangeSearch}
            handleKeyUp={handleKeyUp}
            isVisible={isVisible}
            setIsVisible={setIsVisible}
            modalTitle={modalTitle}
            modalContent={modalContent}
            addModal={addModal}
            addEditModal={addEditModal}
            modalTitleEdit={modalTitleEdit}
            isVisibleEdit={isVisibleEdit}
            setIsVisibleEdit={setIsVisibleEdit}
            modalContentEdit={modalContentEdit}
            isVisibleUsers={isVisibleUsers}
            modalTitleUsers={modalTitleUsers}
            modalContentUsers={modalContentUsers}
            setIsVisibleUsers={setIsVisibleUsers}
        />
    );
}

export default Permissions;