import React from "react"

function GroupsListView({ groups = [], onDelete, onEdit, onDetail, theme }) {
	return (
		<div class={"table-responsive datatable-custom position-relative"}>
			<div class="dataTables_wrapper no-footer pt-2 pl-3 pr-3" style={{maxHeight: "60vh"}}>
				<table class="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
					<thead>
						<tr role="row">
							<th>Clave</th>
							<th>Nombre de grupo</th>
							<th>Correo</th>
							<th>Tipo de grupo</th>
							<th>Acciones</th>
						</tr>
					</thead>
					<tbody>
						{
							groups.map((group) => (
								<tr key={group.id} role="row">
									<td>
										{group.key}
									</td>
									<td>
										{group.description}
									</td>
									<td>
										{
											group.group_email 
											? group.group_email.split(';').map((email, index) => 
												<span className='badge bg-primary me-1'>
												{email}
												</span>
											)
											: ''
										}
									</td>
									<td>
										{group.type === "EMPLOYEE" ? "Grupo de empleados" : "Grupo de grupos"}
									</td>
									<td>
										<button className="btn btn-primary btn-sm" style={{ marginRight: "10px" }} onClick={() => onDetail(group.id)}>
											<i class="bi bi-eye"></i>
										</button>
										<button className="btn btn-warning btn-sm" style={{ marginRight: "10px" }} onClick={() => onEdit(group.id)}>
											<i class="bi bi-pencil"></i>
										</button>
										<button className="btn btn-danger btn-sm" onClick={() => onDelete(group.id)}>
											<i class="bi bi-trash"></i>
										</button>
									</td>

								</tr>
							))
						}
					</tbody>
				</table>
			</div>
		</div>
	)
}

export default GroupsListView