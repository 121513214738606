import React from "react";

const getVideo = (resource) => {
    if (resource === "SCHEDULES") {
        return "assets/img/resources/schedule.mp4";
    } else if (resource === "HOLIDAYS") {
        return "assets/img/resources/holidays.mp4";
    } else if (resource === "SHIFTS") {
        return "assets/img/resources/shifts.mp4";
    } else if (resource === "EXTRA_TIME") {
        return "assets/img/resources/extra_time.mp4";
    } else if (resource === "ATTENDANCE") {
        return "assets/img/resources/attendance.mp4";
    }
    return "";
}

const getIcon = (resource) => {
    if (resource === "SCHEDULES") {
        return "assets/img/resources/schedule.svg";
    } else if (resource === "HOLIDAYS") {
        return "assets/img/resources/holidays.svg";
    } else if (resource === "SHIFTS") {
        return "assets/img/resources/shifts.svg";
    } else if (resource === "EXTRA_TIME") {
        return "assets/img/resources/extra_time.svg";
    } else if (resource === "ATTENDANCE") {
        return "assets/img/resources/attendance.svg";
    }
    return "";
}

const getHeader = (resource) => {
    if (resource === "SCHEDULES") {
        return <>Control de horario de trabajadores</>;
    } else if (resource === "HOLIDAYS") {
        return <>Control de vacaciones y ausencias</>;
    } else if (resource === "SHIFTS") {
        return <>Gestión de turnos</>;
    } else if (resource === "EXTRA_TIME") {
        return <>Gestión de horas extras</>;
    } else if (resource === "ATTENDANCE") {
        return <>Control de asistencia y acceso</>;
    }
    return "";
}

const getTitle = (resource) => {
    if (resource === "SCHEDULES") {
        return "Optimiza tu control de horarios y simplifica la gestión";
    } else if (resource === "HOLIDAYS") {
        return "Simplifica la gestión de vacaciones y ausencias del personal";
    } else if (resource === "SHIFTS") {
        return "Planificación de turnos y cuadrante de trabajo";
    } else if (resource === "EXTRA_TIME") {
        return "Eficiencia en la gestión de horas extra y descanso";
    } else if (resource === "ATTENDANCE") {
        return "Gestión Integral de Asistencia y Acceso";
    }
    return "";
}

const getBody = (resource) => {

    if (resource === "SCHEDULES") {
        return (
            <p>Centraliza y simplifica el control de horarios de los empleados con una solución intuitiva y fácil de usar.
                Se premite el registro preciso y en tiempo real de las horas de entrada y salida, tanto en modalidad online
                como presencial. La automatización elimina la necesidad de utilizar métodos tradicionales, ahorrando tiempo
                y esfuerzo.</p>
        )
    } else if (resource === "HOLIDAYS") {
        return (
            <>
                <p>Autogestión efectiva para el control de vacaciones y ausencias. Con una interfaz fácil de usar podrás tener un registro claro
                    y organizado de las solicitudes de tiempo libre y ausencias del personal.</p>
                <p>Descubre cómo simplificar y mejorar la gestión de vacaciones y ausencias y así, ahorrar tiempo y esfuerzo.</p>
            </>
        )
    } else if (resource === "SHIFTS") {
        return (
            <>
                <p>Optimiza la planificación de turnos en tu empresa con la creación de turnos rotativos y cuadrantes de trabajos.
                    Garantiza una planificación sencilla y eficiente para los empleados.</p>
                <p>Crea rápidamente turnos rotativos adaptados a las necesidades de la empresa para ahorrar tiempo y reducir errores.</p>
            </>
        )
    } else if (resource === "EXTRA_TIME") {
        return (
            <p>Simplifica la gestión de horas extra y descanso en tu empresa con una solución especializada. Nuestra herramienta te permite
                gestionar de manera eficiente las horas trabajadas por encima de la jornada regular y garantizar el adecuado descanso de tus
                empleados.</p>
        )
    } else if (resource === "ATTENDANCE") {
        return (
            <>
                <p>Experimenta una gestion de asistencia y acceso simplificada con nuestra solución, permitiéndote tener un control completo
                    y un seguimiento fácil del horario de trabajo de tus empleados.</p>
                <p>Descubre una única aplicación multifuncional diseñada para controlar las horas de trabajo, las entradas y salidas de tus empleados.</p>
            </>
        )
    }

    return "";
}

const getItems = (resource) => {

    if (resource === "SCHEDULES") {
        return ["Se registran las horas de entrada y salida con precisión y en tiempo real.",
            "Se pueden configurar horarios personalizados y gestionar cambios de manera flexible.",
            "Se reciben notificaciones inmediatas sobre ausencias y retrasos.",
            "Se generan informes detallados para un seguimiento exhaustivo del desempeño.",];
    } else if (resource === "HOLIDAYS") {
        return ["Mantén un equilibrio actualizado de días libres utilizados y restantes.",
            "Accede a un calendario anual de vacaciones por empleado y equipo.",
            "Recibe notificaciones automáticas de solicitudes y aprobaciones.",
            "Gestiona de forma sencilla los justificantes de ausencias."];
    } else if (resource === "SHIFTS") {
        return ["Crea turnos rotativos de forma fácil y personalizada.",
            "Genera cuadrantes de trabajo automáticamente y de manera eficiente.",
            "Ajusta los horarios según las necesidades del negocio de manera sencilla.",
            "Visualiza y coordina los turnos de los empleados de manera clara y organizada."];
    } else if (resource === "EXTRA_TIME") {
        return ["Registra y controla las horas extra realizadas por los empleados de forma precisa.",
            "Planifica y asigna los periodos de descanso necesarios para cumplir con la legislación laboral.",
            "Supervisa y gestiona los límites de horas trabajadas para evitar fatiga y asegurar el bienestar del personal."];
    } else if (resource === "ATTENDANCE") {
        return ["Registra y supervisa el horario de trabajo de manera eficiente y precisa.",
            "Controla las entradas y salidas de los empleados de forma sencilla.",
            "Accede y gestiona la información desde cualquier lugar y dispositivo.",
            "Personaliza la aplicación según las necesidades específicas de tu empresa.",];
    }

    return [];
}


function ResourceView({ onClose, resource }) {

    return (
        <div className='container mt-3' id="contact_form" style={{ padding: "0 2rem 2rem" }}>

            <div className="d-flex flex-row-reverse">
                <i class="bi bi-x-circle" style={{ fontSize: "2rem", cursor: "pointer", color: "#1aa9d1" }}
                    onClick={onClose}></i>
            </div>

            <div className="row w-100">

                <div className="col-md-7">

                    <div className="card h-100 w-100"
                        style={{ border: "0", display: "flex", justifyContent: "center", alignItems: "center" }}>

                        <div className="d-flex">
                            <img src={getIcon(resource)} alt="icon" style={{ marginRight: "2rem" }} />
                            <h3>{getHeader(resource)}</h3>
                        </div>

                        <div className="my-4">
                            <video src={getVideo(resource)} class="w-100" autoPlay controls muted></video>
                        </div>

                    </div>


                </div>

                <div className="col-md-5">
                    <h4 className="mb-5" style={{ color: "#081b21" }}>
                        {getTitle(resource)}
                    </h4>

                    <p className="fs-6" style={{ textAlign: "justify" }}>{getBody(resource)}</p>

                    {
                        getItems(resource).map((item, idx) => (
                            <div className="d-flex" key={idx}>
                                <i class="bi bi-check-circle text-success" style={{ marginRight: "0.8rem" }} />
                                <p className="fs-6">{item}</p>
                            </div>
                        ))
                    }

                </div>

            </div>



        </div >
    );
}

export default ResourceView;