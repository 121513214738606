import React, { useRef, useState, useEffect } from "react";
import FormSave from "components/crh/payrollPeriods/FormSave";
import FormSet from "components/crh/payrollPeriods/FormSet";
import { fetchPost } from "utils/api";
import { toast } from "react-toastify";
import { getUser } from "api/users";
import View from "components/crh/payrollPeriods/PayrollPeriods.view";

function PayrollPeriods() {

  const id = sessionStorage.getItem("id");
  const [user, setUser] = useState({});
  const company = user.company;

  const [isVisible, setIsVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [isVisibleEdit, setIsVisibleEdit] = useState(false);
  const [modalTitleEdit, setModalTitleEdit] = useState("");
  const [modalContentEdit, setModalContentEdit] = useState(null);

  const [excel, setExcel] = useState(false);
  const [read, setRead] = useState(false);
  const [load, setLoad] = useState(false);
  const [create, setCreate] = useState(false);

  const [search, setSearch] = useState("");
  const [isVisibleLoad, setIsVisibleLoad] = useState(false);

  const addModal = () => {
    fetchPost("/payroll_periods/check_permission", { type: "create" }).then((response) => {
      if (response.data) {
        const data = response.data;
        if (data.isAllowed === true) {
          setIsVisible(true);
          setModalTitle("Periodo de nómina");
          setModalContent(
            <FormSave setIsVisible={setIsVisible} refresh={Math.random()} />
          );
        } else {
          if (!toast.isActive("toast-create-payrollperiod-unauthorized")) {
            toast.error("Error, no tienes los permisos para crear periodos de nómina", { toastId: "toast-create-payrollperiod-unauthorized" })
          }
        }
      }
    })
  }

  const addEditModal = (id) => {
    fetchPost("/payroll_periods/check_permission", { type: "edit" }).then((response) => {
      if (response.data) {
        const data = response.data;
        if (data.isAllowed === true) {
          setIsVisibleEdit(true);
          setModalTitleEdit("Editar periodo de nómina");
          setModalContentEdit(
            <FormSet
              id={id}
              refresh={Math.random()}
              setIsVisible={setIsVisibleEdit}
            />
          );
        } else {
          if (!toast.isActive("toast-edit-payrollperiod-unauthorized")) {
            toast.error("Error, no tienes los permisos para editar periodos de nómina", { toastId: "toast-edit-payrollperiod-unauthorized" })
          }
        }
      }
    })
  }

  const onChangeSearch = (event) => {
    if (event.target.value === '') {
      setSearch(event.target.value);
    }
  }

  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      setSearch(event.target.value);
    }
  }

  const addBulkLoadModal = () => {
    fetchPost("/employees/check_permission", { type: "load" }).then((response) => {
      if (response.data) {
        const data = response.data;
        if (data.isAllowed === true) {
          setIsVisibleLoad(true);
        } else {
          if (!toast.isActive("toast-load-employee-unauthorized")) {
            toast.error("Error, no tienes los permisos para hacer carga masiva empleados", { toastId: "toast-load-employee-unauthorized" })
          }
        }
      }
    })
  }

  useEffect(() => {

    getUser(id).then((response) => {
      setUser(response.data)
    });

    fetchPost("/permissions/check", { table: "PAYROLL_PERIOD", permission: "create" }).then((response) => {
      if (response.data) {
        const data = response.data;
        if (data.isAllowed === true) {
          setCreate(true);
        }
      }
    });

    fetchPost("/permissions/check", { table: "PAYROLL_PERIOD", permission: "load" }).then((response) => {
      if (response.data) {
        const data = response.data;
        if (data.isAllowed === true) {
          setLoad(true);
        }
      }
    });

    fetchPost("/permissions/check", { table: "PAYROLL_PERIOD", permission: "read" }).then((response) => {
      if (response.data) {
        const data = response.data;
        if (data.isAllowed === true) {
          setRead(true);
        }
      }
    });

  }, [id]);

  return (
    <View
      company={company}
      search={search}
      excel={excel}
      read={read}
      load={load}
      create={create}
      onChangeSearch={onChangeSearch}
      handleKeyUp={handleKeyUp}
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      modalTitle={modalTitle}
      modalContent={modalContent}
      addModal={addModal}
      addEditModal={addEditModal}
      modalTitleEdit={modalTitleEdit}
      isVisibleEdit={isVisibleEdit}
      setIsVisibleEdit={setIsVisibleEdit}
      modalContentEdit={modalContentEdit}
      isVisibleLoad={isVisibleLoad}
      setIsVisibleLoad={setIsVisibleLoad}
      addBulkLoadModal={addBulkLoadModal}
    />
  );
}

export default PayrollPeriods;