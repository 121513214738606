import React from 'react';
import { Formik, Form, Field } from 'formik';

function FormView({ formikRef = () => { }, edit = false, user = {}, onSubmit, onCancel }) {

	return (
		<div className='container mt-3'>

			<Formik
				innerRef={formikRef}
				initialValues={user}
				onSubmit={onSubmit}>
				{({ values, setFieldValue, resetForm }) =>

					<Form>

						<div class="mb-3 d-flex align-items-center" style={{ gap: "10px" }}>

							{/* Username */}
							<div class="form-group w-50 mr-5">
								<label class="input">
									<Field type="text" name="username" class="form-control input__field" placeholder=" " required value={values.username || ""}/>
									<span class="input__label">
										Usuario <span className='text-danger fw-bold'>*</span>
									</span>
								</label>
							</div>

							{/* Email */}
							<div class="form-group w-50">
								<label class="input">
									<Field type="email" name="email" class="form-control input__field" placeholder=" " value={values.email || ""}/>
									<span class="input__label">Correo</span>
								</label>
							</div>

						</div>

						<div class="mb-3 d-flex align-items-center" style={{ gap: "10px" }}>

							{/* User's name  */}
							<div class="w-50">
								<div class="form-group">
									<label class="input">
										<Field type="text" name="firstname" class="form-control input__field" placeholder=" " required value={values.firstname || ""}/>
										<span class="input__label">
											Nombre(s) del usuario <span className='text-danger fw-bold'>*</span>
										</span>
									</label>
								</div>
							</div>

							{/* User's lastname  */}
							<div class="w-50">
								<div class="form-group">
									<label class="input">
										<Field type="text" name="lastname" class="form-control input__field" placeholder=" " required value={values.lastname || ""}/>
										<span class="input__label">
											Apellidos del usuario <span className='text-danger fw-bold'>*</span>
										</span>
									</label>
								</div>
							</div>

						</div>

						{/* User's password  */}
						<div class="mb-3">
							<div class="form-group">
								<label class="input">
									<Field type="password" name="password" class="form-control input__field" placeholder=" " required={!edit} value={values.password || ""}/>
									<span class="input__label">
										Contraseña {!edit && (<span className='text-danger fw-bold'>*</span>)}
									</span>
								</label>
							</div>
						</div>

						<div class="pt-3">
							<button type="submit" class="btn btn-primary">
								<i class="bi bi-plus" style={{ marginRight: "10px" }}></i>
								{edit ? "Editar super usuario" : "Guardar super usuario"}
							</button>

							<button type="button" class="btn btn-danger" style={{ marginLeft: "20px" }} onClick={onCancel.bind(null, resetForm)}>
								<i class="bi bi-x" style={{ marginRight: "10px" }}></i>
								Cancelar
							</button>
						</div>

					</Form>
				}</Formik>

		</div>
	)
}

export default FormView;