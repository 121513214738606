import React, { useState, useRef, useEffect } from "react";
import View from "components/crh/visits/visitRequests/Requests.view";
import { fetchPost } from "utils/api";
import { toast } from "react-toastify";
import FormSave from "components/crh/visits/visitRequests/FormSave";
import FormSet from "components/crh/visits/visitRequests/FormSet";
import Filters from "components/crh/visits/visitRequests/Filters";
import moment from "moment";
import { downloadExcel } from "utils/files";
import { getUser } from "api/users";

function VisitRequests() {

  const id = sessionStorage.getItem("id");
  const requestsListRef = useRef(null);
  const [user, setUser] = useState({});
  const [search, setSearch] = useState("");

  const [isVisible, setIsVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [isVisibleEdit, setIsVisibleEdit] = useState(false);
  const [modalTitleEdit, setModalTitleEdit] = useState("");
  const [modalContentEdit, setModalContentEdit] = useState(null);

  const [isVisibleFilter, setIsVisibleFilter] = useState(false);
  const [modalTitleFilter, setModalTitleFilter] = useState("");
  const [modalContentFilter, setModalContentFilter] = useState(null);

  const [excel, setExcel] = useState(false);
  const [read, setRead] = useState(false);
  const [create, setCreate] = useState(false);

  const [filters, setFilters] = useState({
    profile_id: null,
    start_date: moment().startOf("day").format("YYYY-MM-DD"),
    end_date: moment().endOf("day").format("YYYY-MM-DD"),
    start_hour: "00:00",
    end_hour: "23:59",
    active: true,
  });

  const onChangeSearch = (event) => {
    if (event.target.value === '') {
      setSearch(event.target.value);
    }
  }

  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      setSearch(event.target.value);
    }
  }

  const addCreateModal = () => {
    fetchPost("/permissions/check", { table: "VISIT_REGISTER", permission: "create" }).then((response) => {
      if (response.data) {
        const data = response.data;
        if (data.isAllowed === true) {
          setIsVisible(true);
          setModalTitle("Agregar visita");
          setModalContent(
            <FormSave 
              setIsVisible={setIsVisible} 
              refresh={Math.random()} 
            />
          );
        } else {
          if (!toast.isActive("toast-create-profile-unauthorized")) {
            toast.error("Error, no tienes los permisos para crear visitas", { toastId: "toast-create-visit-unauthorized" })
          }
        }
      }
    })
  }

  const addEditModal = (id) => {
    fetchPost("/permissions/check", { table: "VISIT_REGISTER", permission: "edit" }).then((response) => {
      if (response.data) {
        const data = response.data;
        if (data.isAllowed === true) {
          setIsVisibleEdit(true);
          setModalTitleEdit("Editar perfil");
          setModalContentEdit(
            <FormSet
              id={id}
              refresh={Math.random()}
              setIsVisible={setIsVisibleEdit}
            />
          );
        } else {
          if (!toast.isActive("toast-edit-profile-unauthorized")) {
            toast.error("Error, no tienes los permisos para editar visitas", { toastId: "toast-edit-visit-unauthorized" })
          }
        }
      }
    })
  }

  const addFilterModal = () => {
    setIsVisibleFilter(true);
    setModalTitleFilter("Filtros de búsqueda");
    setModalContentFilter(
      <Filters
        hideModal={() => setIsVisibleFilter(false)}
        filters={filters}
        setFilters={setFilters}
      />
    );
  }

  const onDownloadXlsx = async () => {

    fetchPost("/visit_requests/check_permission", { type: "excel" }).then((response) => {
      if (response.data) {
        const data = response.data;
        if (data.isAllowed === true) {
          const cells = requestsListRef.current.generateCells();
          downloadExcel({
            user,
            cells: cells,
            report: "visitas",
            start_date: filters.start_date,
            end_date: filters.end_date,
          });
          return;
        }
      }

      if (!toast.isActive("toast-excel-employees-unauthorized")) {
        toast.error("Error, no tienes los permisos para descargar el excel", { toastId: "toast-excel-employees-unauthorized" })
      }

    })

  }

  useEffect(() => {
    getUser(id).then((response) => {
      setUser(response.data)
    });

    fetchPost("/permissions/check", { table: "VISIT_REQUEST", permission: "create" }).then((response) => {
      if (response.data) {
        const data = response.data;
        if (data.isAllowed === true) {
          setCreate(true);
        }
      }
    })

    fetchPost("/permissions/check", { table: "VISIT_REQUEST", permission: "read" }).then((response) => {
      if (response.data) {
        const data = response.data;
        if (data.isAllowed === true) {
          setRead(true);
        }
      }
    })

    fetchPost("/permissions/check", { table: "VISIT_REQUEST", permission: "excel" }).then((response) => {
      if (response.data) {
        const data = response.data;
        if (data.isAllowed === true) {
          setExcel(true);
        }
      }
    })

  }, [id]);

  return (
    <View
      requestsListRef={requestsListRef}
      excel={excel}
      read={read}
      create={create}
      filters={filters}
      search={search}
      handleKeyUp={handleKeyUp}
      onChangeSearch={onChangeSearch}
      addCreateModal={addCreateModal}
      isVisible={isVisible}
      modalTitle={modalTitle}
      modalContent={modalContent}
      addEditModal={addEditModal}
      setIsVisibleEdit={setIsVisibleEdit}
      isVisibleEdit={isVisibleEdit}
      modalTitleEdit={modalTitleEdit}
      modalContentEdit={modalContentEdit}
      setIsVisible={setIsVisible}
      isVisibleFilter={isVisibleFilter}
      addFilterModal={addFilterModal}
      modalTitleFilter={modalTitleFilter}
      modalContentFilter={modalContentFilter}
      setIsVisibleFilter={setIsVisibleFilter}
      onDownloadXlsx={onDownloadXlsx}
    />
  );
}

export default VisitRequests;